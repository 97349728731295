import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import floorplanQueryApi from "@/data/api/floorplans";
import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const ParamsSchema = z.object({
  siteId: z.string(),
});

export default async function teamFloorplanLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const parsedParams = await ParamsSchema.parseAsync(params);
  const { siteId } = parsedParams;

  const account = await authenticate(request);

  if (!account) {
    return parsedParams;
  }

  queryClient.prefetchQuery(teamQueryApi.getTeam(siteId));
  queryClient.prefetchQuery(floorplanQueryApi.getFloorplansByTeam(siteId));

  return parsedParams;
}

export type TeamFloorplanLoaderReturnType = Awaited<
  ReturnType<typeof teamFloorplanLoader>
>;

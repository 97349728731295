import type { LoaderFunctionArgs } from "react-router-dom";

import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function rootGalleryLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { siteId } = params;
  const isVirtualTeam = siteId === "private" || siteId === "all";

  if (!siteId) {
    throw new Response("No Team Id Specified", { status: 400 });
  }

  const account = await authenticate(request);

  if (!account) {
    return { id: siteId, isVirtualTeam };
  }

  queryClient.prefetchQuery(teamQueryApi.getTeam(siteId));

  return {
    id: siteId,
    isVirtualTeam,
  };
}

export type RootGalleryLoaderReturnType = Awaited<
  ReturnType<typeof rootGalleryLoader>
>;

// useBoolState is a custom hook in response to a very common pattern we use;
// e.g.
//      const [showModal, setShowModal] = useState<boolean>(false);
//      ...
//      <BvButton onPress={()=>setShowModal(true)}>Show modal</BvButton>
// This hook wraps the update operations to reduce rerenders.

import { useCallback, useState } from "react";

/**
 * Wrapper for useState hook which optimises boolean operations
 * @param initialState Initial state of boolean
 * @returns tuple of [value:boolean,setStateToTrue:()=>void,setStateToFalse:()=>void]
 */
export default function useBoolState(
  initialState: boolean,
): [value: boolean, setStateToTrue: () => void, setStateToFalse: () => void] {
  const [val, setVal] = useState<boolean>(initialState);
  return [
    val,
    useCallback(() => setVal(true), []),
    useCallback(() => setVal(false), []),
  ];
}

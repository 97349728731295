import { useMutation } from "@tanstack/react-query";

import accountApi from "@/data/api/accounts";
import { useLogout } from "@/features/auth";

export default function useDeleteMyAccount() {
  const logout = useLogout();
  return useMutation({
    ...accountApi.deleteMe,
    onSuccess: () => {
      logout();
    },
  });
}

import type { LoaderFunctionArgs } from "react-router-dom";

import connectApi from "@/data/api/connect";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function techSupportIntegrationLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { integrationId } = params;

  if (!integrationId)
    throw new Response("No Integration Id Specified", { status: 400 });

  const account = await authenticate(request);

  if (!account) return { integrationId };

  queryClient.prefetchQuery({
    ...connectApi.auditIntegrationsGraphId(integrationId, {}),
  });
  queryClient.prefetchQuery({
    ...connectApi.auditIntegrationsLogId(integrationId, {}),
  });

  return { integrationId };
}

export type TechSupportIntegrationLoaderReturnType = Awaited<
  ReturnType<typeof techSupportIntegrationLoader>
>;

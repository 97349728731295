import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";
import type {
  AccountAliasFinishDto,
  AccountAliasStartDto,
  AccountsSettingsDto,
  DomainAccessHintPostDto,
  EmailAliasPostDto,
} from "@/api/generated";

import { cancelableQuery, mutationOptions } from "./helpers";

type TContext = {
  organisation?: string;
  project?: string;
  team?: string;
};

export const accountKeys = {
  root: "account" as const,
  user: (userId = "me") => [accountKeys.root, userId] as const,
  restrictions: () => [accountKeys.root, "restrictions" as const] as const,
  restrictionsWithContext: ({ organisation, project, team }: TContext) =>
    [
      ...accountKeys.restrictions(),
      `${organisation ?? "NA"}::${project ?? "NA"}::${team ?? "NA"}`,
    ] as const,
  quotas: () => [...accountKeys.root, "quotas" as const] as const,
  quotasWithContext: ({ organisation, project, team }: TContext) =>
    [
      accountKeys.quotas(),
      `${organisation ?? "NA"}::${project ?? "NA"}::${team ?? "NA"}`,
    ] as const,
  limits: () => [...accountKeys.root, "limits" as const] as const,

  limitsWithContext: ({ organisation, project, team }: TContext) =>
    [
      ...accountKeys.limits(),
      "limits" as const,
      `${organisation ?? "NA"}::${project ?? "NA"}::${team ?? "NA"}`,
    ] as const,
  aliases: (userId = "me") =>
    [...accountKeys.user(userId), "aliases" as const] as const,
  hints: () => [accountKeys.root, "hints" as const] as const,
  hint: (domain: string) => [...accountKeys.hints(), domain] as const,
};

interface StartAliasEmail extends AccountAliasStartDto {
  userId?: string;
}

interface EndAliasEmail extends AccountAliasFinishDto {
  userId?: string;
}

const accountApi = {
  getRestrictions: (
    organisationId?: string,
    projectId?: string,
    teamId?: string
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.accounts.getApiAccountsRestrictions(
            organisationId,
            projectId,
            teamId
          ),
          signal
        ),
      queryKey: accountKeys.restrictionsWithContext({
        organisation: organisationId,
        project: projectId,
        team: teamId,
      }),
    }),
  getQuotas: (organisationId?: string, projectId?: string, teamId?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.accounts.getApiAccountsQuotas(organisationId, projectId, teamId),
          signal
        ),
      queryKey: accountKeys.quotasWithContext({
        organisation: organisationId,
        project: projectId,
        team: teamId,
      }),
    }),
  getLimits: (organisationId?: string, projectId?: string, teamId?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.accounts.getApiAccountsLimits(organisationId, projectId, teamId),
          signal
        ),
      queryKey: accountKeys.limitsWithContext({
        organisation: organisationId,
        project: projectId,
        team: teamId,
      }),
    }),
  editSettings: mutationOptions({
    mutationFn: (data: AccountsSettingsDto) =>
      api.accounts.patchApiAccountsSettings(undefined, data),
  }),
  getMe: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.accounts.getApiAccountsMe(), signal),
    queryKey: accountKeys.user(),
  }),
  deleteMe: mutationOptions({
    mutationFn: () => api.accounts.deleteApiAccounts(),
  }),
  getEmailAliases: (userId = "me") =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.accounts.getApiAccountsAliases(userId), signal),
      queryKey: accountKeys.aliases(userId),
    }),
  addUserAlias: mutationOptions({
    mutationFn: ({
      userId = "me",
      ...rest
    }: EmailAliasPostDto & { userId: string }) =>
      api.accounts.postApiAccountsAliasesMerge(userId, undefined, rest),
  }),
  startEmailAliasVerification: mutationOptions({
    mutationFn: ({ userId = "me", ...rest }: StartAliasEmail) =>
      api.accounts.postApiAccountsAliasStart(userId, undefined, rest),
  }),
  endEmailAliasVerification: mutationOptions({
    mutationFn: ({ userId = "me", ...rest }: EndAliasEmail) =>
      api.accounts.postApiAccountsAliasFinish(userId, undefined, rest),
  }),
  createDomainHint: mutationOptions({
    mutationFn: (dto: DomainAccessHintPostDto) =>
      api.accounts.postApiAccountsHints(undefined, dto),
  }),
  deleteDomainHint: mutationOptions({
    mutationFn: (hintId: string) => api.accounts.deleteApiAccountsHints(hintId),
  }),
};

export default accountApi;

import { Navigate, Outlet, useSearchParams } from "react-router-dom";

import useIsAuthenticated from "@/features/auth/hooks/useIsAuthenticated";

export function Component() {
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect-to");
  const isAuth = useIsAuthenticated();

  return (
    <>
      {isAuth ? (
        <Navigate
          to={redirect && redirect !== "/" ? redirect : "/gallery/private"}
        />
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default Component;

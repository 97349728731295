import type { LoaderFunctionArgs } from "react-router-dom";

import accountApi from "@/data/api/accounts";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function settingsDeleteMeLoader({
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) {
    return null;
  }
  queryClient.prefetchQuery(accountApi.getMe);
  return null;
}

export type SettingsDeleteMeLoaderReturnType = Awaited<
  ReturnType<typeof settingsDeleteMeLoader>
>;

import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import projectQueryApi from "@/data/api/project";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const ProjectMemberParamsSchema = z.object({
  projectId: z.string(),
});

export default async function projectEditMembersLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { projectId } = ProjectMemberParamsSchema.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      projectId,
    };
  }

  queryClient.prefetchQuery(projectQueryApi.getProject(projectId));

  return {
    projectId,
  };
}

export type ProjectEditMembersLoaderReturnType = Awaited<
  ReturnType<typeof projectEditMembersLoader>
>;

import { Box, CircularProgress, type CircularProgressProps } from "@mui/material";
import Typography from "@mui/material/Typography";

interface Props {
  color?: "primary" | "secondary";
  value?: number;
  className?: string;
  size?: number;
  "aria-label": string;
}

function CircularProgressWithLabel({
  className,
  value,
  ...rest
}: CircularProgressProps & { value: number }) {
  return (
    <Box position="relative" display="inline-flex" className={className}>
      <CircularProgress
        role="progressbar"
        variant="determinate"
        aria-label={value.toString() ?? "No Progress"}
        {...rest}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const Spinner = ({
  color = "primary",
  value,
  className = "",
  size = 40,
  "aria-label": label,
}: Props) =>
  value !== undefined ? (
    <CircularProgressWithLabel
      aria-label={label}
      value={value}
      color={color}
      className={className}
      size={size}
    />
  ) : (
    <CircularProgress
      color={color}
      className={className}
      size={size}
      aria-label={label}
    />
  );

export default Spinner;

import loadable from "@loadable/component";
import type { ButtonProps } from "@mui/material/Button";
import React, { useRef, useState } from "react";

import { useModalShow } from "./hooks";

type OptionContent = {
  value: string;
  label: string;
  buttonColor: ButtonProps["color"];
};

export type UseModalShowReturnType = {
  show: boolean;
  setShow: (value: boolean) => void;
  onHide: () => void;
};

export type SelectOptionContextType = {
  selectOption: (props: {
    title: string;
    message: string | JSX.Element;
    options: OptionContent[];
  }) => Promise<string | null>;
};

export type SelectOptionProviderProps = {
  children: React.ReactNode;
};

export const SelectOptionContext = React.createContext<SelectOptionContextType>(
  {} as SelectOptionContextType
);

const SelectOptionModal = loadable(() => import("./SelectOptionModal"));

export const SelectOptionProvider: React.FC<SelectOptionProviderProps> = ({
  children,
}) => {
  const { setShow, show, onHide } = useModalShow();
  const [content, setContent] = useState<{
    title: string;
    message: string | JSX.Element;
    options: OptionContent[];
  } | null>();
  const resolver = useRef<(value: string | null) => void>();

  const handleShow = ({
    title,
    message,
    options,
  }: {
    title: string;
    message: string | JSX.Element;
    options: OptionContent[];
  }): Promise<string | null> => {
    setContent({
      title,
      message,
      options,
    });
    setShow(true);
    return new Promise<string | null>((resolve) => {
      resolver.current = (value: string | null) => {
        resolve(value);
      };
    });
  };

  const modalContext: SelectOptionContextType = {
    selectOption: handleShow,
  };

  const handleSelect = (option: string) => {
    resolver.current?.(option);
    onHide();
  };

  const handleCancel = () => {
    resolver.current?.(null);
    onHide();
  };

  return (
    <SelectOptionContext.Provider value={modalContext}>
      {children}
      {content && (
        <SelectOptionModal
          show={show}
          content={content}
          handleCancel={handleCancel}
          handleSelect={handleSelect}
        />
      )}
    </SelectOptionContext.Provider>
  );
};

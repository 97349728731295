import type { LoaderFunctionArgs } from "react-router-dom";

import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function teamEditLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { teamId } = params;

  if (!teamId) {
    throw new Response("No Team Id Specified", { status: 400 });
  }

  const account = await authenticate(request);

  if (!account) {
    return { teamId };
  }

  queryClient.prefetchQuery(teamQueryApi.getTeam(teamId));

  return {
    teamId,
  };
}

export type TeamEditLoaderReturnType = Awaited<
  ReturnType<typeof teamEditLoader>
>;

import {
  Alert,
  CircularProgress,
  Grow,
  Snackbar,
  SnackbarContent,
  type SnackbarProps,
} from "@mui/material";
import * as React from "react";
import type { FC } from "react";

import type { ToastMessage } from "./ToastContext";

export type ToastStyle = Omit<
  SnackbarProps,
  "TransitionProps" | "onClose" | "open" | "children" | "message"
>;

export type ToastProps = {
  message: ToastMessage;
  onExited: () => void;
} & ToastStyle;

// https://mui.com/material-ui/react-snackbar/#consecutive-snackbars
export const Toast: FC<ToastProps> = ({
  message,
  onExited,
  autoHideDuration,
  ...props
}) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      key={message.key}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited }}
      TransitionComponent={Grow}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={
        autoHideDuration
          ? autoHideDuration
          : message.timeout === null
          ? null
          : message.timeout
          ? message.timeout
          : 5000
      }
      sx={{
        position: "relative",
        zIndex: 9999,
      }}
      {...props}
    >
      {message.severity !== undefined ? (
        <Alert
          severity={message.severity}
          variant="filled"
          icon={
            typeof message.loading === "number" ? (
              <CircularProgress
                variant="determinate"
                value={message.loading}
                size={20}
              />
            ) : typeof message.loading === "boolean" && message.loading ? (
              <CircularProgress size={20} />
            ) : (
              message.icon
            )
          }
          onClose={handleClose}
        >
          {message.message}
        </Alert>
      ) : (
        <SnackbarContent message={message.message} />
      )}
    </Snackbar>
  );
};

import type {
  EllipseOptions,
  LineOptions,
  PngOptions,
  RectOptions,
  ReportSize,
  RoundRectOptions,
  TextOptions,
} from "./types";

type Text = TextOptions & { type: "text" };
type Rectangle = RectOptions & { type: "rectangle" };
type Image = PngOptions & {
  type: "image";
  src: { data?: string; path?: string };
};
type RoundRectangle = RoundRectOptions & { type: "round-rectangle" };
type Line = LineOptions & { type: "line" };
type Ellipse = EllipseOptions & { type: "ellipse" };

export type Shape = Text | Rectangle | RoundRectangle | Line | Ellipse | Image;

interface Placeholder {
  /** X coordinate of shape, expressed in percentage of screen */
  x: number;
  /** Y coordinate of shape, expressed in percentage of screen */
  y: number;
  /** Width of shape, expressed in percentage of screen */
  w: number;
  /** Height of shape, expressed in percentage of screen */
  h: number;
  type: "photo" | "metadata" | "details";
  /** In the case of multiple ReportItems per layout, which item should be used  */
  index?: number;
}
type PhotoPlaceholder = Placeholder & {
  type: "photo";
};
export const isPhotoPlaceholder = (
  placeholder: Placeholder,
): placeholder is PhotoPlaceholder => placeholder.type === "photo";

type MetadataPlaceholder = Placeholder & {
  type: "metadata";
};
export const isMetadataPlaceholder = (
  placeholder: Placeholder,
): placeholder is MetadataPlaceholder => placeholder.type === "metadata";

type DetailsPlaceholder = Placeholder & {
  type: "details";
};
export const isDetailsPlaceholder = (
  placeholder: Placeholder,
): placeholder is DetailsPlaceholder => placeholder.type === "details";

type ReportPageTemplate = {
  id: `${number}p${"L" | "P"}-${"p" | "pm" | "pd" | "pdm"}`;
  size: ReportSize;
  /** User-friendly name for the template  */
  name: string;
  /** Longer-form description for the template */
  description: string;
  shapes: Shape[];
  placeholders: Placeholder[];
};

export default ReportPageTemplate;

import type { Bounds, Point2D } from "./2d.types";
import { Deg2Rad, Rad2Deg } from "./common";

/** Add two points together */
export const P2D_Add = (a: Point2D, b: Point2D): Point2D => ({
  x: a.x + b.x,
  y: a.y + b.y,
});

/** Subtract a point from another */
export const P2D_Sub = (a: Point2D, b: Point2D): Point2D => ({
  x: a.x - b.x,
  y: a.y - b.y,
});

/** Distance between two points */
export const P2D_Dist = (a: Point2D, b: Point2D): number =>
  Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

export const P2D_Angle = (a: Point2D, b: Point2D): number =>
  Rad2Deg(Math.atan2(b.y - a.y, b.x - a.x));

type Buffer = { t: number; l: number; r: number; b: number };
/**
 * Add a given amount of "padding" to a Bounds value.
 * Padding is calculated by the same rules as for CSS
 * @param bounds Bounds to be padded
 * @param p all or [all] or [vertical horizontal] or [top horizontal bottom] or [top right bottom left]
 */
export const PadBounds = (bounds: Bounds, p: number[] | number): Bounds => {
  // CSS padding rules
  const B: Buffer = Array.isArray(p)
    ? p.length === 0
      ? { r: 0, t: 0, l: 0, b: 0 }
      : p.length >= 4
      ? { t: p[0], r: p[1], b: p[2], l: p[3] }
      : p.length === 3
      ? { t: p[0], r: p[1], l: p[1], b: p[2] }
      : p.length === 2
      ? { t: p[0], b: p[0], l: p[1], r: p[1] }
      : { t: p[0], l: p[0], r: p[0], b: p[0] }
    : { t: p, l: p, r: p, b: p };

  return {
    min: { x: bounds.min.x - B.l, y: bounds.min.y - B.b },
    max: { x: bounds.max.x + B.r, y: bounds.max.y + B.t },
  };
};

/**
 * Find the endpoint of a line segment which starts at a given point and travels in a given direction.
 * @param p Start point
 * @param angle Angle of line
 * @param len Length of line
 */
export const FindEndpoint2D = (p: Point2D, angle: number, len = 1) => {
  const d = Deg2Rad(angle);
  return {
    x: p.x + len * Math.cos(d) + len * Math.sin(d),
    y: p.y - len * Math.sin(d) + len * Math.cos(d),
  };
};

import * as Sentry from "@sentry/react";

import { CONSTANTS } from "@/common";

const SENTRY_DSN =
  "https://41ac81eac4654e1aaf11a5ca31788389@o944971.ingest.sentry.io/6257416";

const init = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `builtview-frontend@${CONSTANTS.VERSION_CODE}`,
    integrations: [new Sentry.BrowserTracing()],
    autoSessionTracking: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
  });
};

const setTag = () => Sentry.setTag;

const setUser = (id: string, email: string, name?: string) =>
  Sentry.setUser({ id, email, name });

const clearUser = () => Sentry.setUser(null);

const captureException = (e: string | Error) => Sentry.captureException(e);

const showReportDialog = () => Sentry.showReportDialog();

const SentryConcrete = {
  init,
  setUser,
  clearUser,
  setTag,
  showReportDialog,
  captureException,
};

export default SentryConcrete;

import { Close, Help, HelpCenter, Mail } from "@mui/icons-material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";

const actions = [
  {
    icon: <HelpCenter />,
    name: "Help Center",
    onClick: () => window.open("https://support.builtview.com"),
  },
  {
    icon: <Mail />,
    name: "Email Support",
    onClick: () => window.open("mailto:ITServiceDesk@laingorourke.com.au"),
  },
  //   {
  //     icon: <Phone />,
  //     name: "Call Support",
  //     onClick: () => window.open("tel:"),
  //   },
];

const SupportButton = () => {
  return (
    <SpeedDial
      ariaLabel="Support dial"
      sx={{ position: "absolute", bottom: 24, right: 48 }}
      icon={<SpeedDialIcon icon={<Help />} openIcon={<Close />} />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  );
};

export default SupportButton;

import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const RolesScreenParamsSchema = z.object({
  teamId: z.string(),
});

export default async function teamRolesLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { teamId } = RolesScreenParamsSchema.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      teamId,
    };
  }

  queryClient.prefetchQuery(teamQueryApi.getTeam(teamId));
  queryClient.prefetchQuery(teamQueryApi.getTeamRoles(teamId));

  return {
    teamId,
  };
}

export type TeamRolesLoaderReturnType = Awaited<
  ReturnType<typeof teamRolesLoader>
>;

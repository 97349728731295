import { Typography } from "@mui/material";
import { Navigate, useParams } from "react-router-dom";

// eslint-disable-next-line no-restricted-imports
import useGetTeamMedia from "@/features/gallery/hooks/useGetTeamMedia";

export function Component() {
  const { mediaIndex } = useParams();
  const { siteId } = useParams<{ siteId?: string }>();

  let index: number;

  if (!mediaIndex) {
    throw new Error("No media index provided");
  }

  // check if string is latest or a number
  if (mediaIndex === "latest") {
    index = 1;
  } else {
    index = Number.parseInt(mediaIndex, 10);
  }

  const { item } = useGetTeamMedia(index);

  if (!item) {
    return <Typography>Loading</Typography>;
  }
  return <Navigate to={`/gallery/${siteId}/tile/view/${item.id}`} replace />;
}

export default Component;

import type { LoaderFunctionArgs } from "react-router-dom";

import { UserRole } from "@/api/generated";
import accountApi from "@/data/api/accounts";
import { fetchCachedQuery } from "@/data/api/helpers";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function superUserLoader({ request }: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) {
    return null;
  }

  const user = await fetchCachedQuery(queryClient, accountApi.getMe);

  if (!user.roles?.includes(UserRole.DEVELOPER)) {
    throw new Response("Unauthorized", {
      status: 401,
      statusText: "Unauthorized",
    });
  }
  return null;
}

export type SuperUserLoaderReturnType = Awaited<
  ReturnType<typeof superUserLoader>
>;

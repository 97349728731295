import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";
import type { LogGrouping, MicroserviceIntegrationType } from "@/api/generated";

import { cancelableQuery, mutationOptions } from "./helpers";

export const connectKeys = {
  root: "connectKeys" as const,
  user: (userId = "me") => [connectKeys.root, userId] as const,
  integrations: (userId = "me") =>
    [...connectKeys.user(userId), "integrations"] as const,
  integration: (integrationId: string, userId = "me") =>
    [...connectKeys.integrations(userId), integrationId] as const,
  connections: (userId = "me") =>
    [...connectKeys.user(userId), "connections"] as const,
  audit: (id?: string, type?: MicroserviceIntegrationType) =>
    [connectKeys.root, "audit", { id, type }] as const,
  graph: (id?: string, type?: MicroserviceIntegrationType) =>
    [...connectKeys.audit(id, type), "graph"] as const,
  log: (id?: string, type?: MicroserviceIntegrationType) =>
    [...connectKeys.audit(id, type), "log"] as const,
};

const connectApi = {
  getIntegrations: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.connect.getApiConnectIntegrations1(), signal),
    queryKey: connectKeys.integrations(),
  }),
  getIntegration: (integrationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.connect.getApiConnectIntegrations(integrationId),
          signal
        ),
      queryKey: connectKeys.integration(integrationId, "all"),
    }),
  getConnections: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.connect.getApiConnectConnections(), signal),
    queryKey: connectKeys.connections(),
  }),
  syncIntegration: mutationOptions({
    mutationFn: (integrationId: string) =>
      api.connect.patchApiConnectIntegrationsSync(integrationId),
  }),
  pauseIntegration: mutationOptions({
    mutationFn: (integrationId: string) =>
      api.connect.patchApiConnectIntegrationsPause(integrationId),
  }),
  resumeIntegration: mutationOptions({
    mutationFn: (integrationId: string) =>
      api.connect.patchApiConnectIntegrationsResume(integrationId),
  }),
  deleteIntegration: mutationOptions({
    mutationFn: (integrationId: string) =>
      api.connect.deleteApiConnectIntegrations(integrationId),
  }),
  listIntegrations: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.connect.getApiConnect(), signal),
    queryKey: connectKeys.integrations("all"),
  }),
  auditIntegrationsGraph: ({
    groupBy,
    from,
    to,
  }: {
    groupBy?: LogGrouping;
    from?: Date;
    to?: Date;
  }) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.connect.getApiConnectAuditGraph(
            from ? from.toISOString() : undefined,
            to ? to.toISOString() : undefined,
            groupBy
          ),
          signal
        ),
      queryKey: connectKeys.graph(),
    }),
  auditIntegrationsGraphId: (
    integrationId: string,
    { groupBy, from, to }: { groupBy?: LogGrouping; from?: Date; to?: Date }
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.connect.getApiConnectAuditGraphIntegration(
            integrationId,
            from ? from.toISOString() : undefined,
            to ? to.toISOString() : undefined,
            groupBy
          ),
          signal
        ),
      queryKey: connectKeys.graph(integrationId),
    }),
  auditIntegrationsGraphType: (
    integrationType: MicroserviceIntegrationType,
    { groupBy, from, to }: { groupBy?: LogGrouping; from?: Date; to?: Date }
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.connect.getApiConnectAuditGraphIntegration(
            integrationType,
            from ? from.toISOString() : undefined,
            to ? to.toISOString() : undefined,
            groupBy
          ),
          signal
        ),
      queryKey: connectKeys.log(undefined, integrationType),
    }),
  auditIntegrationsLogId: (
    integrationId: string,
    { from, to }: { from?: Date; to?: Date }
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.connect.getApiConnectAuditIntegration(
            integrationId,
            from ? from.toISOString() : undefined,
            to ? to.toISOString() : undefined
          ),
          signal
        ),
      queryKey: connectKeys.log(integrationId),
    }),
  auditIntegrationsLogType: (
    integrationType: MicroserviceIntegrationType,
    { from, to }: { from?: Date; to?: Date }
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.connect.getApiConnectAuditType(
            integrationType,
            from ? from.toISOString() : undefined,
            to ? to.toISOString() : undefined
          ),
          signal
        ),
      queryKey: connectKeys.log(undefined, integrationType),
    }),
};

export default connectApi;

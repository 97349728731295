import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import accountApi from "@/data/api/accounts";
import { projectKeys } from "@/data/api/project";
import { teamQueryApi, teamsKeys } from "@/data/api/teams";

export default function useRemoveTeamMemberMutation(projectId?: string) {
  const queryClient = useQueryClient();
  const user = useQuery(accountApi.getMe);

  return useMutation({
    ...teamQueryApi.removeTeamMember,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: teamsKeys.members(variables.teamId),
      });
      queryClient.invalidateQueries({
        queryKey: teamsKeys.details(variables.teamId),
      });
      queryClient.invalidateQueries({
        queryKey: teamsKeys.display(variables.teamId),
      });

      if (user.data && user.data.id === variables.userId) {
        queryClient.invalidateQueries({
          queryKey: teamsKeys.list(),
        });
      }

      if (projectId) {
        queryClient.invalidateQueries({
          queryKey: projectKeys.teams(projectId),
        });
      }
    },
  });
}

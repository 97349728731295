import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";

import { cancelableQuery, mutationOptions } from "./helpers";

export const floorplanKeys = {
  root: "floorplan" as const,
  floorplans: () => [floorplanKeys.root, "integrations" as const] as const,
  floorplan: (id: string) => [...floorplanKeys.floorplans(), id] as const,
  projects: () => [...floorplanKeys.floorplans(), "projects" as const] as const,
  project: (projectId: string) =>
    [...floorplanKeys.projects(), projectId] as const,
  teams: () => [...floorplanKeys.floorplans(), "teams" as const] as const,
  team: (teamId: string) => [...floorplanKeys.teams(), teamId] as const,
};

export type FloorplanPostData = Parameters<
  typeof api.floorplans.postApiFloorplans
>[1];
type PatchData = Parameters<typeof api.floorplans.putApiFloorplans>[2];

const floorplanQueryApi = {
  deleteFloorplan: mutationOptions({
    mutationFn: (id: string) => api.floorplans.deleteApiFloorplans(id),
  }),
  updateFloorplan: mutationOptions({
    mutationFn: ({
      floorplanId,
      ...data
    }: PatchData & { floorplanId: string }) =>
      api.floorplans.putApiFloorplans(floorplanId, undefined, data),
  }),
  rollbackFloorplan: mutationOptions({
    mutationFn: ({
      floorplanId,
      versionName,
    }: {
      floorplanId: string;
      versionName: string;
    }) => api.floorplans.patchApiFloorplans(floorplanId, versionName),
  }),
  getMyFloorplans: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.floorplans.getApiFloorplans1(), signal),
    queryKey: floorplanKeys.floorplans(),
  }),
  getFloorplanById: (floorplanId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.floorplans.getApiFloorplans(floorplanId), signal),
      queryKey: floorplanKeys.floorplan(floorplanId),
    }),
  getFloorplansByTeam: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsFloorplans(teamId), signal),
      queryKey: floorplanKeys.team(teamId),
    }),
  getFloorplanByProject: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.projects.getApiProjectsFloorplans(projectId),
          signal,
        ),
      queryKey: floorplanKeys.project(projectId),
    }),
  createFloorplan: mutationOptions({
    mutationFn: (data: FloorplanPostData) =>
      api.floorplans.postApiFloorplans(undefined, data),
  }),
};

export default floorplanQueryApi;

const sanitise = (short: string | undefined): string =>
  (short ?? "").toLowerCase().replace(/[^a-zA-Z0-9_]/g, "-");

const compare = (a: string | undefined, b: string | undefined): boolean =>
  sanitise(a ?? "") === sanitise(b ?? "");

const Shorthand = {
  sanitise,
  compare,
};

export default Shorthand;

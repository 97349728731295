export const accessTitle: { [id: string]: string } = {
  mine: "My Private Team",
  all: "All Photos & Videos",
};

export const CONSTANTS = {
  APP_VERSION: {
    EXPERIMENTAL: "EXPERIMENTAL",
    STAGING: "STAGING",
    PRODUCTION: "PRODUCTION",
  },
  VERSION_CODE: "2.9.0",

  /** How many items can sync at any one time?  Note this is a "soft limit", and may be exceeded */
  SYNC_QUEUE_SIZE: 6,
  /** What's the limit of file size before you delete it immediately on upload? */
  MAX_FILE_SIZE_KEEP: 100 * 1024 * 1024, // 100MB
  /** How long should files be kept before deleting automatically? (in ms - the unit of Date.getTime()) */
  MAX_AGE_KEEP: 7 * 24 * 60 * 60 * 1000, // 7 days
  // MAX_AGE_KEEP: 1 * 60 * 1000, // 1 minute

  TAGLINE: "Building confidence, pixel by pixel",

  DEFAULT_PAGE_SIZE: 20,
  DEFAULT_BATCH_SIZE: 6,

  PUBLISHED_ONLY: false,
  FINISHED_ONLY: true,

  MICROSERVICE_BASE_URL: "https://builtview-thumbnails.azurewebsites.net/api",

  ACCESS_TITLE: accessTitle,

  LORA_IDP:
    "https://login.microsoftonline.com/95baa318-b818-4676-8a0f-5a95a646c3c1/v2.0",
};

export const CLAIMS = {
  IDP: "http://schemas.microsoft.com/identity/claims/identityprovider",
  DISPLAYNAME: "name",
  GIVENNAME: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname",
  SURNAME: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
  IUI: "issuerUserId",
  COMPANY: "extension_company",
};

import type { Client } from "oauth4webapi";

import Authenticator from "./utils/authenticator";

const issuer = new URL(`${import.meta.env.VITE_MSAL_AUTHORITY}v2.0/`);

const redirect_uri = `${import.meta.env.VITE_FRONTEND_URL}callback-bv`;

const client: Client = {
  client_id: import.meta.env.VITE_MSAL_CLIENT_ID,
  token_endpoint_auth_method: "none",
};

export const authClient = new Authenticator({
  client,
  issuer,
  redirect_uri,
  scopes: [
    "openid",
    "email",
    "https://builtview.onmicrosoft.com/bv-server-dev/User",
    "https://builtview.onmicrosoft.com/bv-server-dev/AuthorisedApp",
    "profile",
    "offline_access",
  ],
});

import { z } from "zod";

/**
 * Access token schema
 */
export const AccessTokenSchema = z.object({
  /** access token, used to access the API */
  access_token: z.string(),
  /** id_token, jwt */
  id_token: z.string(),
  /** Token type, bearer */
  token_type: z.literal("bearer"),
  /** access token not before, milliseconds since epoch, indicates when it's valid */
  not_before: z.number(),
  /** expires in, seconds */
  expires_in: z.number(),
  /** expires on, milliseconds since epoch */
  expires_on: z.number(),
  /** idk, callback url */
  resource: z.string(),
  /**  */
  id_token_expires_in: z.number(),
  /** profile */
  profile_info: z.string(),
  scope: z.string(),

  /** Refresh token */
  refresh_token: z.string(),
  /** Refresh token expiration in seconds? */
  refresh_token_expires_in: z.number(),
});

import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";
import type { LayerItemsPutDto } from "@/api/generated";

import { cancelableQuery, mutationOptions } from "./helpers";

export const layerKeys = {
  root: "layers" as const,
  layer: (id: string) => [layerKeys.root, id] as const,
};

export const layerQueryApi = {
  getLayer: (id: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.layerItems.getApiLayer(id), signal),
      queryKey: layerKeys.layer(id),
    }),
  updateLayer: mutationOptions({
    mutationFn: ({
      layerId,
      ...data
    }: LayerItemsPutDto & { layerId: string }) =>
      api.layerItems.patchApiLayer(layerId, undefined, data),
  }),
  deleteLayer: mutationOptions({
    mutationFn: (layerId: string) =>
      api.layerItems.deleteApiLayer(layerId, undefined),
  }),

  finishLayerUpload: mutationOptions({
    mutationFn: (layerId: string) =>
      api.layerItems.postApiLayerFinish(layerId, undefined),
  }),
};

export default layerQueryApi;

import type { LoaderFunctionArgs } from "react-router-dom";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";
import organisationQueryApi from "@/data/api/organisation";
import customerQueryApi from "@/data/api/customers";

export default async function salesDashboardLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) {
    return null;
  }

  queryClient.prefetchQuery(organisationQueryApi.getOrganisations);
  queryClient.prefetchQuery(customerQueryApi.listCustomers);

  return null;
}

export type SalesDashboardLoaderReturnType = Awaited<
  ReturnType<typeof salesDashboardLoader>
>;

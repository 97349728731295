/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessRoleGetDto } from "../models/AccessRoleGetDto";
import type { AccessRolePatchDto } from "../models/AccessRolePatchDto";
import type { AccessRolePostDto } from "../models/AccessRolePostDto";
import type { ActivityTagGetDto } from "../models/ActivityTagGetDto";
import type { ActivityTeamGetDto } from "../models/ActivityTeamGetDto";
import type { ActivityUserGetDto } from "../models/ActivityUserGetDto";
import type { ArchiveProjectPatchDto } from "../models/ArchiveProjectPatchDto";
import type { DomainAccessHintGetDto } from "../models/DomainAccessHintGetDto";
import type { FloorplanGetDto } from "../models/FloorplanGetDto";
import type { LayerItemsGetDto } from "../models/LayerItemsGetDto";
import type { LogicalOperator } from "../models/LogicalOperator";
import type { MediaItemGpsListDto } from "../models/MediaItemGpsListDto";
import type { MediaItemGraphRecordGetDto } from "../models/MediaItemGraphRecordGetDto";
import type { MediaItemListDto } from "../models/MediaItemListDto";
import type { MediaItemsGetDtoPagedResults } from "../models/MediaItemsGetDtoPagedResults";
import type { MediaType } from "../models/MediaType";
import type { PermissionDefinitionGetDto } from "../models/PermissionDefinitionGetDto";
import type { ProjectInvitesDto } from "../models/ProjectInvitesDto";
import type { ProjectInvitesResultDto } from "../models/ProjectInvitesResultDto";
import type { ProjectsGetDisplayInfoDto } from "../models/ProjectsGetDisplayInfoDto";
import type { ProjectsGetDto } from "../models/ProjectsGetDto";
import type { ProjectsPatchDto } from "../models/ProjectsPatchDto";
import type { ProjectsPostDto } from "../models/ProjectsPostDto";
import type { ProjectStatsGetDto } from "../models/ProjectStatsGetDto";
import type { ProjectUsersGetDto } from "../models/ProjectUsersGetDto";
import type { ProjectUsersMultiPatchDto } from "../models/ProjectUsersMultiPatchDto";
import type { ProjectUsersPatchDto } from "../models/ProjectUsersPatchDto";
import type { SiteTagsUsedGetDto } from "../models/SiteTagsUsedGetDto";
import type { SortOption } from "../models/SortOption";
import type { TeamsGetDisplayInfoDto } from "../models/TeamsGetDisplayInfoDto";
import type { TeamsGetDto } from "../models/TeamsGetDto";
import type { TeamTagsGetDto } from "../models/TeamTagsGetDto";
import type { TeamTagsUnifiedGetDto } from "../models/TeamTagsUnifiedGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class ProjectsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete a single project;  user must be a Developer or a project "owner"; restrictions? (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public deleteApiProjects(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<ProjectsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/projects/{projectId}",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get a single project;  user must be an admin or in the project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public getApiProjects(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<ProjectsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Update a single project;  user must be a Developer or a project "owner" (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public patchApiProjects(
    projectId: string,
    xVersion: string = "2",
    requestBody?: ProjectsPatchDto,
  ): CancelablePromise<ProjectsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/projects/{projectId}",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete a role in this project.  A valid backup role must be provided if any users have this role. (Auth policies: AuthorisedApp, User)
   * @param projectId The project in which this role is
   * @param roleId The role to delete
   * @param replace The role to replace this role with
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public deleteApiProjectsRoles(
    projectId: string,
    roleId: string,
    replace?: string,
    xVersion: string = "2",
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/projects/{projectId}/roles/{roleId}",
      path: {
        projectId: projectId,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        replace: replace,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetches a single role related to this project (Auth policies: AuthorisedApp, User)
   * @param projectId
   * @param roleId
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public getApiProjectsRoles(
    projectId: string,
    roleId: string,
    xVersion: string = "2",
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/roles/{roleId}",
      path: {
        projectId: projectId,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Update a role in this project (Auth policies: AuthorisedApp, User)
   * @param projectId
   * @param roleId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public patchApiProjectsRoles(
    projectId: string,
    roleId: string,
    xVersion: string = "2",
    requestBody?: AccessRolePatchDto,
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/projects/{projectId}/roles/{roleId}",
      path: {
        projectId: projectId,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Remove a team from a project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param teamId
   * @param xVersion The requested API version
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public deleteApiProjectsTeams(
    projectId: string,
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/projects/{projectId}/teams/{teamId}",
      path: {
        projectId: projectId,
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Add an existing team to a project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param teamId
   * @param xVersion The requested API version
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public postApiProjectsTeams(
    projectId: string,
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/projects/{projectId}/teams/{teamId}",
      path: {
        projectId: projectId,
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Remove a user from a project - does not remove any of their media though (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId Project ID
   * @param userId User ID
   * @param purge If true, will remove the user from all teams in this project
   * @param xVersion The requested API version
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public deleteApiProjectsMembers(
    projectId: string,
    userId: string,
    purge: boolean = false,
    xVersion: string = "2",
  ): CancelablePromise<ProjectsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/projects/{projectId}/members/{userId}",
      path: {
        projectId: projectId,
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        purge: purge,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Change the project role of an existing member (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param userId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ProjectUsersGetDto Success
   * @throws ApiError
   */
  public patchApiProjectsMembers(
    projectId: string,
    userId: string,
    xVersion: string = "2",
    requestBody?: ProjectUsersPatchDto,
  ): CancelablePromise<ProjectUsersGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/projects/{projectId}/members/{userId}",
      path: {
        projectId: projectId,
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Revoke invitations (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (site admin)
   *
   * This only removes pending invitations, and does not
   * revoke access to a site if the user has already created an
   * account (and thus redeemed the invitation).
   * Use M:BuiltView360Backend.V2.Controllers.ProjectsController.DeleteProjectUser(System.String,System.String,System.Boolean) to revoke access instead
   * @param projectId
   * @param emails Comma-separated list of emails to revoke
   * @param xVersion The requested API version
   * @returns ProjectInvitesResultDto Success
   * @throws ApiError
   */
  public deleteApiProjectsInvites(
    projectId: string,
    emails?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<ProjectInvitesResultDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/projects/{projectId}/invites",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        emails: emails,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get a list of emails invited to this project (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (project admin)
   * @param projectId
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiProjectsInvites(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/invites",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all projects I have access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public getApiProjects1(
    xVersion: string = "2",
  ): CancelablePromise<Array<ProjectsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public postApiProjects(
    xVersion: string = "2",
    requestBody?: ProjectsPostDto,
  ): CancelablePromise<ProjectsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/projects",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get all floorplans in a project (Auth policies: AuthorisedApp, User)
   * @param projectId
   * @param xVersion The requested API version
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public getApiProjectsFloorplans(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<FloorplanGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/floorplans",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get projects that are archived which I can access (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public getApiProjectsArchived(
    xVersion: string = "2",
  ): CancelablePromise<Array<ProjectsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/archived",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get full media graph for an project. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId Project to retrieve data for
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Needs to have dateString as well.
   * @param granularity Either "year", "month", "week" (default) or "day"
   * @param xVersion The requested API version
   * @returns MediaItemGraphRecordGetDto Success
   * @throws ApiError
   */
  public getApiProjectsGraph(
    projectId: string,
    dateString?: string,
    dateStringEnd?: string,
    granularity: string = "week",
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemGraphRecordGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/graph",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        granularity: granularity,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per tag for the given project. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId Project to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityTagGetDto Success
   * @throws ApiError
   */
  public getApiProjectsActivityTags(
    projectId: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityTagGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/activity/tags",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per user for the given project. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId Project to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityUserGetDto Success
   * @throws ApiError
   */
  public getApiProjectsActivityUsers(
    projectId: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityUserGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/activity/users",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per team for the given project. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId Project to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityTeamGetDto Success
   * @throws ApiError
   */
  public getApiProjectsActivityTeams(
    projectId: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityTeamGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/activity/teams",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get the media stats single project;  user must be an admin or in the project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @returns ProjectStatsGetDto Success
   * @throws ApiError
   */
  public getApiProjectsStats(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<ProjectStatsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/stats",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get media from a project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId Project ID to search within
   * @param page Index page of search results
   * @param size Number of items per page
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param siteIds Restrict to uploads in these teams (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemsGetDtoPagedResults Success
   * @throws ApiError
   */
  public getApiProjectsMedia(
    projectId: string,
    page?: number,
    size: number = 20,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    siteIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGetDtoPagedResults> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/media",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        siteIds: siteIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Return a list of MediaItems with matching search terms that the User can access
   * either as the creator or being part of the same site as the mediaItem (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId Project ID or shorthand to search within
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param siteIds Restrict to uploads in these teams (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemListDto Success
   * @throws ApiError
   */
  public getApiProjectsMediaList(
    projectId: string,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    siteIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemListDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/media/list",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        siteIds: siteIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return a list of MediaItems with matching search terms that the User can access
   * either as the creator or being part of the same site as the mediaItem (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId Project shorthand or ID to search within
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param siteIds Restrict to uploads in these teams (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemGpsListDto Success
   * @throws ApiError
   */
  public getApiProjectsMediaGps(
    projectId: string,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    siteIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemGpsListDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/media/gps",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        siteIds: siteIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetches display information for the given project (Auth policies: AuthorisedApp, User)
   * @param projectId
   * @param xVersion The requested API version
   * @returns ProjectsGetDisplayInfoDto Success
   * @throws ApiError
   */
  public getApiProjectsDisplay(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<ProjectsGetDisplayInfoDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/display",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetch the team tags which exist under this project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param projectId Project ID to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public getApiProjectsTags(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsUnifiedGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/tags",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * [Deprecated] Fetch the team tags which exist under this project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param projectId Project ID to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsGetDto Success
   * @throws ApiError
   */
  public getApiProjectsTagsTeam(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/tags/team",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch all used tags under the given project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param projectId Id of the project to get the tags from
   * @param xVersion The requested API version
   * @returns SiteTagsUsedGetDto Success
   * @throws ApiError
   */
  public getApiProjectsTagsUsed(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<SiteTagsUsedGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/tags/used",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets a list of layer items attached to teams in the given project. <br />
   * If the layer item is a ImageLayer it will also return the coordinates otherwise the coordinates will be null. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId The id of the project to check;  can be "private" or "all"
   * @param includeLink
   * @param xVersion The requested API version
   * @returns LayerItemsGetDto Success
   * @throws ApiError
   */
  public getApiProjectsLayers(
    projectId: string,
    includeLink: boolean = false,
    xVersion: string = "2",
  ): CancelablePromise<Array<LayerItemsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/layers",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        includeLink: includeLink,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch the permissions that relate to projects
   * @param xVersion The requested API version
   * @returns PermissionDefinitionGetDto Success
   * @throws ApiError
   */
  public getApiProjectsPermissions(
    xVersion: string = "2",
  ): CancelablePromise<Array<PermissionDefinitionGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/permissions",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Fetches a list of all roles related to this project (Auth policies: AuthorisedApp, User)
   * @param projectId
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public getApiProjectsRoles1(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<AccessRoleGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/roles",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new role this project (Auth policies: AuthorisedApp, User)
   * @param projectId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public postApiProjectsRoles(
    projectId: string,
    xVersion: string = "2",
    requestBody?: AccessRolePostDto,
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/projects/{projectId}/roles",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all teams in a project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public getApiProjectsTeams(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/teams",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get all teams (display format) in a project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @returns TeamsGetDisplayInfoDto Success
   * @throws ApiError
   */
  public getApiProjectsTeamsDisplay(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamsGetDisplayInfoDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/teams/display",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get all members of a project; must be in the project or a Developer (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @returns ProjectUsersGetDto Success
   * @throws ApiError
   */
  public getApiProjectsMembers(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ProjectUsersGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/{projectId}/members",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Change multiple users' roles in a project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ProjectUsersGetDto Success
   * @throws ApiError
   */
  public patchApiProjectsMembers1(
    projectId: string,
    xVersion: string = "2",
    requestBody?: ProjectUsersMultiPatchDto,
  ): CancelablePromise<Array<ProjectUsersGetDto>> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/projects/{projectId}/members",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Invite a user to a project - “invite” if not a BV user, “add” if a BV user, send an email either way; user must be a member of the project with sufficient roles (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ProjectInvitesResultDto Success
   * @throws ApiError
   */
  public postApiProjectsMembers(
    projectId: string,
    xVersion: string = "2",
    requestBody?: ProjectInvitesDto,
  ): CancelablePromise<Array<ProjectInvitesResultDto>> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/projects/{projectId}/members",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get known access hints for the given project (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, Developer
   * @param projectId
   * @param xVersion The requested API version
   * @returns DomainAccessHintGetDto Success
   * @throws ApiError
   */
  public getApiProjectsProjectHints(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<DomainAccessHintGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/projects/project/{projectId}/hints",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Archive a project the user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public patchApiProjectsArchive(
    projectId: string,
    xVersion: string = "2",
    requestBody?: ArchiveProjectPatchDto,
  ): CancelablePromise<ProjectsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/projects/{projectId}/archive",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Populate/update all projects' stats (Auth policies: AuthorisedApp, Developer)
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public patchApiProjectsDisplay(
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/projects/display",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Join a project you have indirect access to;  note this is subject to restrictions (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param projectId
   * @param xVersion The requested API version
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public postApiProjectsJoin(
    projectId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ProjectsGetDto>> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/projects/{projectId}/join",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

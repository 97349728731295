import type { LoaderFunctionArgs } from "react-router-dom";

import projectQueryApi from "@/data/api/project";
import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function teamEditFloorplansLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { teamId } = params;
  const projectId = new URL(request.url).searchParams.get("projectId");

  if (!teamId) {
    throw new Response("No Team Id Specified", { status: 400 });
  }

  const account = await authenticate(request);

  if (!account) {
    return {
      teamId,
      projectId,
    };
  }

  queryClient.prefetchQuery(teamQueryApi.getTeam(teamId));
  queryClient.prefetchQuery(projectQueryApi.getProjects());

  return {
    teamId,
    projectId,
  };
}

export type TeamEditFloorplansLoaderReturnType = Awaited<
  ReturnType<typeof teamEditFloorplansLoader>
>;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeamsGetDto } from "../models/TeamsGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class TokenService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Redeem a token (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   * @param token
   * @param xVersion The requested API version
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public postApiTokenRedeem(
    token: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/token/redeem/{token}",
      path: {
        token: token,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

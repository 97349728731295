import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import organisationQueryApi from "@/data/api/organisation";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const NewOrganisationRoleParamsSchmea = z.object({
  orgId: z.string(),
});

const NewProjectRoleQueryParamsSchema = z.object({
  media: z.coerce.number().optional().default(0),
  site: z.coerce.number().optional().default(0),
  project: z.coerce.number().optional().default(0),
  organisation: z.coerce.number().optional().default(0),
});

export default async function organisatioNewRoleLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);
  const structuredSearchParams = Object.fromEntries(searchParams.entries());
  const { media, site, project, organisation } =
    NewProjectRoleQueryParamsSchema.parse(structuredSearchParams);

  const { orgId: organisationId } =
    NewOrganisationRoleParamsSchmea.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      organisationId: organisationId,
      initialMediaPermission: media,
      initialSitePermission: site,
      initialProjectPermission: project,
      initialOrganisationPermission: organisation,
    };
  }

  queryClient.prefetchQuery(
    organisationQueryApi.getOrganisation(organisationId),
  );

  return {
    organisationId: organisationId,
    initialMediaPermission: media,
    initialSitePermission: site,
    initialProjectPermission: project,
    initialOrganisationPermission: organisation,
  };
}

export type OrganisationNewRoleLoaderReturnType = Awaited<
  ReturnType<typeof organisatioNewRoleLoader>
>;

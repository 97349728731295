/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogicalOperator } from "../models/LogicalOperator";
import type { MediaItemMicroDuplicateDto } from "../models/MediaItemMicroDuplicateDto";
import type { MediaItemMicroGetDto } from "../models/MediaItemMicroGetDto";
import type { MediaItemMicroPagedGetDto } from "../models/MediaItemMicroPagedGetDto";
import type { MediaItemMicroResultPatchDto } from "../models/MediaItemMicroResultPatchDto";
import type { MediaItemsGetDto } from "../models/MediaItemsGetDto";
import type { MediaType } from "../models/MediaType";
import type { UserGetDto } from "../models/UserGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class MicroserviceService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Ping to test your token
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiMicroservices(xVersion: string = "2"): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/microservices",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Get the latest media items to upload.
   * Sort by creation date (not timestamp) and return results paginated
   * @param page Index page of search results; default = 0
   * @param size Number of items per page; default = 20
   * @param teamIds BuiltView teams to consider when searching
   * @param fromDate Time to start looking for the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param gpsOnly If true, will filter out media items that have GPS data
   * @param xVersion The requested API version
   * @returns MediaItemMicroPagedGetDto Success
   * @throws ApiError
   */
  public getApiMicroservicesMediaItems(
    page?: number,
    size?: number,
    teamIds?: Array<string>,
    fromDate?: string,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    gpsOnly?: boolean,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemMicroPagedGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/microservices/media-items",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        teamIds: teamIds,
        fromDate: fromDate,
        types: types,
        tags: tags,
        tagMode: tagMode,
        gpsOnly: gpsOnly,
      },
    });
  }
  /**
   * @param xVersion The requested API version
   * @param formData
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public postApiMicroservicesMediaItems(
    xVersion: string = "2",
    formData?: {
      /**
       * Optional ID to try to use in the database
       */
      Id?: string;
      /**
       * The byte array of the file to upload.
       * Check size limit with GET /api/settings/chunk-bytes?
       */
      Data: Blob;
      /**
       * Type of Media being uploaded
       */
      Type: MediaType;
      /**
       * Total number of bytes of this upload
       */
      ByteCount: number;
      /**
       * The email of the user who is to be associated with this media item.
       */
      UserEmail: string;
      /**
       * The given name of the user who is to be associated with this media item.
       */
      UserGivenName?: string;
      /**
       * The family name of the user who is to be associated with this media item.
       */
      UserFamilyName?: string;
      /**
       * Format: **yyyy-MM-ddTHH:mm:sszzz** where:
       * - yyyy: the year in full
       * - MM: the month, with leading zero
       * - dd: the day on the month, with leading zero
       * - HH: the hour in 24 hours format, with leading zero
       * - mm: the minute, with leading zero
       * - ss: the second, with leading zero
       * - zzz: the local UTC offset, like +10:00 or -07:30
       *
       * Example: "2020-04-27T22:59:33+00:00"
       */
      Timestamp?: string;
      /**
       * Hash of the file
       */
      Hash?: string;
      /**
       * Description of the file
       */
      Description?: string;
      /**
       * Tags attached to the file
       */
      Tags?: string;
      /**
       * Name of the file, including extension
       */
      FileName: string;
      /**
       * Whether the audio of this file should be played (video only)
       */
      AudioEnabled?: boolean;
      /**
       * Whether this video is a 360-degree photo or video
       */
      Is360?: boolean;
      /**
       * The duration of this video in milliseconds
       */
      DurationMs?: number;
      /**
       * What was the latitude when the media item was captured?
       */
      Latitude?: number;
      /**
       * What was the longitude when the media item was captured?
       */
      Longitude?: number;
      /**
       * What was the compass reading when the media item was captured?
       */
      Heading?: number;
      /**
       * What was the floorplan ID where the media item was captured?
       */
      FloorplanId?: string;
      /**
       * What was the X coordinate on the floorplan where the media item was captured?
       */
      FloorplanX?: number;
      /**
       * What was the Y coordinate on the floorplan where the media item was captured?
       */
      FloorplanY?: number;
      /**
       * Resolution of the original file, width then height.
       * To support encoding of 4K files, please
       * provide the resolution.
       */
      Resolution?: Array<number>;
      /**
       * Optional site id to add the media to immediately
       */
      SiteId?: string;
    },
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/microservices/media-items",
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    });
  }
  /**
   * Get the metadata of a single media item.
   * @param mediaItemId
   * @param xVersion The requested API version
   * @returns MediaItemMicroGetDto Success
   * @throws ApiError
   */
  public getApiMicroservicesMediaItems1(
    mediaItemId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemMicroGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/microservices/media-items/{mediaItemId}",
      path: {
        mediaItemId: mediaItemId,
      },
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Get a user by name
   * @param givenName
   * @param familyName
   * @param xVersion The requested API version
   * @returns UserGetDto Success
   * @throws ApiError
   */
  public getApiMicroservicesUsers(
    givenName: string,
    familyName: string,
    xVersion: string = "2",
  ): CancelablePromise<UserGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/microservices/users/{givenName}/{familyName}",
      path: {
        givenName: givenName,
        familyName: familyName,
      },
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Get a user by email
   * @param email
   * @param xVersion The requested API version
   * @returns UserGetDto Success
   * @throws ApiError
   */
  public getApiMicroservicesUsers1(
    email: string,
    xVersion: string = "2",
  ): CancelablePromise<UserGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/microservices/users/{email}",
      path: {
        email: email,
      },
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Duplicate specified media items to a new team
   * @param xVersion The requested API version
   * @param requestBody
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public postApiMicroservicesMediaItemsDuplicate(
    xVersion: string = "2",
    requestBody?: MediaItemMicroDuplicateDto,
  ): CancelablePromise<Array<MediaItemsGetDto>> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/microservices/media-items/duplicate",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Add AI image results to the specified media item;  replace it if it already exists.
   * @param mediaItemId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns MediaItemMicroGetDto Success
   * @throws ApiError
   */
  public putApiMicroservicesMediaItemsVision(
    mediaItemId: string,
    xVersion: string = "2",
    requestBody?: MediaItemMicroResultPatchDto,
  ): CancelablePromise<MediaItemMicroGetDto> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/microservices/media-items/vision/{mediaItemId}",
      path: {
        mediaItemId: mediaItemId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}

import { useContext, useState } from "react";

import { SelectOptionContext, type UseModalShowReturnType } from "./provider";

export const useModalShow = (): UseModalShowReturnType => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};

export const useSelectOption = () => {
  const { selectOption } = useContext(SelectOptionContext);
  return selectOption;
};

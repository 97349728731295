/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiKeyAddSitesDto } from "../models/ApiKeyAddSitesDto";
import type { ApiKeyAuthPostDto } from "../models/ApiKeyAuthPostDto";
import type { ApiKeyComplyPostDto } from "../models/ApiKeyComplyPostDto";
import type { ApiKeyLorPostDto } from "../models/ApiKeyLorPostDto";
import type { ApiKeyPatchDto } from "../models/ApiKeyPatchDto";
import type { ApiKeyPostDto } from "../models/ApiKeyPostDto";
import type { ApiKeysGetTokenDto } from "../models/ApiKeysGetTokenDto";
import type { ApiKeySuperPostDto } from "../models/ApiKeySuperPostDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class ApiKeyService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Remove a site from a key; only the key owner or a developer can do this (Auth policies: AuthorisedApp, User)
   * @param keyId
   * @param teamId
   * @param xVersion The requested API version
   * @returns ApiKeysGetTokenDto Success
   * @throws ApiError
   */
  public deleteApiKeysTeam(
    keyId: string,
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/keys/{keyId}/team/{teamId}",
      path: {
        keyId: keyId,
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add a site to a key;  only the key owner or a superuser can do this (Auth policies: AuthorisedApp, User)
   * @param keyId
   * @param teamId
   * @param xVersion The requested API version
   * @returns ApiKeysGetTokenDto Success
   * @throws ApiError
   */
  public patchApiKeysTeam(
    keyId: string,
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/keys/{keyId}/team/{teamId}",
      path: {
        keyId: keyId,
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Disable a key; only the key owner or a superuser can do this (Auth policies: AuthorisedApp, User)
   * @param keyId
   * @param xVersion The requested API version
   * @returns ApiKeysGetTokenDto Success
   * @throws ApiError
   */
  public deleteApiKeys(
    keyId: string,
    xVersion: string = "2",
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/keys/{keyId}",
      path: {
        keyId: keyId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Update a key; only the key owner or a superuser can do this, and they need access to the sites (Auth policies: AuthorisedApp, User)
   * @param keyId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ApiKeysGetTokenDto Success
   * @throws ApiError
   */
  public patchApiKeys(
    keyId: string,
    xVersion: string = "2",
    requestBody?: ApiKeyPatchDto,
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/keys/{keyId}",
      path: {
        keyId: keyId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get a user's token.  Only the user or a superuser can do this. (Auth policies: AuthorisedApp, User)
   * @param userId
   * @param xVersion The requested API version
   * @returns ApiKeysGetTokenDto Success
   * @throws ApiError
   */
  public getApiKeys(
    userId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ApiKeysGetTokenDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/keys/{userId}",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add a new ApiKey.  Any user can do this, but only for Sites in which they are Admins (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ApiKeysGetTokenDto Created
   * @throws ApiError
   */
  public postApiKeys(
    xVersion: string = "2",
    requestBody?: ApiKeyPostDto,
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/keys",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add a new ApiKey to any Site.  SuperUser only. (Auth policies: AuthorisedApp, Developer, User)
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ApiKeysGetTokenDto Created
   * @throws ApiError
   */
  public postApiKeysAny(
    xVersion: string = "2",
    requestBody?: ApiKeyPostDto,
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/keys/any",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Add a new super ApiKey.  Only developers can do this (Auth policies: AuthorisedApp, Developer, User)
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ApiKeysGetTokenDto Created
   * @throws ApiError
   */
  public postApiKeysSuper(
    xVersion: string = "2",
    requestBody?: ApiKeySuperPostDto,
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/keys/super",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add a new Comply ApiKey.  Only developers can do this (Auth policies: AuthorisedApp, Developer, User)
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ApiKeysGetTokenDto Created
   * @throws ApiError
   */
  public postApiKeysComply(
    xVersion: string = "2",
    requestBody?: ApiKeyComplyPostDto,
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/keys/comply",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add a new Lor ApiKey.  Only developers can do this (Auth policies: AuthorisedApp, Developer, User)
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ApiKeysGetTokenDto Created
   * @throws ApiError
   */
  public postApiKeysLor(
    xVersion: string = "2",
    requestBody?: ApiKeyLorPostDto,
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/keys/lor",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add a new Auth ApiKey.  Only developers can do this (Auth policies: AuthorisedApp, Developer, User)
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ApiKeysGetTokenDto Created
   * @throws ApiError
   */
  public postApiKeysAuth(
    xVersion: string = "2",
    requestBody?: ApiKeyAuthPostDto,
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/keys/auth",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add a new ApiKey to any Site.  SuperUser only. (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @param requestBody
   * @returns ApiKeysGetTokenDto Success
   * @throws ApiError
   */
  public putApiKeysAnyAddSite(
    xVersion: string = "2",
    requestBody?: ApiKeyAddSitesDto,
  ): CancelablePromise<ApiKeysGetTokenDto> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/keys/any/add-site",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

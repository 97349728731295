import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ApiError } from "@/api/generated";
import { CONSTANTS } from "@/common";
import useChunkedUpload, {
  isLayerUploadType,
  isMarkupUploadType,
  isMediaUploadType,
  uploadIsActive,
  type UploadFileData,
  type UploadProgress,
  type UploadType,
} from "@/hooks/useChunkedUpload";
import logger from "@/utils/logger";

const MAX_CONCURRENT = CONSTANTS.SYNC_QUEUE_SIZE;

const preventNavigating = (e: BeforeUnloadEvent) => {
  // Cancel the event as stated by the standard.
  e.preventDefault();
  // Chrome requires returnValue to be set.
  e.returnValue = "";
};

export interface IUploadManager {
  /** Given a list of upload objects, uploads them. */
  upload: (files: UploadType[]) => void;
  /** Given a single upload object, add it to the queue and return the creation result. */
  uploadSingle: (file: UploadType) => Promise<UploadFileData | null>;
  /** Add a callback to be executed whenever a file finishes uploading. */
  addRefreshCallback: (cb: () => void) => void;
  /** 
   * Remove a callback to be executed whenever a file finishes uploading. <br/>
   * Example: <br/>
   * ```ts
      useEffect(() => {
        addRefreshCallback(fetchFiles);
        return () => removeRefreshCallback(fetchFiles);
      }, [fetchFiles, addRefreshCallback, removeRefreshCallback]);
      ```
   */
  removeRefreshCallback: (cb: () => void) => void;

  /** Cancel an in-progress upload */
  cancel: (id: UploadFile["id"]) => void;
  /** Remove a suingle upload that are successful or failed */
  clearSingle: (id: UploadFile["id"]) => void;
  /** Remove uploads that are successful or failed */
  clear: () => void;
}

export interface UploadFile extends UploadProgress {
  id: number;
  name: string;
  abortController?: AbortController;
  cloudId: string;
}

export const UploadContext = createContext<IUploadManager>({
  upload: () => {
    throw Error("UploadContext has no Provider!");
  },
  uploadSingle: () => {
    throw Error("UploadContext has no Provider!");
  },
  cancel: () => {
    throw Error("UploadContext has no Provider!");
  },
  clear: () => {
    throw Error("UploadContext has no Provider!");
  },
  clearSingle: () => {
    throw Error("UploadContext has no Provider!");
  },
  addRefreshCallback: () => {
    throw Error("UploadContext has no Provider!");
  },
  removeRefreshCallback: () => {
    throw Error("UploadContext has no Provider!");
  },
});

export const UploadFilesContext = createContext<UploadFile[]>([]);

interface UploadContextWrapperProps {
  children: JSX.Element | JSX.Element[];
}

const getNewUIDGenerator = (): (() => number) => {
  let lastId = -1;
  return () => {
    lastId += 1;
    return lastId;
  };
};

export function UploadProvider({
  children,
}: UploadContextWrapperProps): JSX.Element {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [refreshCallbacks, setRefreshCallbacks] = useState<Array<() => void>>(
    []
  );
  const {
    createChunkedLayerUpload,
    createChunkedMarkupUpload,
    createChunkedMediaUpload,
    uploadChunks,
  } = useChunkedUpload();
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false);

  // I hate using an isBusy flag, but I can't think of a better way...
  const [uploadState, setUploadState] = useState<{
    busy: boolean;
    uploads: UploadFileData[];
  }>({ busy: false, uploads: [] });

  const uploadSeveralFiles = useCallback(
    async (files: UploadFileData[]) => {
      console.log(`Uploading: ${files.map((f) => f.file.name).join(",")}`);
      const promises = files.map((f) => uploadChunks(f));
      const res = await Promise.all(promises);
      if (res.find((r) => r !== null)) setNeedsRefreshing(true);
    },
    [uploadChunks]
  );

  useEffect(() => {
    if (uploadState.busy || uploadState.uploads.length === 0) return;
    setUploadState((s) => ({ ...s, busy: true }));

    // Upload MAX_CONCURRENT chunks
    const toUpload = uploadState.uploads.slice(0, MAX_CONCURRENT);
    console.log(`Uploading ${toUpload.length} files`, toUpload);
    uploadSeveralFiles(toUpload)
      .catch((e) => logger.warn(e, "UploadProvider"))
      .finally(() => {
        setUploadState((s) => ({
          uploads: s.uploads.filter(
            (u) => !toUpload.map((x) => x.id).includes(u.id)
          ),
          busy: false,
        }));
      });
  }, [uploadState, uploadSeveralFiles]);

  const generateUID = useMemo(getNewUIDGenerator, []);

  const updateFileFactory = useCallback(
    (id: number) =>
      ({
        deltaLoaded,
        ...updatedData
      }: Partial<UploadFile> & { deltaLoaded?: number }) =>
        setFiles((oldFiles) =>
          oldFiles
            .map((f) =>
              f.id === id
                ? {
                    ...f,
                    ...updatedData,
                    loaded: deltaLoaded
                      ? f.loaded + deltaLoaded
                      : updatedData.loaded
                      ? updatedData.loaded
                      : f.loaded,
                  }
                : f
            )
            .sort((a, b) => b.id - a.id)
        ),
    []
  );

  const uploadSingle = useCallback(
    async (upload: UploadType) => {
      const id = generateUID();
      const updateFile = updateFileFactory(id);
      // const data = new FormData();
      // data.append("file", upload.file);
      const abortController = new AbortController();
      const { signal } = abortController;
      setFiles((oldFiles) =>
        oldFiles.concat([
          {
            id,
            name: upload.file.name,
            status: "pending",
            loaded: 0,
            total: upload.file.size,
            abortController,
            cloudId: "",
          },
        ])
      );

      // Prepare chunked upload
      try {
        if (isMediaUploadType(upload)) {
          const res = await createChunkedMediaUpload(
            id,
            upload,
            updateFile,
            signal
          );
          setUploadState((oldUploads) => ({
            ...oldUploads,
            uploads: [...oldUploads.uploads, res],
          }));
          return res;
        }
        if (isLayerUploadType(upload)) {
          const res = await createChunkedLayerUpload(
            id,
            upload,
            updateFile,
            signal
          );
          setUploadState((oldUploads) => ({
            ...oldUploads,
            uploads: [...oldUploads.uploads, res],
          }));
          return res;
        }
        if (isMarkupUploadType(upload)) {
          const res = await createChunkedMarkupUpload(
            id,
            upload,
            updateFile,
            signal
          );
          setUploadState((oldUploads) => ({
            ...oldUploads,
            uploads: [...oldUploads.uploads, res],
          }));
          return res;
        }

        updateFile({
          total: 0,
          loaded: 0,
          status: "failed",
          error: "Upload type not supported.",
        });
        return null;
      } catch (e) {
        if (e instanceof ApiError) {
          updateFile({
            total: 0,
            loaded: 0,
            status: "cancelled",
            error: e.message,
          });
        } else if (e instanceof Error && e.message === "Cancelled") {
          updateFile({ total: 0, loaded: 0, status: "cancelled" });
        } else if (e instanceof Error) {
          updateFile({
            total: 0,
            loaded: 0,
            status: "failed",
            error: e.message,
          });
        } else {
          updateFile({
            total: 0,
            loaded: 0,
            status: "failed",
            error: "Unknown reason",
          });
        }

        return null;
      }
    },
    [
      generateUID,
      createChunkedLayerUpload,
      createChunkedMarkupUpload,
      createChunkedMediaUpload,
      updateFileFactory,
    ]
  );

  /**
   * Given a list of uploads to create, start the upload process and add them to queue.
   */
  const upload = useCallback(
    (uploads: UploadType[]) => {
      uploads.map(uploadSingle);
    },
    [uploadSingle]
  );

  useEffect(() => {
    if (needsRefreshing) {
      for (const cb of refreshCallbacks) cb();
      setNeedsRefreshing(false);
    }
  }, [needsRefreshing, refreshCallbacks]);

  const [cancelQueue, setCancelQueue] = useState<UploadFile["id"][]>([]);

  useEffect(() => {
    if (cancelQueue.length === 0) return;
    for (const id in cancelQueue)
      files
        .find((f) => f.id.toString() === id)
        ?.abortController?.abort("Cancelled");
    setCancelQueue([]);
  }, [files, cancelQueue]);

  const cancel = useCallback((id: UploadFile["id"]) => {
    setCancelQueue((oldQueue) => [...oldQueue, id]);
  }, []);

  const clearSingle = useCallback((id: UploadFile["id"]) => {
    setFiles((oldFiles) => oldFiles.filter((oldItem) => oldItem.id !== id));
  }, []);

  const clear = useCallback(() => {
    setFiles((oldFiles) => oldFiles.filter(uploadIsActive));
  }, []);

  // Warn page unload if uploads are in progress
  useEffect(() => {
    if (files.filter(uploadIsActive).length > 0)
      window.onbeforeunload = preventNavigating;
    else window.onbeforeunload = () => null;
  }, [files]);

  const uploadManager: IUploadManager = useMemo(
    () => ({
      upload,
      uploadSingle,
      cancel,
      clear,
      clearSingle,
      addRefreshCallback: (cb) =>
        setRefreshCallbacks((oldCbs) => [...oldCbs, cb]),
      removeRefreshCallback: (cb) =>
        setRefreshCallbacks((oldCbs) => oldCbs.filter((oldCb) => oldCb !== cb)),
    }),
    [upload, uploadSingle, cancel, clear, clearSingle]
  );

  return (
    <UploadContext.Provider value={uploadManager}>
      <UploadFilesContext.Provider value={files}>
        {children}
      </UploadFilesContext.Provider>
    </UploadContext.Provider>
  );
}

import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  type ConfirmState,
  Layout,
  type SettingsState,
  TeamSortMode,
} from "@/data/types/settings.types";

const initialState: SettingsState = {
  layout: Layout.Gallery,
  isDarkMode: undefined,
  teamSortMode: TeamSortMode.MostRecent,
  hasSkippedLogin: false,
  hasSeenFileCarousel: false,
  hasSeenMicCarousel: false,
  network: {
    connected: false,
    mode: "none",
  },
  showQueryDevtools: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLayout(state, { payload }: PayloadAction<Layout>) {
      return { ...state, layout: payload };
    },
    setDarkMode(state, { payload }: PayloadAction<boolean>) {
      return { ...state, isDarkMode: payload };
    },
    setConfirm(state, { payload }: PayloadAction<ConfirmState | undefined>) {
      return { ...state, confirm: payload };
    },
    setTeamsortMode(state, { payload }: PayloadAction<TeamSortMode>) {
      return { ...state, teamSortMode: payload };
    },
    setSkip(state, { payload }: PayloadAction<boolean>) {
      return { ...state, hasSkippedLogin: payload };
    },
    setFile(state, { payload }: PayloadAction<boolean>) {
      return { ...state, hasSeenFileCarousel: payload };
    },
    setMic(state, { payload }: PayloadAction<boolean>) {
      return { ...state, hasSeenMicCarousel: payload };
    },
    setNetworkStatus(
      state,
      { payload }: PayloadAction<"wifi" | "mobile" | "none">
    ) {
      return {
        ...state,
        network: { mode: payload, connected: payload !== "none" },
      };
    },
    clear() {
      return initialState;
    },
    toggleQueryDevtools(state) {
      return { ...state, showQueryDevtools: !state.showQueryDevtools };
    },
  },
});

export const settingsAction = settingsSlice.actions;
export default settingsSlice.reducer;

import { ErrorOutlineOutlined, WarningOutlined } from "@mui/icons-material";
import CancelledIcon from "@mui/icons-material/Cancel";
import CompleteIcon from "@mui/icons-material/Check";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import GetApp from "@mui/icons-material/GetApp";
import QueuedIcon from "@mui/icons-material/Schedule";

import { useExport, useExportItem } from "@/context/Export";
import type { ExportItem } from "@/context/Export/utilities";

import SyncListItem from "../SyncListItem/SyncListItem";

type Props = {
  id: ExportItem["id"];
};

const ExportStatusItem = ({ id }: Props) => {
  const xport = useExportItem(id);
  const { clearSingle, cancel } = useExport();

  return xport ? (
    xport.status === "failed" ? (
      <SyncListItem
        Icon={<ErrorOutlineOutlined />}
        title={xport.name}
        message={`Error: ${xport.error}`}
        RightIcon={<DeleteOutline />}
        onRightIconClick={() => clearSingle(xport.id)}
      />
    ) : xport.status === "cancelled" ? (
      <SyncListItem
        Icon={<WarningOutlined />}
        title={xport.name}
        message={"Download cancelled"}
        RightIcon={<DeleteOutline />}
        onRightIconClick={() => clearSingle(xport.id)}
      />
    ) : xport.status === "pending" ? (
      <SyncListItem
        Icon={<QueuedIcon />}
        title={xport.name}
        message={"Pending"}
        RightIcon={<CancelledIcon />}
        onRightIconClick={() => cancel(xport.id)}
      />
    ) : xport.status === "in progress" ? (
      <SyncListItem
        Icon={<GetApp />}
        title={xport.name}
        RightIcon={<CancelledIcon />}
        message={`${((xport.loaded / xport.total) * 100).toFixed(1)}% ${
          xport.type === "mediaItem" ? "Downloading Media" : "Generating report"
        }...`}
        onRightIconClick={() => cancel(xport.id)}
      />
    ) : xport.status === "success" ? (
      <SyncListItem
        Icon={<CompleteIcon />}
        title={xport.name}
        RightIcon={<DeleteOutline />}
        message={"Download complete"}
        onRightIconClick={() => clearSingle(xport.id)}
      />
    ) : (
      <SyncListItem
        title={xport.name}
        message={"Unknown state"}
        RightIcon={<DeleteOutline />}
        onRightIconClick={() => clearSingle(xport.id)}
      />
    )
  ) : (
    <div />
  );
};

export default ExportStatusItem;

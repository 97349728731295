/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountInfoDto } from "../models/AccountInfoDto";
import type { ActivityGetDto } from "../models/ActivityGetDto";
import type { ActivityTagGetDto } from "../models/ActivityTagGetDto";
import type { MediaItemGraphRecordGetDto } from "../models/MediaItemGraphRecordGetDto";
import type { ProjectsGetDto } from "../models/ProjectsGetDto";
import type { TeamsGetDto } from "../models/TeamsGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get account information for all the users I know about,
   * by having access to the same Site. (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns AccountInfoDto Success
   * @throws ApiError
   */
  public getApiUsers(
    xVersion: string = "2",
  ): CancelablePromise<Array<AccountInfoDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/users",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get account information for given user ID.
   * The calling user must have "access" to the requested user,
   * by having access to the same Site or having a relevant sharecode. (Auth policies: UserOrSharecode)
   * **Scopes**: UserOrSharecode
   * @param userId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns AccountInfoDto Success
   * @throws ApiError
   */
  public getApiUsers1(
    userId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<AccountInfoDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/users/{userId}",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get teams of a particular user.
   * The calling user must have "access" to the requested user,
   * by having access to the same Site or having a relevant sharecode. (Auth policies: UserOrSharecode)
   * **Scopes**: UserOrSharecode
   * @param userId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public getApiUsersTeams(
    userId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<Array<TeamsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/users/{userId}/teams",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get projects of a particular user.
   * The calling user must have "access" to the requested user,
   * by having access to the same Site or having a relevant sharecode. (Auth policies: UserOrSharecode)
   * **Scopes**: UserOrSharecode
   * @param userId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public getApiUsersProjects(
    userId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<Array<ProjectsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/users/{userId}/projects",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get full media graph for a user. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param userId User to retrieve data for
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Needs to have dateString as well.
   * @param granularity Either "year", "month", "week" (default) or "day"
   * @param xVersion The requested API version
   * @returns MediaItemGraphRecordGetDto Success
   * @throws ApiError
   */
  public getApiUsersGraph(
    userId: string,
    dateString?: string,
    dateStringEnd?: string,
    granularity: string = "week",
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemGraphRecordGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/users/{userId}/graph",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        granularity: granularity,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per tag for the given user. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param userId User to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityTagGetDto Success
   * @throws ApiError
   */
  public getApiUsersActivityTags(
    userId: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityTagGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/users/{userId}/activity/tags",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per team for the given user. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param userId User to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityGetDto Success
   * @throws ApiError
   */
  public getApiUsersActivityTeams(
    userId: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/users/{userId}/activity/teams",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

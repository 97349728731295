import { useCallback } from "react";
import {
  type NavigateOptions,
  resolvePath,
  useLocation,
  useNavigate,
} from "react-router-dom";

type Content = string | number | boolean;

type Options = NavigateOptions & {
  relativeRoute?: boolean;
  keepQuery?: boolean;
  params?: {
    [key: string]: Content;
  };
};

const objToUrlParams = (data: { [key: string]: Content }): URLSearchParams => {
  const params = new URLSearchParams();
  for (const key in data) params.append(key, `${data[key]}`);
  return params;
};

const useAppNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();

  const appNavigate = useCallback<(to: string, options?: Options) => void>(
    // @todo - make this dynamic
    (to: string, options: Options = {}) => {
      const searchParams = new URLSearchParams(search);
      const { keepQuery, params, ...o } = options;
      if (keepQuery && params) {
        console.log("KeepQuery", keepQuery, "params", params);
        const kept = objToUrlParams(params);
        kept.forEach((value, key) => searchParams.set(key, value));
      }
      const path = options.relativeRoute
        ? resolvePath(to, location.pathname).pathname
        : to;
      if (keepQuery) navigate(`${path}?${searchParams.toString()}`, o);
      else if (params) {
        const sp = objToUrlParams(params);
        navigate(`${path}?${sp.toString()}`, o);
      } else navigate(to, o);
    },
    [location.pathname, navigate, search]
  );

  return appNavigate;
};

export default useAppNavigate;

import type { LoaderFunctionArgs } from "react-router-dom";

import organisationQueryApi from "@/data/api/organisation";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function organisationSettingsGeneralLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { orgId } = params;

  if (!orgId) {
    throw new Response("No Organisation Id Specified", { status: 400 });
  }

  const account = await authenticate(request);

  if (!account) {
    return {
      organisationId: orgId,
    };
  }

  queryClient.prefetchQuery(organisationQueryApi.getOrganisation(orgId));

  return {
    organisationId: orgId,
  };
}

export type OrganisationSettingsGeneralLoaderReturnType = Awaited<
  ReturnType<typeof organisationSettingsGeneralLoader>
>;

import type { LoaderFunctionArgs } from "react-router-dom";

// import fieldViewQueryApi from "@/data/api/fieldview";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";
import connectApi from "@/data/api/connect";

export default async function settingsIntegrationLoader({
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) return null;

  queryClient.prefetchQuery(connectApi.getIntegrations);

  return null;
}

export type SettingsIntegrationLoaderReturnType = Awaited<
  ReturnType<typeof settingsIntegrationLoader>
>;

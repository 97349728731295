import type { LoaderFunctionArgs } from "react-router-dom";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";
import { UserRole } from "@/api/generated";
import { fetchCachedQuery } from "@/data/api/helpers";
import accountApi from "@/data/api/accounts";

export default async function salesPageLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) {
    return null;
  }

  const user = await fetchCachedQuery(queryClient, accountApi.getMe);

  if (
    !user.roles?.includes(UserRole.SALES) &&
    !user.roles?.includes(UserRole.DEVELOPER)
  ) {
    throw new Response("Unauthorized", {
      status: 401,
      statusText:
        "Only authorized BuiltView personnel may access this section of the website.",
    });
  }
  return null;
}

export type SalesPageLoaderReturnType = Awaited<
  ReturnType<typeof salesPageLoader>
>;

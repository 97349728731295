import { useContext } from "react";

import {
  type IUploadManager,
  UploadContext,
  type UploadFile,
  UploadFilesContext,
} from "./provider";

/**
 * useUpload hook provides an upload manager so you can upload media items.
 * @returns {upload, addRefreshCallback, removeRefreshCallback}
 */
export const useUpload = (): IUploadManager => useContext(UploadContext);

export const useUploadFiles = (): UploadFile[] =>
  useContext(UploadFilesContext);

export const useUploadFile = (id: UploadFile["id"]) => {
  const uploads = useUploadFiles();
  return uploads.find((f) => f.id === id);
};

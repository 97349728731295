import type { LoaderFunctionArgs } from "react-router-dom";

import projectQueryApi from "@/data/api/project";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function settingsProjectsLoader({
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) {
    return null;
  }

  queryClient.prefetchQuery(projectQueryApi.getProjects());

  return null;
}

export type SettingsProjectsLoaderReturnType = Awaited<
  ReturnType<typeof settingsProjectsLoader>
>;

import type { LoaderFunctionArgs } from "react-router-dom";

import { readURLParams } from "@/context/filter";
import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";
import floorplanQueryApi from "@/data/api/floorplans";

export default async function galleryFloorplansLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);

  const urlParams = readURLParams(searchParams);
  const account = await authenticate(request);

  const id = params.siteId;

  if (!id) {
    throw new Response("No Team Id Specified", { status: 400 });
  }

  if (!account) {
    return { id };
  }

  if (urlParams.floorplanId)
    queryClient.prefetchQuery({
      ...teamQueryApi.getTeamMediaFloorplan(id, urlParams.floorplanId ?? "", {
        bbox: urlParams.bbox ?? undefined,
        dateString: urlParams.dateString,
        dateStringEnd: urlParams.dateStringEnd,
        dayOnly: urlParams.dayOnly,
        finishedOnly: urlParams.finishedOnly,
        includeEmpty: urlParams.includeEmpty,
        is360: urlParams.is360,
        publishedOnly: urlParams.publishedOnly,
        sort: urlParams.sort,
        search: urlParams.search,
        tags: urlParams.tags ?? undefined,
        tagMode: urlParams.tagMode,
        types: urlParams.types ? [urlParams.types] : undefined,
        userIds: urlParams.userIds ?? undefined,
        gpsOnly: true,
      }),
    });

  queryClient.prefetchQuery(floorplanQueryApi.getFloorplansByTeam(id));

  return { id };
}

export type GalleryLoaderReturnType = Awaited<
  ReturnType<typeof galleryFloorplansLoader>
>;

import type { LoaderFunctionArgs } from "react-router-dom";

import userQueryApi from "@/data/api/user";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function techSupportUserLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { userId } = params;

  if (!userId) throw new Response("No User Id Specified", { status: 400 });

  const account = await authenticate(request);

  if (!account) return { userId };

  queryClient.prefetchQuery(userQueryApi.getAllUsers);

  return { userId };
}

export type TechSupportUserLoaderReturnType = Awaited<
  ReturnType<typeof techSupportUserLoader>
>;

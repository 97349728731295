import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { useDisclosure } from "@/hooks/useDisclosure";

export type SidebarContextType = {
  state: boolean;
  cycle: () => void;
};

export type SidebarProviderProps = {
  children: React.ReactNode;
};

export const SidebarContext = React.createContext<SidebarContextType>(
  {} as SidebarContextType,
);

export const SidebarProvider: React.FC<SidebarProviderProps> = ({
  children,
}) => {
  const currentTheme = useTheme();
  const isMobile = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const { isOpen, onToggle } = useDisclosure({
    localStorageKey: "sidebar",
    defaultOpen: !isMobile,
  });

  return (
    <SidebarContext.Provider value={{ state: isOpen, cycle: onToggle }}>
      {children}
    </SidebarContext.Provider>
  );
};

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotifPrefsDto } from "../models/NotifPrefsDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class NotificationPreferencesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Return notification preferences of the user (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns NotifPrefsDto Success
   * @throws ApiError
   */
  public getApiNotificationPreferences(
    xVersion: string = "2",
  ): CancelablePromise<NotifPrefsDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/notification-preferences",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Update notification preferences of the user (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns NotifPrefsDto Success
   * @throws ApiError
   */
  public putApiNotificationPreferences(
    xVersion: string = "2",
    requestBody?: NotifPrefsDto,
  ): CancelablePromise<NotifPrefsDto> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/notification-preferences",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

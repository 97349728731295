import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import {
  type AccountsGetDto,
  LogicalOperator,
  MediaType,
  SortOption,
  type TeamsGetDto,
} from "@/api/generated";
import useAppNavigate from "@/hooks/useAppNavigate";

import { useTrack } from "./Tracking";

const DateFormat = "yyyy-MM-dd'T'HH:MM:ssZZ";

type BBoxType = string;

/**
 * NOTE:  DO NOT CHANGE THESE (without adding backwards compatibility)
 * If you do, existing URLs will stop working.
 */
const URLS = {
  SORT: "st",
  TAGS: "t",
  TAG_MODE: "tm",
  MEDIA: "m",
  SEARCH: "sr",
  UPLOADERS: "u",
  DATE_FROM: "ds",
  DATE_TO: "de",
  LAYOUT: "l",
  IS_360: "360",
  BBOX: "bx",
  FLOORPLAN: "fp",
  FINISHED: "fn",
  PUBLISHED: "pb",
  RECYCLE_BIN: "rb",
};

export const JsDateToFilterString = (date: Date): string =>
  DateTime.fromJSDate(date).toFormat(DateFormat);
export const JsDateFromFilterString = (date: string): Date =>
  DateTime.fromFormat(date, DateFormat).toJSDate();

export type FilterParams = {
  sort?: SortOption;
  search?: string;
  siteIds?: TeamsGetDto["id"][];
  dayOnly?: boolean;
  dateString?: string;
  dateStringEnd?: string;
  userIds?: AccountsGetDto["id"][];
  includeEmpty?: boolean;
  tags?: string[];
  tagMode?: LogicalOperator;
  types?: MediaType;
  hasGps?: boolean;
  publishedOnly?: boolean;
  finishedOnly?: boolean;
  bbox?: BBoxType;
  floorplanId?: string;
  is360?: boolean;
  deleted?: boolean;
};

export const processType = (initial: string | null): MediaType | undefined => {
  switch (initial) {
    case "Photo":
      return MediaType.PHOTO;
    case "Video":
      return MediaType.VIDEO;
    default:
      return undefined;
  }
};

export const processSort = (initial: string | null): SortOption | undefined => {
  switch (initial) {
    case "DateNewestFirst":
      return SortOption.DATE_NEWEST_FIRST;
    case "DateOldestFirst":
      return SortOption.DATE_OLDEST_FIRST;
    default:
      return undefined;
  }
};

// export const processBBox = (initial: string | null) => {
//   if (!initial) return undefined;
//   const rawBBox = initial.split(",");
//   if (rawBBox.length !== 4) return undefined;
//   const numbers = rawBBox.map(parseFloat) as BBoxType;
//   return numbers;
// };

export const readURLParams = (params: URLSearchParams): FilterParams => {
  const tags = params.get(URLS.TAGS)?.split(",");

  const search = params.get(URLS.SEARCH) ?? undefined;

  const uploaders = params.get(URLS.UPLOADERS)?.split(",");

  const rawType = params.get(URLS.MEDIA);
  const type = processType(rawType);

  const rawSort = params.get(URLS.SORT);
  const sort = processSort(rawSort);

  const rawFrom = params.get(URLS.DATE_FROM) ?? undefined;
  // const from = rawFrom ? DateTime.fromISO(rawFrom).toISO() : undefined;

  const rawTo = params.get(URLS.DATE_TO) ?? undefined;
  // const to = rawTo ? DateTime.fromISO(rawTo) : undefined;

  const is360Str = params.get(URLS.IS_360);
  const is360 = is360Str === null ? undefined : is360Str === "true";

  const tagModeStr = params.get(URLS.TAG_MODE);
  const tagMode =
    tagModeStr === LogicalOperator.AND
      ? LogicalOperator.AND
      : tagModeStr === LogicalOperator.OR
      ? LogicalOperator.OR
      : undefined;

  const floorplanIdStr = params.get(URLS.FLOORPLAN);
  const floorplanId = floorplanIdStr ? floorplanIdStr : undefined;

  const bbox = params.get(URLS.BBOX) ?? undefined;

  const finishedOnly = params.get(URLS.FINISHED) ? true : undefined;

  const publishedOnly = params.get(URLS.PUBLISHED) ? true : undefined;

  const recycleBin = params.get(URLS.RECYCLE_BIN) ? true : undefined;

  return {
    search,
    tags,
    tagMode,
    userIds: uploaders,
    types: type,
    sort,
    dateStringEnd: rawTo,
    dateString: rawFrom,
    is360,
    publishedOnly,
    bbox,
    floorplanId,
    finishedOnly,
    deleted: recycleBin,
  };
};

export const writeURLParams = (
  params: ReturnType<typeof readURLParams>
): URLSearchParams => {
  const newParams = new URLSearchParams();
  if (params.tags?.length) {
    newParams.set(URLS.TAGS, params.tags.join(","));
  }

  if (params.search) {
    newParams.set(URLS.SEARCH, params.search);
  }

  if (params.userIds && params.userIds.length > 0) {
    newParams.set(URLS.UPLOADERS, params.userIds.join(","));
  }

  if (params.types) {
    newParams.set(URLS.MEDIA, params.types);
  }

  if (params.sort) {
    newParams.set(URLS.SORT, params.sort);
  }
  if (params.tagMode) {
    newParams.set(URLS.TAG_MODE, params.tagMode);
  }

  if (params.dateString) {
    newParams.set(URLS.DATE_FROM, params.dateString);
  }

  if (params.dateStringEnd) {
    newParams.set(URLS.DATE_TO, params.dateStringEnd);
  }

  if (params.floorplanId) {
    newParams.set(URLS.FLOORPLAN, params.floorplanId);
  }

  if (params.deleted) {
    newParams.set(URLS.RECYCLE_BIN, "true");
  }

  // if (params.is360 !== undefined) {
  if (params.is360) {
    newParams.set(URLS.IS_360, "true");
  } else if (params.is360 === false) {
    newParams.set(URLS.IS_360, "false");
  }

  // }

  if (params.bbox?.length) {
    newParams.set(URLS.BBOX, params.bbox);
  }

  if (params.finishedOnly) {
    newParams.set(URLS.FINISHED, "true");
  }

  if (params.publishedOnly) {
    newParams.set(URLS.PUBLISHED, "true");
  }

  return newParams;
};

export const useParamsFilter = () => {
  const [searchParams, setURLParams] = useSearchParams();
  const track = useTrack();

  /** The current url parameters */
  const urlFilters = useMemo(() => readURLParams(searchParams), [searchParams]);
  const navigate = useAppNavigate();

  /**
   * Sets parameters and overwrites the existing ones
   * @param params The parameters you want to set
   * @param teamId The current TeamId, required if you want to navigate to list after setting search
   */
  const setParams = useCallback(
    (params: Partial<ReturnType<typeof readURLParams>>, teamId?: string) => {
      const newParams = { ...urlFilters, ...params };
      console.log("Setting params", urlFilters, params);
      const newURLParams = writeURLParams(newParams);
      if (params.sort !== undefined && params.sort !== urlFilters.sort) {
        track({
          name: "Sort by",
          data: { option: params.sort },
          siteId: teamId,
        });
      }

      if (
        params.search !== undefined &&
        params.search !== urlFilters.search &&
        teamId
      ) {
        track({ name: "Search", data: { Search: params.search } });
        navigate(`/gallery/${teamId}/list?${newURLParams}`, { replace: true });
      } else {
        track({ name: "Filter", data: params, siteId: teamId });
        setURLParams(newURLParams, { replace: true });
      }
    },
    [navigate, setURLParams, track, urlFilters]
  );

  return { track, urlFilters, setParams };
};

/**  */
export const useInSearchMode = () => {
  const [searchParams] = useSearchParams();

  /** The current url parameters */
  const urlFilters = useMemo(() => readURLParams(searchParams), [searchParams]);

  return !!urlFilters.search;
};

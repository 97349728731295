enum SitePermissionFlagBits {
  Any = 0x7fffffff,

  // "Read" permissions - generally safer to grant to anyone
  ///<summary>View media in a team</summary>
  ViewTeamMedia = 0x1,
  /// <summary>See the team dashboard</summary>
  ViewTeamDashboard = 0x2,
  /// <summary>See who else is on this team</summary>
  ViewTeamUsers = 0x4,
  // UNUSED = 0x8,
  /// <summary>If this team is archived, you can still view it</summary>
  ViewArchived = 0x10,
  /// <summary>Can see media items that were deleted from this site</summary>
  ViewRecycleBin = 0x20,
  // UNUSED = 0x40,
  /// <summary>Can see the audit trail of activity on this site
  ViewAuditTrail = 0x80,

  // "Write" permissions - require more scrutiny
  /// <summary>Invite new users to the team by email</summary>
  InviteByEmail = 0x100,
  /// <summary>Create invite links to a team that anyone can use</summary>
  CreateInviteLink = 0x200,
  /// <summary>Change the role of existing team members</summary>
  ChangeUserRoles = 0x400,
  /// <summary>Change the definition of team roles</summary>
  EditUserRoles = 0x800,
  /// <summary>Add/update the list of team tags, including autotags</summary>
  ManageTeamTags = 0x1000,
  /// <summary>Edit team metadata, like name, description, project</summary>
  EditTeamMetadata = 0x2000,
  /// <summary>Add/edit integrations on this team</summary>
  ManageIntegrations = 0x4000,
  /// <summary>Link api tokens to this team</summary>
  CreateApiTokens = 0x8000,
  /// <summary>Create floorplans for this team</summary>
  AddFloorplans = 0x10000,
  /// <summary>Edit floorplans for this team </summary>
  EditFloorplans = 0x20000,
  /// <summary>Can export the full team data in zip or csv format
  ExportTeamData = 0x40000,
  /// <summary>Can add and edit map layers</summary>
  ManageLayers = 0x80000,
  // UNUSED = 0x100000,
  // UNUSED = 0x200000,
  // UNUSED = 0x400000,
  // UNUSED = 0x800000,
  // UNUSED = 0x1000000,
  // UNUSED = 0x2000000,
  // UNUSED = 0x4000000,
  // UNUSED = 0x8000000,
  /// <summary>Archive this team</summary>
  ArchiveTeam = 0x10000000,
  // UNUSED = 0x10000000,
  // UNUSED = 0x20000000,
  // UNUSED = 0x40000000,
}

export default SitePermissionFlagBits;

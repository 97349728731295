import type { LoaderFunctionArgs } from "react-router-dom";

import accountApi from "@/data/api/accounts";
import organisationQueryApi from "@/data/api/organisation";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";
import OrganisationPermissionFlagBits from "@/utils/permissionFlagBits/OrganisationPermissionFlagBits";
import PermissionError from "@/utils/permissionFlagBits/PermissionError";

export default async function organisationDashboardLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const id = params.orgId;

  if (!id) {
    throw new Response("No Org Id Specified", { status: 400 });
  }

  const account = await authenticate(request);

  if (!account) {
    return null;
  }

  const me = await queryClient.ensureQueryData(accountApi.getMe);

  if (!me) {
    return null;
  }

  if (!me.organisationPermissions) {
    return null;
  }

  const orgPermissions = me.organisationPermissions.organisation;

  if (
    !(orgPermissions & OrganisationPermissionFlagBits.ViewOrganisationDashboard)
  ) {
    throw new PermissionError(
      orgPermissions,
      OrganisationPermissionFlagBits.ViewOrganisationDashboard,
    );
  }

  queryClient.prefetchQuery(organisationQueryApi.getOrganisation(id));

  return null;
}

export type OrganisationDashboardLoaderReturnType = Awaited<
  ReturnType<typeof organisationDashboardLoader>
>;

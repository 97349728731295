import Cancel from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import {
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListItem,
} from "@mui/material";

interface Props {
  Icon?: React.ReactNode;
  RightIcon?: React.ReactNode;
  title: string;
  message?: string;
  onClick?: () => void;
  onRightIconClick?: () => void;
  classes?: {
    container?: string;
  };
}

const SyncListItem = ({
  Icon,
  RightIcon,
  title,
  message = "",
  onClick = () => null,
  onRightIconClick = () => null,
}: Props) => {
  return (
    <ListItem onClick={onClick}>
      <ListItemIcon>{Icon ?? <InfoIcon />}</ListItemIcon>
      <ListItemText primary={title} secondary={message} />

      <ListItemSecondaryAction>
        <IconButton
          onClick={onRightIconClick}
          aria-label={RightIcon ? "right icon" : "cancel"}
          size="large"
        >
          {RightIcon ?? <Cancel />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default SyncListItem;

import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import Button, { type ButtonProps } from "@mui/material/Button";

type OptionContent = {
  value: string;
  label: string;
  buttonColor: ButtonProps["color"];
};

interface SelectOptionModalProps {
  handleCancel: () => void;
  handleSelect: (option: string) => void;
  show: boolean;
  content: {
    title: string;
    message: string | JSX.Element;
    options: OptionContent[];
  };
}

const SelectOptionModal: React.FC<SelectOptionModalProps> = ({
  handleCancel,
  handleSelect,
  content,
  show,
}: SelectOptionModalProps) => {
  return (
    <Dialog onClose={handleCancel} open={show} maxWidth="md" fullWidth>
      <DialogTitle>{content.title}</DialogTitle>
      <Divider />
      <DialogContentText>{content.message}</DialogContentText>
      <DialogActions>
        <Button onClick={handleCancel} variant="outlined" color={"primary"}>
          Cancel
        </Button>
        {content.options.map((option, index) => (
          <Button
            key={option.value}
            onClick={() => handleSelect(option.value)}
            color={option.buttonColor ?? "primary"}
            variant="contained"
          >
            {option.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default SelectOptionModal;

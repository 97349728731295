/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RestrictionsTierGetDto } from "../models/RestrictionsTierGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class RestrictionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get all defined tiers
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @returns RestrictionsTierGetDto Success
   * @throws ApiError
   */
  public getApiRestrictions(
    xVersion: string = "2",
  ): CancelablePromise<Array<RestrictionsTierGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/restrictions",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

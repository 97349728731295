import { useQuery } from "@tanstack/react-query";

import { CONSTANTS } from "@/common";
import { useParamsFilter } from "@/context/filter";
import { teamQueryApi } from "@/data/api/teams";

import { useTeamId } from ".";

/**
 *
 * @param index Index of the media item out of all media items for a team matching filters (used to calculate page and grab specific item)
 * @returns Hook
 */
export default function useGetTeamMedia(index: number) {
  const pageSize = CONSTANTS.DEFAULT_PAGE_SIZE;
  const page = Math.floor(index / pageSize);
  const pageIndex = index % pageSize;
  const { urlFilters: urlParams } = useParamsFilter();
  const teamId = useTeamId();

  const { data } = useQuery({
    ...teamQueryApi.getTeamMedia(teamId, {
      page,
      size: pageSize,
      bbox: urlParams.bbox ?? undefined,
      dateString: urlParams.dateString,
      dateStringEnd: urlParams.dateStringEnd,
      dayOnly: urlParams.dayOnly,
      finishedOnly: urlParams.finishedOnly,
      includeEmpty: urlParams.includeEmpty,
      is360: urlParams.is360,
      publishedOnly: urlParams.publishedOnly,
      sort: urlParams.sort,
      search: urlParams.search,
      tags: urlParams.tags,
      tagMode: urlParams.tagMode,
      types: urlParams.types ? [urlParams.types] : undefined,
      userIds: urlParams.userIds,
      deleted: urlParams.deleted,
    }),
  });

  const item = data?.results[pageIndex];
  return { item, data };
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerGetDto } from "../models/CustomerGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class CustomersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get a list of all the customers (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns CustomerGetDto Success
   * @throws ApiError
   */
  public getApiCustomers(
    xVersion: string = "2",
  ): CancelablePromise<Array<CustomerGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/customers",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get a specific customer (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   * @param userId
   * @param xVersion The requested API version
   * @returns CustomerGetDto Success
   * @throws ApiError
   */
  public getApiCustomers1(
    userId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<CustomerGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/customers/{userId}",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

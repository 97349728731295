import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import projectQueryApi from "@/data/api/project";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const RolesScreenParamsSchema = z.object({
  projectId: z.string(),
});

export default async function projectRolesLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { projectId } = RolesScreenParamsSchema.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      projectId,
    };
  }

  queryClient.prefetchQuery(projectQueryApi.getProject(projectId));
  queryClient.prefetchQuery(projectQueryApi.getProjectRoles(projectId));

  return {
    projectId,
  };
}

export type ProjectRolesLoaderReturnType = Awaited<
  ReturnType<typeof projectRolesLoader>
>;

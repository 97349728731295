import type { LoaderFunctionArgs } from "react-router-dom";

import projectQueryApi from "@/data/api/project";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";
import PermissionFlags from "@/utils/permissionFlagBits";
import PermissionError from "@/utils/permissionFlagBits/PermissionError";

export default async function projectDashboardLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { projectId } = params;

  if (!projectId) {
    throw new Response("No Project Id Specified", { status: 400 });
  }
  const account = await authenticate(request);

  if (!account) {
    return {
      projectId,
    };
  }

  const project = await queryClient.ensureQueryData(
    projectQueryApi.getProject(projectId),
  );

  console.log(project);

  if (
    !(
      project.permissions.project & PermissionFlags.Project.ViewProjectDashboard
    )
  ) {
    throw new PermissionError(
      PermissionFlags.Project.ViewProjectDashboard,
      project.permissions.project,
    );
  }
  return {
    projectId,
  };
}

export type ProjectDashboardLoaderReturnType = Awaited<
  ReturnType<typeof projectDashboardLoader>
>;

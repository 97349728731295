/// Context for event tracking using Mixpanel

import { useQuery } from "@tanstack/react-query";
import { createContext, useCallback, useEffect, useMemo } from "react";

import api from "@/api/client";
import type {
  AccountInfoDto,
  OrganisationsGetDto,
  ProjectsGetDto,
} from "@/api/generated";
import { CLAIMS, CONSTANTS } from "@/common";
import accountApi from "@/data/api/accounts";
import useIsAuthenticated from "@/features/auth/hooks/useIsAuthenticated";
import type { UploadProgress } from "@/hooks/useChunkedUpload";
import logger from "@/utils/logger";

import type { AnalyticsEventType } from "./event-types";

type EventType = AnalyticsEventType;

export interface ITrackingManager {
  /** Track an event! */
  track: (evt: EventType) => void;
}

export interface UploadFile extends UploadProgress {
  id: number;
  name: string;
  cancelTokenSource?: AbortController;
  cloudId: string;
}

export const TrackingContext = createContext<ITrackingManager>({
  track: () => {
    throw Error("TrackingContext has no Provider!");
  },
});

interface TrackingContextWrapperProps {
  children: JSX.Element | JSX.Element[];
}

export function TrackingProvider({
  children,
}: TrackingContextWrapperProps): JSX.Element {
  useEffect(() => {
    const initMixpanel = async () => {
      const Mixpanel = await import("@/utils/interfaces/mixpanel.interface");
      const token = import.meta.env.VITE_MIXPANEL_TOKEN;

      if (!token) return;

      Mixpanel.default.init(token);
      // logger.success("Mixpanel initiated!", "analytics.actions.ts, TrackInit");

      /** Set up analytics */
    };
    initMixpanel();
  }, []);
  const isAuthenticated = useIsAuthenticated();
  const { data: user } = useQuery({
    ...accountApi.getMe,
    enabled: isAuthenticated,
  });

  const getProjectData = useCallback(async (projectId: string) => {
    try {
      const project = await api.projects.getApiProjects(projectId);
      if (project) {
        let organisation: OrganisationsGetDto | undefined = undefined;
        try {
          if (project.organisationId) {
            organisation = await api.organisations.getApiOrganisations(
              project.organisationId
            );
          }
        } catch (error) {
          logger.warn(error, "Tracking provider::getProjectData");
        }
        return {
          "Project Name": project.name ?? "Unknown",
          "Project Organisation": organisation?.id,
          "Project Organisation Name": organisation?.name,
        };
      }
      return {};
    } catch (error) {
      return {};
    }
  }, []);

  const getOrganisationData = useCallback(async (organisationId: string) => {
    try {
      const organisation = await await api.organisations.getApiOrganisations(
        organisationId
      );
      if (organisation) {
        return {
          Organisation: organisationId,
          "Organisation Name": organisation.name ?? "Unknown",
        };
      }
      return { Organisation: organisationId };
    } catch (error) {
      return { Organisation: organisationId };
    }
  }, []);

  const getSiteData = useCallback(async (siteId: string) => {
    try {
      const site = await api.teams.getApiTeams(siteId);
      if (site) {
        let project: ProjectsGetDto | undefined = undefined;
        let organisation: OrganisationsGetDto | undefined = undefined;
        try {
          if (site.projectId) {
            project = await api.projects.getApiProjects(site.projectId);
          }
          if (site.organisationId) {
            organisation = await api.organisations.getApiOrganisations(
              site.organisationId
            );
          }
        } catch (error) {
          logger.warn(error, "Tracking provider::getSiteData");
        }
        return {
          "Team name": site.title ?? "Unknown",
          teamName: site.title ?? "Unknown",
          "Team Project": project?.name,
          "Team Organisation": organisation?.id,
          "Team Organisation Name": organisation?.name,
        };
      }
      return {};
    } catch (error) {
      return { "Team name": "Unknown", teamName: "Unknown" };
    }
  }, []);

  const getMediaData = useCallback(
    async (mediaId: string, sharecode?: string) => {
      try {
        const media = await api.mediaItems.getApiMedia(
          mediaId,
          undefined,
          sharecode
        );
        if (media) {
          let u: AccountInfoDto | undefined = undefined;
          try {
            u = await api.users.getApiUsers1(
              media.userId,
              undefined,
              sharecode
            );
          } catch (error) {
            logger.error(error, "TrackingProvider, getMediaData");
          }
          return {
            "Capture date": media.timestamp,
            "Owner email": u?.email ?? "Unknown",
            // Length:media.,
            id: media.id,
            is360: media.is360,
            "Owner ID": media.userId,
            type: media.type,
            access: media.access,
            sharecode,
          };
        }
        return {};
      } catch (error) {
        logger.warn(error, "Tracking provider::getMediaData");
        return {};
      }
    },
    []
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: bug in linter
  const track = useCallback(
    async (evt: EventType) => {
      try {
        const { default: Mixpanel } = await import(
          "@/utils/interfaces/mixpanel.interface"
        );

        if (evt.name === "Log in" && !!user) {
          const idp = user.claims[CLAIMS.IDP];
          const sso = !!idp && idp === CONSTANTS.LORA_IDP;

          Mixpanel.identify(user, "msal");
          let organisation: OrganisationsGetDto | undefined = undefined;
          try {
            if (user.organisationId) {
              organisation = await api.organisations.getApiOrganisations(
                user.organisationId
              );
            }
          } catch (error) {
            logger.warn(error, "Tracking provider::track");
          }
          Mixpanel.track(evt.name, {
            userId: user.id,
            "Full name":
              user.givenName && user.familyName
                ? `${user.givenName} ${user.familyName}`
                : user.claims[CLAIMS.DISPLAYNAME],
            "Login with SSO": sso,
            method: "msal",
            idp: idp ?? "BuiltView B2C",
            issuerUserId: user.claims[CLAIMS.IUI],
            "Company (B2C)": user.claims.extension_company,
            "Department (B2C)": user.claims.department,
            "Job Title (B2C)": user.claims.jobTitle,
            "Organisation ID": user.organisationId,
            "Organisation Role": user.organisationRole,
            Organisation: organisation?.id,
            "Organisation Name": organisation?.name,
          });
        } else if (evt.name === "Log out") {
          Mixpanel.reset();
        }

        let data = evt.data;

        if (evt.mediaItemId) {
          try {
            const mediaData = await getMediaData(
              evt.mediaItemId,
              evt.sharecode
            );
            data = { ...data, ...mediaData };
          } catch (error) {
            logger.error(error, "TrackingProvider, track");
          }
        }
        if (evt.siteId) {
          try {
            const siteData = await getSiteData(evt.siteId);
            data = { ...data, ...siteData };
          } catch (error) {
            logger.error(error, "TrackingProvider, track");
          }
        }
        if (evt.projectId) {
          try {
            const projectData = await getProjectData(evt.projectId);
            data = { ...data, ...projectData };
          } catch (error) {
            logger.error(error, "TrackingProvider, track");
          }
        }
        if (evt.organisationId) {
          try {
            const orgData = await getOrganisationData(evt.organisationId);
            data = { ...data, ...orgData };
          } catch (error) {
            logger.error(error, "TrackingProvider, track");
          }
        }

        Mixpanel.track(evt.name, data);
      } catch (error) {
        console.error(error);
      }
    },
    [user, getMediaData, getSiteData, getProjectData, getOrganisationData]
  );

  const trackingManager: ITrackingManager = useMemo(
    () => ({
      track,
    }),
    [track]
  );

  return (
    <TrackingContext.Provider value={trackingManager}>
      {children}
    </TrackingContext.Provider>
  );
}

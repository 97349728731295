import {
  ErrorOutlineOutlined,
  PublishOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import CompleteIcon from "@mui/icons-material/Check";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import QueuedIcon from "@mui/icons-material/Schedule";
import { alpha } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useCallback } from "react";

import { type UploadFile, useUpload, useUploadFile } from "@/context/Upload";

import SyncListItem from "../SyncListItem/SyncListItem";

type Props = {
  id: UploadFile["id"];
};

const UploadItem = ({ id }: Props) => {
  const upload = useUploadFile(id);
  const { cancel, clearSingle } = useUpload();

  const onCancel = useCallback(() => cancel(id), [id, cancel]);
  const onClear = useCallback(() => clearSingle(id), [id, clearSingle]);

  const classes = useStyles();
  return upload ? (
    upload.status === "failed" ? (
      <SyncListItem
        Icon={<ErrorOutlineOutlined />}
        title={upload.name}
        message={`Error: ${upload.error}`}
        RightIcon={<DeleteOutline />}
        onRightIconClick={onClear}
      />
    ) : upload.status === "cancelled" ? (
      <SyncListItem
        Icon={<WarningOutlined />}
        title={upload.name}
        message={"Upload cancelled"}
        RightIcon={<DeleteOutline />}
        onRightIconClick={onClear}
      />
    ) : upload.status === "queued" ? (
      <SyncListItem
        Icon={<QueuedIcon />}
        title={upload.name}
        message={"Queued for upload"}
        onRightIconClick={onCancel}
        classes={{ container: classes.clickable }}
      />
    ) : upload.status === "pending" ? (
      <SyncListItem
        Icon={<QueuedIcon />}
        title={upload.name}
        message={"Pending"}
        onRightIconClick={onCancel}
        classes={{ container: classes.clickable }}
      />
    ) : upload.status === "in progress" ? (
      <SyncListItem
        Icon={<PublishOutlined />}
        title={upload.name}
        message={`${((upload.loaded / upload.total) * 100).toFixed(
          2,
        )}% Uploading media...`}
        onRightIconClick={onCancel}
        classes={{ container: classes.clickable }}
      />
    ) : upload.status === "success" ? (
      <SyncListItem
        Icon={<CompleteIcon />}
        title={upload.name}
        RightIcon={<DeleteOutline />}
        message={"Upload complete"}
        onRightIconClick={onClear}
      />
    ) : (
      <SyncListItem
        title={upload.name}
        message={"Unknown state"}
        RightIcon={<DeleteOutline />}
        onRightIconClick={onClear}
      />
    )
  ) : null;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    syncitem: { display: "flex", margin: 8, width: "calc(100% - 16px)" },
    thumbnail: {
      height: 100,
      width: 100,
      objectFit: "cover",
    },
    clickable: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.04),
      },
    },
    errorText: {
      color: theme.palette.warning.main,
    },
    successText: {
      color: theme.palette.success.main,
    },
  }),
);

export default UploadItem;

export interface VideoMetaResult {
  durationMs: number;
  resolution: {
    height: number;
    width: number;
  };
}

const getVideoMeta = (file: File) =>
  new Promise<VideoMetaResult>((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve({
        durationMs: video.duration * 1000,
        resolution: {
          height: video.videoHeight,
          width: video.videoWidth,
        },
      });
    };

    video.onerror = (error) => {
      reject(error);
    };

    video.src = URL.createObjectURL(file);
  });

export default getVideoMeta;

import loadable from "@loadable/component";
import { CssBaseline } from "@mui/material";
import { type Theme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ErrorBoundary } from "@sentry/react";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { ConfirmProvider } from "material-ui-confirm";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import type { FallbackProps } from "@/pages/ErrorFallbackPage/ErrorFallback";
import router from "@/router";
import CenteredLoadingComponent from "./components/CenteredLoadingComponent";
import { ToastProvider } from "./components/Snackbar/ToastProvider";
import SyncManager from "./components/organisms/SyncManager";
import { ExportProvider } from "./context/Export";
import { SelectOptionProvider } from "./context/SelectOption";
import { SidebarProvider } from "./context/SidebarContext";
import { TrackingProvider } from "./context/Tracking/index.js";
import { UploadProvider } from "./context/Upload";
import { store } from "./data/store";
import queryClient, { persister } from "./queryClient";

import "@fontsource/montserrat";
import "@total-typescript/ts-reset/fetch";
// import "@total-typescript/ts-reset";
import "@total-typescript/ts-reset/filter-boolean";
import "@total-typescript/ts-reset/is-array";
import "@total-typescript/ts-reset/json-parse";
import "@total-typescript/ts-reset/set-has";
import { useColourModeTheme } from "./hooks/useDarkMode";
import "./index.css";
import SupportButton from "./features/support/SupportButton";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const Fallback = loadable<FallbackProps>(
  () => import("@/pages/ErrorFallbackPage/ErrorFallback")
);

export default function App() {
  const theme = useColourModeTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary
        fallback={(props) => <Fallback {...props} />}
        showDialog={import.meta.env.PROD}
      >
        <ToastProvider>
          <PersistQueryClientProvider
            persistOptions={{ persister }}
            onSuccess={() => {
              // resume mutations after initial restore from localStorage was successful
              queryClient.resumePausedMutations().then(() => {
                queryClient.invalidateQueries();
              });
            }}
            client={queryClient}
          >
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <Provider store={store}>
                <TrackingProvider>
                  <UploadProvider>
                    <ExportProvider>
                      <ConfirmProvider>
                        <SelectOptionProvider>
                          <SidebarProvider>
                            <RouterProvider
                              router={router}
                              fallbackElement={<CenteredLoadingComponent />}
                            />
                            <SyncManager />
                            <SupportButton />
                          </SidebarProvider>
                        </SelectOptionProvider>
                      </ConfirmProvider>
                    </ExportProvider>
                  </UploadProvider>
                </TrackingProvider>
              </Provider>
            </LocalizationProvider>
          </PersistQueryClientProvider>
        </ToastProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class WebhooksService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Webhook to listen for and handle Stripe events
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public postApiWebhooksStripe(
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/webhooks/stripe",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
}

import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import organisationQueryApi from "@/data/api/organisation";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const OrganisationMemberParamsSchema = z.object({
  orgId: z.string(),
});

export default async function organisationEditMembers({
  request,
  params,
}: LoaderFunctionArgs) {
  const { orgId: organisationId } =
    OrganisationMemberParamsSchema.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      organisationId,
    };
  }

  queryClient.prefetchQuery(
    organisationQueryApi.getOrganisation(organisationId),
  );

  return {
    organisationId,
  };
}

export type OrganisationEditMembersLoaderReturnType = Awaited<
  ReturnType<typeof organisationEditMembers>
>;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LayerItemsFinishDto } from "../models/LayerItemsFinishDto";
import type { LayerItemsGetDto } from "../models/LayerItemsGetDto";
import type { LayerItemsPutDto } from "../models/LayerItemsPutDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class LayerItemsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Deletes a layer item including the underlying file on blob storage (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * <br />
   * Any user which has access to the site is allowed to delete
   * any layer file that is attached to the site
   * @param id The id of the layer to delete
   * @param xVersion The requested API version
   * @returns LayerItemsGetDto Success
   * @throws ApiError
   */
  public deleteApiLayer(
    id: string,
    xVersion: string = "2",
  ): CancelablePromise<LayerItemsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/layer/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets a layer item corresponding to the given id. <br />
   * If the layer item is a ImageLayer it will also return the coordinates otherwise the coordinates will be null. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param id The id of the layer item
   * @param xVersion The requested API version
   * @returns LayerItemsGetDto Success
   * @throws ApiError
   */
  public getApiLayer(
    id: string,
    xVersion: string = "2",
  ): CancelablePromise<LayerItemsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/layer/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets a layer item corresponding to the given id. <br />
   * If the layer item is a ImageLayer it will also return the coordinates otherwise the coordinates will be null. (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, Developer
   * @param id The id of the layer item
   * @param xVersion The requested API version
   * @returns LayerItemsGetDto Success
   * @throws ApiError
   */
  public getApiLayerAny(
    id: string,
    xVersion: string = "2",
  ): CancelablePromise<LayerItemsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/layer/any/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Edit fields for an existing layer item (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param layerId
   * @param xVersion The requested API version
   * @param requestBody The dto containing the fields to edit.
   * Must contain the id and only the AutoDisplay, DisplayName and Coordinate properties are editable.
   * Unspecified fields retain their previous value and aren't overwritten
   * @returns LayerItemsGetDto Success
   * @throws ApiError
   */
  public patchApiLayer(
    layerId: string,
    xVersion: string = "2",
    requestBody?: LayerItemsPutDto,
  ): CancelablePromise<LayerItemsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/layer/{layerId}",
      path: {
        layerId: layerId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Upload a chunk to a previously created layer item (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * <br />
   * This uploads a chunk of data to a previously created layer item. <br />
   * You must POST /finish to complete the upload. <br />
   * Ensure that your chunk fits within the predefined limits under settings/
   * @param layerId The layer item to access
   * @param xVersion The requested API version
   * @param formData
   * @returns void
   * @throws ApiError
   */
  public postApiLayerChunks(
    layerId: string,
    xVersion: string = "2",
    formData?: {
      /**
       * The index number of the chunk.
       * Check the limit with GET /api/settings/max-chunks
       */
      ChunkNum: number;
      /**
       * The byte array of the chunk.
       * Check size limit with GET /api/settings/chunk-bytes
       */
      Chunk: Blob;
    },
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/layer/{layerId}/chunks",
      path: {
        layerId: layerId,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Finishes uploading a layer item that has been uploaded in chunks (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * <br />
   * This finalises the uploading of a layer item. <br />
   * All chunks should have been previously uploaded before this is called
   * @param id The id of the layer item to finish uploading
   * @param xVersion The requested API version
   * @returns LayerItemsFinishDto Success
   * @throws ApiError
   */
  public postApiLayerFinish(
    id: string,
    xVersion: string = "2",
  ): CancelablePromise<LayerItemsFinishDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/layer/{id}/finish",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditCommentCreatedGetDto } from "../models/AuditCommentCreatedGetDto";
import type { AuditCommentDeletedGetDto } from "../models/AuditCommentDeletedGetDto";
import type { AuditCommentUpdatedGetDto } from "../models/AuditCommentUpdatedGetDto";
import type { AuditMediaCreatedGetDto } from "../models/AuditMediaCreatedGetDto";
import type { AuditMediaDeletedGetDto } from "../models/AuditMediaDeletedGetDto";
import type { AuditMediaMarkedUpGetDto } from "../models/AuditMediaMarkedUpGetDto";
import type { AuditMediaRestoredGetDto } from "../models/AuditMediaRestoredGetDto";
import type { AuditMediaUpdatedGetDto } from "../models/AuditMediaUpdatedGetDto";
import type { ErrorMessage } from "../models/ErrorMessage";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class PingService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Test the connection to the server
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPing(xVersion: string = "2"): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Test the connection to the server
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingAudit(
    xVersion: string = "2",
  ): CancelablePromise<
    Array<
      | AuditMediaCreatedGetDto
      | AuditMediaUpdatedGetDto
      | AuditMediaDeletedGetDto
      | AuditMediaRestoredGetDto
      | AuditMediaMarkedUpGetDto
      | AuditCommentCreatedGetDto
      | AuditCommentUpdatedGetDto
      | AuditCommentDeletedGetDto
    >
  > {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/audit",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Test the connection to the server, without authorisation
   * **Scope**: AuthorisedApp
   * @param tags
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingOpen(
    tags?: string,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/open",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        tags: tags,
      },
    });
  }
  /**
   * Test the connection to the server, as an AuthorisedApp (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingAuthorised(xVersion: string = "2"): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/authorised",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Test the connection to the server, as an AuthorisedApp and User (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingUser(xVersion: string = "2"): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/user",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Test the connection to the server, as a User through Azure (Auth policies: AzUser)
   * **Scope**: User
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingAzuser(xVersion: string = "2"): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/azuser",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Test the 400 Bad Request error response.
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingErrorBadRequest(
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/error/bad-request",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Test the 401 Unauthorized error response (due to policy). (Auth policies: Dev)
   * @param xVersion The requested API version
   * @returns ErrorMessage Success
   * @throws ApiError
   */
  public getApiPingErrorUnauthorizedPolicy(
    xVersion: string = "2",
  ): CancelablePromise<ErrorMessage> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/error/unauthorized-policy",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Test the 401 Unauthorized error response.
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingErrorUnauthorized(
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/error/unauthorized",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Test the 403 Forbidden error response.
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingErrorForbidden(
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/error/forbidden",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Test the 404 Not Found error response.
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingErrorNotFound(
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/error/not-found",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Test the 500 Inner Server Error response.
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingErrorIse(xVersion: string = "2"): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/error/ise",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Test the 500 Inner Server Error response.
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingErrorUncaught(
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/error/uncaught",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Test that you have the developer scope. (Auth policies: Developer)
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiPingDeveloper(xVersion: string = "2"): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/ping/developer",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessRoleGetDto } from "../models/AccessRoleGetDto";
import type { AccessRolePatchDto } from "../models/AccessRolePatchDto";
import type { AccessRolePostDto } from "../models/AccessRolePostDto";
import type { ActivityTagGetDto } from "../models/ActivityTagGetDto";
import type { ActivityUserGetDto } from "../models/ActivityUserGetDto";
import type { ArchiveTeamPatchDto } from "../models/ArchiveTeamPatchDto";
import type { FloorplanGetDto } from "../models/FloorplanGetDto";
import type { IntegrationGetDto } from "../models/IntegrationGetDto";
import type { LayerItemsGetDto } from "../models/LayerItemsGetDto";
import type { LayerItemsPostDto } from "../models/LayerItemsPostDto";
import type { LogicalOperator } from "../models/LogicalOperator";
import type { MediaItemFloorplanListDto } from "../models/MediaItemFloorplanListDto";
import type { MediaItemGpsListDto } from "../models/MediaItemGpsListDto";
import type { MediaItemGraphRecordGetDto } from "../models/MediaItemGraphRecordGetDto";
import type { MediaItemGroupGetDto } from "../models/MediaItemGroupGetDto";
import type { MediaItemListDto } from "../models/MediaItemListDto";
import type { MediaItemsGetDto } from "../models/MediaItemsGetDto";
import type { MediaItemsGetDtoPagedResults } from "../models/MediaItemsGetDtoPagedResults";
import type { MediaType } from "../models/MediaType";
import type { PermissionDefinitionGetDto } from "../models/PermissionDefinitionGetDto";
import type { SiteInvitesDto } from "../models/SiteInvitesDto";
import type { SiteInvitesResultDto } from "../models/SiteInvitesResultDto";
import type { SiteInvitesTokenPostDto } from "../models/SiteInvitesTokenPostDto";
import type { SiteRolePatchDto } from "../models/SiteRolePatchDto";
import type { SiteRolesPatchDto } from "../models/SiteRolesPatchDto";
import type { SiteTagsUsedDeleteDto } from "../models/SiteTagsUsedDeleteDto";
import type { SiteTagsUsedEditResultDto } from "../models/SiteTagsUsedEditResultDto";
import type { SiteTagsUsedGetDto } from "../models/SiteTagsUsedGetDto";
import type { SiteTagsUsedPatchDto } from "../models/SiteTagsUsedPatchDto";
import type { SiteUsersAccountDto } from "../models/SiteUsersAccountDto";
import type { SiteUserTagsDto } from "../models/SiteUserTagsDto";
import type { SortOption } from "../models/SortOption";
import type { TeamAutotagRecalculateGetDto } from "../models/TeamAutotagRecalculateGetDto";
import type { TeamsGetDisplayInfoDto } from "../models/TeamsGetDisplayInfoDto";
import type { TeamsGetDto } from "../models/TeamsGetDto";
import type { TeamsGetLocationDto } from "../models/TeamsGetLocationDto";
import type { TeamsPatchDto } from "../models/TeamsPatchDto";
import type { TeamsPostDto } from "../models/TeamsPostDto";
import type { TeamTagsFloorplanPatchDto } from "../models/TeamTagsFloorplanPatchDto";
import type { TeamTagsFloorplanPostDto } from "../models/TeamTagsFloorplanPostDto";
import type { TeamTagsGetDto } from "../models/TeamTagsGetDto";
import type { TeamTagsGpsPatchDto } from "../models/TeamTagsGpsPatchDto";
import type { TeamTagsGpsPostDto } from "../models/TeamTagsGpsPostDto";
import type { TeamTagsPostDto } from "../models/TeamTagsPostDto";
import type { TeamTagsUnifiedGetDto } from "../models/TeamTagsUnifiedGetDto";
import type { TeamTagsUserPatchDto } from "../models/TeamTagsUserPatchDto";
import type { TeamTagsUserPostDto } from "../models/TeamTagsUserPostDto";
import type { TeamTagsWithFpInfoGetDto } from "../models/TeamTagsWithFpInfoGetDto";
import type { TeamTagsWithGeoInfoGetDto } from "../models/TeamTagsWithGeoInfoGetDto";
import type { TeamTagsWithUserInfoGetDto } from "../models/TeamTagsWithUserInfoGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class TeamsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete a Site - forbidden if there are any SiteMediaLinks referencing it (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId ID of the site to delete
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public deleteApiTeams(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get a team the user has access to.
   * Accepts "private" and "all" as valid site IDs (Auth policies: UserOrSharecode)
   * **Scope**: UserOrSharecode
   * @param teamId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public getApiTeams(
    teamId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<TeamsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Edit a team the user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public patchApiTeams(
    teamId: string,
    xVersion: string = "2",
    requestBody?: TeamsPatchDto,
  ): CancelablePromise<TeamsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Remove a floorplan from a team.  The floorplan must belong to a project or another team, or
   * else it needs to be deleted properly !:DeleteFloorplan (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param floorplanId
   * @param floorplan
   * @param xVersion The requested API version
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsFloorplans(
    teamId: string,
    floorplanId: string,
    floorplan?: string,
    xVersion: string = "2",
  ): CancelablePromise<FloorplanGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/floorplans/{floorplanId}",
      path: {
        teamId: teamId,
        floorplanId: floorplanId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        floorplan: floorplan,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Add a floorplan to a team.  If the floorplan is not in the project's library, a duplicate will be made. (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param floorplanId
   * @param floorplan
   * @param xVersion The requested API version
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public postApiTeamsFloorplans(
    teamId: string,
    floorplanId: string,
    floorplan?: string,
    xVersion: string = "2",
  ): CancelablePromise<FloorplanGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/floorplans/{floorplanId}",
      path: {
        teamId: teamId,
        floorplanId: floorplanId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        floorplan: floorplan,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Remove media from a given team by ID.
   * If any media is not found, the request fails.
   * If any media is not in the given team, it is skipped. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team, does not accept "private" or "all"
   * @param mediaIds IDs of media to remove.  All of these must be in the specified site.
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsMedia(
    teamId: string,
    mediaIds?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemsGetDto>> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/media",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        mediaIds: mediaIds,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Return a list of MediaItems with matching search terms that the User can access
   * either as the creator or being part of the same site as the mediaItem (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Site ID to search within; accepts "all" and "private" as well
   * @param page Index page of search results
   * @param size Number of items per page
   * @param group The week group we want to fetch for this result;  in the format "year/week", e.g. "2023/5" refers to the 5th week of 2023.
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param gpsOnly If true, will filter out media items that have GPS data
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param floorplanId If set, only return media items on this floorplan
   * @param deleted If true, only return media items that have been deleted
   * @param xVersion The requested API version
   * @returns MediaItemsGetDtoPagedResults Success
   * @throws ApiError
   */
  public getApiTeamsMedia(
    teamId: string,
    page?: number,
    size?: number,
    group?: string,
    search?: string,
    sort?: SortOption,
    dayOnly?: boolean,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty?: boolean,
    publishedOnly?: boolean,
    finishedOnly?: boolean,
    is360?: boolean,
    gpsOnly?: boolean,
    bbox?: string,
    floorplanId?: string,
    deleted?: boolean,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGetDtoPagedResults> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/media",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        group: group,
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        gpsOnly: gpsOnly,
        bbox: bbox,
        floorplanId: floorplanId,
        deleted: deleted,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add media to a given team by ID.
   * If any media is not accessible, the request fails.
   * If any media is not found, the request fails.
   * If any media is already in the given team, it is skipped. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team, does not accept "private" or "all"
   * @param mediaIds IDs of media to add.  You must have access to all of these.
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public postApiTeamsMedia(
    teamId: string,
    mediaIds?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemsGetDto>> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/media",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        mediaIds: mediaIds,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Remove a single media from a given team by ID. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team, does not accept "private" or "all"
   * @param mediaId IDs of media to remove.  All of these must be in the specified site.
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsMedia1(
    teamId: string,
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemsGetDto>> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/media/{mediaId}",
      path: {
        teamId: teamId,
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete a role in this team.  A valid backup role must be provided if any users have this role. (Auth policies: AuthorisedApp, User)
   * @param teamId The team in which this role is
   * @param roleId The role to delete
   * @param replace The role to replace this role with
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsRoles(
    teamId: string,
    roleId: string,
    replace?: string,
    xVersion: string = "2",
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/roles/{roleId}",
      path: {
        teamId: teamId,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        replace: replace,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetches a single role related to this team (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param roleId
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public getApiTeamsRoles(
    teamId: string,
    roleId: string,
    xVersion: string = "2",
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/roles/{roleId}",
      path: {
        teamId: teamId,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Update a role in this team (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param roleId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public patchApiTeamsRoles(
    teamId: string,
    roleId: string,
    xVersion: string = "2",
    requestBody?: AccessRolePatchDto,
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/roles/{roleId}",
      path: {
        teamId: teamId,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Delete a team tag from the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team from which to delete a tag
   * @param tagId ID of tag to remove
   * @param xVersion The requested API version
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsTags(
    teamId: string,
    tagId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/tags/{tagId}",
      path: {
        teamId: teamId,
        tagId: tagId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete gps info from a team tag in the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team from which to delete a tag
   * @param tagId ID of tag to remove
   * @param gpsId ID of the gps info to remove
   * @param xVersion The requested API version
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsTagsGps(
    teamId: string,
    tagId: string,
    gpsId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/tags/{tagId}/gps/{gpsId}",
      path: {
        teamId: teamId,
        tagId: tagId,
        gpsId: gpsId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete floorplan info from a team tag in the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team from which to delete a tag
   * @param tagId ID of tag to remove
   * @param floorplanId ID of the floorplan info to remove
   * @param xVersion The requested API version
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsTagsFloorplan(
    teamId: string,
    tagId: string,
    floorplanId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/tags/{tagId}/floorplan/{floorplanId}",
      path: {
        teamId: teamId,
        tagId: tagId,
        floorplanId: floorplanId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete user info from a team tag in the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team from which to delete a tag
   * @param tagId ID of tag to remove
   * @param userId ID of the user info to remove
   * @param xVersion The requested API version
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsTagsUser(
    teamId: string,
    tagId: string,
    userId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/tags/{tagId}/user/{userId}",
      path: {
        teamId: teamId,
        tagId: tagId,
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete a tag used in the team - removes it from all media items in the team with that tag. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to delete the tag from
   * @param xVersion The requested API version
   * @param requestBody
   * @returns SiteTagsUsedEditResultDto Success
   * @throws ApiError
   */
  public deleteApiTeamsTagsUsed(
    teamId: string,
    xVersion: string = "2",
    requestBody?: SiteTagsUsedDeleteDto,
  ): CancelablePromise<SiteTagsUsedEditResultDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/tags/used",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch all used tags of the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to get the tags from
   * @param xVersion The requested API version
   * @returns SiteTagsUsedGetDto Success
   * @throws ApiError
   */
  public getApiTeamsTagsUsed(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<SiteTagsUsedGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/tags/used",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Rename a tag used in the team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to edit the tags from
   * @param xVersion The requested API version
   * @param requestBody
   * @returns SiteTagsUsedEditResultDto Success
   * @throws ApiError
   */
  public patchApiTeamsTagsUsed(
    teamId: string,
    xVersion: string = "2",
    requestBody?: SiteTagsUsedPatchDto,
  ): CancelablePromise<SiteTagsUsedEditResultDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/tags/used",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Delete a team tag from the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team from which to delete a tag
   * @param tagId ID of tag to remove
   * @param xVersion The requested API version
   * @returns TeamTagsGetDto Success
   * @throws ApiError
   */
  public deleteApiTeamsTagsTeam(
    teamId: string,
    tagId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamTagsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/tags/team/{tagId}",
      path: {
        teamId: teamId,
        tagId: tagId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete a user from a team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId
   * @param userId
   * @param xVersion The requested API version
   * @returns SiteUsersAccountDto Success
   * @throws ApiError
   */
  public deleteApiTeamsMembers(
    teamId: string,
    userId: string,
    xVersion: string = "2",
  ): CancelablePromise<SiteUsersAccountDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/members/{userId}",
      path: {
        teamId: teamId,
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Change the user's role in a team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId
   * @param userId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns SiteUsersAccountDto Success
   * @throws ApiError
   */
  public patchApiTeamsMembers(
    teamId: string,
    userId: string,
    xVersion: string = "2",
    requestBody?: SiteRolePatchDto,
  ): CancelablePromise<SiteUsersAccountDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/members/{userId}",
      path: {
        teamId: teamId,
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Revoke invitations (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (site admin)
   *
   * This only removes pending invitations, and does not
   * revoke access to a site if the user has already created an
   * account (and thus redeemed the invitation).
   * Use M:BuiltView360Backend.V2.Controllers.TeamsController.RemoveUserFromTeam(System.String,System.String) to revoke access instead,
   * or !:AccountsController.DeleteAccount to
   * delete an account
   * @param teamId
   * @param emails Comma-separated list of emails to revoke
   * @param xVersion The requested API version
   * @returns SiteInvitesResultDto Success
   * @throws ApiError
   */
  public deleteApiTeamsInvites(
    teamId: string,
    emails?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<SiteInvitesResultDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/invites",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        emails: emails,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get a list of emails invited to this site (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (site admin)
   * @param teamId
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiTeamsInvites(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/invites",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Invite these email addresses to the Site (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   *
   * When an email is added, an email will be sent
   * to the provided address with a link to sign up
   * to BuiltView. Once signed up, the user will be added
   * to the Site.
   *
   * If the email address belongs to an existing account,
   * that account will be granted access.
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns SiteInvitesResultDto Success
   * @throws ApiError
   */
  public postApiTeamsInvites(
    teamId: string,
    xVersion: string = "2",
    requestBody?: SiteInvitesDto,
  ): CancelablePromise<SiteInvitesResultDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/invites",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Stop monitoring the given tag (Auth policies: AuthorisedApp, User)
   * @param teamId Relevant team ID
   * @param tagId ID of tag to stop following
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public deleteApiTeamsTagsMonitor(
    teamId: string,
    tagId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/tags/monitor/{tagId}",
      path: {
        teamId: teamId,
        tagId: tagId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Monitor the given tag (Auth policies: AuthorisedApp, User)
   * @param teamId Relevant team ID
   * @param tagId ID of tag to follow (from team tag get route)
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public putApiTeamsTagsMonitor(
    teamId: string,
    tagId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/teams/{teamId}/tags/monitor/{tagId}",
      path: {
        teamId: teamId,
        tagId: tagId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Remove tags from the SiteUser tags list (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public deleteApiTeamsTagsMonitor1(
    teamId: string,
    xVersion: string = "2",
    requestBody?: SiteUserTagsDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/teams/{teamId}/tags/monitor",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Update SiteUser tags for monitoring and notification (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public patchApiTeamsTagsMonitor(
    teamId: string,
    xVersion: string = "2",
    requestBody?: SiteUserTagsDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/tags/monitor",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add multiple tags To SiteUser for monitoring and notification (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiTeamsTagsMonitor(
    teamId: string,
    xVersion: string = "2",
    requestBody?: SiteUserTagsDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/tags/monitor",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all teams (Sites) user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public getApiTeams1(
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a new Team. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public postApiTeams(
    xVersion: string = "2",
    requestBody?: TeamsPostDto,
  ): CancelablePromise<TeamsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get all floorplans in a team (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public getApiTeamsFloorplans(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<FloorplanGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/floorplans",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get teams that are archived which I can access (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public getApiTeamsArchived(
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/archived",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get all the integrations this site has access to (Auth policies: AuthorisedApp, User)
   * @param siteId
   * @param xVersion The requested API version
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public getApiTeamsIntegrations(
    siteId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<IntegrationGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{siteId}/integrations",
      path: {
        siteId: siteId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get full media graph for a team. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Team to retrieve data for
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Needs to have dateString as well.
   * @param granularity Either "year", "month", "week" (default) or "day"
   * @param xVersion The requested API version
   * @returns MediaItemGraphRecordGetDto Success
   * @throws ApiError
   */
  public getApiTeamsGraph(
    teamId: string,
    dateString?: string,
    dateStringEnd?: string,
    granularity: string = "week",
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemGraphRecordGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/graph",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        granularity: granularity,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per tag for the given team. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Team to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityTagGetDto Success
   * @throws ApiError
   */
  public getApiTeamsActivityTags(
    teamId: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityTagGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/activity/tags",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per user for the given team. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Team to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityUserGetDto Success
   * @throws ApiError
   */
  public getApiTeamsActivityUsers(
    teamId: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityUserGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/activity/users",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Return the total number of MediaItems matching the specified search parameters (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Site ID to search within; accepts "all" and "private" as well
   * @param page Index page of search results
   * @param size Number of items per page
   * @param group The week group we want to fetch for this result;  in the format "year/week", e.g. "2023/5" refers to the 5th week of 2023.
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param gpsOnly If true, will filter out media items that have GPS data
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param floorplanId If set, only return media items on this floorplan
   * @param deleted If true, only return media items that have been deleted
   * @param xVersion The requested API version
   * @returns number Success
   * @throws ApiError
   */
  public getApiTeamsMediaCount(
    teamId: string,
    page?: number,
    size?: number,
    group?: string,
    search?: string,
    sort?: SortOption,
    dayOnly?: boolean,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty?: boolean,
    publishedOnly?: boolean,
    finishedOnly?: boolean,
    is360?: boolean,
    gpsOnly?: boolean,
    bbox?: string,
    floorplanId?: string,
    deleted?: boolean,
    xVersion: string = "2",
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/media/count",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        group: group,
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        gpsOnly: gpsOnly,
        bbox: bbox,
        floorplanId: floorplanId,
        deleted: deleted,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return a list of "groups" of MediaItems with matching search terms.  Each group represents
   * a year in time and lists how many media items belong in that group (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Site ID to search within; accepts "all" and "private" as well
   * @param page Index page of search results
   * @param size Number of items per page
   * @param group The week group we want to fetch for this result;  in the format "year/week", e.g. "2023/5" refers to the 5th week of 2023.
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param gpsOnly If true, will filter out media items that have GPS data
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param floorplanId If set, only return media items on this floorplan
   * @param deleted If true, only return media items that have been deleted
   * @param xVersion The requested API version
   * @returns MediaItemGroupGetDto Success
   * @throws ApiError
   */
  public getApiTeamsMediaGroups(
    teamId: string,
    page?: number,
    size?: number,
    group?: string,
    search?: string,
    sort?: SortOption,
    dayOnly?: boolean,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty?: boolean,
    publishedOnly?: boolean,
    finishedOnly?: boolean,
    is360?: boolean,
    gpsOnly?: boolean,
    bbox?: string,
    floorplanId?: string,
    deleted?: boolean,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemGroupGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/media/groups",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        group: group,
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        gpsOnly: gpsOnly,
        bbox: bbox,
        floorplanId: floorplanId,
        deleted: deleted,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return a list of MediaItems with matching search terms that the User can access
   * either as the creator or being part of the same site as the mediaItem (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Site ID to search within; accepts "all" and "private" as well
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemListDto Success
   * @throws ApiError
   */
  public getApiTeamsMediaList(
    teamId: string,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemListDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/media/list",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return a list of MediaItems with matching search terms that the User can access
   * either as the creator or being part of the same site as the mediaItem (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Site ID to search within; accepts "all" and "private" as well
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param group
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemGpsListDto Success
   * @throws ApiError
   */
  public getApiTeamsMediaGps(
    teamId: string,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    group?: string,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemGpsListDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/media/gps",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        group: group,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return a list of floorplan locations for a given team and floorplan (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Site ID to search within; accepts "all" and "private" as well
   * @param floorplanId Floorplan to check; the given team must have access to it
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param group
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemFloorplanListDto Success
   * @throws ApiError
   */
  public getApiTeamsMediaFloorplan(
    teamId: string,
    floorplanId: string,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    group?: string,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemFloorplanListDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/media/floorplan/{floorplanId}",
      path: {
        teamId: teamId,
        floorplanId: floorplanId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        group: group,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetch an approximate location for this team (based on a recent media item location) (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @returns TeamsGetLocationDto Success
   * @throws ApiError
   */
  public getApiTeamsGps(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamsGetLocationDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/gps",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetches display information for the given team (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @returns TeamsGetDisplayInfoDto Success
   * @throws ApiError
   */
  public getApiTeamsDisplay(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamsGetDisplayInfoDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/display",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Gets a list of layer items attached to the given team. <br />
   * If the layer item is a ImageLayer it will also return the coordinates otherwise the coordinates will be null. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId The id of the site to check;  can be "private" or "all"
   * @param includeLink
   * @param xVersion The requested API version
   * @returns LayerItemsGetDto Success
   * @throws ApiError
   */
  public getApiTeamsLayers(
    teamId: string,
    includeLink: boolean = false,
    xVersion: string = "2",
  ): CancelablePromise<Array<LayerItemsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/layers",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        includeLink: includeLink,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch the permissions that relate to teams
   * @param xVersion The requested API version
   * @returns PermissionDefinitionGetDto Success
   * @throws ApiError
   */
  public getApiTeamsPermissions(
    xVersion: string = "2",
  ): CancelablePromise<Array<PermissionDefinitionGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/permissions",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Fetches a list of all roles related to this team (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public getApiTeamsRoles1(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<AccessRoleGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/roles",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new role this team (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public postApiTeamsRoles(
    teamId: string,
    xVersion: string = "2",
    requestBody?: AccessRolePostDto,
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/roles",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetch the team tags of the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public getApiTeamsTags(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsUnifiedGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/tags",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch team tags within the specified location and radius (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to get the tags from
   * @param longitude
   * @param latitude
   * @param radius
   * @param xVersion The requested API version
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public getApiTeams2(
    teamId: string,
    longitude: number,
    latitude: number,
    radius: number,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsUnifiedGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/{longitude}/{latitude}/{radius}",
      path: {
        teamId: teamId,
        longitude: longitude,
        latitude: latitude,
        radius: radius,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Fetch the team tags of the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsGetDto Success
   * @throws ApiError
   */
  public getApiTeamsTagsTeam(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/tags/team",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Add a new team tag to the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team to which to add a tag
   * @param xVersion The requested API version
   * @param requestBody Data for the tag to add
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public postApiTeamsTagsTeam(
    teamId: string,
    xVersion: string = "2",
    requestBody?: TeamTagsPostDto,
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/tags/team",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Fetch the gps team autotags of the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsWithGeoInfoGetDto Success
   * @throws ApiError
   */
  public getApiTeamsTagsGps(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsWithGeoInfoGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/tags/gps",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Fetch the floorplan team autotags of the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsWithFpInfoGetDto Success
   * @throws ApiError
   */
  public getApiTeamsTagsFloorplan(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsWithFpInfoGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/tags/floorplan",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Fetch the floorplan user autotags of the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsWithUserInfoGetDto Success
   * @throws ApiError
   */
  public getApiTeamsTagsUser(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsWithUserInfoGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/tags/user",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch the members of the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site of which to get the members
   * @param xVersion The requested API version
   * @returns SiteUsersAccountDto Success
   * @throws ApiError
   */
  public getApiTeamsMembers(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<SiteUsersAccountDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/members",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Change multiple users' roles in a team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns SiteUsersAccountDto Success
   * @throws ApiError
   */
  public patchApiTeamsMembers1(
    teamId: string,
    xVersion: string = "2",
    requestBody?: SiteRolesPatchDto,
  ): CancelablePromise<Array<SiteUsersAccountDto>> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/members",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch the uploaders of the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param teamId Id of the site of which to get the uploaders
   * @param xVersion The requested API version
   * @returns SiteUsersAccountDto Success
   * @throws ApiError
   */
  public getApiTeamsUploaders(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<SiteUsersAccountDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/teams/{teamId}/uploaders",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Archive a team the user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public patchApiTeamsArchive(
    teamId: string,
    xVersion: string = "2",
    requestBody?: ArchiveTeamPatchDto,
  ): CancelablePromise<TeamsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/archive",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Recalculate autotags for all media in this team (Auth policies: AuthorisedApp, User)
   * @param teamId
   * @param xVersion The requested API version
   * @returns TeamAutotagRecalculateGetDto Success
   * @throws ApiError
   */
  public patchApiTeamsMediaAutotags(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamAutotagRecalculateGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/media/autotags",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Populate/update all sites' stats (Auth policies: AuthorisedApp, Developer)
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public patchApiTeamsDisplay(
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/display",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add geo info to a particular tag in the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team to which to add a tag
   * @param tagId Id of the tag to which to add geodata to
   * @param xVersion The requested API version
   * @param requestBody Data for the tag to add
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public patchApiTeamsTagsGps(
    teamId: string,
    tagId: string,
    xVersion: string = "2",
    requestBody?: TeamTagsGpsPatchDto,
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/tags/{tagId}/gps",
      path: {
        teamId: teamId,
        tagId: tagId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Add floorplan info to the given tag in the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team to which to add a tag
   * @param tagId Id of the tag to which to add floorplan info
   * @param xVersion The requested API version
   * @param requestBody Data for the tag to add
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public patchApiTeamsTagsFloorplan(
    teamId: string,
    tagId: string,
    xVersion: string = "2",
    requestBody?: TeamTagsFloorplanPatchDto,
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/tags/{tagId}/floorplan",
      path: {
        teamId: teamId,
        tagId: tagId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Add user/email info to the given tag in the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team to which to add a tag
   * @param tagId Tag to add user info to
   * @param xVersion The requested API version
   * @param requestBody Data for the tag to add
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public patchApiTeamsTagsUser(
    teamId: string,
    tagId: string,
    xVersion: string = "2",
    requestBody?: TeamTagsUserPatchDto,
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/teams/{teamId}/tags/{tagId}/user",
      path: {
        teamId: teamId,
        tagId: tagId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Move media to a given team by ID.
   * If any media is not accessible, the request fails.
   * If any media is not found, the request fails.
   * If any media is already in the given team, it is skipped. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param toTeamId Id of the team to add media to, does not accept "private" or "all"
   * @param fromTeamId Id of the team to pull media from, does not accept "private" or "all"
   * @param mediaIds IDs of media to add.  You must have access to all of these.
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public postApiTeamsMediaMove(
    toTeamId: string,
    fromTeamId: string,
    mediaIds?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemsGetDto>> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{fromTeamId}/media/move/{toTeamId}",
      path: {
        toTeamId: toTeamId,
        fromTeamId: fromTeamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        mediaIds: mediaIds,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a new layer item (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * <br />
   * This creates an empty layer item. Data is not uploaded here. <br />
   * Check the file size limit /settings/max-bytes before calling this route. <br />
   * Add data to the item through the POST /chunks route. <br />
   * Finish off the upload by calling the POST /finish route.
   * @param siteId ID of the site to which to add a layer
   * @param xVersion The requested API version
   * @param requestBody The dto containing the layer item details
   * @returns LayerItemsGetDto Success
   * @throws ApiError
   */
  public postApiTeamsLayers(
    siteId: string,
    xVersion: string = "2",
    requestBody?: LayerItemsPostDto,
  ): CancelablePromise<LayerItemsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{siteId}/layers",
      path: {
        siteId: siteId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Add multiple new team tags with geo info to the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team to which to add tags
   * @param xVersion The requested API version
   * @param requestBody Data for the tags to add
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public postApiTeamsTagsTeamGpsMulti(
    teamId: string,
    xVersion: string = "2",
    requestBody?: Array<TeamTagsGpsPostDto>,
  ): CancelablePromise<TeamTagsUnifiedGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/tags/team/gps-multi",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Add a new team tag with geo info to the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team to which to add a tag
   * @param xVersion The requested API version
   * @param requestBody Data for the tag to add
   * @returns TeamTagsGetDto Success
   * @throws ApiError
   */
  public postApiTeamsTagsTeamGps(
    teamId: string,
    xVersion: string = "2",
    requestBody?: TeamTagsGpsPostDto,
  ): CancelablePromise<TeamTagsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/tags/team/gps",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Add a new team tag with floorplan info to the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team to which to add a tag
   * @param xVersion The requested API version
   * @param requestBody Data for the tag to add
   * @returns TeamTagsGetDto Success
   * @throws ApiError
   */
  public postApiTeamsTagsTeamFloorplan(
    teamId: string,
    xVersion: string = "2",
    requestBody?: TeamTagsFloorplanPostDto,
  ): CancelablePromise<TeamTagsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/tags/team/floorplan",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Add a new team tag with user/email info to the given team (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId Id of the team to which to add a tag
   * @param xVersion The requested API version
   * @param requestBody Data for the tag to add
   * @returns TeamTagsGetDto Success
   * @throws ApiError
   */
  public postApiTeamsTagsTeamUser(
    teamId: string,
    xVersion: string = "2",
    requestBody?: TeamTagsUserPostDto,
  ): CancelablePromise<TeamTagsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/tags/team/user",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Join a team you have indirect access to;  note this is subject to restrictions (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param teamId
   * @param xVersion The requested API version
   * @returns TeamsGetDto Success
   * @throws ApiError
   */
  public postApiTeamsJoin(
    teamId: string,
    xVersion: string = "2",
  ): CancelablePromise<TeamsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/join",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a token to access a Site the User has access to (Auth policies: AuthorisedApp)
   * **Scopes**: AuthorisedApp, User
   * @param teamId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public postApiTeamsToken(
    teamId: string,
    xVersion: string = "2",
    requestBody?: SiteInvitesTokenPostDto,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/teams/{teamId}/token",
      path: {
        teamId: teamId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

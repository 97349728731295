import BvTheme from "@/styles/theme/_theme";
import { useMediaQuery } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

export enum ColourMode {
  LIGHT = "light",
  DARK = "dark",
  SYSTEM = "system",
}

/**
 * A hook to get and set the current colour mode
 * @returns A tuple containing the current colour mode and a function to set the colour mode
 */
export const useDarkMode = () => {
  const systemDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [localStorageMode, setLocalStorageMode] = useLocalStorage<ColourMode>(
    "colourMode",
    ColourMode.SYSTEM,
  );

  const colourMode = useMemo(() => {
    if (localStorageMode === ColourMode.SYSTEM) {
      return systemDarkMode ? ColourMode.DARK : ColourMode.LIGHT;
    }
    return localStorageMode;
  }, [localStorageMode, systemDarkMode]);

  const setColourMode = useCallback(
    (mode: ColourMode) => {
      setLocalStorageMode(mode);
    },
    [setLocalStorageMode],
  );

  return [colourMode, setColourMode, localStorageMode] as const;
};

export const useColourModeTheme = () => {
  const [colourMode] = useDarkMode();
  const theme = useMemo(
    () => BvTheme(colourMode === ColourMode.DARK),
    [colourMode],
  );

  return theme;
};

export const useInverseColourModeTheme = () => {
  const [colourMode] = useDarkMode();
  const theme = useMemo(
    () => BvTheme(colourMode === ColourMode.LIGHT),
    [colourMode],
  );

  return theme;
};

import { type Params, redirect } from "react-router-dom";

import { authClient } from "@/features/auth/authClient";

interface AuthenticateOptions {
  skip?: boolean;
}

export async function authenticate(
  request: Request,

  options?: AuthenticateOptions,
) {
  const redirectUrl = request.url.replace(window.origin, "");

  const authenticated = await authClient.hasValidAuth();

  if (!authenticated) {
    throw redirect(`/login?redirect=${redirectUrl}`, { status: 302 });
  }

  return authenticated;
}

/**
 * Authenticate or return the sharecode
 *
 * If authenticated is false then you must use the sharecode
 *
 * @param request The request object
 * @param params The route params incl, sharecode
 * @returns true if authenticated, otherwise the sharecode, if neither then redirect to login
 */
export async function authenticateOrMediaCode(
  request: Request,
  params: Params<string>,
) {
  const redirectUrl = request.url.replace(window.origin, "");
  const sharecode = params.sharecode;

  const authenticated = await authClient.hasValidAuth();

  if (!sharecode && !authenticated) {
    throw redirect(`/login?redirect=${redirectUrl}`, { status: 302 });
  }

  return sharecode;
}

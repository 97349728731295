/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UserRole {
  USER = "User",
  DEVELOPER = "Developer",
  OVERSEER = "Overseer",
  SALES = "Sales",
  SUPPORT = "Support",
  TEST_ROLE = "TestRole",
}

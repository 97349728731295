/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from "./core/BaseHttpRequest";
import type { OpenAPIConfig } from "./core/OpenAPI";
import { FetchHttpRequest } from "./core/FetchHttpRequest";
import { AccountsService } from "./services/AccountsService";
import { ApiKeyService } from "./services/ApiKeyService";
import { AuthService } from "./services/AuthService";
import { CallbacksService } from "./services/CallbacksService";
import { CommentsService } from "./services/CommentsService";
import { ConnectService } from "./services/ConnectService";
import { CustomersService } from "./services/CustomersService";
import { FieldViewService } from "./services/FieldViewService";
import { FloorplansService } from "./services/FloorplansService";
import { IntegrationsService } from "./services/IntegrationsService";
import { LayerItemsService } from "./services/LayerItemsService";
import { MediaItemsService } from "./services/MediaItemsService";
import { MicroserviceService } from "./services/MicroserviceService";
import { NotificationPreferencesService } from "./services/NotificationPreferencesService";
import { OrganisationsService } from "./services/OrganisationsService";
import { PingService } from "./services/PingService";
import { ProjectsService } from "./services/ProjectsService";
import { RestrictionsService } from "./services/RestrictionsService";
import { SettingsService } from "./services/SettingsService";
import { StripeService } from "./services/StripeService";
import { TeamsService } from "./services/TeamsService";
import { TokenService } from "./services/TokenService";
import { UsersService } from "./services/UsersService";
import { WebhooksService } from "./services/WebhooksService";
import { XMigrationsService } from "./services/XMigrationsService";
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class BuiltView360Client {
  public readonly accounts: AccountsService;
  public readonly apiKey: ApiKeyService;
  public readonly auth: AuthService;
  public readonly callbacks: CallbacksService;
  public readonly comments: CommentsService;
  public readonly connect: ConnectService;
  public readonly customers: CustomersService;
  public readonly fieldView: FieldViewService;
  public readonly floorplans: FloorplansService;
  public readonly integrations: IntegrationsService;
  public readonly layerItems: LayerItemsService;
  public readonly mediaItems: MediaItemsService;
  public readonly microservice: MicroserviceService;
  public readonly notificationPreferences: NotificationPreferencesService;
  public readonly organisations: OrganisationsService;
  public readonly ping: PingService;
  public readonly projects: ProjectsService;
  public readonly restrictions: RestrictionsService;
  public readonly settings: SettingsService;
  public readonly stripe: StripeService;
  public readonly teams: TeamsService;
  public readonly token: TokenService;
  public readonly users: UsersService;
  public readonly webhooks: WebhooksService;
  public readonly xMigrations: XMigrationsService;
  public readonly request: BaseHttpRequest;
  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = FetchHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? "",
      VERSION: config?.VERSION ?? "2",
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? "include",
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
    this.accounts = new AccountsService(this.request);
    this.apiKey = new ApiKeyService(this.request);
    this.auth = new AuthService(this.request);
    this.callbacks = new CallbacksService(this.request);
    this.comments = new CommentsService(this.request);
    this.connect = new ConnectService(this.request);
    this.customers = new CustomersService(this.request);
    this.fieldView = new FieldViewService(this.request);
    this.floorplans = new FloorplansService(this.request);
    this.integrations = new IntegrationsService(this.request);
    this.layerItems = new LayerItemsService(this.request);
    this.mediaItems = new MediaItemsService(this.request);
    this.microservice = new MicroserviceService(this.request);
    this.notificationPreferences = new NotificationPreferencesService(
      this.request,
    );
    this.organisations = new OrganisationsService(this.request);
    this.ping = new PingService(this.request);
    this.projects = new ProjectsService(this.request);
    this.restrictions = new RestrictionsService(this.request);
    this.settings = new SettingsService(this.request);
    this.stripe = new StripeService(this.request);
    this.teams = new TeamsService(this.request);
    this.token = new TokenService(this.request);
    this.users = new UsersService(this.request);
    this.webhooks = new WebhooksService(this.request);
    this.xMigrations = new XMigrationsService(this.request);
  }
}

/* eslint-disable no-restricted-imports */

import { Stack, Typography } from "@mui/material";
import { OAuthPopup } from "@tasoskakour/react-use-oauth2";
import {
  Navigate,
  createBrowserRouter,
  redirect,
  useSearchParams,
} from "react-router-dom";

import logoutLoader from "./features/auth/loaders/logoutLoader";
import { msalLayoutLoader } from "./features/auth/loaders/msalLayoutLoader";
import loginCallbackLoader from "./features/auth/pages/LoginCallback/loader";
import dashboardLoader from "./features/dashboard/loaders/dashboardLoader";
import galleryFloorplansLoader from "./features/gallery/loaders/galleryFloorplansLoader";
import galleryLoader from "./features/gallery/loaders/galleryLoader";
import rootGalleryLoader from "./features/gallery/loaders/rootGalleryLoader";
import mediaItemLoader from "./features/media-item/loaders/mediaItemLoader";
import organisationDashboardLoader from "./features/organisation/loaders/organisationDashboardLoader";
import organisationEditMembers from "./features/organisation/loaders/organisationEditMembersLoader";
import organisatioNewRoleLoader from "./features/organisation/loaders/organisationNewRoleLoader";
import organisationProjectsLoader from "./features/organisation/loaders/organisationProjectsLoader";
import organisationRoleLoader from "./features/organisation/loaders/organisationRoleLoader";
import organisationRolesLoader from "./features/organisation/loaders/organisationRolesLoader";
import organisationSettingsLoader from "./features/organisation/loaders/organisationSettingsLoader";
import projectDashboardLoader from "./features/project/loaders/projectDashboardLoader";
import projectEditMembersLoader from "./features/project/loaders/projectEditMembersLoader";
import projectNewRoleLoader from "./features/project/loaders/projectNewRoleLoader";
import projectRoleLoader from "./features/project/loaders/projectRoleLoader";
import projectRolesLoader from "./features/project/loaders/projectRolesLoader";
import projectSettingsGeneralLoader from "./features/project/loaders/projectSettingsGeneralLoader";
import projectSettingsLoader from "./features/project/loaders/projectSettingsLoader";
import projectTeamsLoader from "./features/project/loaders/projectTeamsLoader";
import projectsDashboardLoader from "./features/project/loaders/projectsDashboardLoader";
import salesDashboardLoader from "./features/sales/loaders/salesDashboardLoader";
import salesOrganisationLoader from "./features/sales/loaders/salesOrganisationLoader";
import salesPageLoader from "./features/sales/loaders/salesPageLoader";
import confirmAliasLoader from "./features/settings/loaders/confirmAliasLoader";
import settingsBillingLoader from "./features/settings/loaders/settingsBillingLoader";
import settingsDeleteMeLoader from "./features/settings/loaders/settingsDeleteMeLoader";
import settingsIntegrationLoader from "./features/settings/loaders/settingsIntegrationLoader";
import settingsOrganisationLoader from "./features/settings/loaders/settingsOrganisationLoader";
import settingsPeopleLoader from "./features/settings/loaders/settingsPeopleLoader";
import settingsProfileLoader from "./features/settings/loaders/settingsProfileLoader";
import settingsProjectsLoader from "./features/settings/loaders/settingsProjectsLoader";
import teamSettingsLoader from "./features/settings/loaders/settingsTeamLoader";
import teamsSettingsLoader from "./features/settings/loaders/settingsTeamsLoader";
import teamCreateLoader from "./features/team/loaders/teamCreateLoader";
import teamEditFloorplansLoader from "./features/team/loaders/teamEditFloorplansLoader";
import teamEditGeneralLoader from "./features/team/loaders/teamEditGeneralLoader";
import teamEditLoader from "./features/team/loaders/teamEditLoader";
import teamEditTagsLoader from "./features/team/loaders/teamEditTagsLoader";
import teamFloorplanLoader from "./features/team/loaders/teamFloorplanLoader";
import teamIntegrationsLoader from "./features/team/loaders/teamIntegrationsLoader";
import teamMembersEditLoader from "./features/team/loaders/teamMembersEditLoader";
import teamNewRoleLoader from "./features/team/loaders/teamNewRoleLoader";
import teamRoleLoader from "./features/team/loaders/teamRoleLoader";
import teamRolesLoader from "./features/team/loaders/teamRolesLoader";
import teamTagFloorplanLoader from "./features/team/loaders/teamTagFloorplan";
import teamTagGPSLoader from "./features/team/loaders/teamTagGPSLoader";
import techSupportDashboardLoader from "./features/techsupport/loaders/techSupportDashboardLoader";
import techSupportIntegrationLoader from "./features/techsupport/loaders/techSupportIntegrationLoader";
import techSupportPageLoader from "./features/techsupport/loaders/techSupportPageLoader";
import techSupportUserLoader from "./features/techsupport/loaders/techSupportUserLoader";
import superUserLoader from "./pages/AdminPage/superUserLoader";
import { ErrorPage } from "./pages/ErrorPage";
import organisationSettingsGeneralLoader from "./features/organisation/loaders/organisationSettingsGeneralLoader";

export type LocalRoute =
  | `/`
  | `/gallery/${string}`
  | `/gallery/${string}/list`
  | `/gallery/${string}/map`
  | `/gallery/${string}/view/${string}`
  | `/gallery/view/${string}/${string}`
  | `/my-people`
  | `/logout`
  | `/admin`
  | `/admin/teams`
  | `/admin/test`
  | `/style-guide`
  | `/projects`
  | `/projects/${string}`
  | `/projects/${string}/teams`
  | `/teams`
  | `/login`
  | `/register`
  | `/callback-bv`
  | `/logout`
  | `/profile`
  | `/storefront`
  | `/order-review`
  | `/teams/create`
  | `/org/${string}`
  | `/invite/${string}`
  | `/integrations/connections`
  | `/integrations/${string}/connect`
  | `/integrations/${string}/list`
  | `/integrations/${string}/create`
  | `/settings`
  | `/settings/people`
  | `/settings/notifications`
  | `/settings/teams`
  | `/settings/projects`
  | `/settings/teams/${string}`
  | `/settings/projects/${string}`
  | `/settings/billing`
  | `/settings/organisations`
  | `/settings/integrations`
  | `/settings/integrations/${string}`
  | `/settings/integrations/${string}/new`
  | `/settings/integrations/${string}/connect`
  | `/settings/superuser`
  | `/sales`
  | `/sales/organisations`
  | `/sales/organisations/create`
  | `/sales/organisations/${string}`
  | `/tech-support`
  | `/tech-support/users`
  | `/tech-support/users/${string}`
  | `/delete-me`;

const Test = () => {
  const params = useSearchParams();
  console.log(params);

  return (
    <Stack>
      <Typography>Redirect!</Typography>
    </Stack>
  );
};

const routes = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    // lazy: () => import("./layouts/RootLayout"),
    children: [
      {
        lazy: () => import("./features/auth/layouts/MsalLayout"),
        loader: msalLayoutLoader,
        children: [
          {
            lazy: () => import("./layouts/AppLayout"),
            children: [
              {
                index: true,
                errorElement: <ErrorPage />,
                loader: dashboardLoader,
                lazy: () => import("./features/dashboard/pages/Dashboard"),
              },
              {
                path: "invite/:token",
                lazy: () => import("./pages/InviteLandingPage/InviteLanding"),
              },
              {
                path: "confirm-alias",
                loader: confirmAliasLoader,
                lazy: () => import("./features/settings/pages/ConfirmAlias"),
              },
              {
                path: "profile",
                errorElement: <ErrorPage />,
                lazy: () => import("./pages/ProfilePage/Profile"),
              },
              {
                path: "storefront",
                errorElement: <ErrorPage />,
                lazy: () => import("./features/storefront/pages/ProductSelect"),
              },
              {
                path: "order-review",
                errorElement: <ErrorPage />,
                lazy: () => import("./features/storefront/pages/OrderReview"),
              },
              {
                path: "private",
                loader: () => redirect("/gallery/private"),
              },
              {
                path: "gallery",
                loader: () => redirect("/gallery/all"),
              },
              {
                path: "gallery/view/:mediaId",
                errorElement: <ErrorPage />,
                children: [
                  {
                    index: true,
                    errorElement: <ErrorPage />,
                    loader: mediaItemLoader,
                    lazy: () =>
                      import("./features/media-item/pages/MediaItem/MediaItem"),
                  },
                  {
                    path: "floorplan",
                    errorElement: <ErrorPage />,
                    loader: mediaItemLoader,
                    lazy: () =>
                      import(
                        "./features/media-item/pages/MediaItemFloorplanPage/MediaItemFloorplanPage"
                      ),
                  },
                ],
              },
              {
                path: "gallery/:siteId/media/:mediaIndex",
                errorElement: <ErrorPage />,
                lazy: () =>
                  import(
                    "./features/media-item/pages/LatestMediaRedirect/LatestMediaRedirect"
                  ),
              },
              {
                path: "gallery/:siteId/:layout/view/:mediaId",
                errorElement: <ErrorPage />,
                loader: mediaItemLoader,
                lazy: () =>
                  import("./features/media-item/pages/MediaItem/MediaItem"),
              },
              {
                path: "floorplan/:siteId",
                errorElement: <ErrorPage />,
                loader: teamFloorplanLoader,
                lazy: () =>
                  import(
                    "./features/team/pages/TeamFloorplanPage/TeamFloorplanPage"
                  ),
              },
              {
                path: "gallery/:siteId",
                errorElement: <ErrorPage />,
                loader: rootGalleryLoader,
                lazy: () => import("./features/gallery/pages/Gallery/Gallery"),
                children: [
                  {
                    index: true,
                    loader: () => redirect("tile"),
                  },
                  {
                    path: "tile",
                    loader: galleryLoader,
                    lazy: () =>
                      import(
                        "./features/gallery/pages/GalleryTile/GalleryTile"
                      ),
                  },
                  {
                    path: "list",
                    loader: galleryLoader,
                    lazy: () =>
                      import(
                        "./features/gallery/pages/GalleryList/GalleryList"
                      ),
                  },
                  {
                    path: "map",
                    loader: galleryLoader,
                    lazy: () =>
                      import("./features/gallery/pages/GalleryMap/GalleryMap"),
                  },
                  {
                    path: "floorplan",
                    loader: galleryFloorplansLoader,
                    lazy: () =>
                      import(
                        "./features/gallery/pages/GalleryFloorplan/GalleryFloorplan"
                      ),
                  },
                ],
              },
              {
                path: "org",
                children: [
                  {
                    path: ":orgId",
                    children: [
                      {
                        index: true,
                        loader: organisationDashboardLoader,
                        lazy: () =>
                          import("./features/organisation/pages/OrgDashboard"),
                      },
                      {
                        path: "projects",
                        loader: organisationProjectsLoader,
                        lazy: () =>
                          import("./features/organisation/pages/OrgProjects"),
                      },
                      {
                        path: "settings",
                        loader: organisationSettingsLoader,
                        lazy: () =>
                          import(
                            "./features/organisation/pages/OrganisationSettings"
                          ),
                        children: [
                          {
                            index: true,
                            loader: organisationSettingsGeneralLoader,
                            lazy: () =>
                              import(
                                "./features/organisation/pages/OrganisationSettingsGeneral"
                              ),
                          },
                          {
                            path: "members",
                            loader: organisationEditMembers,
                            lazy: () =>
                              import(
                                "./features/organisation/pages/NewOrganisationEditMembers"
                              ),
                          },
                          {
                            path: "permissions",
                            children: [
                              {
                                index: true,
                                loader: organisationRolesLoader,
                                lazy: () =>
                                  import(
                                    "./features/organisation/pages/OrganisationRoles"
                                  ),
                              },
                              {
                                path: "create",
                                loader: organisatioNewRoleLoader,
                                lazy: () =>
                                  import(
                                    "./features/organisation/pages/NewOrganisationRole"
                                  ),
                              },
                              {
                                path: ":roleId",
                                loader: organisationRoleLoader,
                                lazy: () =>
                                  import(
                                    "./features/organisation/pages/OrganisationRole"
                                  ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "create",
                    lazy: () =>
                      import("./features/organisation/pages/OrgCreate"),
                  },
                ],
              },
              {
                path: "projects",
                children: [
                  {
                    path: "dashboard",
                    loader: projectsDashboardLoader,
                    lazy: () =>
                      import("./features/project/pages/ProjectsDashboard"),
                  },
                  {
                    path: ":projectId",
                    children: [
                      {
                        index: true,
                        loader: projectDashboardLoader,
                        lazy: () =>
                          import("./features/project/pages/ProjectDashboard"),
                      },
                      {
                        path: "teams",
                        loader: projectTeamsLoader,
                        lazy: () =>
                          import("./features/project/pages/ProjectTeams"),
                      },
                      {
                        path: "settings",
                        loader: projectSettingsLoader,
                        lazy: () =>
                          import("./features/project/pages/ProjectSettings"),
                        children: [
                          {
                            index: true,
                            loader: projectSettingsGeneralLoader,
                            lazy: () =>
                              import(
                                "./features/project/pages/ProjectSettingsGeneral"
                              ),
                          },
                          {
                            path: "members",
                            loader: projectEditMembersLoader,
                            lazy: () =>
                              import(
                                "./features/project/pages/NewProjectEditMembers"
                              ),
                          },
                          {
                            path: "permissions",
                            children: [
                              {
                                index: true,
                                loader: projectRolesLoader,
                                lazy: () =>
                                  import(
                                    "./features/project/pages/ProjectRoles"
                                  ),
                              },
                              {
                                path: "create",
                                loader: projectNewRoleLoader,
                                lazy: () =>
                                  import(
                                    "./features/project/pages/NewProjectRole"
                                  ),
                              },
                              {
                                path: ":roleId",
                                loader: projectRoleLoader,
                                lazy: () =>
                                  import(
                                    "./features/project/pages/ProjectRole"
                                  ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "create",
                    lazy: () =>
                      import("./features/project/pages/ProjectCreate"),
                  },
                ],
              },
              {
                path: "teams",
                children: [
                  // @todo - separate out the Dashboard page from the Teams page - this requires some design work
                  {
                    index: true,
                    errorElement: <ErrorPage />,
                    loader: dashboardLoader,
                    lazy: () => import("./features/dashboard/pages/Dashboard"),
                  },
                  {
                    path: "create",
                    loader: teamCreateLoader,
                    lazy: () => import("./features/team/pages/TeamCreate"),
                  },
                  {
                    path: ":teamId",
                    children: [
                      {
                        path: "settings",
                        loader: teamEditLoader,
                        lazy: () => import("./features/team/pages/TeamEdit"),
                        children: [
                          {
                            index: true,
                            loader: teamEditGeneralLoader,
                            lazy: () =>
                              import("./features/team/pages/TeamEditGeneral"),
                          },
                          {
                            path: "members",
                            loader: teamMembersEditLoader,
                            lazy: () =>
                              import(
                                "./features/team/pages/NewTeamEditMembers"
                              ),
                          },
                          {
                            path: "permissions",
                            children: [
                              {
                                index: true,
                                loader: teamRolesLoader,
                                lazy: () =>
                                  import("./features/team/pages/TeamRoles"),
                              },
                              {
                                path: "create",
                                loader: teamNewRoleLoader,
                                lazy: () =>
                                  import("./features/team/pages/NewTeamRole"),
                              },
                              {
                                path: ":roleId",
                                loader: teamRoleLoader,
                                lazy: () =>
                                  import("./features/team/pages/TeamRole"),
                              },
                            ],
                          },
                          {
                            path: "tags",
                            loader: teamEditTagsLoader,
                            lazy: () =>
                              import("./features/team/pages/TeamEditTags"),
                          },
                          {
                            path: "new-tags",
                            loader: teamEditTagsLoader,
                            lazy: () =>
                              import("./features/team/pages/NewTagsPage"),
                          },
                          {
                            path: "floorplans",
                            loader: teamEditFloorplansLoader,
                            lazy: () =>
                              import(
                                "./features/team/pages/TeamEditFloorplans"
                              ),
                          },
                          {
                            path: "integrations",
                            loader: teamIntegrationsLoader,
                            lazy: () =>
                              import("./features/team/pages/TeamIntegrations"),
                          },
                        ],
                      },
                      {
                        path: "tags/:tagId",
                        children: [
                          {
                            path: "gps",
                            lazy: () => import("./features/team/pages/TagGPS"),
                            loader: teamTagGPSLoader,
                          },
                          {
                            path: "floorplan",
                            lazy: () =>
                              import("./features/team/pages/TagFloorplan"),
                            loader: teamTagFloorplanLoader,
                          },
                        ],
                        // loader
                      },
                    ],
                  },
                  {
                    path: ":teamId/settings",
                  },
                ],
              },
              {
                loader: settingsDeleteMeLoader,
                lazy: () =>
                  import("./features/settings/pages/DeleteMyAccountPage"),
                path: "delete-me",
                errorElement: <ErrorPage />,
              },
              {
                lazy: () => import("./features/settings/pages/SettingsPage"),
                path: "settings",
                errorElement: <ErrorPage />,
                children: [
                  {
                    index: true,
                    loader: settingsProfileLoader,
                    lazy: () =>
                      import("./features/settings/pages/ProfileSettings"),
                  },
                  {
                    path: "people",
                    loader: settingsPeopleLoader,
                    lazy: () =>
                      import("./features/settings/pages/PeopleSettings"),
                  },
                  {
                    path: "notifications",
                    lazy: () =>
                      import(
                        "./features/settings/pages/NotificationSettingsPage"
                      ),
                  },
                  {
                    path: "teams",
                    loader: teamsSettingsLoader,
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import("./features/settings/pages/TeamsSettingsPage"),
                      },
                      {
                        path: ":teamId",
                        loader: teamSettingsLoader,
                        lazy: () =>
                          import("./features/settings/pages/TeamSettingsPage"),
                      },
                    ],
                  },
                  {
                    path: "projects",
                    loader: settingsProjectsLoader,
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import(
                            "./features/settings/pages/ProjectsSettingsPage"
                          ),
                      },
                      {
                        path: ":projectId",
                        loader: projectSettingsLoader,
                        lazy: () =>
                          import(
                            "./features/settings/pages/ProjectSettingsPage"
                          ),
                      },
                    ],
                  },
                  {
                    path: "billing",
                    loader: settingsBillingLoader,
                    lazy: () =>
                      import("./features/settings/pages/BillingSettingsPage"),
                  },
                  {
                    path: "organisations",
                    loader: settingsOrganisationLoader,
                    lazy: () =>
                      import(
                        "./features/settings/pages/OrganisationSettingsPage"
                      ),
                  },
                  {
                    path: "integrations",
                    loader: settingsIntegrationLoader,
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import(
                            "./features/settings/pages/IntegrationsSettingsPage"
                          ),
                      },
                      {
                        path: ":integrationType",
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                "./features/settings/pages/IntegrationGenericList"
                              ),
                          },
                          {
                            path: "connect",
                            lazy: () =>
                              import(
                                "./features/settings/pages/IntegrationGenericConnect"
                              ),
                          },
                          {
                            path: "create",
                            lazy: () =>
                              import(
                                "./features/settings/pages/IntegrationGenericCreate"
                              ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "superuser",
                    loader: superUserLoader,
                    lazy: () => import("./pages/AdminPage/Superuser"),
                  },
                ],
              },
              {
                lazy: () =>
                  import("./features/techsupport/pages/TechSupportPage"),
                loader: techSupportPageLoader,
                path: "tech-support",
                errorElement: <ErrorPage />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import(
                        "./features/techsupport/pages/TechSupportDashboard"
                      ),
                    loader: techSupportDashboardLoader,
                  },
                  {
                    path: "users",
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import(
                            "./features/techsupport/pages/TechSupportUsers"
                          ),
                        loader: techSupportDashboardLoader,
                      },
                      {
                        path: ":userId",
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import("./features/techsupport/pages/UserPage"),
                            loader: techSupportUserLoader,
                          },
                          {
                            path: "add-alias",
                            lazy: async () =>
                              await import(
                                "./features/techsupport/pages/AddUserAliasPage"
                              ),
                            loader: techSupportUserLoader,
                          },
                          {
                            path: "add-to-team",
                            lazy: async () =>
                              await import(
                                "./features/techsupport/pages/AddUserToTeamPage"
                              ),
                            loader: techSupportUserLoader,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "integrations",
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import(
                            "./features/techsupport/pages/TechSupportIntegrations"
                          ),
                        // loader: techSupportDashboardLoader,
                      },
                      {
                        path: ":integrationId",
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                "./features/techsupport/pages/TechSupportIntegration"
                              ),
                            loader: techSupportIntegrationLoader,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                lazy: () => import("./features/sales/pages/SalesPage"),
                loader: salesPageLoader,
                path: "sales",
                errorElement: <ErrorPage />,
                children: [
                  {
                    index: true,
                    lazy: () => import("./features/sales/pages/SalesDashboard"),
                    loader: salesDashboardLoader,
                  },
                  {
                    path: "organisations",
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import(
                            "./features/sales/pages/OrganisationsSalesPage"
                          ),
                        loader: salesDashboardLoader,
                      },
                      {
                        path: "create",
                        lazy: async () =>
                          await import(
                            "./features/sales/pages/CreateOrganisation"
                          ),
                      },

                      {
                        path: ":organisationId",
                        lazy: () =>
                          import("./features/sales/pages/OrganisationPage"),
                        loader: salesOrganisationLoader,
                      },
                    ],
                  },
                ],
              },

              {
                lazy: () => import("./features/auth/layouts/AdminLayout"),
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: "admin",
                    loader: superUserLoader,
                    lazy: () => import("./pages/AdminPage/Superuser"),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        lazy: () => import("./features/auth/layouts/LoginLayout"),
        children: [
          {
            path: "login",
            lazy: () => import("./features/auth/pages/Login/Login"),
          },
          {
            path: "callback-bv",
            loader: loginCallbackLoader,
            Component: null,
          },
        ],
      },
      {
        path: "logout",
        loader: logoutLoader,
      },
      {
        path: "landing",
        loader: () => redirect("/login"),
      },
      {
        path: "gallery/view/:mediaId/:sharecode",
        loader: mediaItemLoader,
        lazy: () => import("./features/media-item/pages/MediaItem/MediaItem"),
      },
      {
        path: "experiments/template-preview",
        lazy: () =>
          import(
            "./utils/reporting/pages/TemplatePreviewPage/TemplatePreviewPage"
          ),
      },
      {
        path: "*",
        lazy: () => import("./pages/NotFoundPage/NotFound"),
      },
    ],
  },
  {
    path: "/oauth2-callback",
    element: <OAuthPopup />,
  },
  {
    path: "/oauth2-callback2",
    element: <Test />,
  },
]);

export default routes;

import { Box } from "@mui/material";
import { type FC, type ReactNode, useCallback, useState } from "react";

import { Toast, type ToastStyle } from "./Toast";
import { ToastContext, type ToastMessage } from "./ToastContext";

export const ToastProvider: FC<{ children: ReactNode } & ToastStyle> = ({
  children,
  ...props
}) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const removeMessage = useCallback(
    (key: string | number) =>
      setMessages((arr) => arr.filter((m) => m.key !== key)),
    []
  );

  const upsertMessage = useCallback((message: ToastMessage) => {
    setMessages((arr) => {
      const index = arr.findIndex((m) => m.key === message.key);
      if (index === -1) {
        return [...arr, message];
      }
      arr[index] = message;
      return arr;
    });
  }, []);

  return (
    <ToastContext.Provider
      value={{
        removeMessage,
        upsertMessage,
      }}
    >
      {children}
      <Box
        sx={{
          position: "absolute",
          flexDirection: "column",
          gap: 1,
          bottom: "0px",
          left: "50%",
          right: "auto",
          transform: "translateX(-50%)",
          display: "flex",
        }}
      >
        {messages.map((m) => (
          <Toast
            key={`${m.key}${m.loading}${m.severity}${m.message}${m.icon}`}
            message={m}
            onExited={() => removeMessage(m.key)}
            {...props}
          />
        ))}
      </Box>
    </ToastContext.Provider>
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";

import projectQueryApi, { projectKeys } from "@/data/api/project";

export default function useRemoveProjectMember() {
  const queryClient = useQueryClient();

  return useMutation({
    ...projectQueryApi.removeProjectMember,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.members(variables.projectId),
      });
    },
  });
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PhotoSizeType {
  ORIGINAL = "Original",
  DISPLAY = "Display",
  THUMBNAIL = "Thumbnail",
  FLOORPLAN_THUMBNAIL = "FloorplanThumbnail",
  NANO = "Nano",
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditCommentCreatedGetDto } from "../models/AuditCommentCreatedGetDto";
import type { AuditCommentDeletedGetDto } from "../models/AuditCommentDeletedGetDto";
import type { AuditCommentUpdatedGetDto } from "../models/AuditCommentUpdatedGetDto";
import type { AuditMediaCreatedGetDto } from "../models/AuditMediaCreatedGetDto";
import type { AuditMediaDeletedGetDto } from "../models/AuditMediaDeletedGetDto";
import type { AuditMediaMarkedUpGetDto } from "../models/AuditMediaMarkedUpGetDto";
import type { AuditMediaRestoredGetDto } from "../models/AuditMediaRestoredGetDto";
import type { AuditMediaUpdatedGetDto } from "../models/AuditMediaUpdatedGetDto";
import type { ChunkUploadGetDto } from "../models/ChunkUploadGetDto";
import type { CommentsGetDto } from "../models/CommentsGetDto";
import type { CommentsPostDto } from "../models/CommentsPostDto";
import type { LogicalOperator } from "../models/LogicalOperator";
import type { MediaItemAccessCheckGetDto } from "../models/MediaItemAccessCheckGetDto";
import type { MediaItemAiMetaGetDto } from "../models/MediaItemAiMetaGetDto";
import type { MediaItemGpsGetDto } from "../models/MediaItemGpsGetDto";
import type { MediaItemMicroResultPatchDto } from "../models/MediaItemMicroResultPatchDto";
import type { MediaItemsBulkPatchDto } from "../models/MediaItemsBulkPatchDto";
import type { MediaItemsBulkPatchReturnDto } from "../models/MediaItemsBulkPatchReturnDto";
import type { MediaItemsBulkTagPatchDto } from "../models/MediaItemsBulkTagPatchDto";
import type { MediaItemsFinishDto } from "../models/MediaItemsFinishDto";
import type { MediaItemsFloorplanLocationPutDto } from "../models/MediaItemsFloorplanLocationPutDto";
import type { MediaItemsFullGetDto } from "../models/MediaItemsFullGetDto";
import type { MediaItemsGet360Dto } from "../models/MediaItemsGet360Dto";
import type { MediaItemsGetDto } from "../models/MediaItemsGetDto";
import type { MediaItemsMarkupPostDto } from "../models/MediaItemsMarkupPostDto";
import type { MediaItemsPatchDto } from "../models/MediaItemsPatchDto";
import type { MediaItemsPostDto } from "../models/MediaItemsPostDto";
import type { MediaItemsTranscriptPutDto } from "../models/MediaItemsTranscriptPutDto";
import type { MediaItemsUsedTagGetDto } from "../models/MediaItemsUsedTagGetDto";
import type { MediaLinkGetDto } from "../models/MediaLinkGetDto";
import type { MediaMapGetDto } from "../models/MediaMapGetDto";
import type { MediaMapPostOptionsDto } from "../models/MediaMapPostOptionsDto";
import type { MediaMapProgressGetDto } from "../models/MediaMapProgressGetDto";
import type { MediaStartWormDto } from "../models/MediaStartWormDto";
import type { MediaType } from "../models/MediaType";
import type { PermissionDefinitionGetDto } from "../models/PermissionDefinitionGetDto";
import type { ShareCodesDto } from "../models/ShareCodesDto";
import type { SortOption } from "../models/SortOption";
import type { StorageLinkDto } from "../models/StorageLinkDto";
import type { TeamsUploaderGetDto } from "../models/TeamsUploaderGetDto";
import type { UploadSource } from "../models/UploadSource";
import type { VideoTranscriptGetDto } from "../models/VideoTranscriptGetDto";
import type { ViewsMediaMetadata } from "../models/ViewsMediaMetadata";
import type { ZipCheckSizeGetDto } from "../models/ZipCheckSizeGetDto";
import type { ZipExportCreateDto } from "../models/ZipExportCreateDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class MediaItemsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete a media item the user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public deleteApiMedia(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/media/{mediaId}",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get full details of a media item the User has access to (Auth policies: UserOrSharecode)
   * **Scope**: UserOrSharecode
   * @param mediaId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns MediaItemsFullGetDto Success
   * @throws ApiError
   */
  public getApiMedia(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<MediaItemsFullGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Edit a media item the user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns MediaItemsFullGetDto Success
   * @throws ApiError
   */
  public patchApiMedia(
    mediaId: string,
    xVersion: string = "2",
    requestBody?: MediaItemsPatchDto,
  ): CancelablePromise<MediaItemsFullGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media/{mediaId}",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete multiple media items (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param ids
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public deleteApiMedia1(
    ids?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemsGetDto>> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/media",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        ids: ids,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Edit the description and/or tags of several media items. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns MediaItemsBulkPatchReturnDto Success
   * @throws ApiError
   */
  public patchApiMedia1(
    xVersion: string = "2",
    requestBody?: MediaItemsBulkPatchDto,
  ): CancelablePromise<MediaItemsBulkPatchReturnDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a new Media Item (photo or video) (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User <br />
   * Check file size limit with GET /api/media/limits/max-bytes <br />
   * This creates an empty Media Item. Data is NOT uploaded here.
   * Add data to the Item by calling the POST /chunks API. <br />
   * Finalise the Item and make it available by calling the POST /finish API.
   * @param xVersion The requested API version
   * @param requestBody
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public postApiMedia(
    xVersion: string = "2",
    requestBody?: MediaItemsPostDto,
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Delete any media item - "true" delete, rather than flagged (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public deleteApiMediaAny(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/media/any/{mediaId}",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete transcript of a Media Item the User has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @returns VideoTranscriptGetDto Success
   * @throws ApiError
   */
  public deleteApiMediaTranscript(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<VideoTranscriptGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/media/{mediaId}/transcript",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get the transcript of a Video Media Item the User has access to (Auth policies: UserOrSharecode)
   * **Scope**: UserOrSharecode
   * @param mediaId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns VideoTranscriptGetDto Success
   * @throws ApiError
   */
  public getApiMediaTranscript(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<VideoTranscriptGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/transcript",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Edit transcript of a Media Item the User has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns VideoTranscriptGetDto Success
   * @throws ApiError
   */
  public patchApiMediaTranscript(
    mediaId: string,
    xVersion: string = "2",
    requestBody?: MediaItemsTranscriptPutDto,
  ): CancelablePromise<VideoTranscriptGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media/{mediaId}/transcript",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get comments of a Media Item the user has access to (Auth policies: UserOrSharecode)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns CommentsGetDto Success
   * @throws ApiError
   */
  public getApiMediaComments(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<Array<CommentsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/comments",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a comment (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   *
   * Users can comment on Media they created or in a site to which they have access.
   * @param mediaId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns CommentsGetDto Success
   * @throws ApiError
   */
  public postApiMediaComments(
    mediaId: string,
    xVersion: string = "2",
    requestBody?: CommentsPostDto,
  ): CancelablePromise<CommentsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/comments",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * API to get the commenters of a media item (Auth policies: UserOrSharecode)
   * **Scope**: UserOrSharecode
   * @param mediaId ID of the media item
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns TeamsUploaderGetDto Success
   * @throws ApiError
   */
  public getApiMediaCommenters(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<Array<TeamsUploaderGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/commenters",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Check if the user has access to all the listed media items (Auth policies: UserOrSharecode)
   * **Scope**: User
   * @param mediaIds
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns MediaItemAccessCheckGetDto Success
   * @throws ApiError
   */
  public getApiMediaCheckAccess(
    mediaIds?: Array<string>,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<MediaItemAccessCheckGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/check-access",
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      query: {
        mediaIds: mediaIds,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get the views for a MediaItem (Auth policies: UserOrSharecode)
   * **Scope**: UserOrSharecode
   * @param mediaId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns ViewsMediaMetadata Success
   * @throws ApiError
   */
  public getApiMediaViews(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<ViewsMediaMetadata> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/views",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get the tags used on multiple media items (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaIds
   * @param xVersion The requested API version
   * @returns MediaItemsUsedTagGetDto Success
   * @throws ApiError
   */
  public getApiMediaTags(
    mediaIds?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemsUsedTagGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/tags",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        mediaIds: mediaIds,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Rename or delete a specific tag on multiple media items (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns MediaItemsBulkPatchReturnDto Success
   * @throws ApiError
   */
  public patchApiMediaTags(
    xVersion: string = "2",
    requestBody?: MediaItemsBulkTagPatchDto,
  ): CancelablePromise<MediaItemsBulkPatchReturnDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media/tags",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Returns a list of gps data for the specified media mediaId (Auth policies: UserOrSharecode)
   * **Scope**: UserOrSharecode
   * @param mediaId The ids of the media items we want the GPS of
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns MediaItemGpsGetDto Success
   * @throws ApiError
   */
  public getApiMediaGps(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<MediaItemGpsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/gps",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * API to edit a video GPS file (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, Developer
   * @param mediaId ID of the video media item
   * @param xVersion The requested API version
   * @param formData
   * @returns MediaItemGpsGetDto Success
   * @throws ApiError
   */
  public patchApiMediaGps(
    mediaId: string,
    xVersion: string = "2",
    formData?: {
      Latitude?: number;
      Longitude?: number;
      Data?: Blob;
    },
  ): CancelablePromise<MediaItemGpsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media/{mediaId}/gps",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * API to get the uploader details of a media item (Auth policies: UserOrSharecode)
   * **Scope**: UserOrSharecode
   * @param mediaId ID of the media item
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns TeamsUploaderGetDto Success
   * @throws ApiError
   */
  public getApiMediaUploader(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<TeamsUploaderGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/uploader",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch a random photo you have access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns StorageLinkDto Success
   * @throws ApiError
   */
  public getApiMediaRandomPhoto(
    xVersion: string = "2",
  ): CancelablePromise<StorageLinkDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/random/photo",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get the map if it exists (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaMapGetDto Success
   * @throws ApiError
   */
  public getApiMediaMap(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaMapGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/map",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Start the mapping process for this video (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiMediaMap(
    mediaId: string,
    xVersion: string = "2",
    requestBody?: MediaMapPostOptionsDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/map",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Check how making the map is going (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaMapProgressGetDto Success
   * @throws ApiError
   */
  public getApiMediaMapProgress(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaMapProgressGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/map/progress",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Check if a media item is 360 (Auth policies: UserOrSharecode)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns MediaItemsGet360Dto Success
   * @throws ApiError
   */
  public getApiMedia360(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<MediaItemsGet360Dto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/360",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Edit whether a media item is 360 (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param is360
   * @param xVersion The requested API version
   * @returns MediaItemsGet360Dto Success
   * @throws ApiError
   */
  public patchApiMedia360(
    mediaId: string,
    is360?: boolean,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGet360Dto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media/{mediaId}/360",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        is360: is360,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get the video worm file for a video the User has access to (Auth policies: UserOrSharecode)
   * **Scope**: AuthorisedApp, User
   * @param id ID of the video media item
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns string Success
   * @throws ApiError
   */
  public getApiMediaWorm(
    id: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{id}/worm",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch the permissions that relate to media
   * @param xVersion The requested API version
   * @returns PermissionDefinitionGetDto Success
   * @throws ApiError
   */
  public getApiMediaPermissions(
    xVersion: string = "2",
  ): CancelablePromise<Array<PermissionDefinitionGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/permissions",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   *  (Auth policies: AuthorisedApp, User)
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaItemAiMetaGetDto Success
   * @throws ApiError
   */
  public getApiMediaAi(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemAiMetaGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/ai",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   *  (Auth policies: AuthorisedApp, User)
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaItemMicroResultPatchDto Success
   * @throws ApiError
   */
  public putApiMediaAi(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemMicroResultPatchDto> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/media/{mediaId}/ai",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetch the audit log of a particular media item (Auth policies: AuthorisedApp, User)
   * @param mediaId
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiMediaAudit(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<
    Array<
      | AuditMediaCreatedGetDto
      | AuditMediaUpdatedGetDto
      | AuditMediaDeletedGetDto
      | AuditMediaRestoredGetDto
      | AuditMediaMarkedUpGetDto
      | AuditCommentCreatedGetDto
      | AuditCommentUpdatedGetDto
      | AuditCommentDeletedGetDto
    >
  > {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/audit",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Download media items as a CSV (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp
   * @param page Index page of search results
   * @param size Number of items per page
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param tags Comma-separated list of tags to restrict results by
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param siteIds Site IDs to search within; accepts "all" and "private" as well
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param include
   * @param hasGpsOnly
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiMediaCsv(
    page?: number,
    size: number = 50,
    sort?: SortOption,
    tags?: Array<string>,
    types?: Array<MediaType>,
    dayOnly: boolean = true,
    siteIds?: Array<string>,
    userIds?: Array<string>,
    dateString?: string,
    dateStringEnd?: string,
    include?: string,
    hasGpsOnly: boolean = false,
    tagMode?: LogicalOperator,
    search?: string,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/csv",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        tags: tags,
        types: types,
        dayOnly: dayOnly,
        siteIds: siteIds,
        userIds: userIds,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        include: include,
        hasGpsOnly: hasGpsOnly,
        tagMode: tagMode,
        search: search,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Download private media items as a CSV (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp
   * @param page Index page of search results
   * @param size Number of items per page
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param tags Comma-separated list of tags to restrict results by
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param include
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param hasGpsOnly
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiMediaCsvPrivate(
    page?: number,
    size: number = 50,
    sort?: SortOption,
    tags?: Array<string>,
    types?: Array<MediaType>,
    dayOnly: boolean = true,
    userIds?: Array<string>,
    dateString?: string,
    dateStringEnd?: string,
    include?: string,
    tagMode?: LogicalOperator,
    search?: string,
    hasGpsOnly: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/csv/private",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        tags: tags,
        types: types,
        dayOnly: dayOnly,
        userIds: userIds,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        include: include,
        tagMode: tagMode,
        search: search,
        hasGpsOnly: hasGpsOnly,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Download specific media items as a CSV
   * <param name="mediaIds"> The bounding box we want to search in as a comma-separated string of the form west, south, east, north </param> (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp
   * @param mediaIds
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiMediaCsvIds(
    mediaIds?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/csv/ids",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        mediaIds: mediaIds,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Download notes as a CSV (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp
   * @param page
   * @param size
   * @param sort
   * @param tags
   * @param types
   * @param dayOnly
   * @param siteIds
   * @param userIds
   * @param dateString
   * @param dateStringEnd
   * @param include
   * @param hasGpsOnly
   * @param bbox
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiMediaNotesCsv(
    page?: number,
    size: number = 50,
    sort?: SortOption,
    tags?: string,
    types?: string,
    dayOnly: boolean = true,
    siteIds?: string,
    userIds?: string,
    dateString?: string,
    dateStringEnd?: string,
    include?: string,
    hasGpsOnly: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/notes/csv",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        tags: tags,
        types: types,
        dayOnly: dayOnly,
        siteIds: siteIds,
        userIds: userIds,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        include: include,
        hasGpsOnly: hasGpsOnly,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Download notes as a CSV (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp
   * @param page
   * @param size
   * @param sort
   * @param tags
   * @param types
   * @param dayOnly
   * @param userIds
   * @param dateString
   * @param dateStringEnd
   * @param include
   * @param hasGpsOnly
   * @param bbox
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiMediaNotesCsvPrivate(
    page?: number,
    size: number = 50,
    sort?: SortOption,
    tags?: string,
    types?: string,
    dayOnly: boolean = true,
    userIds?: string,
    dateString?: string,
    dateStringEnd?: string,
    include?: string,
    hasGpsOnly: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/notes/csv/private",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        tags: tags,
        types: types,
        dayOnly: dayOnly,
        userIds: userIds,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        include: include,
        hasGpsOnly: hasGpsOnly,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create Zip export via microservice (Auth policies: AuthorisedApp, User)
   * @param mediaItemIds
   * @param xVersion The requested API version
   * @returns ZipCheckSizeGetDto Success
   * @throws ApiError
   */
  public getApiMediaZipCheckSize(
    mediaItemIds?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<ZipCheckSizeGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/zip/check-size",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        mediaItemIds: mediaItemIds,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get thumbnail link for a Media Item (from microservice if it exists :) ) (Auth policies: UserOrSharecode)
   * **Scope**: UserOrSharecode
   * @param mediaId Media item whose thumbnail to fetch
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns StorageLinkDto Success
   * @throws ApiError
   */
  public getApiMediaThumbnail(
    mediaId: string,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<StorageLinkDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/thumbnail",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get thumbnail link for multiple Media Items (Auth policies: User)
   * **Scope**: UserOrSharecode
   * @param mediaIds
   * @param xVersion The requested API version
   * @returns StorageLinkDto Success
   * @throws ApiError
   */
  public getApiMediaThumbnails(
    mediaIds?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<StorageLinkDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/thumbnails",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        mediaIds: mediaIds,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get a list of display links for multiple Media Items (Auth policies: UserOrSharecode)
   * **Scope**: AuthorisedApp, User
   *
   * If the user does not have access to the media item, null will be added
   * @param mediaId ID of the media item for which to generate links
   * @param permalink True if the link should be permanent; otherwise it's a short-life link
   * @param xVersion The requested API version
   * @param xMediaCode
   * @returns MediaLinkGetDto Success
   * @throws ApiError
   */
  public getApiMediaLinks(
    mediaId: string,
    permalink: boolean = false,
    xVersion: string = "2",
    xMediaCode?: string,
  ): CancelablePromise<Array<MediaLinkGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/links",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
        "x-media-code": xMediaCode,
      },
      query: {
        permalink: permalink,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Return all access codes for a Media Item (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (with site access)
   * @param mediaId
   * @param xVersion The requested API version
   * @returns ShareCodesDto Success
   * @throws ApiError
   */
  public getApiMediaCodes(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<ShareCodesDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/codes",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Generate an access code for a Media Item (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (with site access)
   * @param mediaId
   * @param xVersion The requested API version
   * @returns ShareCodesDto Success
   * @throws ApiError
   */
  public postApiMediaCodes(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<ShareCodesDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/codes",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Delete an access code for a Media Item (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (with site access)
   * @param mediaId
   * @param code
   * @param xVersion The requested API version
   * @returns ShareCodesDto Success
   * @throws ApiError
   */
  public getApiMediaCodes1(
    mediaId: string,
    code: string,
    xVersion: string = "2",
  ): CancelablePromise<ShareCodesDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/media/{mediaId}/codes/{code}",
      path: {
        mediaId: mediaId,
        code: code,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Restore a deleted media item the user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public patchApiMediaRestore(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media/{mediaId}/restore",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Rotate a single photo the User has access to (Auth policies: User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId ID of the photo media item
   * @param clockwise If true, rotate clockwise; else counterclockwise
   * @param xVersion The requested API version
   * @returns MediaLinkGetDto Success
   * @throws ApiError
   */
  public patchApiMediaRotate(
    mediaId: string,
    clockwise: boolean = true,
    xVersion: string = "2",
  ): CancelablePromise<MediaLinkGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media/rotate/{mediaId}",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        clockwise: clockwise,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Rotate multiple photos the User has access to (Auth policies: User)
   * **Scope**: AuthorisedApp, User
   * @param mediaIds IDs of the photo media item
   * @param clockwise If true, rotate clockwise; else counterclockwise
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public patchApiMediaRotate1(
    mediaIds?: Array<string>,
    clockwise: boolean = true,
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/media/rotate",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        mediaIds: mediaIds,
        clockwise: clockwise,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * API for the user to upload a worm file.
   * If one already exists, it is replaced. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId ID of the video media item
   * @param xVersion The requested API version
   * @param formData
   * @returns any Success
   * @throws ApiError
   */
  public postApiMediaWorm(
    mediaId: string,
    xVersion: string = "2",
    formData?: {
      Data: Blob;
    },
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/worm/{mediaId}",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Start the worm mapping process for this video (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @param requestBody ID of the video media item
   * @returns any Success
   * @throws ApiError
   */
  public postApiMediaWormStart(
    mediaId: string,
    xVersion: string = "2",
    requestBody?: MediaStartWormDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/worm/start",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new Media Item by duplicating an existing media item (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User <br />
   * @param id
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public postApiMediaDuplicate(
    id: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/duplicate/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new Photo Media Item by duplicating an existing media item as a photo (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User <br />
   * Check file size limit with GET /api/media/limits/max-bytes <br />
   * This creates an empty Media Item. Data is NOT uploaded here.
   * Add data to the Item by calling the POST /chunks API. <br />
   * Finalise the Item and make it available by calling the POST /finish API.
   * @param id
   * @param xVersion The requested API version
   * @param requestBody
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public postApiMediaSaveAs(
    id: string,
    xVersion: string = "2",
    requestBody?: MediaItemsMarkupPostDto,
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/save-as/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create Zip export via microservice (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiMediaZip(
    xVersion: string = "2",
    requestBody?: ZipExportCreateDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/zip",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Start transcribing a Video Media Item the User has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public postApiMediaTranscribe(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/transcribe",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   *  (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param formData
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public postApiMediaOneShot(
    xVersion: string = "2",
    formData?: {
      /**
       * Optional ID to try to use in the database
       */
      Id?: string;
      /**
       * The byte array of the file to upload.
       * Check size limit with GET /api/settings/chunk-bytes?
       */
      Data: Blob;
      /**
       * Type of Media being uploaded
       */
      Type: MediaType;
      /**
       * Total number of bytes of this upload
       */
      ByteCount: number;
      /**
       * Format: **yyyy-MM-ddTHH:mm:sszzz** where:
       * - yyyy: the year in full
       * - MM: the month, with leading zero
       * - dd: the day on the month, with leading zero
       * - HH: the hour in 24 hours format, with leading zero
       * - mm: the minute, with leading zero
       * - ss: the second, with leading zero
       * - zzz: the local UTC offset, like +10:00 or -07:30
       *
       * Example: "2020-04-27T22:59:33+00:00"
       */
      Timestamp?: string;
      /**
       * Hash of the file
       */
      Hash?: string;
      /**
       * Description of the file
       */
      Description?: string;
      /**
       * Tags attached to the file
       */
      Tags?: Array<string>;
      /**
       * Name of the file, including extension
       */
      FileName: string;
      /**
       * Whether the audio of this file should be played (video only)
       */
      AudioEnabled?: boolean;
      /**
       * Whether this video is a 360-degree photo or video
       */
      Is360?: boolean;
      /**
       * The duration of this video in milliseconds
       */
      DurationMs?: number;
      /**
       * What was the latitude when the media item was captured?
       */
      Latitude?: number;
      /**
       * What was the longitude when the media item was captured?
       */
      Longitude?: number;
      /**
       * What was the compass reading when the media item was captured?
       */
      Heading?: number;
      /**
       * What was the floorplan ID where the media item was captured?
       */
      FloorplanId?: string;
      /**
       * What was the X coordinate on the floorplan where the media item was captured?
       */
      FloorplanX?: number;
      /**
       * What was the Y coordinate on the floorplan where the media item was captured?
       */
      FloorplanY?: number;
      /**
       * Where is this upload coming from?  Is it a camera upload or an import, or an API call?
       */
      Source?: UploadSource;
      /**
       * Resolution of the original file, width then height.
       * To support encoding of 4K files, please
       * provide the resolution.
       */
      Resolution?: Array<number>;
      /**
       * Optional site ids to add the media to immediately - must have "AddMedia" access to the teams.
       */
      SiteIds?: Array<string>;
    },
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/one-shot",
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Upload a chunk to a created Media Item the user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User <br />
   *
   * This uploads a Chunk to an Upload. Create one first using POST /api/media
   * Consult the GET /api/media/limits* APIs for the limits on chunk count
   * and chunk size.
   * @param mediaId
   * @param xVersion The requested API version
   * @param formData
   * @returns ChunkUploadGetDto Success
   * @throws ApiError
   */
  public postApiMediaChunks(
    mediaId: string,
    xVersion: string = "2",
    formData?: {
      /**
       * The index number of the chunk.
       * Check the limit with GET /api/settings/max-chunks
       */
      ChunkNum: number;
      /**
       * The byte array of the chunk.
       * Check size limit with GET /api/settings/chunk-bytes
       */
      Chunk: Blob;
    },
  ): CancelablePromise<ChunkUploadGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/chunks",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Upload a chunk to any created Media Item (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, Developer <br />
   *
   * This uploads a Chunk to a Media Item. Create one first using POST /api/media
   * Consult the GET /api/media/limits* APIs for the limits on chunk count
   * and chunk size.
   * @param mediaId
   * @param xVersion The requested API version
   * @param formData
   * @returns ChunkUploadGetDto Success
   * @throws ApiError
   */
  public postApiMediaChunksAny(
    mediaId: string,
    xVersion: string = "2",
    formData?: {
      /**
       * The index number of the chunk.
       * Check the limit with GET /api/settings/max-chunks
       */
      ChunkNum: number;
      /**
       * The byte array of the chunk.
       * Check size limit with GET /api/settings/chunk-bytes
       */
      Chunk: Blob;
    },
  ): CancelablePromise<ChunkUploadGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/chunks/any",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Finish uploading a Media Item the User has access to. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   *
   * This will finalise the list of chunks for
   * an upload. If the Media Item is a video, this also
   * starts the encoding process on Azure.
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaItemsFinishDto Success
   * @throws ApiError
   */
  public postApiMediaFinish(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsFinishDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/finish",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Finish uploading a Media Item the User has access to. (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, User
   *
   * This will finalise the list of chunks for
   * an upload. If the Media Item is a video, this also
   * starts the encoding process on Azure.
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaItemsFinishDto Success
   * @throws ApiError
   */
  public postApiMediaFinishAny(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsFinishDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/media/{mediaId}/finish/any",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Update the floorplan location of this media item (Auth policies: User)
   * **Scope**: AuthorisedApp, User
   * @param mediaId ID of the photo media item
   * @param xVersion The requested API version
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public putApiMediaFloorplan(
    mediaId: string,
    xVersion: string = "2",
    requestBody?: MediaItemsFloorplanLocationPutDto,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/media/{mediaId}/floorplan",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   *  (Auth policies: AuthorisedApp, User)
   * @param mediaId
   * @param xVersion The requested API version
   * @returns MediaItemsGetDto Success
   * @throws ApiError
   */
  public putApiMediaAiTrigger(
    mediaId: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGetDto> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/media/{mediaId}/ai/trigger",
      path: {
        mediaId: mediaId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

import type { AlertColor } from "@mui/material";
import { type ReactNode, createContext } from "react";

export interface ToastMessage {
  message: string;
  severity?: AlertColor;
  icon?: ReactNode;
  key: string | number;
  /**
   * If `true`, the message will be displayed as a loading message.
   * If a number, the message will be displayed with a progress indicator. (0-100)
   * @default false
   */
  loading?: boolean | number;
  /**
   * Time in milliseconds to display the message before removing it.
   * If null then the message will not be removed automatically.
   */
  timeout?: number | null;
}

export const ToastContext = createContext<{
  removeMessage: (key: string | number) => void;
  upsertMessage: (message: ToastMessage) => void;
}>(null as never);

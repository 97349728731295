/** Main types */
export enum Layout {
  Gallery = 0,
  List = 1,
  Map = 2,
}

export type ConfirmState = {
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  message: string;
  actions: {
    confirm: string;
    cancel: string;
  };
};

export type NetworkStatus = {
  connected: boolean;
  mode: "wifi" | "mobile" | "none";
};

export type SettingsState = {
  layout: Layout;
  isDarkMode: boolean | undefined;
  teamSortMode: TeamSortMode;
  hasSkippedLogin: boolean;
  hasSeenMicCarousel: boolean;
  hasSeenFileCarousel: boolean;
  network: NetworkStatus;
  showQueryDevtools: boolean;
};

export enum TeamSortMode {
  Alphabetical = 0,
  MostRecent = 1,
}

import * as blurhash from "blurhash-as/browser";
import blurhashWasmUrl from "blurhash-as/build/optimized.wasm?url";
import { createRoot } from "react-dom/client";

import Sentry from "@/utils/interfaces/sentry.interface";

import App from "./App";
// import "./index.css";
// import api_instance, { setBackendUrl } from "./utilities/api.instance";
import { success, warn } from "./utils/logger";

Sentry.init();
blurhash.setURL(blurhashWasmUrl);

// Prepare WebGL context ; weirdly it's much more reliable if I do this...
const canvas = document.createElement("canvas");
const gl =
  canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
// Report the result.
if (gl && gl instanceof WebGLRenderingContext) {
  success("REACT: WebGL supported!", "index.ts");
} else {
  warn("REACT: WebGL not supported...", "index.ts");
  window.alert(
    "Your browser doesn't seem to support WebGL.  Please refresh the page and try again, or try using a different browser (Google Chrome and Mozilla Firefox are preferred).  More information can be found at https://get.webgl.org/; If these options do not work, please send an email to cskinner@laingorourke.com.au"
  );
}

// msalInstance.initialize().then(() => {
//   msalInstance
//     .handleRedirectPromise()
//     .then(handleResponse)
//     .catch((err) => {
//       console.error(err);
//     });
// });

// function handleResponse(resp: AuthenticationResult | null) {
//   if (resp !== null) {
//     msalInstance.setActiveAccount(resp.account);
//   } else {
//     // need to call getAccount here?
//     const currentAccounts = msalInstance.getAllAccounts();
//     if (!currentAccounts || currentAccounts.length < 1) {
//       return;
//     } else if (currentAccounts.length > 1) {
//       // Add choose account code here
//     } else if (currentAccounts.length === 1) {
//       const activeAccount = currentAccounts[0];
//       msalInstance.setActiveAccount(activeAccount);
//     }
//   }
// }

// trace(api_instance.name, "Invoking api_instance");
// biome-ignore lint/style/noNonNullAssertion: pain to check
const root = createRoot(document.getElementById("root")!);
root.render(<App />);

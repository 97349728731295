import { useContext } from "react";

import {
  ExportContext,
  ExportProgressContext,
  type IExportManager,
} from "./provider";
import type { ExportItem } from "./utilities";

/**
 * useExport hook provides an upload manager so you can upload media items.
 * @returns {createExport, clear}
 */
export const useExport = (): IExportManager => useContext(ExportContext);

export const useExportItems = (): ExportItem[] =>
  useContext(ExportProgressContext);

export const useExportItem = (id: ExportItem["id"]) => {
  const downloads = useExportItems();
  return downloads.find((f) => f.id === id);
};

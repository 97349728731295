import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useToast } from "@/components/Snackbar/useToast";
import organisationQueryApi, {
  organisationKeys,
} from "@/data/api/organisation";

export default function useInviteOrganisationMember() {
  const queryClient = useQueryClient();
  const alert = useToast();
  return useMutation({
    ...organisationQueryApi.inviteOrganisationMember,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: organisationKeys.invitations(variables.organisationId),
      });

      alert.success("Members invited successfully");
    },
  });
}

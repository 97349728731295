// Utilities to help with exporting
import JSZipUtils from "jszip-utils";
import { DateTime } from "luxon";

import { DownloadSettings } from "@/api/backend.types";
import type {
  AccountInfoDto,
  MediaItemsFullGetDto,
  MediaLinkGetDto,
} from "@/api/generated";
import type { UploadProgress } from "@/hooks/useChunkedUpload";
import Random from "@/utils/interfaces/random.interface";
import type ReportTemplate from "@/utils/reporting/ReportTemplate";

export type ExportImageQuality =
  | "nano"
  | "thumbnail"
  | "display"
  | "original"
  | `${number}x${number}`;
export type ExportOrientation = "portrait" | "landscape";

export type ExportStatus = UploadProgress["status"];

export type ReportExportData = {
  mediaIds: string[];
  type: "mediaItem" | "report";
  format: "ppt" | "pdf" | "csv";
  options: {
    /** Name of export file */
    filename: string;
    /** Which link should we use for photos? */
    imageQuality: ExportImageQuality;
    /** For documents, which orientation should they have? */
    reportOrientation: ExportOrientation;
    /** How to structure the filename in Zips  */
    downloadSettings: DownloadSettings;
    /** Which template should we use for reports? */
    template: ReportTemplate;
    /** Team name?  @todo shouldn't this be per media? */
    teamName?: string;
    /** Team ID where this is being called from - for tracking reasons. */
    teamId?: string;
    /** Sharecode */
    sharecode?: string;
    /** How zoomed in should the map be, if used? */
    mapZoomLevel?: number;
  };
};

export type ZipExportData = {
  mediaIds: string[];
  type: "mediaItem" | "report";
  format: "zip";
  options: {
    /** Name of export file */
    filename: string;
    /** Which link should we use for photos? */
    imageQuality: ExportImageQuality;
    /** How to structure the filename in Zips  */
    downloadSettings: DownloadSettings;
    /** Team ID where this is being called from - for tracking reasons. */
    teamId?: string;
    /** Sharecode */
    sharecode?: string;
  };
};

export type ExportData = ZipExportData | ReportExportData;

export type SingleExportData = ExportData & {
  mediaIds: [string];
  format: "zip";
};

export type ExportProgress = {
  loaded: number;
  total: number;
  status: ExportStatus;
  error?: string;
};

export interface ExportItem extends ExportProgress {
  id: number;
  name: string;
  cancelTokenSource?: AbortController;
  type: "mediaItem" | "report";
}

export const exportIsInactive = (u: ExportProgress): boolean =>
  u.status === "failed" || u.status === "success" || u.status === "cancelled";

export const exportIsActive = (u: ExportProgress): boolean =>
  u.status === "pending" || u.status === "in progress";

/** Return the closest link given an array */
export const getSelectedLinkForExport = (
  links: MediaLinkGetDto[],
  quality: ExportImageQuality
): string => {
  /** MP4 links, sorted in descending order by resolution. Empty list if this is a photo. */
  const videoLinks = links
    .filter((l) => l.format === "mp4")
    .map((l) => ({
      url: l.link,
      resolution: l.resolution,
      res: Number.parseInt(l.resolution?.split("x")[0] ?? "0"),
    }))
    .sort((a, b) => b.res - a.res);
  if (videoLinks.length > 0) {
    const link =
      (quality === "original"
        ? videoLinks[0]?.url
        : quality === "display"
        ? videoLinks[1]
          ? videoLinks[1].url
          : videoLinks[0]?.url
        : quality === "thumbnail"
        ? videoLinks[Math.round((videoLinks.length * 2) / 3)]?.url
        : quality === "nano"
        ? videoLinks[videoLinks.length - 1]?.url
        : videoLinks.find((l) => l.resolution === quality)?.url) ??
      videoLinks[0]?.url;
    if (!link)
      throw new Error("Failed to get valid download link from server.");
    return link;
  }

  const link = links.find((l) => l.resolution === quality)?.link;
  if (!link) throw new Error("Failed to get valid download link from server.");
  return link;
};
/** Return the thumbnail link given an array */
export const getThumbnailForExport = (
  links: MediaLinkGetDto[]
): string | undefined => {
  return links.find((l) => l.resolution === "thumbnail" || l.format === "jpg")
    ?.link;
};

export const urlToMimeType = (URL: string): string => {
  const url = URL.toLowerCase();
  if (url.includes(".jpg") || url.includes(".jpeg")) return "image/jpeg";
  if (url.includes(".png")) return "image/png";
  if (url.includes(".svg")) return "image/svg+xml";
  if (url.includes(".gif")) return "image/gif";
  if (url.includes(".tiff")) return "image/tiff";
  if (url.includes(".bmp")) return "image/bmp";
  if (url.includes(".mp4")) return "video/mp4";
  if (url.includes(".mpeg")) return "video/mpeg";
  if (url.includes(".avi")) return "video/avi";
  if (url.includes(".webm")) return "video/webm";
  if (url.includes(".json")) return "application/json";
  if (url.includes(".pdf")) return "application/pdf";
  if (url.includes(".pptx"))
    return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  if (url.includes(".ppt")) return "application/vnd.ms-powerpoint";

  if (url.includes("/photos")) return "image/jpeg";
  return "application/octet-stream";
};

/**
 * Fetch the content and return the promise which resolves to an ArrayBuffer with the data.
 */
export const urlToArrayBuffer = (url: string): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    // http://stuk.github.io/jszip-utils/documentation/api/getbinarycontent.html
    // No defined types for it so we categorise it as any
    JSZipUtils.getBinaryContent(url, (err: unknown, data: ArrayBuffer) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const generateName = (
  media: MediaItemsFullGetDto,
  user: AccountInfoDto,
  downloadSettings: number
) => {
  const nameParts: string[] = [];

  console.log(`Generating name for ${media.id}`);
  if (downloadSettings & DownloadSettings.CaptureDateTime)
    nameParts.push(
      DateTime.fromISO(media.timestamp).toFormat("yyyy-LL-dd_HH-mm-ss")
    );

  if (downloadSettings & DownloadSettings.Username)
    nameParts.push(`${user.givenName}-${user.familyName}`);

  if (downloadSettings & DownloadSettings.Tags) {
    const tags = (media.tags ?? []).join("-");
    if (tags) nameParts.push(tags);
  }

  if (downloadSettings & DownloadSettings.Description) {
    nameParts.push(media.description);
  }
  // Milla was having issues with downloading, and it seemed to be related to the "replaceAll" function...
  try {
    let name: string = !nameParts.length
      ? "builtview-file"
      : nameParts.join("_");
    const regex = /[<>.:/"|\\?*]/g; // Remove invalid filename characters
    name = name.replace(regex, "").slice(0, 250); //Technically 255, but later we might add suffixes
    return name.replaceAll(" ", "_").replaceAll("\n", "_");
  } catch {
    return Random.guid();
  }
};

// Fetch and parse the data from the video URL
export const toDataUrl = async (url: string | null | undefined) => {
  if (!url) return "";
  const blob = await toBlob(url);
  if (!blob) throw new Error("Failed to create blob");
  return URL.createObjectURL(blob);
};

export const toBlob = async (url: string | null | undefined) => {
  if (!url) return null;

  const response = await fetch(url);
  const blob = await response.blob();
  // return new Blob([response.data], { type: urlToMimeType(url) });
  return blob;
};

export const toBase64 = (blob: Blob): Promise<string> =>
  new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve(reader.result as string); /** @todo handle ArrayBuffer */
    reader.readAsDataURL(blob);
  });

export const convertBase64ToBlob = async (base64: string): Promise<Blob> => {
  const base64Response = await fetch(base64);
  const blob = await base64Response.blob();
  return blob;
  // var bytes = atob(base64);
  // var ab = new ArrayBuffer(bytes.length);
  // var ia = new Uint8Array(ab);
  // for (var i = 0; i < bytes.length; i++) {
  //   ia[i] = bytes.charCodeAt(i);
  // }
  // return new Blob([ab], { type: type });
};

import type { LoaderFunctionArgs } from "react-router-dom";

import projectQueryApi from "@/data/api/project";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function teamCreateLoader({
  request,
}: LoaderFunctionArgs) {
  const projectId = new URL(request.url).searchParams.get("projectId");

  const account = await authenticate(request);

  if (!account) {
    return {
      projectId,
    };
  }

  queryClient.prefetchQuery(projectQueryApi.getProjects());

  return {
    projectId,
  };
}

export type TeamCreateLoaderReturnType = Awaited<
  ReturnType<typeof teamCreateLoader>
>;

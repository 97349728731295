import type { LoaderFunctionArgs } from "react-router-dom";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";
import organisationQueryApi from "@/data/api/organisation";

export default async function salesOrganisationLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { organisationId } = params;

  if (!organisationId)
    throw new Response("No Organisation Id Specified", { status: 400 });

  const account = await authenticate(request);

  if (!account) return { organisationId };

  queryClient.prefetchQuery(
    organisationQueryApi.getOrganisation(organisationId),
  );

  return { organisationId };
}

export type SalesOrganisationLoaderReturnType = Awaited<
  ReturnType<typeof salesOrganisationLoader>
>;

import { Sync } from "@mui/icons-material";
import FinishedIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import PreFlightIcon from "@mui/icons-material/FlightTakeoff";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  Typography,
  styled,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useCallback } from "react";

import { useExport, useExportItems } from "@/context/Export";
import type { ExportItem } from "@/context/Export/utilities";
import { useUpload, useUploadFiles } from "@/context/Upload";
import { useSidebar } from "@/context/sidebarHooks";
import useBoolState from "@/hooks/useBoolState";
import type { UploadProgress } from "@/hooks/useChunkedUpload";

import ExportStatusItem from "../../molecules/ExportStatusItem/ExportStatusItem";
import UploadItem from "../../molecules/UploadItem/UploadItem";

const isInactive = (item: ExportItem | UploadProgress): boolean =>
  item.status === "cancelled" ||
  item.status === "failed" ||
  item.status === "success";

const SyncManager = () => {
  const [modalOpen, openModal, closeModal] = useBoolState(false);

  const { clear: clearUploads } = useUpload();
  const { clear: clearExports } = useExport();
  const uploads = useUploadFiles();
  const exports = useExportItems();

  const handleClear = useCallback(() => {
    clearUploads();
    clearExports();
  }, [clearUploads, clearExports]);
  const classes = useStyles();
  const { state } = useSidebar();
  return [...uploads, ...exports].length ? (
    <div className={classes.syncmanager}>
      <Fab
        // className={classes.syncmanager}
        color="primary"
        variant="circular"
        onClick={openModal}
      >
        {[...exports, ...uploads].filter((j) => !isInactive(j)).length ? (
          <Tooltip title="Uploading...">
            <Sync
              sx={{
                animation: "spin 2s linear infinite",
                "@keyframes spin": {
                  "0%": {
                    transform: "rotate(360deg)",
                  },
                  "100%": {
                    transform: "rotate(0deg)",
                  },
                },
              }}
            />
          </Tooltip>
        ) : [...exports, ...uploads].filter((j) => j.status === "failed")
            .length ? (
          <Tooltip title="Uploads have failed!">
            <ErrorIcon />
          </Tooltip>
        ) : [...exports, ...uploads].filter((j) => j.status === "pending")
            .length ? (
          <Tooltip title="Preparing to upload...">
            <PreFlightIcon />
          </Tooltip>
        ) : (
          // ) : uploads.filter((j) => j.status === "waiting-for-wifi").length ? (
          //   <WaitingForWifiIcon />
          <Tooltip title="Uploads finished">
            <FinishedIcon />
          </Tooltip>
        )}
      </Fab>
      <Dialog
        onClose={closeModal}
        open={modalOpen}
        keepMounted
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Tasks in progress</DialogTitle>
        <Divider />
        <DialogContent>
          {[...exports, ...uploads].length === 0 ? (
            <Typography>No uploads or downloads in progress</Typography>
          ) : (
            <List>
              {/** @todo this list should be a virtualized one. */}
              {exports.map((xport) => (
                <ExportStatusItem key={xport.id} id={xport.id} />
              ))}
              {uploads.map((upload) => (
                <UploadItem key={upload.id} id={upload.id} />
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          {[...exports, ...uploads].filter(isInactive).length > 0 && (
            <ClearInactiveBtn
              variant="outlined"
              color="primary"
              onClick={handleClear}
            >
              Clear inactive
            </ClearInactiveBtn>
          )}
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    syncmanager: {
      position: "absolute",
      bottom: theme.spacing(3),
      right: theme.spacing(12),
      zIndex: theme.zIndex.snackbar,
    },
    spinning: {
      animation: "spin 2s linear infinite",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      // maxHeight: "calc(100vh - 20px)",
      // [theme.breakpoints.up("sm")]: {
      //   width: 500,
      // },
    },
    container: {
      zIndex: `${theme.zIndex.modal + 1} !important`,
    },
    scrollable: {
      overflow: "auto",
      maxHeight: "90%",
      // maxWidth: "90%",
    },
  })
);

const ClearInactiveBtn = styled(Button)({
  margin: "0 1rem 0 auto",
});

export default SyncManager;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentsGetDto } from "../models/CommentsGetDto";
import type { CommentsPatchDto } from "../models/CommentsPatchDto";
import type { CommentsUserGetDtoPagedResults } from "../models/CommentsUserGetDtoPagedResults";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class CommentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete a comment (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   *
   * Users can delete comments they created.
   * Users with "Delete note from media" access to a site
   * can delete comments from that site.
   * @param commentId
   * @param xVersion The requested API version
   * @returns CommentsGetDto Success
   * @throws ApiError
   */
  public deleteApiComments(
    commentId: string,
    xVersion: string = "2",
  ): CancelablePromise<CommentsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/comments/{commentId}",
      path: {
        commentId: commentId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Edit a comment (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   *
   * Users can only edit comments they created
   * @param commentId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns CommentsGetDto Success
   * @throws ApiError
   */
  public patchApiComments(
    commentId: string,
    xVersion: string = "2",
    requestBody?: CommentsPatchDto,
  ): CancelablePromise<CommentsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/comments/{commentId}",
      path: {
        commentId: commentId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get my comments (Auth policies: AuthorisedApp, User)
   * @param page
   * @param pageSize
   * @param xVersion The requested API version
   * @returns CommentsUserGetDtoPagedResults Success
   * @throws ApiError
   */
  public getApiComments(
    page: number = 1,
    pageSize: number = 10,
    xVersion: string = "2",
  ): CancelablePromise<CommentsUserGetDtoPagedResults> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/comments",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        pageSize: pageSize,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get a particular user's comment activity (Auth policies: AuthorisedApp, User)
   * @param userId
   * @param page
   * @param pageSize
   * @param xVersion The requested API version
   * @returns CommentsUserGetDtoPagedResults Success
   * @throws ApiError
   */
  public getApiCommentsUser(
    userId: string,
    page: number = 1,
    pageSize: number = 10,
    xVersion: string = "2",
  ): CancelablePromise<CommentsUserGetDtoPagedResults> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/comments/user/{userId}",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        pageSize: pageSize,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

import type { Shape } from "azure-maps-control";

import logger from "../logger";

import { P2D_Add, P2D_Angle, P2D_Dist, P2D_Sub } from "./2d.operations";
import { NULL_AFFINE, Scale2D } from "./2d.transforms";
import { type Bounds, type Dimensions, Dims, type Point2D } from "./2d.types";

/**
 * Find the minimum bounding rectangle for a given set of 2D points
 * @param data Points to check
 */
export const GetBounds = (data: Point2D[]): Bounds =>
  data.length < 1
    ? {
        min: { x: Number.POSITIVE_INFINITY, y: Number.POSITIVE_INFINITY },
        max: { x: Number.NEGATIVE_INFINITY, y: Number.NEGATIVE_INFINITY },
      }
    : data[0] && data.length === 1
      ? {
          min: { x: data[0].x, y: data[0].y },
          max: { x: data[0].x, y: data[0].y },
        }
      : data.reduce(
          (x, c) => ({
            min: {
              x: Math.min(x.min.x, c.x),
              y: Math.min(x.min.y, c.y),
            },
            max: {
              x: Math.max(x.max.x, c.x),
              y: Math.max(x.max.y, c.y),
            },
          }),
          {
            min: { x: Number.POSITIVE_INFINITY, y: Number.POSITIVE_INFINITY },
            max: { x: Number.NEGATIVE_INFINITY, y: Number.NEGATIVE_INFINITY },
          },
        );

/**
 * Calculate largest possible scale factor to fill target area with input without overflow.
 * @param source Input area to be scaled
 * @param target Target area
 * @returns Scale factor
 */
export const Fit = (source: Bounds, target: Bounds): number => {
  const dt = Dims(target);
  const ds = Dims(source);
  if (dt.height === 0 || dt.width === 0 || ds.height === 0 || ds.width === 0)
    return 1;
  return Math.min(dt.height / ds.height, dt.width / ds.width);
};

/**
 * Calculate transform to scale bounds to fit target and then center them within it.
 * @param source Input area to be transformed
 * @param target Target area
 * @returns Affine transformation to use to move points within source bounds.
 */
export const FitAndCenter = (
  source: Bounds,
  target: Bounds,
): { s: number; t: { x: number; y: number } } => {
  const s = Fit(source, target);
  const dt = Dims(target);
  const ds = Dims(source);

  let topLeft = P2D_Sub(target.min, Scale2D(source.min, s));
  if (!ds.height || !ds.width) {
    topLeft = {
      x: target.min.x + dt.width / 2,
      y: target.min.y + dt.height / 2,
    };
  } else if (dt.height / ds.height > dt.width / ds.width) {
    // Was fit to width, add top padding
    topLeft = P2D_Add(topLeft, { x: 0, y: (dt.height - ds.height * s) / 2 });
  } else {
    // Was fit to height, add left padding
    topLeft = P2D_Add(topLeft, { y: 0, x: (dt.width - ds.width * s) / 2 });
  }
  return { s, t: topLeft };
};

/**
 * Interpolate a point between two known points.
 * @param a Start point of interval
 * @param b Endpoint of interval
 * @param r Ratio between the two; e.g r=0.5 will return the midpoint between a and b
 */
export const Interpolate = (a: Point2D, b: Point2D, r = 0.5): Point2D => {
  return {
    x: a.x + (b.x - a.x) * r,
    y: a.y + (b.y - a.y) * r,
  };
};

export const FitPath = (
  start: Point2D,
  end: Point2D,
  path: Point2D[],
): { t: { x: number; y: number }; r: number; s: number } => {
  // If no path, do nothing
  if (path.length === 0) return NULL_AFFINE;
  // If only one point in path, center between start and end
  if (path.length === 1)
    return {
      ...NULL_AFFINE,
      ...FitAndCenter({ min: start, max: end }, GetBounds(path)),
    };
  // If start and end are identical, just offset to start
  if (start.x === end.x && start.y === end.y)
    return { ...NULL_AFFINE, t: start };

  /** Distance from start to end */
  const seD = P2D_Dist(start, end);
  /** Angle from start to end */
  const seA = P2D_Angle(start, end);

  const firstPath = path[0];
  const lastPath = path[path.length - 1];

  if (!firstPath || !lastPath) throw new Error("Invalid path");
  const a = firstPath;
  const b = lastPath;

  /** Distance from a to b; */
  const abD = P2D_Dist(a, b);

  /** Angle from a to b; */
  const abA = P2D_Angle(a, b);

  logger.info({ a, b, start, end, seD, seA, abD, abA }, "FitPath");

  return { t: P2D_Sub(start, a), s: seD / abD, r: abA - seA };
};

export const objectFit = (src: Dimensions, dest: Dimensions): Dimensions => {
  let scale = 1;
  if (
    dest.height === 0 ||
    dest.width === 0 ||
    src.height === 0 ||
    src.width === 0
  )
    scale = 1;
  else scale = Math.min(dest.height / src.height, dest.width / src.width);

  return { height: src.height * scale, width: src.width * scale };
};

/**
 * Determine is a shape is a rectangle
 * @param shape Azure Maps Shape
 * @returns boolean
 */
export const isShapeRectangle = (shape: Shape) => {
  // shape.isRectangle will return true if subtype is 'Rectangle' even if it is a weird polygon but we can still use it to check if we have the correct number of coordinates
  if (!shape.isRectangle) return false;
  const originalCoordinates = shape.getCoordinates();
  const coordinates =
    Array.isArray(originalCoordinates) && !Array.isArray(originalCoordinates[0])
      ? (originalCoordinates as [number, number][])
      : (originalCoordinates[0] as [number, number][]);
  return isRectangle(coordinates);
};

const isRectangle = (coordinates: [number, number][]) => {
  if (
    !(
      coordinates.length === 4 ||
      (coordinates.length === 5 &&
        JSON.stringify(coordinates[0]) === JSON.stringify(coordinates[4]))
    )
  )
    return false;

  const [x1, y1] = coordinates[0] ?? [0, 0];
  const [x2, y2] = coordinates[1] ?? [0, 0];
  const [x3, y3] = coordinates[2] ?? [0, 0];
  const [x4, y4] = coordinates[3] ?? [0, 0];
  // a rectangle should have equal opposite sides regardless if they are tilted or not
  return (
    Math.abs(x1 - x2) === Math.abs(x3 - x4) &&
    Math.abs(y1 - y3) === Math.abs(y2 - y4)
  );
};

export const isRectangleTilted = (shape: Shape) => {
  if (!shape.isRectangle) return false;
  const originalCoordinates = shape.getCoordinates();
  const coordinates =
    Array.isArray(originalCoordinates) && !Array.isArray(originalCoordinates[0])
      ? (originalCoordinates as [number, number][])
      : (originalCoordinates[0] as [number, number][]);

  const [x1] = coordinates[0] ?? [0];
  const [x2] = coordinates[1] ?? [0];
  const [x3] = coordinates[2] ?? [0];
  const [x4] = coordinates[3] ?? [0];
  return x1 !== x4 || x2 !== x3;
};

import { Button, Stack, Typography } from "@mui/material";

import useQuotaRestriction, { type ValidQuotaData } from "@/hooks/useQuota";

import LinkBehavior from "./LinkBehavior";

interface QuotaProgressProps<T extends keyof ValidQuotaData> {
  quota: T;
  /** Any additional items to add */
  buffer?: number;
  label: string;
}

export default function QuotaProgress<T extends keyof ValidQuotaData>({
  quota,
  buffer,
  label,
}: QuotaProgressProps<T>) {
  const { data } = useQuotaRestriction(quota, {});

  return (
    <Stack direction="row" justifyContent="space-between">
      <Button
        size="small"
        variant="text"
        LinkComponent={LinkBehavior}
        href="/storefront"
      >
        Upgrade
      </Button>

      <Typography>
        {label} {"- "}
        <b>
          {data
            ? `${data.current}${buffer ? ` + ${buffer}` : ""}/${
                data.limit !== 2147483647 ? data.limit : "∞"
              }`
            : "Loading..."}
        </b>
      </Typography>
    </Stack>
  );
}

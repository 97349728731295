import type { Point2D } from "./2d.types";
import { Deg2Rad } from "./common";

export const NULL_AFFINE = { t: { x: 0, y: 0 }, s: 1, r: 0 };

/** Rotation (in degrees) about the z axis */
export type Rotation2D = number;
/** Translation along x and y axes */
export type Translation2D = Point2D;
/** Scale along x, y and z axes */
export type Scale = number;
export type Affine2D = { r?: Rotation2D; t?: Translation2D; s?: Scale };

export const Transform2D = (p: Point2D, t: Affine2D = NULL_AFFINE) =>
  t === NULL_AFFINE ? p : Translate2D(Rotate2D(Scale2D(p, t.s), t.r), t.t);

export const Scale2D = (p: Point2D, s: Scale = 1): Point2D => ({
  x: p.x * s,
  y: p.y * s,
});

export const Rotate2D = (p: Point2D, r: Rotation2D = 0): Point2D => {
  const d = Deg2Rad(r);
  return {
    x: p.x * Math.cos(d) + p.y * Math.sin(d),
    y: -p.x * Math.sin(d) + p.y * Math.cos(d),
  };
};

export const Translate2D = (
  p: Point2D,
  t: Translation2D = { x: 0, y: 0 },
): Point2D => ({
  x: p.x + t.x,
  y: p.y + t.y,
});

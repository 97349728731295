export default class PermissionError extends Error {
  public currentPermission: number;
  public requiredPermission: number;

  constructor(currentPermission: number, requiredPermission: number) {
    super("You do not have permission to perform this action.");
    this.currentPermission = currentPermission;
    this.requiredPermission = requiredPermission;
    this.name = "PermissionError";
  }
}

import type {
  FetchQueryOptions,
  DefaultError,
  QueryKey,
  UseMutationOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
  QueryClient,
} from "@tanstack/react-query";

import type { CancelablePromise } from "@/api/generated";

export function cancelableQuery<T>(
  cancellablePromise: CancelablePromise<T>,
  signal: AbortSignal
): Promise<T> {
  signal.addEventListener("abort", () => cancellablePromise.cancel());
  return cancellablePromise;
}

export function mutationOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationOptions<TData, TError, TVariables, TContext> {
  return options;
}

export function infiniteQueryOptions<
  TQueryFnData,
  TError = DefaultError,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown
>(
  options: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey,
    TPageParam
  >
): UseInfiniteQueryOptions<
  TQueryFnData,
  TError,
  TData,
  TQueryFnData,
  TQueryKey,
  TPageParam
> {
  return options;
}

export async function fetchCachedQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryClient: QueryClient,
  options: FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): Promise<TData> {
  const cachedData = queryClient.getQueryData<TData>(options.queryKey);
  if (cachedData) {
    return cachedData;
  }
  return queryClient.fetchQuery(options);
}

import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";
import type {
  CommentsPostDto,
  MediaItemsBulkPatchDto,
  MediaItemsBulkTagPatchDto,
  MediaItemsFloorplanLocationPutDto,
  MediaItemsMarkupPostDto,
  MediaItemsPatchDto,
  MediaItemsPostDto,
  MediaItemsTranscriptPutDto,
  ZipExportCreateDto,
} from "@/api/generated";

import { cancelableQuery, mutationOptions } from "./helpers";

export const mediaKeys = {
  root: "media" as const,
  permissions: () => [...mediaKeys.root, "permissions" as const] as const,
  mediaItem: (id: string, sharecode?: string) =>
    sharecode
      ? ([mediaKeys.root, id, sharecode] as const)
      : ([mediaKeys.root, id] as const),
  details: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "details" as const] as const,
  history: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "history" as const] as const,
  thumbnail: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "thumbnail" as const] as const,
  comments: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "comments" as const] as const,
  links: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "links" as const] as const,
  transcript: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "transcript" as const] as const,
  gps: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "gps" as const] as const,
  floorplan: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "floorplan" as const] as const,
  worm: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "worm" as const] as const,
  wormProgress: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "wormProgress" as const] as const,
  uploader: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "uploader" as const] as const,
  commenters: (id: string, sharecode?: string) =>
    [...mediaKeys.mediaItem(id, sharecode), "commenters" as const] as const,
  bulkTags: (ids: string[]) =>
    [mediaKeys.root, "bulkTags" as const, ids.join(",")] as const,
};

export const mediaApi = {
  /**
   * Create a new media item.
   */
  createMedia: mutationOptions({
    mutationFn: (data: MediaItemsPostDto) =>
      api.mediaItems.postApiMedia(undefined, data),
  }),

  /**
   * Create a new media item from a media item.
   */
  createSaveAsMedia: mutationOptions({
    mutationFn: ({
      mediaId,
      ...data
    }: MediaItemsMarkupPostDto & { mediaId: string }) =>
      api.mediaItems.postApiMediaSaveAs(mediaId, undefined, data),
  }),

  /**
   * Delete a media item.
   */
  deleteMedia: mutationOptions({
    mutationFn: (mediaId: string) => api.mediaItems.deleteApiMedia(mediaId),
  }),

  /**
   * Restore a media item.
   */
  restoreMedia: mutationOptions({
    mutationFn: (mediaId: string) =>
      api.mediaItems.patchApiMediaRestore(mediaId),
  }),

  /**
   * Delete multiple media items.
   */
  deleteMediaMulti: mutationOptions({
    mutationFn: (mediaIds: string[]) =>
      api.mediaItems.deleteApiMedia1(mediaIds),
  }),

  /**
   * Edit a media item.
   */
  editMedia: mutationOptions({
    mutationFn: ({
      mediaId,
      ...data
    }: MediaItemsPatchDto & { mediaId: string }) =>
      api.mediaItems.patchApiMedia(mediaId, undefined, data),
  }),

  rotateMedia: mutationOptions({
    mutationFn: ({
      mediaId,
      clockwise,
      siteId,
    }: {
      mediaId: string;
      clockwise?: boolean;
      siteId?: string;
    }) => api.mediaItems.patchApiMediaRotate(mediaId, clockwise),
  }),

  /**
   * Get a media item.
   * @param mediaId
   * @param sharecode
   * @returns
   */
  getMedia: (mediaId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMedia(mediaId, undefined, sharecode),
          signal
        ),
      queryKey: mediaKeys.details(mediaId, sharecode),
    }),

  getMediaHistory: (mediaId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaAudit(mediaId, undefined),
          signal
        ),
      queryKey: mediaKeys.history(mediaId),
    }),
  /**
   * Get a media item thumbnail.
   * @param mediaId
   * @param sharecode
   * @returns
   */
  getMediaThumbnail: (mediaId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaThumbnail(mediaId, undefined, sharecode),
          signal
        ),
      queryKey: mediaKeys.thumbnail(mediaId, sharecode),
    }),

  /**
   * Get a media item's comments.
   * @param mediaId
   * @param sharecode
   * @returns
   */
  getMediaComments: (mediaId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaComments(mediaId, undefined, sharecode),
          signal
        ),
      queryKey: mediaKeys.comments(mediaId, sharecode),
    }),

  /**
   * Create a new comment on a media item.
   */
  createMediaComment: mutationOptions({
    mutationFn: ({ mediaId, ...data }: CommentsPostDto & { mediaId: string }) =>
      api.mediaItems.postApiMediaComments(mediaId, undefined, data),
  }),

  /**
   * Get the links for a media item. (download, original etc.)
   * @param mediaId
   * @param sharecode
   * @param permalink
   * @returns
   */
  getMediaLinks: (mediaId: string, sharecode?: string, permalink?: boolean) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaLinks(
            mediaId,
            permalink,
            undefined,
            sharecode
          ),
          signal
        ),
      queryKey: [...mediaKeys.links(mediaId, sharecode), permalink] as const,
    }),

  /**
   * Get the transcript for a media item.
   * @param mediaId
   * @param sharecode
   * @returns
   */
  getMediaTranscript: (mediaId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaTranscript(mediaId, undefined, sharecode),
          signal
        ),
      queryKey: mediaKeys.transcript(mediaId, sharecode),
    }),

  /**
   * Edit the transcript for a media item.
   */
  editMediaTranscript: mutationOptions({
    mutationFn: ({
      mediaId,
      ...data
    }: MediaItemsTranscriptPutDto & { mediaId: string }) =>
      api.mediaItems.patchApiMediaTranscript(mediaId, undefined, data),
  }),

  /**
   * Delete the transcript for a media item.
   */
  deleteMediaTranscript: mutationOptions({
    mutationFn: (mediaId: string) =>
      api.mediaItems.deleteApiMediaTranscript(mediaId),
  }),

  /**
   * Get the GPS data for a media item.
   * @param mediaId
   * @param sharecode
   * @returns
   */
  getMediaGPS: (mediaId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaGps(mediaId, undefined, sharecode),
          signal
        ),
      queryKey: mediaKeys.gps(mediaId, sharecode),
    }),

  /**
   * Edit the GPS data for a media item.
   */
  editMediaGPS: mutationOptions({
    mutationFn: ({
      mediaId,
      ...data
    }: {
      mediaId: string;
      Latitude?: number;
      Longitude?: number;
      Data?: Blob;
    }) => api.mediaItems.patchApiMediaGps(mediaId, undefined, data),
  }),

  /**
   * Edit the floorplan location for a media item.
   */
  editMediaFloorplan: mutationOptions({
    mutationFn: ({
      mediaId,
      ...data
    }: {
      mediaId: string;
    } & MediaItemsFloorplanLocationPutDto) =>
      api.mediaItems.putApiMediaFloorplan(mediaId, undefined, data),
  }),

  /**
   * Edit the GPS data for a media item.
   */
  analyseMediaItem: mutationOptions({
    mutationFn: (mediaId: string) => api.mediaItems.putApiMediaAi(mediaId),
  }),

  /**
   * Export the given media items as a ZIP file to email to the user later.
   */
  checkZipSize: mutationOptions({
    mutationFn: (mediaItemIds: string[]) =>
      api.mediaItems.getApiMediaZipCheckSize(mediaItemIds),
  }),

  /**
   * Export the given media items as a ZIP file to email to the user later.
   */
  exportMediaZip: mutationOptions({
    mutationFn: (data: ZipExportCreateDto) =>
      api.mediaItems.postApiMediaZip(undefined, data),
  }),

  /**
   * Bulk edit media items.
   */
  bulkEditMedia: mutationOptions({
    mutationFn: (data: MediaItemsBulkPatchDto) =>
      api.mediaItems.patchApiMedia1(undefined, data),
  }),

  /**
   * Bulk get media item tags
   */
  bulkGetMediaTags: (mediaIds: string[]) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaTags(mediaIds, undefined),
          signal
        ),
      queryKey: mediaKeys.bulkTags(mediaIds),
    }),

  /**
   * Bulk edit media item tags.
   */
  bulkEditTags: mutationOptions({
    mutationFn: (data: MediaItemsBulkTagPatchDto) =>
      api.mediaItems.patchApiMediaTags(undefined, data),
  }),

  /**
   * Create a sharecode for a media item.
   */
  createSharecode: mutationOptions({
    mutationFn: (mediaId: string) => api.mediaItems.postApiMediaCodes(mediaId),
  }),

  /**
   * Finish a media item upload.
   */
  finishMediaUpload: mutationOptions({
    mutationFn: (mediaId: string) => api.mediaItems.postApiMediaFinish(mediaId),
  }),

  /**
   * Create a video worm/route map
   */
  createMediaMap: mutationOptions({
    mutationFn: ({ mediaId }: { mediaId: string }) =>
      api.mediaItems.postApiMediaMap(mediaId, undefined, {
        forceUpdate: true,
      }),
  }),

  /**
   * Get a video worm/route map
   * @param mediaId
   * @param sharecode
   * @returns
   * @todo Sharecode is not implemented in the API yet.
   */
  getMediaMap: (mediaId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.mediaItems.getApiMediaMap(mediaId), signal),
      queryKey: mediaKeys.worm(mediaId),
    }),

  getMediaUploader: (mediaId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaUploader(mediaId, undefined, sharecode),
          signal
        ),
      queryKey: mediaKeys.uploader(mediaId, sharecode),
    }),

  getMediaCommenters: (mediaId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.mediaItems.getApiMediaCommenters(mediaId, undefined, sharecode),
          signal
        ),
      queryKey: mediaKeys.commenters(mediaId, sharecode),
    }),

  getPermissions: () =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.mediaItems.getApiMediaPermissions(), signal),
      queryKey: mediaKeys.permissions(),
    }),
  getMapProgress: (mediaId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.mediaItems.getApiMediaMapProgress(mediaId), signal),
      queryKey: mediaKeys.wormProgress(mediaId),
      refetchInterval: 1000,
    }),
};

export default mediaApi;

import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import type { AccountQuotasGetDto } from "@/api/generated";
import accountApi from "@/data/api/accounts";

type Contexts = {
  organisation?: string;
  project?: string;
  team?: string;
};

export type ValidQuotaData = Omit<AccountQuotasGetDto, "id" | "tierName">;

export default function useQuotaRestriction<T extends keyof ValidQuotaData>(
  key: T,
  { organisation, project, team }: Contexts
) {
  const { data, ...rest } = useQuery(
    accountApi.getQuotas(organisation, project, team)
  );

  const restrictionData = useMemo(() => data?.[key], [key, data?.[key]]);

  return {
    data: restrictionData,
    ...rest,
  };
}

import * as oauth from "oauth4webapi";

import { AccessTokenSchema } from "../schema/AccessTokenSchema";

interface AuthConfig {
  issuer: URL;
  redirect_uri: string;
  client: oauth.Client;
  scopes: string[];
}

class AuthError extends Error {
  public error: string;
  public error_description: string;

  constructor(error: string, error_description: string) {
    super(error_description);
    this.error = error;
    this.error_description = error_description;
  }
}

export default class Authenticator {
  private client: oauth.Client;
  private issuer: URL;
  private approvedIssuer: URL | undefined;
  private redirect_uri: string;
  private as: oauth.AuthorizationServer | undefined;
  private scopes: string[];

  constructor(config: AuthConfig) {
    this.client = config.client;
    this.issuer = config.issuer;
    this.redirect_uri = config.redirect_uri;
    this.scopes = config.scopes;
  }

  private async getApprovedIssuer() {
    if (this.approvedIssuer) {
      return this.approvedIssuer;
    }

    const approvedIssuer = await fetch(
      `${this.issuer.toString()}.well-known/openid-configuration`
    );

    if (!approvedIssuer.ok) {
      throw new Error("Could not fetch openid configuration");
    }

    const approvedIssuerJson = await approvedIssuer.json();
    const approvedIssuerJsonIssuer = approvedIssuerJson.issuer;
    if (typeof approvedIssuerJsonIssuer !== "string") {
      throw new Error("Issuer is not a string");
    }

    this.approvedIssuer = new URL(approvedIssuerJsonIssuer);

    return this.approvedIssuer;
  }

  private async getAS() {
    if (!this.as) {
      const approvedIssuer = await this.getApprovedIssuer();

      const as = await oauth
        .discoveryRequest(this.issuer)
        .then((response) =>
          oauth.processDiscoveryResponse(approvedIssuer, response)
        );

      this.as = as;

      return as;
    }

    return this.as;
  }

  /**
   * Returns the oauth url to get the initial token
   * @returns Oauth url to redirect to
   */
  public async getInitialToken() {
    const as = await this.getAS();

    const code_verifier = oauth.generateRandomCodeVerifier();

    window.localStorage.setItem("code_verifier", code_verifier);

    const code_challenge = await oauth.calculatePKCECodeChallenge(
      code_verifier
    );
    const code_challenge_method = "S256";

    const authorizationUrl = new URL(as.authorization_endpoint ?? "");
    authorizationUrl.searchParams.set("login_hint", "ignore_me");
    authorizationUrl.searchParams.set("client_id", this.client.client_id);
    authorizationUrl.searchParams.set("code_challenge", code_challenge);
    authorizationUrl.searchParams.set(
      "code_challenge_method",
      code_challenge_method
    );
    authorizationUrl.searchParams.set("redirect_uri", this.redirect_uri);
    authorizationUrl.searchParams.set("response_type", "code");
    const scopesString = this.scopes.join(" ");
    authorizationUrl.searchParams.set("scope", scopesString);
    authorizationUrl.searchParams.set("response_type", "code");

    // Disables use of personal MS accounts
    authorizationUrl.searchParams.set("msafed", "0");
    console.log("xAuthorizationUrl", authorizationUrl);
    console.log("Stringified auth url", authorizationUrl.toString());
    return authorizationUrl.toString();
  }

  /**
   * Sets the initial token in local storage
   * This should be called once
   *
   * @param currentUrl The current url (of the callback page) that includes the state
   * @returns void
   */
  public async handleCallback(currentUrl: string) {
    const code_verifier = window.localStorage.getItem("code_verifier");
    const as = await this.getAS();

    if (!code_verifier) {
      return;
    }

    const params = oauth.validateAuthResponse(
      as,
      this.client,
      new URL(currentUrl),
      oauth.expectNoState
    );
    if (oauth.isOAuth2Error(params)) {
      console.log("error", params);
      throw new Error(params.error_description); // Handle OAuth 2.0 redirect error
    }

    const response = await oauth.authorizationCodeGrantRequest(
      as,
      this.client,
      params,
      this.redirect_uri,
      code_verifier,
      {
        additionalParameters: {
          redirect_uri: this.redirect_uri,
          prompt: "login",
        },
      }
    );

    window.localStorage.removeItem("code_verifier");

    let challenges: oauth.WWWAuthenticateChallenge[] | undefined;
    // biome-ignore lint/suspicious/noAssignInExpressions: it works, don't want to mess with it
    if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
      for (const challenge of challenges) {
        console.log("challenge", challenge);
      }
      throw new Error("Unhandled WWWAuthenticate challenge"); // Handle www-authenticate challenges as needed
    }

    const result = await oauth.processAuthorizationCodeOpenIDResponse(
      as,
      this.client,
      response
    );
    if (oauth.isOAuth2Error(result)) {
      console.log("error", result);
      throw new Error(result.error_description); // Handle OAuth 2.0 response body error
    }

    window.localStorage.setItem("auth", JSON.stringify(result));
    window.dispatchEvent(new Event("local-storage"));

    const redirectUrl = window.localStorage.getItem("redirect");

    if (!redirectUrl) {
      return;
    }

    window.localStorage.removeItem("redirect");

    // window.location.href = redirectUrl;
  }

  /**
   * Returns a new token if the current one is expired
   * @returns New token and sets it in local storage
   */
  public async getToken() {
    // handle new token fetch
    const authDeets = window.localStorage.getItem("auth");

    if (!authDeets) {
      const redirectAuthUrl = await this.getInitialToken();
      const currentUrl = window.location.href;
      window.localStorage.setItem("redirect", currentUrl);
      await new Promise(() => {
        window.location.href = redirectAuthUrl; // Eternally unresolved promise that blocks until the redirect happens
      });

      return;
    }

    const parsedAuthDeets = JSON.parse(authDeets);

    const validatedAuthDeets = await AccessTokenSchema.parseAsync(
      parsedAuthDeets
    );

    if (Date.now() < validatedAuthDeets.expires_on * 1000) {
      return validatedAuthDeets.access_token;
    }

    const as = await this.getAS();
    const response = await oauth.refreshTokenGrantRequest(
      as,
      this.client,
      validatedAuthDeets.refresh_token,
      {
        additionalParameters: {
          redirect_uri: this.redirect_uri,
        },
      }
    );

    let challenges: oauth.WWWAuthenticateChallenge[] | undefined;
    // biome-ignore lint/suspicious/noAssignInExpressions: It works, don't want to mess with it
    if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
      for (const challenge of challenges) {
        console.log("challenge", challenge);
      }
      throw new Error("Unhandled WWWAuthenticate challenge"); // Handle www-authenticate challenges as needed
    }

    const result = await oauth.processRefreshTokenResponse(
      as,
      this.client,
      response
    );

    if (oauth.isOAuth2Error(result)) {
      // check if refresh token is expired
      if (result.error === "invalid_grant") {
        const redirectAuthUrl = await this.getInitialToken(); // Get the initial token, e.g. without refresh token
        window.localStorage.setItem("redirect", window.location.href); // Save the current url for later
        await new Promise(() => {
          window.location.href = redirectAuthUrl; // Eternally unresolved promise that blocks until the redirect happens
        });
        throw new Error("Redirect not awaited"); // Handle OAuth 2.0 response body error
      }
      throw new Error("Error processing refresh token"); // Handle OAuth 2.0 response body error
    }

    const validatedResult = await AccessTokenSchema.parseAsync(result);

    window.localStorage.setItem("auth", JSON.stringify(validatedResult)); // Set the new token in local storage

    window.dispatchEvent(new Event("local-storage")); // Broadcast that local storage has been updated so that hooks can update

    return validatedResult.access_token;
  }

  public async logout() {
    const as = await this.getAS();
    const authDeets = window.localStorage.getItem("auth");

    if (!authDeets) {
      throw new Error("No auth details found, needs to be logged in");
    }

    const parsedAuthDeets = JSON.parse(authDeets);
    const validatedAuthDeets = await AccessTokenSchema.parseAsync(
      parsedAuthDeets
    );
    const end_session_endpoint = as.end_session_endpoint;

    if (!end_session_endpoint) {
      throw new Error("No end session endpoint");
    }

    const endSessionUrl = new URL(end_session_endpoint);
    endSessionUrl.searchParams.set(
      "id_token_hint",
      validatedAuthDeets.id_token
    );
    endSessionUrl.searchParams.set(
      "post_logout_redirect_uri",
      `${import.meta.env.VITE_FRONTEND_URL}logout`
    );

    endSessionUrl.searchParams.set("client_id", this.client.client_id);
    await new Promise(() => {
      window.location.href = endSessionUrl.toString();
    });
  }

  /**
   * Whether the current refresh token is valid and can be used to get a new access token
   * @returns the validity of the refresh token
   */
  public async hasValidAuth() {
    // get auth from local storage
    const authDeets = window.localStorage.getItem("auth");
    if (!authDeets) {
      return false;
    }

    const parsedAuthDeets = JSON.parse(authDeets);

    const validatedAuthDeets = await AccessTokenSchema.parseAsync(
      parsedAuthDeets
    );

    const { expires_on, expires_in, refresh_token_expires_in } =
      validatedAuthDeets;
    // work out the expires_on for the refresh token
    const refreshExpiresOn = new Date(
      (expires_on - expires_in + refresh_token_expires_in) * 1000
    );

    // console.log("refreshExpiresOn", refreshExpiresOn);

    if (Date.now() < refreshExpiresOn.getTime()) {
      return true;
    }

    return false;
  }
}

// The Logger is here to help!
import { ThemeColour } from "@/styles/theme/colors";

// TODO make this whole file procedurally generated using a list of levels, rather than all this copy-paste stuff.

// To prevent RAM crashes, don't let the log get longer than this
const MAX_LOG_LENGTH = 256;

/**
 * Types
 */

export enum LOG_LEVEL {
  ALL = -1,
  TRACE = 2,
  DEBUG = 5,
  INFO = 15,
  SUCCESS = 20,
  WARN = 25,
  ERROR = 35,
  CRITICAL = 45,
  NONE = 55,
}
export type LogContentType = string | string[] | number | object | unknown;
export type DataType = string | object | number | null;

export type LogItem = {
  ts: Date;
  content: LogContentType;
  name?: string;
  data?: DataType;
};

export interface Log {
  trace: LogItem[];
  debug: LogItem[];
  info: LogItem[];
  success: LogItem[];
  warn: LogItem[];
  error: LogItem[];
  critical: LogItem[];
}

const logs: Log = {
  trace: [],
  debug: [],
  info: [],
  success: [],
  warn: [],
  error: [],
  critical: [],
};

let level: LOG_LEVEL = LOG_LEVEL.INFO;
let levelSave: LOG_LEVEL = LOG_LEVEL.ALL;

const isSpreadable = (x: unknown): x is unknown[] =>
  typeof x === "object" || Array.isArray(x);

const console_debug = (message?: unknown) => {
  if (process.env.NODE_ENV !== "production")
    console.debug(...(isSpreadable(message) ? message : [message]));
};
const console_info = (message?: unknown) => {
  if (process.env.NODE_ENV !== "production")
    console.info(...(isSpreadable(message) ? message : [message]));
};
const console_warn = (message?: unknown) => {
  if (process.env.NODE_ENV !== "production")
    console.warn(...(isSpreadable(message) ? message : [message]));
};
const console_error = (message?: unknown) => {
  if (process.env.NODE_ENV !== "production")
    console.error(...(isSpreadable(message) ? message : [message]));
};

export const createLogger = (
  minLevel: LOG_LEVEL = LOG_LEVEL.INFO,
  minSave: LOG_LEVEL = LOG_LEVEL.ALL
) => {
  // minLevel - nothing below this will be reported to the developer console
  // Set up my "global variables"
  try {
    level = minLevel; // Should come in as a string.
    levelSave = minSave; // Should come in as a string.
  } catch {
    // You did the wrong thing...
    warn("Incompatible level detected", "createLogger");
  }
};

export const history = () => {
  return logs;
};

export const ftrace = (
  content: LogContentType,
  _name = "",

  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";

  const _content = typeof content === "number" ? `${content}` : content;

  if (levelSave <= LOG_LEVEL.TRACE)
    logs.trace.push({
      ts: new Date(),
      name,
      content: _content,
      data: _data,
    });
  if (logs.trace.length > MAX_LOG_LENGTH) logs.trace.pop();
  if (level > LOG_LEVEL.TRACE) return [];

  if (_data)
    return [
      `%c TRACE${name ? ` [${name}]` : ""}:`,
      "color: #959595",
      { message: _content, data: _data },
    ];
  if (typeof _content !== "string")
    return [`%c TRACE${name ? ` [${name}]` : ""}:`, "color: #959595", _content];

  return [`%c TRACE${name ? ` [${name}]` : ""}: ${_content}`, "color:#959595"];
};

export const fdebug = (
  content: LogContentType,
  _name = "",

  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";

  const _content = typeof content === "number" ? `${content}` : content;

  if (levelSave <= LOG_LEVEL.DEBUG)
    logs.debug.push({
      ts: new Date(),
      name,
      content: _content,
      data: _data,
    });
  if (logs.debug.length > MAX_LOG_LENGTH) logs.debug.pop();
  if (level > LOG_LEVEL.DEBUG) return [];

  if (_data)
    return [
      `%c DEBUG${name ? ` [${name}]` : ""}:`,
      "color: #656565",
      { message: _content, data: _data },
    ];
  if (typeof _content !== "string")
    return [`%c DEBUG${name ? ` [${name}]` : ""}:`, "color: #656565", _content];
  return [`%c DEBUG${name ? ` [${name}]` : ""}: ${_content}`, "color:#656565"];
};

export const finfo = (
  content: LogContentType,
  _name = "",

  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";

  const _content = typeof content === "number" ? `${content}` : content;
  if (levelSave <= LOG_LEVEL.INFO)
    logs.info.push({
      ts: new Date(),
      name,
      content: _content,
      data: _data,
    });
  if (logs.info.length > MAX_LOG_LENGTH) logs.info.pop();
  if (level > LOG_LEVEL.INFO) return [];
  if (_data)
    return [
      `%c INFO${name ? ` [${name}]` : ""}:`,
      `color: ${ThemeColour.primary.active}`,
      { message: _content, data: _data },
    ];
  if (typeof _content !== "string")
    return [
      `%c INFO${name ? ` [${name}]` : ""}:`,
      `color: ${ThemeColour.primary.active}`,

      _content,
    ];

  return [
    `%c INFO${name ? ` [${name}]` : ""}: ${_content}`,
    `color: ${ThemeColour.primary.active}`,
  ];
};

export const fsuccess = (
  content: LogContentType,
  _name = "",

  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";

  const _content = typeof content === "number" ? `${content}` : content;

  if (levelSave <= LOG_LEVEL.SUCCESS)
    logs.success.push({
      ts: new Date(),
      name,
      content: _content,
      data: _data,
    });
  if (logs.success.length > MAX_LOG_LENGTH) logs.success.pop();
  if (level > LOG_LEVEL.SUCCESS) return [];
  if (_data)
    return [
      `%c SUCCESS${name ? ` [${name}]` : ""}:`,
      "color: #00bb00",
      { message: _content, data: _data },
    ];
  if (typeof _content !== "string")
    return [
      `%c SUCCESS${name ? ` [${name}]` : ""}:`,
      "color: #00bb00",
      _content,
    ];

  return [
    `%c SUCCESS${name ? ` [${name}]` : ""}: ${_content}`,
    "color: #00bb00",
  ];
};

export const fwarn = (
  content: LogContentType,
  _name = "",

  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";

  const _content = typeof content === "number" ? `${content}` : content;

  if (levelSave <= LOG_LEVEL.WARN) {
    // if (
    //   name.includes("useMediaUploader") ||
    //   name.includes("uploadChunk") ||
    //   (JSON.stringify(content) ?? "").includes("media/chunks")
    // )
    logs.warn.push({
      ts: new Date(),
      name,
      content: _content,
      data: _data,
    });
    if (logs.warn.length > MAX_LOG_LENGTH) logs.warn.pop();
  }
  if (level > LOG_LEVEL.WARN) return [];

  if (_data)
    return [
      `%c WARN${name ? ` [${name}]` : ""}:`,
      "color: #f3722c",
      { message: _content, data: _data },
    ];
  if (typeof _content !== "string")
    return [`%c WARN${name ? ` [${name}]` : ""}:`, "color: #f3722c", _content];
  return [`%c WARN${name ? ` [${name}]` : ""}: ${_content}`, "color: #f3722c"];
};

export const ferror = (
  content: LogContentType,
  _name = "",

  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";

  const _content = typeof content === "number" ? `${content}` : content;

  if (levelSave <= LOG_LEVEL.ERROR)
    logs.error.push({
      ts: new Date(),
      name,
      content: _content,
      data: _data,
    });
  if (logs.error.length > MAX_LOG_LENGTH) logs.error.pop();
  if (level > LOG_LEVEL.ERROR) return [];

  if (_data)
    return [
      `%c ERROR${name ? ` [${name}]` : ""}:`,
      "color: #000; font-weight: bold",
      { message: _content, data: _data },
    ];
  if (typeof _content !== "string")
    return [
      `%c ERROR${name ? ` [${name}]` : ""}:`,
      "color: #000; font-weight: bold",
      _content,
    ];

  return [
    `%c ERROR${name ? ` [${name}]` : ""}: ${_content}`,
    "color: #000; font-weight: bold",
  ];
};

export const fcritical = (
  content: LogContentType,
  _name = "",

  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";

  const _content = typeof content === "number" ? `${content}` : content;

  if (levelSave <= LOG_LEVEL.CRITICAL)
    logs.critical.push({
      ts: new Date(),
      name,
      content,
      data: _data,
    });
  if (logs.critical.length > MAX_LOG_LENGTH) logs.critical.pop();
  if (level > LOG_LEVEL.CRITICAL) return [];

  if (_data)
    return [
      `%c CRITICAL${name ? ` [${name}]` : ""}:`,
      "color: #ff0000; background: #ddd; font-weight: bold",
      { message: _content, data: _data },
    ];
  if (typeof _content !== "string")
    return [
      `%c CRITICAL${name ? ` [${name}]` : ""}:`,
      "color: #ff0000; background: #ddd; font-weight: bold",
      _content,
    ];

  return [
    `%c CRITICAL${name ? ` [${name}]` : ""}: ${_content}`,
    "color: #ff0000; background: #ddd; font-weight: bold",
  ];
};

export const trace = (
  content: LogContentType,
  name = "",

  _data: DataType = null
) => {
  const m = ftrace(content, name, _data);
  if (m && m.length > 0) console_debug(m);
};
export const debug = (
  content: LogContentType,
  name = "",

  _data: DataType = null
) => {
  const m = fdebug(content, name, _data);
  if (m && m.length > 0) console_debug(m);
};
export const info = (
  content: LogContentType,
  name = "",

  _data: DataType = null
) => {
  const m = finfo(content, name, _data);
  if (m && m.length > 0) console_info(m);
};
export const success = (
  content: LogContentType,
  name = "",

  _data: DataType = null
) => {
  const m = fsuccess(content, name, _data);
  if (m && m.length > 0) console_info(m);
};
export const warn = (
  content: LogContentType,
  name = "",

  _data: DataType = null
) => {
  const m = fwarn(content, name, _data);
  if (m && m.length > 0) console_warn(m);
};
export const error = (
  content: LogContentType,
  name = "",

  _data: DataType = null
) => {
  const m = ferror(content, name, _data);
  if (m && m.length > 0) console_error(m);
};

export const critical = (
  content: LogContentType,
  name = "",

  _data: DataType = null
) => {
  const m = fcritical(content, name, _data);
  if (m && m.length > 0) console_error(m);
};

/* Haven't been able to Type this yet
export function withLogger(Compo) {
  const displayName = `withLogger(${Compo.displayName ?? Compo.name})`;
  function trace(content,  _data = null) {
    const m = ftrace(content, Compo.name, _alert, _data);
    if (m && m.length > 0) console_trace(m);
  }
  function debug(content,  _data = null) {
    const m = fdebug(content, Compo.name, _alert, _data);
    if (m && m.length > 0) console_debug(m);
  }
  function info(content,  _data = null) {
    const m = finfo(content, Compo.name, _alert, _data);
    if (m && m.length > 0) console_info(m);
  }
  function success(content,  _data = null) {
    const m = fsuccess(content, Compo.name, _alert, _data);
    if (m && m.length > 0) console_info(m);
  }
  function warn(content,  _data = null) {
    const m = fwarn(content, Compo.name, _alert, _data);
    if (m && m.length > 0) console_warn(m);
  }
  function error(content,  _data = null) {
    const m = ferror(content, Compo.name, _alert, _data);
    if (m && m.length > 0) console_error(m);
  }
  function critical(content,  _data = null) {
    const m = fcritical(content, Compo.name, _alert, _data);
    if (m && m.length > 0) console_error(m);
  }

  const C = (props) => {
    const { wrappedComponentRef, ...remainingProps } = props;
    return (
      <Compo
        {...remainingProps}
        ref={wrappedComponentRef}
        Log={{ trace, debug, info, success, warn, error, critical }}
      />
    );
  };
  C.displayName = displayName;
  C.WrappedComponent = Compo;
  return C;
}
*/

const logger = {
  history,
  ftrace,
  fdebug,
  finfo,
  fsuccess,
  fwarn,
  ferror,
  trace,
  debug,
  info,
  success,
  warn,
  error,
  critical,
};

export default logger;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getAccessHeaders } from "@/api/client";
import { accountKeys } from "@/data/api/accounts";
import { mediaKeys } from "@/data/api/media";
import { teamsKeys } from "@/data/api/teams";

import type { UploadProgress } from "./useChunkedUpload";

interface TusUploadParams {
  file: File;
  cloudId: string;
  cb: (progress: UploadProgress) => void;
  teamId?: string;
  signal?: AbortSignal;
}

const uploadTus = async ({
  file,
  cb,
  cloudId,
  teamId,
  signal,
}: TusUploadParams) => {
  const tus = await import("tus-js-client");
  const headers = await getAccessHeaders();
  await new Promise<void>((res, rej) => {
    const upload = new tus.Upload(file, {
      endpoint: `${import.meta.env.VITE_BACKEND_URL}/api/files`,
      fingerprint: async () => cloudId,
      removeFingerprintOnSuccess: true,
      metadata: {
        mediaItemId: cloudId,
      },
      chunkSize: 1024 * 1024 * 1, // 1MB
      headers,
      onProgress: (bytesUploaded, bytesTotal) => {
        // const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        // console.log(bytesUploaded, bytesTotal, percentage + "%");
        cb({
          total: bytesTotal,
          loaded: bytesUploaded,
          status: "in progress",
        });
      },
      onError: (e) => {
        rej(e);
      },
      onSuccess: () => {
        res();
      },
    });

    if (signal) {
      signal.addEventListener("abort", () => {
        // console.log("aborting upload");
        upload.abort(true);
        rej(new Error("Upload aborted"));
      });
    }

    upload.findPreviousUploads().then((previousUploads) => {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  });
};

export default function useTusUpload() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: uploadTus,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: mediaKeys.details(variables.cloudId),
      });
      if (variables.teamId) {
        queryClient.invalidateQueries({
          queryKey: teamsKeys.media(variables.teamId),
        });
      }
      queryClient.invalidateQueries({
        queryKey: accountKeys.quotas(),
      });
    },
    retry: (failureCount, error) => {
      if (error?.message === "Upload aborted") return false;

      if (failureCount > 10) return false;

      return true;
    },
    mutationKey: ["uploadTus"],
  });
}

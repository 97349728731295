/**
 * toPlural generates a string which is either plural or singular depending on a numeric value.
 * e.g. if a string needs to be "1 job" or "2 jobs" depending on the number.
 *
 * @param value The number to be encoded
 * @param singular The string to be used if the value is 1
 * @param plural The string to be used if the value is not 1, expressed as a function
 */
export const toPlural = (
  value: number,
  singular: string,
  plural: (x: number) => string
): string => (value === 1 ? singular : plural(value));

export const ellipsis = (text: string | undefined, maxLength = 20): string =>
  text
    ? text.length <= maxLength
      ? text
      : `${text.slice(0, maxLength - 3)}...`
    : "";

export const capitalise = (s?: string): string =>
  s ? `${s.substring(0, 1).toUpperCase()}${s.substring(1)}` : "";

import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";

import api from "@/api/client";
import type {
  AccessRolePatchDto,
  AccessRolePostDto,
  ArchiveTeamPatchDto,
  LayerItemsPostDto,
  MediaSearchParamsDto,
  SiteInvitesDto,
  SiteRolePatchDto,
  SiteTagsUsedGetDto,
  TeamTagsFloorplanPatchDto,
  TeamTagsFloorplanPostDto,
  TeamTagsGetDto,
  TeamTagsGpsPatchDto,
  TeamTagsUnifiedGetDto,
  TeamTagsUserPostDto,
  TeamTagsWithFpInfoGetDto,
  TeamTagsWithGeoInfoGetDto,
  TeamTagsWithUserInfoGetDto,
  TeamsPatchDto,
  TeamsPostDto,
} from "@/api/generated";
import type { TeamTagsGpsPostDto } from "@/api/generated/models/TeamTagsGpsPostDto";

import { cancelableQuery, mutationOptions } from "./helpers";

type MediaListDto = Omit<MediaSearchParamsDto, "page" | "size" | "gpsOnly">;

type MediaGPSListDto = Omit<MediaSearchParamsDto, "page" | "size">;
type MediaFloorplanListDto = Omit<MediaSearchParamsDto, "page" | "size">;

type InfiniteMediaDto = Omit<MediaSearchParamsDto, "page">;

interface EditTeamRole extends AccessRolePatchDto {
  teamId: string;
  roleId: string;
}

interface CreateTeamRole extends AccessRolePostDto {
  teamId: string;
}

interface ArchiveTeamData extends ArchiveTeamPatchDto {
  teamId: string;
}

interface GraphPayload {
  dateString?: string | undefined;
  dateStringEnd?: string | undefined;
  granularity?: "year" | "month" | "week" | "day";
}

/**
 * The payload for getting the activity of a team
 */
interface ActivityPayload {
  dateString?: string;
  dateStringEnd?: string;
}

interface AddGeoInfoData extends TeamTagsGpsPatchDto {
  teamId: string;
  tagId: string;
}

interface AddFloorplanInfoData extends TeamTagsFloorplanPatchDto {
  teamId: string;
  tagId: string;
}

export const teamsKeys = {
  root: "teams" as const,
  permissions: () => [teamsKeys.root, "permissions" as const] as const,
  team: (id: string, sharecode?: string) =>
    sharecode
      ? ([teamsKeys.root, id, sharecode] as const)
      : ([teamsKeys.root, id] as const),
  details: (id: string, sharecode?: string) =>
    [...teamsKeys.team(id, sharecode), "details" as const] as const,
  list: () => [teamsKeys.root, "list" as const] as const,
  listArchived: () => [...teamsKeys.list(), "archived" as const] as const,
  display: (id: string, sharecode?: string) =>
    [...teamsKeys.team(id, sharecode), "display" as const] as const,
  members: (id: string, sharecode?: string) =>
    [...teamsKeys.team(id, sharecode), "members" as const] as const,

  media: (id: string) => [...teamsKeys.team(id), "media" as const] as const,
  mediaList: (id: string) => [...teamsKeys.media(id), "list" as const] as const,
  mediaGPS: (id: string) => [...teamsKeys.media(id), "gps"] as const,
  uploaders: (id: string) =>
    [...teamsKeys.team(id), "uploaders" as const] as const,
  layers: (id: string) => [...teamsKeys.team(id), "layers" as const] as const,
  tags: (id: string) => [...teamsKeys.team(id), "tags" as const] as const,
  teamTags: (id: string) =>
    [...teamsKeys.team(id), "teamTags" as const] as const,
  usedTags: (id: string) =>
    [...teamsKeys.team(id), "usedTags" as const] as const,
  gpsTags: (id: string) => [...teamsKeys.team(id), "gpsTags" as const] as const,
  floorplanTags: (id: string) =>
    [...teamsKeys.team(id), "floorplanTags" as const] as const,
  floorplans: (id: string) =>
    [...teamsKeys.team(id), "floorplans" as const] as const,
  userTags: (id: string) =>
    [...teamsKeys.team(id), "userTags" as const] as const,
  invitations: (id: string) =>
    [...teamsKeys.team(id), "invitations" as const] as const,
  roles: (id: string) => [...teamsKeys.team(id), "roles" as const] as const,
  integrations: (id: string) =>
    [...teamsKeys.team(id), "integrations" as const] as const,
  role: (id: string, roleId: string) =>
    [...teamsKeys.team(id), "roles", roleId] as const,
  graph: (
    id: string,
    dateString?: string | undefined,
    dateStringEnd?: string | undefined,
    granularity?: string
  ) =>
    [
      ...teamsKeys.team(id),
      "graph" as const,
      {
        dateString,
        dateStringEnd,
        granularity,
      },
    ] as const,
  activity: (
    id: string,
    type: "users" | "tags",
    dateString?: string | undefined,
    dateStringEnd?: string | undefined,
    granularity?: string
  ) =>
    [
      ...teamsKeys.graph(id, dateString, dateStringEnd, granularity),
      type,
    ] as const,
};

export function usedTagsFilter(data: SiteTagsUsedGetDto[]) {
  return data
    .filter(Boolean)
    .filter((tag) => Boolean(tag.label?.trim()))
    .filter((x, i, a) => a.findIndex((y) => y.label === x.label) === i)
    .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label));
}

export function teamTagsFilter(data: TeamTagsGetDto[]) {
  return data
    .filter(Boolean)
    .filter((tag) => Boolean(tag.label.trim()))
    .filter((x, i, a) => a.findIndex((y) => y.label === x.label) === i)
    .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label));
}

export function newTeamTagsFilter(data: TeamTagsUnifiedGetDto[]) {
  return data
    .filter(Boolean)
    .filter((tag) => Boolean(tag.label.trim()))
    .filter((x, i, a) => a.findIndex((y) => y.label === x.label) === i)
    .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label));
}

export const teamQueryApi = {
  getTeams: queryOptions({
    queryFn: ({ signal }) => cancelableQuery(api.teams.getApiTeams1(), signal),
    queryKey: [...teamsKeys.list()],
  }),

  getArchivedTeams: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.teams.getApiTeamsArchived(), signal),
    queryKey: [...teamsKeys.listArchived()],
  }),

  getTeam: (id: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeams(id, undefined, sharecode),
          signal
        ),
      queryKey: teamsKeys.details(id, sharecode),
    }),

  getTeamDisplay: (id: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsDisplay(id), signal),
      queryKey: teamsKeys.display(id),
      staleTime: 1000 * 60 * 60,
    }),

  editTeam: mutationOptions({
    mutationFn: ({
      teamId,
      ...data
    }: TeamsPatchDto & {
      teamId: string;
    }) => api.teams.patchApiTeams(teamId, undefined, data),
  }),

  deleteTeam: mutationOptions({
    mutationFn: (teamId: string) => api.teams.deleteApiTeams(teamId, undefined),
  }),

  createTeam: mutationOptions({
    mutationFn: (data: TeamsPostDto) => api.teams.postApiTeams(undefined, data),
  }),

  getTeamMembers: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsMembers(teamId), signal),
      queryKey: teamsKeys.members(teamId),
    }),

  changeTeamMemberRole: mutationOptions({
    mutationFn: ({
      teamId,
      userId,
      ...data
    }: {
      teamId: string;
      userId: string;
    } & SiteRolePatchDto) =>
      api.teams.patchApiTeamsMembers(teamId, userId, undefined, data),
  }),

  removeTeamMember: mutationOptions({
    mutationFn: (data: { teamId: string; userId: string }) =>
      api.teams.deleteApiTeamsMembers(data.teamId, data.userId),
  }),

  getTeamInvitations: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsInvites(teamId), signal),
      queryKey: teamsKeys.invitations(teamId),
    }),

  getTeamLocation: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsGps(teamId), signal),
      queryKey: teamsKeys.gpsTags(teamId),
    }),

  removeTeamInvitations: mutationOptions({
    mutationFn: ({ teamId, emails }: { teamId: string; emails: string[] }) =>
      api.teams.deleteApiTeamsInvites(teamId, emails),
  }),

  getTeamMediaList: (teamId: string, filters: MediaListDto) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeamsMediaList(
            teamId,
            filters.search ?? undefined,
            filters.sort ?? undefined,
            filters.dayOnly ?? undefined,
            filters.dateString ?? undefined,
            filters.dateStringEnd ?? undefined,
            filters.userIds ?? undefined,
            filters.types ?? undefined,
            filters.tags ?? undefined,
            filters.tagMode ?? undefined,
            filters.includeEmpty ?? undefined,
            filters.publishedOnly ?? undefined,
            filters.finishedOnly ?? undefined,
            filters.is360 ?? undefined,
            filters.bbox ?? undefined
          ),
          signal
        ),
      queryKey: [...teamsKeys.mediaList(teamId), filters],
    }),

  getTeamMedia: (teamId: string, filters: MediaSearchParamsDto) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeamsMedia(
            teamId,
            filters.page ?? undefined,
            filters.size ?? undefined,
            filters.group ?? undefined,
            filters.search ?? undefined,
            filters.sort ?? undefined,
            filters.dayOnly ?? undefined,
            filters.dateString ?? undefined,
            filters.dateStringEnd ?? undefined,
            filters.userIds ?? undefined,
            filters.types ?? undefined,
            filters.tags ?? undefined,
            filters.tagMode ?? undefined,
            filters.includeEmpty ?? undefined,
            filters.publishedOnly ?? undefined,
            filters.finishedOnly ?? undefined,
            filters.is360 ?? undefined,
            filters.gpsOnly ?? undefined,
            filters.bbox ?? undefined,
            filters.floorplanId ?? undefined,
            filters.deleted ?? undefined
          ),
          signal
        ),
      queryKey: [...teamsKeys.media(teamId), filters],
    }),

  getTeamMediaGroups: (
    teamId: string,
    filters: Omit<MediaSearchParamsDto, "page" | "size">
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeamsMediaGroups(
            teamId,
            // No page or size here, just kept for convenience
            undefined,
            undefined,
            filters.group ?? undefined,
            filters.search ?? undefined,
            filters.sort ?? undefined,
            filters.dayOnly ?? undefined,
            filters.dateString ?? undefined,
            filters.dateStringEnd ?? undefined,
            filters.userIds ?? undefined,
            filters.types ?? undefined,
            filters.tags ?? undefined,
            filters.tagMode ?? undefined,
            filters.includeEmpty ?? undefined,
            filters.publishedOnly ?? undefined,
            filters.finishedOnly ?? undefined,
            filters.is360 ?? undefined,
            filters.gpsOnly ?? undefined,
            filters.bbox ?? undefined,
            filters.floorplanId ?? undefined,
            filters.deleted ?? undefined
          ),
          signal
        ),
      queryKey: [...teamsKeys.media(teamId), filters],
    }),

  getTeamMediaGPS: (teamId: string, filters: MediaGPSListDto) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeamsMediaGps(
            teamId,
            filters.search ?? undefined,
            filters.sort ?? undefined,
            filters.dayOnly ?? undefined,
            filters.dateString ?? undefined,
            filters.dateStringEnd ?? undefined,
            filters.userIds ?? undefined,
            filters.types ?? undefined,
            filters.tags ?? undefined,
            filters.tagMode ?? undefined,
            filters.includeEmpty ?? undefined,
            filters.publishedOnly ?? undefined,
            filters.finishedOnly ?? undefined,
            filters.is360 ?? undefined,
            filters.bbox ?? undefined
          ),
          signal
        ),
      queryKey: [...teamsKeys.media(teamId), filters],
    }),

  getTeamMediaFloorplan: (
    teamId: string,
    floorplanId: string,
    filters: MediaFloorplanListDto
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeamsMediaFloorplan(
            teamId,
            floorplanId,
            filters.search ?? undefined,
            filters.sort ?? undefined,
            filters.dayOnly ?? undefined,
            filters.dateString ?? undefined,
            filters.dateStringEnd ?? undefined,
            filters.userIds ?? undefined,
            filters.types ?? undefined,
            filters.tags ?? undefined,
            filters.tagMode ?? undefined,
            filters.includeEmpty ?? undefined,
            filters.publishedOnly ?? undefined,
            filters.finishedOnly ?? undefined,
            filters.is360 ?? undefined,
            filters.bbox ?? undefined
          ),
          signal
        ),
      queryKey: [...teamsKeys.media(teamId), filters],
    }),

  createTeamInvitations: mutationOptions({
    mutationFn: ({ teamId, ...body }: SiteInvitesDto & { teamId: string }) =>
      api.teams.postApiTeamsInvites(teamId, undefined, body),
  }),

  joinTeam: mutationOptions({
    mutationFn: ({ teamId }: { teamId: string }) =>
      api.teams.postApiTeamsJoin(teamId, undefined),
  }),
  recalculateAutotags: mutationOptions({
    mutationFn: (teamId: string) =>
      api.teams.patchApiTeamsMediaAutotags(teamId, undefined),
  }),

  moveMediaItem: mutationOptions({
    mutationFn: ({
      toTeamId,
      fromTeamId,
      mediaIds,
    }: {
      toTeamId: string;
      fromTeamId: string;
      mediaIds: string[];
    }) => api.teams.postApiTeamsMediaMove(toTeamId, fromTeamId, mediaIds),
  }),

  getTeamUploaders: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsUploaders(teamId), signal),
      queryKey: teamsKeys.uploaders(teamId),
    }),

  getTeamLayers: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsLayers(teamId), signal),
      queryKey: teamsKeys.layers(teamId),
    }),

  createTeamLayer: mutationOptions({
    mutationFn: ({
      teamId,
      ...data
    }: LayerItemsPostDto & {
      teamId: string;
    }) => api.teams.postApiTeamsLayers(teamId, undefined, data),
  }),

  getTeamTags: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsTagsTeam(teamId), signal),
      queryKey: teamsKeys.teamTags(teamId),
      select: teamTagsFilter,
    }),

  getNewTeamTags: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsTags(teamId), signal),
      queryKey: teamsKeys.teamTags(teamId),
      select: newTeamTagsFilter,
    }),

  getTeamUsedTags: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsTagsUsed(teamId), signal),
      queryKey: teamsKeys.usedTags(teamId),
      select: usedTagsFilter,
    }),

  createTeamTag: mutationOptions({
    mutationFn: ({ teamId, label }: { teamId: string; label: string }) =>
      api.teams.postApiTeamsTagsTeam(teamId, undefined, { label }),
  }),

  removeTeamTag: mutationOptions({
    mutationFn: ({ teamId, tagId }: { teamId: string; tagId: string }) =>
      api.teams.deleteApiTeamsTagsTeam(teamId, tagId),
  }),

  createTeamTagGps: mutationOptions({
    mutationFn: ({
      teamId,
      body,
    }: {
      teamId: string;
      body: TeamTagsGpsPostDto;
    }) => api.teams.postApiTeamsTagsTeamGps(teamId, undefined, body),
  }),

  createTeamTagsGps: mutationOptions({
    mutationFn: ({
      teamId,
      body,
    }: {
      teamId: string;
      body: TeamTagsGpsPostDto[];
    }) => api.teams.postApiTeamsTagsTeamGpsMulti(teamId, undefined, body),
  }),

  createTeamTagFp: mutationOptions({
    mutationFn: ({
      teamId,
      body,
    }: {
      teamId: string;
      body: TeamTagsFloorplanPostDto;
    }) => api.teams.postApiTeamsTagsTeamFloorplan(teamId, undefined, body),
  }),
  createTeamTagUser: mutationOptions({
    mutationFn: ({
      teamId,
      body,
    }: {
      teamId: string;
      body: TeamTagsUserPostDto;
    }) => api.teams.postApiTeamsTagsTeamUser(teamId, undefined, body),
  }),

  getUsedTags: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsTagsUsed(teamId), signal),
      queryKey: teamsKeys.usedTags(teamId),
      select: usedTagsFilter,
    }),

  getTeamGpsTags: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsTagsGps(teamId), signal),
      queryKey: teamsKeys.gpsTags(teamId),
      select: (data) =>
        (data as TeamTagsWithGeoInfoGetDto[])
          .filter(Boolean)
          .filter((tag) => Boolean(tag.label))
          .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label)),
    }),
  getTeamFloorplanTags: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsTagsFloorplan(teamId), signal),
      queryKey: teamsKeys.floorplanTags(teamId),
      select: (data) =>
        (data as TeamTagsWithFpInfoGetDto[])
          .filter(Boolean)
          .filter((tag) => Boolean(tag.label))
          .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label)),
    }),
  getTeamUserTags: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsTagsUser(teamId), signal),
      queryKey: teamsKeys.userTags(teamId),
      select: (data) =>
        (data as TeamTagsWithUserInfoGetDto[])
          .filter(Boolean)
          .filter((tag) => Boolean(tag.label))
          .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label)),
    }),

  addMediaToTeam: mutationOptions({
    mutationFn: ({
      teamId,
      mediaIds,
    }: {
      teamId: string;
      mediaIds: string[];
    }) => api.teams.postApiTeamsMedia(teamId, mediaIds),
  }),

  removeMediaFromTeam: mutationOptions({
    mutationFn: ({
      teamId,
      mediaIds,
    }: {
      teamId: string;
      mediaIds: string[];
    }) => api.teams.deleteApiTeamsMedia(teamId, mediaIds),
  }),

  getInfiniteMedia: (teamId: string, filters: InfiniteMediaDto) =>
    infiniteQueryOptions({
      initialPageParam: 0,
      queryFn: ({ signal, pageParam }) =>
        cancelableQuery(
          api.teams.getApiTeamsMedia(
            teamId,
            pageParam as number,
            filters.size ?? undefined,
            filters.group ?? undefined,
            filters.search ?? undefined,
            filters.sort ?? undefined,
            filters.dayOnly ?? undefined,
            filters.dateString ?? undefined,
            filters.dateStringEnd ?? undefined,
            filters.userIds ?? undefined,
            filters.types ?? undefined,
            filters.tags ?? undefined,
            filters.tagMode ?? undefined,
            filters.includeEmpty ?? undefined,
            filters.publishedOnly ?? undefined,
            filters.finishedOnly ?? undefined,
            filters.is360 ?? undefined,
            filters.gpsOnly ?? undefined,
            filters.bbox ?? undefined
          ),
          signal
        ),
      queryKey: [...teamsKeys.media(teamId), filters],
      getNextPageParam: (currentPage) =>
        (currentPage.page + 1) * currentPage.size < currentPage.total
          ? currentPage.page + 1
          : undefined,
      getPreviousPageParam: (currentPage) =>
        currentPage.page > 0 ? currentPage.page - 1 : undefined,
      select(data) {
        return data;
      },
    }),

  redeemAccessCode: mutationOptions({
    mutationFn: (token: string) => api.token.postApiTokenRedeem(token),
  }),

  createAccessCode: mutationOptions({
    mutationFn: ({
      teamId,
      ...data
    }: {
      teamId: string;
      /** Which role this should provide */
      role: string;
      /** Should this token only work one time? */
      oneTimeCode: boolean;
      /** When should this token expire? */
      expiryDate?: string;
    }) => api.teams.postApiTeamsToken(teamId, undefined, data),
  }),

  getTeamRoles: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsRoles1(teamId), signal),
      queryKey: teamsKeys.roles(teamId),
    }),

  getTeamRole: (teamId: string, roleId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsRoles(teamId, roleId), signal),
      queryKey: teamsKeys.role(teamId, roleId),
    }),

  editTeamRole: mutationOptions({
    mutationFn: ({ teamId, roleId, ...data }: EditTeamRole) =>
      api.teams.patchApiTeamsRoles(teamId, roleId, undefined, data),
  }),

  getTeamPermissions: () =>
    queryOptions({
      queryKey: teamsKeys.permissions(),
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsPermissions(), signal),
    }),

  createTeamRole: mutationOptions({
    mutationFn: ({ teamId, ...data }: CreateTeamRole) =>
      api.teams.postApiTeamsRoles(teamId, undefined, data),
  }),

  deleteTeamRole: mutationOptions({
    mutationFn: ({ teamId, roleId }: { teamId: string; roleId: string }) =>
      api.teams.deleteApiTeamsRoles(teamId, roleId),
  }),

  archiveTeam: mutationOptions({
    mutationFn: ({ teamId, ...data }: ArchiveTeamData) =>
      api.teams.patchApiTeamsArchive(teamId, undefined, data),
  }),
  getTeamGraph: (teamId: string, data: GraphPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeamsGraph(
            teamId,
            data?.dateString,
            data?.dateStringEnd,
            data?.granularity
          ),
          signal
        ),
      queryKey: teamsKeys.graph(
        teamId,
        data.dateString,
        data.dateStringEnd,
        data.granularity
      ),
    }),
  getTeamUserActivity: (teamId: string, data: ActivityPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeamsActivityUsers(
            teamId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: teamsKeys.activity(
        teamId,
        "users",
        data.dateString,
        data.dateStringEnd
      ),
    }),
  getTeamTagActivity: (teamId: string, data: ActivityPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.teams.getApiTeamsActivityTags(
            teamId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: teamsKeys.activity(
        teamId,
        "users",
        data.dateString,
        data.dateStringEnd
      ),
    }),
  getIntegrations: (siteId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsIntegrations(siteId), signal),
      queryKey: teamsKeys.integrations(siteId),
    }),

  addGeoInfo: mutationOptions({
    mutationFn: ({ teamId, tagId, ...data }: AddGeoInfoData) =>
      api.teams.patchApiTeamsTagsGps(teamId, tagId, undefined, data),
  }),

  addFloorplanInfo: mutationOptions({
    mutationFn: ({ teamId, tagId, ...data }: AddFloorplanInfoData) =>
      api.teams.patchApiTeamsTagsFloorplan(teamId, tagId, undefined, data),
  }),

  getFloorplans: (teamId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.teams.getApiTeamsFloorplans(teamId), signal),
      queryKey: teamsKeys.floorplans(teamId),
    }),
};

import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";

import { cancelableQuery } from "./helpers";

export const customerKeys = {
  root: "customer" as const,
  permissions: () => [customerKeys.root, "permissions" as const] as const,
  list: () => [customerKeys.root, "list" as const] as const,
  customer: (id: string) => [customerKeys.root, id] as const,
};

const customerQueryApi = {
  listCustomers: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.customers.getApiCustomers(), signal),
    queryKey: customerKeys.list(),
  }),

  getCustomer: (userId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.customers.getApiCustomers1(userId), signal),
      queryKey: customerKeys.customer(userId),
    }),
};

export default customerQueryApi;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UploadSource {
  CAMERA = "Camera",
  IMPORT = "Import",
  API = "Api",
  MARKUP = "Markup",
  MICROSERVICE = "Microservice",
  UNKNOWN = "Unknown",
}

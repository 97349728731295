import type { LoaderFunctionArgs } from "react-router-dom";

import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function teamsSettingsLoader({
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) {
    return null;
  }

  queryClient.prefetchQuery(teamQueryApi.getTeams);

  return null;
}

export type SettingsTeamsLoaderReturnType = Awaited<
  ReturnType<typeof teamsSettingsLoader>
>;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountAliasFinishDto } from "../models/AccountAliasFinishDto";
import type { AccountAliasStartDto } from "../models/AccountAliasStartDto";
import type { AccountInfoDto } from "../models/AccountInfoDto";
import type { AccountLimitsGetDto } from "../models/AccountLimitsGetDto";
import type { AccountQuotasGetDto } from "../models/AccountQuotasGetDto";
import type { AccountRestrictionsGetDto } from "../models/AccountRestrictionsGetDto";
import type { AccountsChangeEmailDto } from "../models/AccountsChangeEmailDto";
import type { AccountsChangeEmailFinishDto } from "../models/AccountsChangeEmailFinishDto";
import type { AccountsChangePasswordDto } from "../models/AccountsChangePasswordDto";
import type { AccountsCreateDto } from "../models/AccountsCreateDto";
import type { AccountsCreatePwDto } from "../models/AccountsCreatePwDto";
import type { AccountsEditRoleDto } from "../models/AccountsEditRoleDto";
import type { AccountsGetDto } from "../models/AccountsGetDto";
import type { AccountsResendConfirmDto } from "../models/AccountsResendConfirmDto";
import type { AccountsResetPwDto } from "../models/AccountsResetPwDto";
import type { AccountsResetPwFinishDto } from "../models/AccountsResetPwFinishDto";
import type { AccountsSettingsDto } from "../models/AccountsSettingsDto";
import type { AccountsTokenDto } from "../models/AccountsTokenDto";
import type { DomainAccessHintGetDto } from "../models/DomainAccessHintGetDto";
import type { DomainAccessHintPostDto } from "../models/DomainAccessHintPostDto";
import type { EmailAliasGetDto } from "../models/EmailAliasGetDto";
import type { EmailAliasMergeResultDto } from "../models/EmailAliasMergeResultDto";
import type { EmailAliasPostDto } from "../models/EmailAliasPostDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class AccountsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Remove a user from a role. (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, Developer, (+User if from restricted clients)
   * Valid roles are:
   * - Developer: for EnExG developers to act like gods
   * - User: an authenticated user. Users have this role by default
   * - SiteAdmin: a user that has administrative capabilities
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public deleteApiAccountsRoles(
    xVersion: string = "2",
    requestBody?: AccountsEditRoleDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/accounts/roles",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Assign a user to a role. (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, Developer, (+User if from restricted clients)
   * Valid roles are:
   * - Developer: for EnExG developers to act like gods
   * - User: an authenticated user. Users have this role by default
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public putApiAccountsRoles(
    xVersion: string = "2",
    requestBody?: AccountsEditRoleDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/accounts/roles",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Delete my user account - required for privacy reasons. (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public deleteApiAccounts(xVersion: string = "2"): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/accounts",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Delete a single domain access hint (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param hintId
   * @param xVersion The requested API version
   * @returns DomainAccessHintGetDto Success
   * @throws ApiError
   */
  public deleteApiAccountsHints(
    hintId: string,
    xVersion: string = "2",
  ): CancelablePromise<DomainAccessHintGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/accounts/hints/{hintId}",
      path: {
        hintId: hintId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Delete all hints for a particular domain (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, User
   * @param domain
   * @param xVersion The requested API version
   * @returns DomainAccessHintGetDto Success
   * @throws ApiError
   */
  public deleteApiAccountsHintsDomain(
    domain: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<DomainAccessHintGetDto>> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/accounts/hints/domain/{domain}",
      path: {
        domain: domain,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get known email aliases (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param userId
   * @param xVersion The requested API version
   * @returns EmailAliasGetDto Success
   * @throws ApiError
   */
  public getApiAccountsAliases(
    userId: string,
    xVersion: string = "2",
  ): CancelablePromise<EmailAliasGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/{userId}/aliases",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add an email alias, but return BadRequest if the email is already associated with a user. (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, User
   * @param userId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns EmailAliasGetDto Success
   * @throws ApiError
   */
  public postApiAccountsAliases(
    userId: string,
    xVersion: string = "2",
    requestBody?: EmailAliasPostDto,
  ): CancelablePromise<EmailAliasGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/{userId}/aliases",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Check if the given email is associated with a user.
   * @param email
   * @param token
   * @param xVersion The requested API version
   * @returns AccountInfoDto Success
   * @throws ApiError
   */
  public getApiAccountsCheckEmail(
    email: string,
    token: string,
    xVersion: string = "2",
  ): CancelablePromise<AccountInfoDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/check/email/{token}/{email}",
      path: {
        email: email,
        token: token,
      },
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Check whether this User has a password (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns boolean Success
   * @throws ApiError
   */
  public getApiAccountsPasswordCheck(
    xVersion: string = "2",
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/password/check",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get email address of user (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiAccountsEmail(
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/email",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Returns account details for a list of user Ids.
   * This includes first name, last name and email address. (Auth policies: AuthorisedApp)
   * **Scopes**: AuthorisedApp
   *
   * Pass a list of accountIds as a query to the request
   * @param ids
   * @param xVersion The requested API version
   * @returns AccountInfoDto Success
   * @throws ApiError
   */
  public getApiAccountsMultiple(
    ids?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<Array<AccountInfoDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/multiple",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        ids: ids,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Check what claims the provided AuthorisedApp token has (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiAccountsCheckAppClaims(
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/check/app/claims",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Check the Claims this User has (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   *
   * If you want to get the roles of a User
   * logged in externally, use !:CheckRolesExt
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiAccountsCheckUserClaims(
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/check/user/claims",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return information about this account.
   * Also consolidates user providers. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param distinctId
   * @param xVersion The requested API version
   * @returns AccountsGetDto Success
   * @throws ApiError
   */
  public getApiAccountsMe(
    distinctId?: string,
    xVersion: string = "2",
  ): CancelablePromise<AccountsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/me",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        distinctId: distinctId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Signs the user in with LOR credentials (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   *
   * This will redirect the user to LOR for authentication, and will eventually
   * return a LOR access token. Use the token endpoint to exchange the LOR
   * token for a BV360 token.
   * @param callbackUrl URL-encoded callback URL to return the LOR access token.
   * This needs to be registered on the backend first
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiAccountsExternalLorSignin(
    callbackUrl?: string,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/external/lor/signin",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        callbackUrl: callbackUrl,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * This is the callback point from M:BuiltView360Backend.V2.Controllers.AccountsController.ExternalLorSignIn(System.String)
   * @param code The authorization code returned from Microsoft
   * @param state The frontend callback URL
   * @param xVersion The requested API version
   * @returns any Success
   * @throws ApiError
   */
  public getApiAccountsExternalLorSigninCallback(
    code?: string,
    state?: string,
    xVersion: string = "2",
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/external/lor/signin-callback",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        code: code,
        state: state,
      },
    });
  }
  /**
   * Get my user restrictions (Auth policies: AuthorisedApp, User)
   * All three optional parameters of this query provide context to the restriction check.
   * If any of the three is provided, the organisation of the highest level of context
   * that differs from the users' own context will be used to check the restrictions.
   * @param organisationRef If provided, this check is in the context of an organisation other than the user's own;
   * in that case, they should have the higher of the two sets of restrictions,
   * and be added as an OrganisationGuestUser
   * @param projectId If provided, this check is in the context of the organisation of that project
   * in that case, they should have the higher of the two sets of restrictions,
   * and be added as an OrganisationGuestUser
   * @param teamId If provided, this check is in the context of the organisation of that team
   * in that case, they should have the higher of the two sets of restrictions,
   * and be added as an OrganisationGuestUser
   * @param xVersion The requested API version
   * @returns AccountRestrictionsGetDto Success
   * @throws ApiError
   */
  public getApiAccountsRestrictions(
    organisationRef?: string,
    projectId?: string,
    teamId?: string,
    xVersion: string = "2",
  ): CancelablePromise<AccountRestrictionsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/restrictions",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        organisationRef: organisationRef,
        projectId: projectId,
        teamId: teamId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get my user quotas (Auth policies: AuthorisedApp, User)
   * @param organisationRef
   * @param projectId
   * @param teamId
   * @param xVersion The requested API version
   * @returns AccountQuotasGetDto Success
   * @throws ApiError
   */
  public getApiAccountsQuotas(
    organisationRef?: string,
    projectId?: string,
    teamId?: string,
    xVersion: string = "2",
  ): CancelablePromise<AccountQuotasGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/quotas",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        organisationRef: organisationRef,
        projectId: projectId,
        teamId: teamId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get my user limits (Auth policies: AuthorisedApp, User)
   * @param organisationRef
   * @param projectId
   * @param teamId
   * @param xVersion The requested API version
   * @returns AccountLimitsGetDto Success
   * @throws ApiError
   */
  public getApiAccountsLimits(
    organisationRef?: string,
    projectId?: string,
    teamId?: string,
    xVersion: string = "2",
  ): CancelablePromise<AccountLimitsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/limits",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        organisationRef: organisationRef,
        projectId: projectId,
        teamId: teamId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get known access hints (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, Developer
   * @param xVersion The requested API version
   * @returns DomainAccessHintGetDto Success
   * @throws ApiError
   */
  public getApiAccountsHints(
    xVersion: string = "2",
  ): CancelablePromise<Array<DomainAccessHintGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/hints",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new domain access hint.  Must have access to the organisation/project, or be a developer or salesperson (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns DomainAccessHintGetDto Success
   * @throws ApiError
   */
  public postApiAccountsHints(
    xVersion: string = "2",
    requestBody?: DomainAccessHintPostDto,
  ): CancelablePromise<DomainAccessHintGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/hints",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get known access hints for the given domain (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, Developer
   * @param domain
   * @param xVersion The requested API version
   * @returns DomainAccessHintGetDto Success
   * @throws ApiError
   */
  public getApiAccountsHints1(
    domain: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<DomainAccessHintGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/accounts/hints/{domain}",
      path: {
        domain: domain,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   *  (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns AccountsGetDto Success
   * @throws ApiError
   */
  public patchApiAccountsSettings(
    xVersion: string = "2",
    requestBody?: AccountsSettingsDto,
  ): CancelablePromise<AccountsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/accounts/settings",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Start the add alias flow for a user (Auth policies: AuthorisedApp, User)
   * An email containing a confirm token will be sent to the user.
   * The frontend needs to provide this token along the new password
   * using M:BuiltView360Backend.V2.Controllers.AccountsController.FinishCreateAlias(System.String,BuiltView360Backend.V2.DTOs.AccountAliasFinishDto) to create the alias
   * @param userId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsAliasStart(
    userId: string,
    xVersion: string = "2",
    requestBody?: AccountAliasStartDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/{userId}/alias/start",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Finish the reset password flow for a user (Auth policies: AuthorisedApp)
   * **Scopes**: AuthorisedApp
   * @param userId Which user is being accessed
   * @param xVersion The requested API version
   * @param requestBody Data for confirming email
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsAliasFinish(
    userId: string,
    xVersion: string = "2",
    requestBody?: AccountAliasFinishDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/{userId}/alias/finish",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Add a known email to a user and merge the two users if the email is already associated with a user. (Auth policies: AuthorisedApp, Developer)
   * **Scope**: AuthorisedApp, User
   * @param userId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns EmailAliasMergeResultDto Success
   * @throws ApiError
   */
  public postApiAccountsAliasesMerge(
    userId: string,
    xVersion: string = "2",
    requestBody?: EmailAliasPostDto,
  ): CancelablePromise<EmailAliasMergeResultDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/{userId}/aliases/merge",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new account. (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsCreate(
    xVersion: string = "2",
    requestBody?: AccountsCreateDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/create",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Resend confirmation email (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsEmailConfirmResend(
    xVersion: string = "2",
    requestBody?: AccountsResendConfirmDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/email/confirm/resend",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Confirm email (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsEmailConfirm(
    xVersion: string = "2",
    requestBody?: AccountsTokenDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/email/confirm",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a password for this User (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   *
   * This will create a password for an external account
   * e.g. LOR, enabling that account to
   * sign in with password.
   *
   * This will not work if the account has already
   * had a password
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsPasswordCreate(
    xVersion: string = "2",
    requestBody?: AccountsCreatePwDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/password/create",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Start the reset password flow for a user (Auth policies: AuthorisedApp)
   * **Scopes**: AuthorisedApp
   *
   * This only works for accounts that has a password.
   *
   * This API always returns HTTP Status 200 with no messages
   * to prevent bruteforce attacks to harvest email addresses
   *
   * An email containing a reset token will be sent to the user.
   * The frontend needs to provide this token along the new password
   * using M:BuiltView360Backend.V2.Controllers.AccountsController.FinishResetPassword(BuiltView360Backend.V2.DTOs.AccountsResetPwFinishDto) to finish the password
   * reset
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsPasswordResetStart(
    xVersion: string = "2",
    requestBody?: AccountsResetPwDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/password/reset/start",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Finish the reset password flow for a user (Auth policies: AuthorisedApp)
   * **Scopes**: AuthorisedApp
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsPasswordResetFinish(
    xVersion: string = "2",
    requestBody?: AccountsResetPwFinishDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/password/reset/finish",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Change this user's password (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsPasswordChange(
    xVersion: string = "2",
    requestBody?: AccountsChangePasswordDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/password/change",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Start the change email flow for this user (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User
   *
   * In order to change email, a password check must first be passed.
   * This therefore is unavailable for accounts without a password
   *
   * Then an email with a token will be sent to the new email address.
   * That token needs to be sent to the finish API to finish
   * the change
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsEmailChangeStart(
    xVersion: string = "2",
    requestBody?: AccountsChangeEmailDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/email/change/start",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Start the change email flow for this user (Auth policies: AuthorisedApp)
   * **Scopes**: AuthorisedApp
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsEmailChangeFinish(
    xVersion: string = "2",
    requestBody?: AccountsChangeEmailFinishDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/email/change/finish",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   *  (Auth policies: AuthorisedApp, Developer)
   * @param id
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiAccountsAnyPasswordCreate(
    id: string,
    xVersion: string = "2",
    requestBody?: AccountsCreatePwDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/accounts/any/password/create/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

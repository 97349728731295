import { authClient } from "../authClient";

import useIsAuthenticated from "./useIsAuthenticated";

export default function useLogout() {
  const auth = useIsAuthenticated();

  if (!auth) {
    return () => {};
  }

  const logout = () => authClient.logout();

  return logout;
}

enum OrganisationPermissionFlagBits {
  Any = 0x7fffffff,

  // "Read" permissions - generally safer to grant to anyone
  /// <summary>View media in an organisation</summary>
  ViewOrganisationMedia = 0x1,
  /// <summary>View teams in an organisation</summary>
  ViewOrganisationTeams = 0x2,
  /// <summary>View teams in an organisation</summary>
  ViewOrganisationProjects = 0x4,
  /// <summary>View organisation dashboard</summary>
  ViewOrganisationDashboard = 0x8,
  /// <summary>View organisation users</summary>
  ViewOrganisationUsers = 0x10,
  ///<summary>See sites in this project which have been archived
  SeeArchivedSites = 0x20,
  ///<summary>If this project is archived, you can still view it</summary>
  SeeArchivedProjects = 0x40,
  // UNUSED = 0x40,
  /// <summary>Can see the audit trail of activity on this organisation
  ViewAuditTrail = 0x80,

  // "Write" permissions - require more scrutiny
  /// <summary>Join organisation subprojects</summary>
  JoinOrganisationProjects = 0x100,
  /// <summary>Create projects in the organisation</summary>
  CreateOrganisationProjects = 0x200,
  /// <summary>Invite organisation members</summary>
  InviteUsersToOrganisation = 0x400,
  /// <summary>Change the role of existing organisation members</summary>
  ChangeOrganisationUserRoles = 0x800,
  /// <summary>Change the definition of organisation roles</summary>
  EditOrganisationRoles = 0x1000,
  EditOrganisationMetadata = 0x2000,
  // UNUSED = 0x4000,
  // UNUSED = 0x8000,
  // UNUSED = 0x10000,
  // UNUSED = 0x20000,
  // UNUSED = 0x40000,
  // UNUSED = 0x80000,
  // UNUSED = 0x100000,
  // UNUSED = 0x200000,
  // UNUSED = 0x400000,
  // UNUSED = 0x800000,
  // UNUSED = 0x1000000,
  // UNUSED = 0x2000000,
  // UNUSED = 0x4000000,
  // UNUSED = 0x8000000,
  CanArchiveOrganisation = 0x10000000,
  /// <summary>Can unarchive projects or teams</summary>
  CanUnarchiveContent = 0x20000000,
  // UNUSED = 0x40000000,
}

export default OrganisationPermissionFlagBits;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AIPhotoMetadata } from "../models/AIPhotoMetadata";
import type { MediaStartWormDto } from "../models/MediaStartWormDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class CallbacksService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get the video worm file for a video the User has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param id ID of the video media item
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiCallbacksWorm(
    id: string,
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/callbacks/worm/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Callback for the worm microservice to return a video worm (Auth policies: Microservice)
   * **Scope**: Microservice
   * @param id ID of the video media item
   * @param xVersion The requested API version
   * @param formData
   * @returns any Success
   * @throws ApiError
   */
  public postApiCallbacksWorm(
    id: string,
    xVersion: string = "2",
    formData?: {
      Data: Blob;
    },
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/callbacks/worm/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Callback for the thumbnail microservice to post a nano thumbnail
   * for a photo (Auth policies: Microservice)
   * **Scope**: Microservice
   * @param id
   * @param xVersion The requested API version
   * @param formData
   * @returns any Success
   * @throws ApiError
   */
  public postApiCallbacksPhotoNano(
    id: string,
    xVersion: string = "2",
    formData?: {
      Data: Blob;
    },
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/callbacks/photo/nano/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Callback for the thumbnail microservice to post a thumbnail
   * for a photo (Auth policies: Microservice)
   * **Scope**: Microservice
   * @param id
   * @param xVersion The requested API version
   * @param formData
   * @returns any Success
   * @throws ApiError
   */
  public postApiCallbacksPhotoThumbnail(
    id: string,
    xVersion: string = "2",
    formData?: {
      Data: Blob;
    },
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/callbacks/photo/thumbnail/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Callback for the thumbnail microservice to post a display size
   * for a photo (Auth policies: Microservice)
   * **Scope**: Microservice
   * @param id
   * @param xVersion The requested API version
   * @param formData
   * @returns any Success
   * @throws ApiError
   */
  public postApiCallbacksPhotoDisplay(
    id: string,
    xVersion: string = "2",
    formData?: {
      Data: Blob;
    },
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/callbacks/photo/display/{id}",
      path: {
        id: id,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Start the worm mapping process for this video (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiCallbacksWormStart(
    xVersion: string = "2",
    requestBody?: MediaStartWormDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/callbacks/worm/start",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get AI Media Metadata for a photo and save it to the database
   * @param mediaItemId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public postApiCallbacksAiPhoto(
    mediaItemId: string,
    xVersion: string = "2",
    requestBody?: AIPhotoMetadata,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/callbacks/ai/photo/{mediaItemId}",
      path: {
        mediaItemId: mediaItemId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}

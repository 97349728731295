import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const TeamRoleParamsSchema = z.object({
  teamId: z.string(),
  roleId: z.string(),
});

export default async function teamRoleLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { teamId, roleId } = TeamRoleParamsSchema.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      teamId,
      roleId,
    };
  }

  queryClient.prefetchQuery(teamQueryApi.getTeam(teamId));
  const initialRole = await queryClient.ensureQueryData(
    teamQueryApi.getTeamRole(teamId, roleId),
  );

  return {
    teamId,
    roleId,
    initialRole,
  };
}

export type TeamRoleLoaderReturnType = Awaited<
  ReturnType<typeof teamRoleLoader>
>;

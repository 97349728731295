import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const ParamsSchema = z.object({
  teamId: z.string(),
  tagId: z.string(),
});

export default async function teamTagGPSLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const validatedParams = await ParamsSchema.parseAsync(params);

  const { teamId, tagId } = validatedParams;

  const account = await authenticate(request);

  if (!account) {
    return {
      teamId,
      tagId,
    };
  }

  queryClient.prefetchQuery(teamQueryApi.getTeam(teamId));
  queryClient.prefetchQuery(teamQueryApi.getNewTeamTags(teamId));

  return {
    teamId,
    tagId,
  };
}

export type TeamTagGPSLoaderReturnType = Awaited<
  ReturnType<typeof teamTagGPSLoader>
>;

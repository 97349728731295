import monteratUrl from "@fontsource/montserrat/files/montserrat-latin-400-normal.woff2";
import { createTheme } from "@mui/material";

import DialogTransition from "@/components/DialogTransition";
import LinkBehavior from "@/components/LinkBehavior";

import { ThemeColour, ThemeColourV3 } from "./colors";

const theme = (darkMode: boolean) => {
  return createTheme({
    typography: {
      fontFamily: ["Montserrat", "Roboto", "sans-serif"].join(","),
    },
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: darkMode ? ThemeColourV3.yellow.main : ThemeColourV3.blue.main,
      },
      secondary: {
        main: darkMode ? ThemeColourV3.blue.main : ThemeColourV3.yellow.main,
      },
      info: {
        main: "#edf4ff",
      },
      error: {
        main: darkMode ? "#EF5350" : "#C62828",
      },
      warning: {
        main: darkMode ? "#FF9800" : "#E65100",
      },
      success: {
        main: darkMode ? "#4CAF50" : "#1B5E20",
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Montserrat-Regular';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: local('Montserrat'), local('Montserrat-Regular'), url(${monteratUrl}) format('woff2');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: "bold",
          },
          text: {
            color: ThemeColour[darkMode ? "light" : "dark"].background.default,
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiDialog: {
        defaultProps: {
          TransitionComponent: DialogTransition,
          PaperProps: {
            elevation: 4,
          },
          maxWidth: "md",
          fullWidth: true,
        },
      },
    },
  });
};

export default theme;

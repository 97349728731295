/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessRoleGetDto } from "../models/AccessRoleGetDto";
import type { AccessRolePatchDto } from "../models/AccessRolePatchDto";
import type { AccessRolePostDto } from "../models/AccessRolePostDto";
import type { ActivityGetDto } from "../models/ActivityGetDto";
import type { ActivityTagGetDto } from "../models/ActivityTagGetDto";
import type { ActivityTeamGetDto } from "../models/ActivityTeamGetDto";
import type { ActivityUserGetDto } from "../models/ActivityUserGetDto";
import type { ArchiveOrganisationPatchDto } from "../models/ArchiveOrganisationPatchDto";
import type { DomainAccessHintGetDto } from "../models/DomainAccessHintGetDto";
import type { LayerItemsGetDto } from "../models/LayerItemsGetDto";
import type { LogicalOperator } from "../models/LogicalOperator";
import type { MediaItemGpsListDto } from "../models/MediaItemGpsListDto";
import type { MediaItemGraphRecordGetDto } from "../models/MediaItemGraphRecordGetDto";
import type { MediaItemListDto } from "../models/MediaItemListDto";
import type { MediaItemsGetDtoPagedResults } from "../models/MediaItemsGetDtoPagedResults";
import type { MediaType } from "../models/MediaType";
import type { OrganisationInvitesDto } from "../models/OrganisationInvitesDto";
import type { OrganisationInvitesResultDto } from "../models/OrganisationInvitesResultDto";
import type { OrganisationsGetDisplayInfoDto } from "../models/OrganisationsGetDisplayInfoDto";
import type { OrganisationsGetDto } from "../models/OrganisationsGetDto";
import type { OrganisationsPatchDto } from "../models/OrganisationsPatchDto";
import type { OrganisationsPostDto } from "../models/OrganisationsPostDto";
import type { OrganisationStatsGetDto } from "../models/OrganisationStatsGetDto";
import type { OrganisationUsersGetDto } from "../models/OrganisationUsersGetDto";
import type { OrganisationUsersMultiPatchDto } from "../models/OrganisationUsersMultiPatchDto";
import type { OrganisationUsersPatchDto } from "../models/OrganisationUsersPatchDto";
import type { PermissionDefinitionGetDto } from "../models/PermissionDefinitionGetDto";
import type { ProjectsGetDisplayInfoDto } from "../models/ProjectsGetDisplayInfoDto";
import type { ProjectsGetDto } from "../models/ProjectsGetDto";
import type { SiteTagsUsedGetDto } from "../models/SiteTagsUsedGetDto";
import type { SortOption } from "../models/SortOption";
import type { TeamTagsGetDto } from "../models/TeamTagsGetDto";
import type { TeamTagsUnifiedGetDto } from "../models/TeamTagsUnifiedGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class OrganisationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete a single organisation;  user must be a Developer or an organisation "owner"; restrictions? (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns OrganisationsGetDto Success
   * @throws ApiError
   */
  public deleteApiOrganisations(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<OrganisationsGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/organisations/{organisationRef}",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get a single organisation;  user must be an admin or in the organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns OrganisationsGetDto Success
   * @throws ApiError
   */
  public getApiOrganisations(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<OrganisationsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Update an single organisation;  user must be a Developer or an organisation "owner" (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @param requestBody
   * @returns OrganisationsGetDto Success
   * @throws ApiError
   */
  public patchApiOrganisations(
    organisationRef: string,
    xVersion: string = "2",
    requestBody?: OrganisationsPatchDto,
  ): CancelablePromise<OrganisationsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/organisations/{organisationRef}",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete a role in this organisation.  A valid backup role must be provided if any users have this role. (Auth policies: AuthorisedApp, User)
   * @param organisationRef The organisation in which this role is
   * @param roleId The role to delete
   * @param replace The role to replace this role with
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public deleteApiOrganisationsRoles(
    organisationRef: string,
    roleId: string,
    replace?: string,
    xVersion: string = "2",
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/organisations/{organisationRef}/roles/{roleId}",
      path: {
        organisationRef: organisationRef,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        replace: replace,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetches a single role related to this organisation (Auth policies: AuthorisedApp, User)
   * @param organisationRef
   * @param roleId
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsRoles(
    organisationRef: string,
    roleId: string,
    xVersion: string = "2",
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/roles/{roleId}",
      path: {
        organisationRef: organisationRef,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Update a role in this organisation (Auth policies: AuthorisedApp, User)
   * @param organisationRef
   * @param roleId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public patchApiOrganisationsRoles(
    organisationRef: string,
    roleId: string,
    xVersion: string = "2",
    requestBody?: AccessRolePatchDto,
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/organisations/{organisationRef}/roles/{roleId}",
      path: {
        organisationRef: organisationRef,
        roleId: roleId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Remove a user from an organisation - does not remove any of their media though (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param userId
   * @param xVersion The requested API version
   * @returns OrganisationUsersGetDto Success
   * @throws ApiError
   */
  public deleteApiOrganisationsMembers(
    organisationRef: string,
    userId: string,
    xVersion: string = "2",
  ): CancelablePromise<OrganisationUsersGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/organisations/{organisationRef}/members/{userId}",
      path: {
        organisationRef: organisationRef,
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Change the organisation role of an existing member (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param userId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns OrganisationUsersGetDto Success
   * @throws ApiError
   */
  public patchApiOrganisationsMembers(
    organisationRef: string,
    userId: string,
    xVersion: string = "2",
    requestBody?: OrganisationUsersPatchDto,
  ): CancelablePromise<OrganisationUsersGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/organisations/{organisationRef}/members/{userId}",
      path: {
        organisationRef: organisationRef,
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Revoke invitations (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (site admin)
   *
   * This only removes pending invitations, and does not
   * revoke access to a site if the user has already created an
   * account (and thus redeemed the invitation).
   * Use M:BuiltView360Backend.V2.Controllers.OrganisationsController.DeleteOrganisationUser(System.String,System.String) to revoke access instead
   * @param organisationRef
   * @param emails Comma-separated list of emails to revoke
   * @param xVersion The requested API version
   * @returns OrganisationInvitesResultDto Success
   * @throws ApiError
   */
  public deleteApiOrganisationsInvites(
    organisationRef: string,
    emails?: Array<string>,
    xVersion: string = "2",
  ): CancelablePromise<OrganisationInvitesResultDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/organisations/{organisationRef}/invites",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        emails: emails,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get a list of emails invited to this organisation (Auth policies: AuthorisedApp, User)
   * **Scopes**: AuthorisedApp, User (organisation admin)
   * @param organisationRef ID or shorthand of org
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiOrganisationsInvites(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/invites",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all organisations (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @returns OrganisationsGetDto Success
   * @throws ApiError
   */
  public getApiOrganisations1(
    xVersion: string = "2",
  ): CancelablePromise<Array<OrganisationsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a new organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User, Developer
   * @param xVersion The requested API version
   * @param requestBody
   * @returns OrganisationsGetDto Success
   * @throws ApiError
   */
  public postApiOrganisations(
    xVersion: string = "2",
    requestBody?: OrganisationsPostDto,
  ): CancelablePromise<OrganisationsGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/organisations",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get your organisation (if any) (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns OrganisationsGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsMine(
    xVersion: string = "2",
  ): CancelablePromise<OrganisationsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/mine",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get organisations that are archived which I can access (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @returns OrganisationsGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsArchived(
    xVersion: string = "2",
  ): CancelablePromise<Array<OrganisationsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/archived",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get full media graph for an organisation. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef Organisation to fetch data for - you must have access
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Needs to have dateString as well.
   * @param granularity Either "year", "month", "week" (default) or "day"
   * @param privateOnly If true, only return media items which have not been added to a team
   * @param xVersion The requested API version
   * @returns MediaItemGraphRecordGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsGraph(
    organisationRef: string,
    dateString?: string,
    dateStringEnd?: string,
    granularity: string = "week",
    privateOnly: boolean = false,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemGraphRecordGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/graph",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        granularity: granularity,
        privateOnly: privateOnly,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per tag for the given organisation. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef Organisation to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityTagGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsActivityTags(
    organisationRef: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityTagGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/activity/tags",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per user for the given organisation. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef Organisation to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityUserGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsActivityUsers(
    organisationRef: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityUserGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/activity/users",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per team for the given organisation. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef Organisation to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityTeamGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsActivityTeams(
    organisationRef: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityTeamGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/activity/teams",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per project for the given organisation. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef Organisation to retrieve data for
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsActivityProjects(
    organisationRef: string,
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/activity/projects",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get activity per orgainsation for all organisations. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param numOfDays Number of days to look back from today
   * @param numOfMonths Number of months to look back from today
   * @param startDate Start date of the range to look back from
   * @param endDate End date of the range to look back from
   * @param xVersion The requested API version
   * @returns ActivityGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsActivity(
    numOfDays?: number,
    numOfMonths?: number,
    startDate?: string,
    endDate?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ActivityGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/activity",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        num_of_days: numOfDays,
        num_of_months: numOfMonths,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get the media stats single organisation;  user must be an admin or in the organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns OrganisationStatsGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsStats(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<OrganisationStatsGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/stats",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get media from an organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef Organisation ID or shortref to search within
   * @param page Index page of search results
   * @param size Number of items per page
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param siteIds Restrict to uploads in these teams (comma-separated)
   * @param projectIds Restrict to uploads in these projects (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemsGetDtoPagedResults Success
   * @throws ApiError
   */
  public getApiOrganisationsMedia(
    organisationRef: string,
    page?: number,
    size: number = 20,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    siteIds?: Array<string>,
    projectIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<MediaItemsGetDtoPagedResults> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/media",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        page: page,
        size: size,
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        siteIds: siteIds,
        projectIds: projectIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Return a list of MediaItems with matching search terms that the User can access
   * either as the creator or being part of the same site as the mediaItem (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef Organisation ID or shorthand to search within
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param siteIds Restrict to uploads in these teams (comma-separated)
   * @param projectIds Restrict to uploads in these projects (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemListDto Success
   * @throws ApiError
   */
  public getApiOrganisationsMediaList(
    organisationRef: string,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    siteIds?: Array<string>,
    projectIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemListDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/media/list",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        siteIds: siteIds,
        projectIds: projectIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return a list of MediaItems with matching search terms that the User can access
   * either as the creator or being part of the same site as the mediaItem (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef Organisation shorthand or ID to search within
   * @param search Supply a list of *whitespace-delimited* search terms
   * @param sort Available options to sort results by: DateNewestFirst, DateOldestFirst
   * @param dayOnly If true, dateString will search for MediaItems created on the day while
   * ignoring the time. If false, will look for MediaItems with exact time as
   * well as day.
   * @param dateString Time to find the MediaItem. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * If dateStringEnd is not included, will search for MediaItems on this date.
   * If dateStringEnd is included, will search for date range
   * @param dateStringEnd End of time range to find MediaItems. Format **yyyy-MM-ddTHH:mm:sszzz**.
   * Coordinate with dayOnly.
   * Needs to have dateString as well.
   * @param userIds Restrict to uploads by these users (comma-separated)
   * @param siteIds Restrict to uploads in these teams (comma-separated)
   * @param projectIds Restrict to uploads in these projects (comma-separated)
   * @param types Comma-separated list of media types to get e.g. Video, Photo...
   * @param tags Comma-separated list of tags to restrict results by
   * @param tagMode AND/OR operator for the tag query. Accepts 0, 1, And, Or.
   * @param includeEmpty Include MediaItems with no media
   * @param publishedOnly If true, will filter out Video media items that are not published
   * @param finishedOnly If true, will filter out media items that are not fully uploaded
   * @param is360 If true, will filter out media items that are 360
   * @param bbox The bounding box we want to search in as a comma-separated string of the form west, south, east, north
   * @param xVersion The requested API version
   * @returns MediaItemGpsListDto Success
   * @throws ApiError
   */
  public getApiOrganisationsMediaGps(
    organisationRef: string,
    search?: string,
    sort?: SortOption,
    dayOnly: boolean = true,
    dateString?: string,
    dateStringEnd?: string,
    userIds?: Array<string>,
    siteIds?: Array<string>,
    projectIds?: Array<string>,
    types?: Array<MediaType>,
    tags?: Array<string>,
    tagMode?: LogicalOperator,
    includeEmpty: boolean = false,
    publishedOnly: boolean = true,
    finishedOnly: boolean = true,
    is360: boolean = false,
    bbox?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<MediaItemGpsListDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/media/gps",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        search: search,
        sort: sort,
        dayOnly: dayOnly,
        dateString: dateString,
        dateStringEnd: dateStringEnd,
        userIds: userIds,
        siteIds: siteIds,
        projectIds: projectIds,
        types: types,
        tags: tags,
        tagMode: tagMode,
        includeEmpty: includeEmpty,
        publishedOnly: publishedOnly,
        finishedOnly: finishedOnly,
        is360: is360,
        bbox: bbox,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetches display information for the given organisation (Auth policies: AuthorisedApp, User)
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns OrganisationsGetDisplayInfoDto Success
   * @throws ApiError
   */
  public getApiOrganisationsDisplay(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<OrganisationsGetDisplayInfoDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/display",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetch the team tags which exist under this organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param organisationRef Organisation ID or shorthand to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsUnifiedGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsTags(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsUnifiedGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/tags",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * [Deprecated] Fetch the team tags which exist under this organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param organisationRef Organisation ID or shorthand to get the tags from
   * @param xVersion The requested API version
   * @returns TeamTagsGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsTagsTeam(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<TeamTagsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/tags/team",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch all used tags of the given organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User (Site Admin)
   * @param organisationRef Id or shorthand of the organisation to get the tags from
   * @param xVersion The requested API version
   * @returns SiteTagsUsedGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsTagsUsed(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<SiteTagsUsedGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/tags/used",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets a list of layer items attached to teams in the given organisation. <br />
   * If the layer item is a ImageLayer it will also return the coordinates otherwise the coordinates will be null. (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef The id or shorthand of the organisation to check;  can be "private" or "all"
   * @param includeLink
   * @param xVersion The requested API version
   * @returns LayerItemsGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsLayers(
    organisationRef: string,
    includeLink: boolean = false,
    xVersion: string = "2",
  ): CancelablePromise<Array<LayerItemsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/layers",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        includeLink: includeLink,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get projects from an organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns ProjectsGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsProjects(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ProjectsGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/projects",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get display information for projects from an organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns ProjectsGetDisplayInfoDto Success
   * @throws ApiError
   */
  public getApiOrganisationsProjectsDisplay(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ProjectsGetDisplayInfoDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/projects/display",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch the permissions that relate to organisations
   * @param xVersion The requested API version
   * @returns PermissionDefinitionGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsPermissions(
    xVersion: string = "2",
  ): CancelablePromise<Array<PermissionDefinitionGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/permissions",
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Fetches a list of all roles related to this organisation (Auth policies: AuthorisedApp, User)
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsRoles1(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<AccessRoleGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/roles",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new role this organisation (Auth policies: AuthorisedApp, User)
   * @param organisationRef
   * @param xVersion The requested API version
   * @param requestBody
   * @returns AccessRoleGetDto Success
   * @throws ApiError
   */
  public postApiOrganisationsRoles(
    organisationRef: string,
    xVersion: string = "2",
    requestBody?: AccessRolePostDto,
  ): CancelablePromise<AccessRoleGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/organisations/{organisationRef}/roles",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all members of an organisation; must be in the organisation or a Developer (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns OrganisationUsersGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsMembers(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<OrganisationUsersGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/{organisationRef}/members",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Change the organisation role of multiple existing members (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @param requestBody
   * @returns OrganisationUsersGetDto Success
   * @throws ApiError
   */
  public patchApiOrganisationsMembers1(
    organisationRef: string,
    xVersion: string = "2",
    requestBody?: OrganisationUsersMultiPatchDto,
  ): CancelablePromise<Array<OrganisationUsersGetDto>> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/organisations/{organisationRef}/members",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Invite a user to an organisation - “invite” if not a BV user, “add” if a BV user, send an email either way; user must be a member of the organisation with sufficient roles (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationRef
   * @param xVersion The requested API version
   * @param requestBody
   * @returns OrganisationInvitesResultDto Success
   * @throws ApiError
   */
  public postApiOrganisationsMembers(
    organisationRef: string,
    xVersion: string = "2",
    requestBody?: OrganisationInvitesDto,
  ): CancelablePromise<OrganisationInvitesResultDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/organisations/{organisationRef}/members",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get known access hints for the given organisation (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, Developer
   * @param organisationRef
   * @param xVersion The requested API version
   * @returns DomainAccessHintGetDto Success
   * @throws ApiError
   */
  public getApiOrganisationsOrganisationHints(
    organisationRef: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<DomainAccessHintGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/organisations/organisation/{organisationRef}/hints",
      path: {
        organisationRef: organisationRef,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Archive a organisation the user has access to (Auth policies: AuthorisedApp, User)
   * **Scope**: AuthorisedApp, User
   * @param organisationId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns OrganisationsGetDto Success
   * @throws ApiError
   */
  public patchApiOrganisationsArchive(
    organisationId: string,
    xVersion: string = "2",
    requestBody?: ArchiveOrganisationPatchDto,
  ): CancelablePromise<OrganisationsGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/organisations/{organisationId}/archive",
      path: {
        organisationId: organisationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Populate/update all organisations' stats (Auth policies: AuthorisedApp, Developer)
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public patchApiOrganisationsDisplay(
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/organisations/display",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import accountApi from "@/data/api/accounts";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function confirmAliasLoader({
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  const queryParams = new URL(request.url).searchParams;

  const tkn = await z.string().nullable().parseAsync(queryParams.get("tkn"));

  if (!account) {
    return {
      tkn,
    };
  }

  queryClient.prefetchQuery(accountApi.getMe);

  return {
    tkn,
  };
}

export type ConfirmAliasLoaderReturnType = Awaited<
  ReturnType<typeof confirmAliasLoader>
>;

import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import projectQueryApi from "@/data/api/project";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const ProjectRoleParamsSchema = z.object({
  projectId: z.string(),
  roleId: z.string(),
});

export default async function projectRoleLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { projectId, roleId } = ProjectRoleParamsSchema.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      projectId,
      roleId,
    };
  }

  queryClient.prefetchQuery(projectQueryApi.getProject(projectId));
  const initialRole = await queryClient.ensureQueryData(
    projectQueryApi.getProjectRole(projectId, roleId),
  );

  return {
    projectId,
    roleId,
    initialRole,
  };
}

export type ProjectRoleLoaderReturnType = Awaited<
  ReturnType<typeof projectRoleLoader>
>;

import type { LoaderFunctionArgs } from "react-router-dom";

import organisationQueryApi from "@/data/api/organisation";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function organisationSettingsLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { orgId: organisationId } = params;

  if (!organisationId) {
    throw new Response("No Team Id Specified", { status: 400 });
  }

  const account = await authenticate(request);

  if (!account) {
    return { organisationId };
  }

  queryClient.prefetchQuery(
    organisationQueryApi.getOrganisation(organisationId),
  );

  return {
    organisationId,
  };
}

export type OrganisationSettingsLoaderReturnType = Awaited<
  ReturnType<typeof organisationSettingsLoader>
>;

export const ThemeColourV3 = {
  yellow: {
    main: "#E0FF66",
    light1: "#E6FF85",
    light2: "#ECFFA3",
    light3: "#F3FFC2",
    light4: "#F9FFE0",
  },
  blue: {
    main: "#04395F",
    light1: "#36617F",
    light2: "#68889F",
    light3: "#9BB0BF",
    light4: "#CDD7DF",
  },
  grey: {
    main: "#232933",
    light1: "#4F545C",
    light2: "#7B7f85",
    light3: "#A7A9AD",
    light4: "#D3D4D6",
  },
  cyan: {
    main: "#9FFFEF",
    light1: "#B3FFF2",
    light2: "#C5FFF5",
    light3: "#D9FFF9",
    light4: "#ECFFFC",
  },
};

export const NEUTRAL_BLUE = "#458EFF";

// colors which references colors from the styleguide
export const ThemeColour = {
  primary: {
    inactive: "#B6D1FC", //'#E87400 50%',
    active: "#4776E6",
    activeLight: "#5582EF",
    hover: "#345AC5",
    selected: "#2242A5",
    light: "#EFF4FF",
    text: "#FFFFFF",
  },
  secondary: {
    inactive: "#DCBCFD",
    active: "#8E54E9",
    hover: "#6D3DC7",
    selected: "#512AA7",
  },
  danger: {
    main: "#FE3F46",
    background: "#F8E0E0",
  },
  success: {
    main: "#28A745",
    background: "#DFF0D8",
  },
  warning: {
    main: "#FFC107",
    background: "#FCF8E3",
  },
  info: {
    main: "#0066DD",
    background: "#B8D0EC",
  },
  light: {
    border: "#DAE2E8",
    background: {
      default: "#FFFFFF",
      component: "#FAFAFA",
    },
    component: "#EFEFEF",
    contrast: {
      high: "#282635",
      regular: "#28263570",
      medium: "#6279BB33",
      low: "#A3ACBA",
      lowTransparent: "",
    },
  },
  dark: {
    border: "rgba(163, 172, 186, 0.2)",
    background: {
      default: "#282635",
      component: "#424654",
    },
    component: "#E9E9E9", // missing from atoms
    contrast: {
      high: "#FFFFFF",
      regular: "#FFFFFF70",
      medium: "#FFFFFF30",
      low: "#FFFFFF15",
    },
    text: {
      contrast: {
        low: "#6F757E",
      },
    },
  },
  gray: {
    veryLightGray: "#F6F6F6",
    lightGrey: "#DDDDDD",
    grey: "#838383",
    darkGrey: "#323232",
  },
};

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LefindrMigrationPhotoGetDto } from "../models/LefindrMigrationPhotoGetDto";
import type { LefindrMigrationPhotoPostDto } from "../models/LefindrMigrationPhotoPostDto";
import type { LefindrMigrationProjectPostDto } from "../models/LefindrMigrationProjectPostDto";
import type { LefindrMigrationTeamsGetDto } from "../models/LefindrMigrationTeamsGetDto";
import type { LefindrMigrationUserGetDto } from "../models/LefindrMigrationUserGetDto";
import type { LefindrMigrationUserPostDto } from "../models/LefindrMigrationUserPostDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class XMigrationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Migrate all Lefindr users to BuiltView
   * @param xVersion The requested API version
   * @param requestBody
   * @returns LefindrMigrationUserGetDto Success
   * @throws ApiError
   */
  public postApiXMigrationsLefindrUsers(
    xVersion: string = "2",
    requestBody?: Array<LefindrMigrationUserPostDto>,
  ): CancelablePromise<Array<LefindrMigrationUserGetDto>> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/x-migrations/lefindr-users",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Migrate all Lefindr projects to BuiltView, including access control.
   * Assumes that users have already been migrated, and if not just doesn't add the users to it.
   * @param xVersion The requested API version
   * @param requestBody
   * @returns LefindrMigrationTeamsGetDto Success
   * @throws ApiError
   */
  public postApiXMigrationsLefindrTeams(
    xVersion: string = "2",
    requestBody?: Array<LefindrMigrationProjectPostDto>,
  ): CancelablePromise<Array<LefindrMigrationTeamsGetDto>> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/x-migrations/lefindr-teams",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Migrate the given Lefindr photos (fetched from SQL separately) to BuiltView.
   * Assumes that users and teams have already been migrated;  throws an error if the team is not found.
   * @param xVersion The requested API version
   * @param requestBody
   * @returns LefindrMigrationPhotoGetDto Success
   * @throws ApiError
   */
  public postApiXMigrationsLefindrPhotos(
    xVersion: string = "2",
    requestBody?: Array<LefindrMigrationPhotoPostDto>,
  ): CancelablePromise<Array<LefindrMigrationPhotoGetDto>> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/x-migrations/lefindr-photos",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComplyItemsCreateDto } from "../models/ComplyItemsCreateDto";
import type { ComplyItemsUpdateDto } from "../models/ComplyItemsUpdateDto";
import type { ComplyMediaGetDto } from "../models/ComplyMediaGetDto";
import type { ComplyTeamsRegisterDto } from "../models/ComplyTeamsRegisterDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class IntegrationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete specified Comply item from all of its BuiltView teams
   * @param itemId
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public deleteApiIntegrationsComplyItems(
    itemId: string,
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/integrations/comply/items/{itemId}",
      path: {
        itemId: itemId,
      },
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Change title of the specified Comply item across all its BuiltView teams
   * @param itemId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public putApiIntegrationsComplyItems(
    itemId: string,
    xVersion: string = "2",
    requestBody?: ComplyItemsUpdateDto,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/integrations/comply/items/{itemId}",
      path: {
        itemId: itemId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Ping the controller to test the integration and token
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiIntegrationsComplyPing(
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/comply/ping",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return a list of teams that these emails have access to.
   * @param emails
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiIntegrationsComplyTeams(
    emails: string,
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/comply/teams/{emails}",
      path: {
        emails: emails,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        403: `Forbidden`,
      },
    });
  }
  /**
   * Return a list of team names given comply-registered team IDs
   * @param teamIds
   * @param xVersion The requested API version
   * @returns string Success
   * @throws ApiError
   */
  public getApiIntegrationsComplyTeamsNames(
    teamIds: string,
    xVersion: string = "2",
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/comply/teams/names/{teamIds}",
      path: {
        teamIds: teamIds,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        403: `Forbidden`,
      },
    });
  }
  /**
   * Fetch all media items tagged with the provided Comply item
   * @param itemId
   * @param xVersion The requested API version
   * @returns ComplyMediaGetDto Success
   * @throws ApiError
   */
  public getApiIntegrationsComplyMedia(
    itemId: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<ComplyMediaGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/comply/media/{itemId}",
      path: {
        itemId: itemId,
      },
      headers: {
        "X-Version": xVersion,
      },
    });
  }
  /**
   * Register specific BuiltView teams as integrated with Comply
   * @param xVersion The requested API version
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public postApiIntegrationsComplyRegister(
    xVersion: string = "2",
    requestBody?: ComplyTeamsRegisterDto,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/integrations/comply/register",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Add Comply items to specified BuiltView teams
   * @param xVersion The requested API version
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public postApiIntegrationsComplyItems(
    xVersion: string = "2",
    requestBody?: ComplyItemsCreateDto,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/integrations/comply/items",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthGetDto } from "../models/AuthGetDto";
import type { AuthPostDto } from "../models/AuthPostDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class AuthService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Create a user;  or, update/return one if already registered
   * **Scope**: AuthorisedApp, User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns AuthGetDto Success
   * @throws ApiError
   */
  public postApiAuth(
    xVersion: string = "2",
    requestBody?: AuthPostDto,
  ): CancelablePromise<AuthGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/auth",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}

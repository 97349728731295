import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useCallback } from "react";

import api from "@/api/client";
import {
  type LayerItemsPostDto,
  type MediaItemsMarkupPostDto,
  type MediaItemsPostDto,
  MediaType,
  UploadSource,
} from "@/api/generated";
import { useTrack } from "@/context/Tracking";
import type { UploadFile } from "@/context/Upload";
import { accountKeys } from "@/data/api/accounts";
import layerQueryApi from "@/data/api/layer";
import mediaApi, { mediaKeys } from "@/data/api/media";
import { teamsKeys } from "@/data/api/teams";
import type { Resolution } from "@/data/common.types";
import getVideoMeta from "@/utils/getVideoMeta";

import useTusUpload from "./useTusMediaUpload";

export function useCreateMedia() {
  const queryClient = useQueryClient();
  return useMutation({
    ...mediaApi.createMedia,
    onSuccess: (data, variables) => {
      for (const siteId of variables.siteIds ?? []) {
        queryClient.invalidateQueries({
          queryKey: teamsKeys.media(siteId),
        });

        queryClient.invalidateQueries({
          queryKey: accountKeys.quotas(),
        });
      }
    },
  });
}

export function useCreateSaveAsMedia() {
  return useMutation({
    ...mediaApi.createSaveAsMedia,
  });
}

export function useFinishMediaUpload() {
  const queryClient = useQueryClient();
  return useMutation({
    ...mediaApi.finishMediaUpload,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: mediaKeys.details(data.id),
      });
      queryClient.invalidateQueries({
        queryKey: teamsKeys.media(data.teamId),
      });
      queryClient.invalidateQueries({
        queryKey: accountKeys.quotas(),
      });
    },
  });
}

export function useFinishLayerUpload() {
  const queryClient = useQueryClient();
  return useMutation({
    ...layerQueryApi.finishLayerUpload,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: teamsKeys.layers(data.teamId),
      });
    },
  });
}

/**
 * Given a string, return the SHA256 hash of that string
 * @param message The string to hash
 * @returns The SHA256 hash of the string
 */
async function sha256(message: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hash));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

export interface MarkupUploadType extends MarkupMeta {
  /** What type of upload is this? */
  uploadType: "markup";
  /** File to upload */
  file: File;
}
export interface MediaUploadType extends MediaMeta {
  /** What type of upload is this? */
  uploadType: "media";
  /** File to upload */
  file: File;
}
export interface LayerUploadType extends LayerMeta {
  /** What type of upload is this? */
  uploadType: "layer";
  /** File to upload */
  file: File;
}

export type UploadType = MediaUploadType | LayerUploadType | MarkupUploadType;

export const isMediaUploadType = (u: UploadType): u is MediaUploadType =>
  u.uploadType === "media";
export const isLayerUploadType = (u: UploadType): u is LayerUploadType =>
  u.uploadType === "layer";
export const isMarkupUploadType = (u: UploadType): u is MarkupUploadType =>
  u.uploadType === "markup";

export interface LayerMeta
  extends Omit<LayerItemsPostDto, "fileName" | "byteCount"> {
  /** Which team this layer is linked to */
  teamId: string;
}

export interface MarkupMeta {
  mediaId: string;
  resolution: {
    height: number;
    width: number;
  };
}

export interface MediaMeta {
  /**
   * Description of the file
   */
  description?: string | null;
  /**
   * Tags attached to the file
   */
  tags?: Array<string> | null;

  /**
   * Whether the audio of this file should be played (video only)
   */
  audioEnabled: boolean;

  /** Which teams to add this media to */
  siteIds?: string[];

  resolution?: Resolution;
  durationMs?: number;
  timestamp?: string;
}
const DateFormat = "yyyy-MM-dd'T'HH:MM:ssZZ";

export type UploadProgress = {
  /** How many bytes total are being uploaded? */
  total: number;
  /** How many bytes have uploaded so far? */
  loaded: number;
  /** What is the current status? */
  status:
    | "pending"
    | "queued"
    | "in progress"
    | "success"
    | "failed"
    | "cancelled";
  /** If failed, why? */
  error?: string;
};

export const uploadIsInactive = (u: UploadProgress): boolean =>
  u.status === "cancelled" || u.status === "failed" || u.status === "success";

export const uploadIsActive = (u: UploadProgress): boolean =>
  u.status === "pending" || u.status === "queued" || u.status === "in progress";

const getMeta = async (
  file: File
  // data?: Omit<MediaUploadType, "file">
): Promise<{
  timestamp: string;
  resolution: Resolution;
  is360: boolean;
  durationMs: number;
}> => {
  if (file.type.startsWith("image")) {
    try {
      const exifReader = await import("exifreader");
      const exifData = await exifReader.load(file, { expanded: true });

      const is360 =
        exifData?.file?.["Image Width"] && exifData?.file?.["Image Height"]
          ? exifData.file["Image Width"].value /
              exifData.file["Image Height"].value ===
            2
          : false;

      const resolution: Resolution | undefined =
        exifData?.file?.["Image Width"] && exifData?.file?.["Image Height"]
          ? {
              height: exifData.file["Image Height"].value,

              width: exifData.file["Image Width"].value,
            }
          : { height: 0, width: 0 };

      const timestamp = exifData?.exif?.DateTimeOriginal
        ? DateTime.fromFormat(
            exifData?.exif?.DateTimeOriginal.description,
            "yyyy:MM:dd HH:mm:ss"
          ).toFormat(DateFormat)
        : DateTime.fromMillis(file.lastModified).toFormat(DateFormat) ??
          DateTime.local().toFormat(DateFormat);

      return { is360, timestamp, resolution, durationMs: 0 };
    } catch (e) {
      throw new Error("Failed to get metadata from image");
    }
  } else if (file.type.startsWith("video")) {
    const video = await getVideoMeta(file);

    const timestamp =
      DateTime.fromMillis(file.lastModified).toFormat(DateFormat) ??
      DateTime.local().toFormat(DateFormat);

    const resolution = {
      height: video.resolution.height,
      width: video.resolution.width,
    };

    const is360 = video.resolution.width / video.resolution.height === 2;

    const durationMs = video.durationMs;

    return {
      timestamp,
      resolution,
      is360,
      durationMs,
    };
  } else {
    return {
      timestamp: DateTime.local().toFormat(DateFormat),
      resolution: { height: 0, width: 0 },
      is360: false,
      durationMs: 0,
    };
  }
};

export type UploadFileData = {
  id: UploadFile["id"];
  type: "media" | "layer";
  file: UploadType["file"];
  updateProgress: (pr: UploadProgress) => void;
  cloudId: string;
  signal?: AbortSignal;
  // When the file upload was begun, in millis since the epoch (Date().getTime())
  startTime: number;
  is360: boolean;
  teamId?: string;
};

/**
 * Hook wrapper for upload functions so we can use RTK slices to do the actual mutations.
 */
const useChunkedUpload = () => {
  const createMedia = useCreateMedia();
  const markupMedia = useCreateSaveAsMedia();
  const { mutateAsync: finishMedia } = useFinishMediaUpload();
  const { mutateAsync: finishLayer } = useFinishLayerUpload();
  const queryClient = useQueryClient();
  const { mutateAsync: tusUpload } = useTusUpload();

  /**
   * Create a new media upload
   * @param upload UploadType object
   * @param updateProgress Function to update the progress of this upload
   * @param cancelToken axios cancel token (optional)
   * @returns UploadFileData object
   * @throws AxiosError, Error
   */
  const createChunkedMediaUpload = useCallback(
    async (
      id: UploadFile["id"],
      { file, ...data }: MediaUploadType,
      updateProgress: (pr: UploadProgress) => void = () => null,
      signal?: AbortSignal
    ): Promise<UploadFileData> => {
      const type = file.type.startsWith("image")
        ? MediaType.PHOTO
        : MediaType.VIDEO;

      const hash = await sha256(
        file.name + file.lastModified.toString() + file.size
      );

      let meta: {
        timestamp: string;
        resolution: Resolution;
        is360: boolean;
        durationMs: number;
      };

      // Avoid checking exif data if it's already provided...
      if (
        (!data.timestamp && type === MediaType.PHOTO) ||
        !data.resolution ||
        (data.durationMs === undefined && type === MediaType.VIDEO)
      ) {
        meta = await getMeta(file);
      } else {
        meta = {
          timestamp: data.timestamp ?? DateTime.local().toFormat(DateFormat),
          resolution: data.resolution,
          durationMs: data.durationMs ?? 0,
          is360: data.resolution.width / data.resolution.height === 2,
        };
      }

      const uploadData: MediaItemsPostDto = {
        ...data,
        type,
        hash,
        byteCount: file.size,
        fileName: file.name,
        resolution: [meta.resolution.height, meta.resolution.width],
        is360: meta.is360,
        durationMs: Math.round(meta.durationMs ?? 0),
        timestamp: meta.timestamp,
        source: UploadSource.IMPORT,
      };

      const createResult = await createMedia.mutateAsync(uploadData);

      // record progress
      updateProgress({ total: file.size, loaded: 0, status: "queued" });

      return {
        id,
        file,
        updateProgress,
        cloudId: createResult.id,
        signal: signal,
        type: "media",
        startTime: new Date().getTime(),
        is360: meta.is360 ?? false,
        teamId: data.siteIds?.[0] ?? undefined,
      };
    },
    [createMedia]
  );

  /**
   * Create a new media upload
   * @param upload UploadType object
   * @param updateProgress Function to update the progress of this upload
   * @param cancelToken axios cancel token (optional)
   * @returns UploadFileData object
   * @throws AxiosError, Error
   */
  const createChunkedMarkupUpload = useCallback(
    async (
      id: UploadFile["id"],
      { file, ...data }: MarkupUploadType,
      updateProgress: (pr: UploadProgress) => void = () => null,
      signal?: AbortSignal
    ): Promise<UploadFileData> => {
      const hash = await sha256(
        file.name + file.lastModified.toString() + file.size
      );
      const uploadData: MediaItemsMarkupPostDto & { mediaId: string } = {
        mediaId: data.mediaId,
        thumbnailParams: JSON.stringify(data.resolution),
        hash,
        byteCount: file.size,
        fileName: file.name,
      };

      const createResult = await markupMedia.mutateAsync(uploadData);

      // record progress
      updateProgress({ total: file.size, loaded: 0, status: "queued" });

      return {
        id,
        file,
        updateProgress,
        cloudId: createResult.id,
        signal: signal,
        type: "media",
        startTime: new Date().getTime(),
        is360: false,
      };
    },
    [markupMedia]
  );

  /**
   * Create a new media upload
   * @param upload UploadType object
   * @param updateProgress Function to update the progress of this upload
   * @param cancelToken axios cancel token (optional)
   * @returns UploadFileData object
   * @throws AxiosError, Error
   */
  const createChunkedLayerUpload = useCallback(
    async (
      id: UploadFile["id"],
      { file, teamId, ...data }: LayerUploadType,
      updateProgress: (pr: UploadProgress) => void = () => null,
      signal?: AbortSignal
    ): Promise<UploadFileData> => {
      if (!teamId) throw new Error("Layer uploads must be tied to a team");
      const body: LayerItemsPostDto = {
        ...data,
        fileName: file.name,
        byteCount: file.size,
      };
      const createResult = await api.teams.postApiTeamsLayers(
        teamId,
        undefined,
        body
      );

      updateProgress({ total: file.size, loaded: 0, status: "queued" });

      return {
        id,
        file,
        cloudId: createResult.id,
        type: "layer",
        updateProgress,
        signal: signal,
        startTime: new Date().getTime(),
        is360: false,
      };
    },
    []
  );

  // IS THIS A PROBLEM?  Will it cause uploadChunks to rerender?
  const track = useTrack();

  /**
   * Sequentially upload all chunks of the given file
   * @param data UploadFileData
   * @returns The media item ID if successful;  null otherwise
   * @throws NOTHING -- all exceptions are handled locally and the promise is never rejected.
   */
  const uploadChunks = useCallback(
    async ({
      type,
      file,
      cloudId,
      updateProgress,
      signal,
      startTime,
      is360,
      teamId,
    }: UploadFileData): Promise<string | null> => {
      try {
        // split file into chunks

        // use Tus if the file is smaller than 10GB
        const useTus = file.size < 10 * 1024 * 1024 * 1024;

        // upload each chunk
        let progressVar = 0;
        /**
         * @todo -- each chunk should be trycatch'd independently, and if one fails, just try it again later?
         * At least some kind of error handling is required - on mobile at least, if you fail to upload a photo you have to keep trying.
         */
        if (type === "media") {
          if (useTus) {
            console.log(
              `------- USING TUS -------\nUploading ${file.name} to ${cloudId}\n------- USING TUS -------`
            );

            await tusUpload({
              file,
              cloudId,
              teamId,
              cb: updateProgress,
              signal,
            });
          } else {
            const chunkSize = 1024 * 1024 * 1; // 1MB
            /** @todo can we check which chunks have been uploaded already and skip those?  Should the backend handle that? */
            const chunks: Blob[] = [];
            for (let i = 0; i < file.size; i += chunkSize) {
              chunks.push(file.slice(i, i + chunkSize));
            }
            for (let i = 0; i < chunks.length; i++) {
              // Manual cancellation without switching RTK to axios!
              signal?.throwIfAborted();

              const chunk = chunks[i];
              if (!chunk) continue;
              // const chunksEndpoint = `${chunkEndpoint}/${createResult.data.id}/chunks/${i}`;
              await api.mediaItems.postApiMediaChunks(cloudId, undefined, {
                Chunk: chunk,
                ChunkNum: i,
              });
              progressVar += chunk.size;
              updateProgress({
                total: file.size,
                loaded: progressVar,
                status: "in progress",
              });
            }
            await finishMedia(cloudId);
          }
        } else {
          const chunkSize = 1024 * 1024 * 1; // 1MB
          /** @todo can we check which chunks have been uploaded already and skip those?  Should the backend handle that? */
          const chunks: Blob[] = [];
          for (let i = 0; i < file.size; i += chunkSize) {
            chunks.push(file.slice(i, i + chunkSize));
          }
          for (let i = 0; i < chunks.length; i++) {
            // Manual cancellation without switching RTK to axios!
            const listener = () => {
              throw new Error("Upload cancelled");
            };

            signal?.addEventListener("abort", listener);

            const chunk = chunks[i];
            if (!chunk) continue;
            // const chunksEndpoint = `${chunkEndpoint}/${createResult.data.id}/chunks/${i}`;

            await api.layerItems.postApiLayerChunks(cloudId, undefined, {
              Chunk: chunk,
              ChunkNum: i,
            });

            progressVar += chunk.size;
            updateProgress({
              total: file.size,
              loaded: progressVar,
              status: "in progress",
            });

            signal?.removeEventListener("abort", listener);
          }
          await finishLayer(cloudId);
        }

        const isPhoto = file.type.includes("image");

        track({
          name: "Uploaded item",
          data: {
            duration_seconds: (new Date().getTime() - startTime) / 1000,
            fileSize: file.size,
            type: file.type,
            itemType:
              type === "layer"
                ? "mapLayer"
                : isPhoto
                ? is360
                  ? "photo360"
                  : "photo2D"
                : is360
                ? "video360"
                : "video2D",
          },
        });

        updateProgress({
          total: file.size,
          loaded: progressVar,
          status: "success",
        });

        return cloudId;
      } catch (e) {
        if (e instanceof Error) {
          // Does this work with mutation -> unwrap?  Or does something else happen?
          updateProgress({
            total: 0,
            loaded: 0,
            status: "cancelled",
            error: e.message,
          });
        } else
          updateProgress({
            total: 0,
            loaded: 0,
            status: "failed",
            error: (e as Error)?.message ?? "Unknown reason",
          });
        return null;
      }
    },
    [track, finishMedia, tusUpload, finishLayer]
  );

  return {
    createChunkedMediaUpload,
    createChunkedMarkupUpload,
    createChunkedLayerUpload,
    uploadChunks,
  };
};

export default useChunkedUpload;

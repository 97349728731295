import { useQuery } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";

import { UserRole } from "@/api/generated";
import accountApi from "@/data/api/accounts";

export function Component() {
  const {
    data: user,
    isSuccess,
    isError,
    isFetching,
    isLoading,
  } = useQuery(accountApi.getMe);

  if (isError) {
    return <>Error Loading Admin Access</>;
  }

  if (isLoading || isFetching) {
    return <>Loading Admin Access</>;
  }

  if (!isSuccess) {
    return <>Unknown Error</>;
  }

  if (user.roles.includes(UserRole.DEVELOPER)) {
    return <Outlet />;
  }
  throw new Response("Not Authorized", { status: 401 });
}

export default Component;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { teamQueryApi, teamsKeys } from "@/data/api/teams";

export default function useEditTeam() {
  const queryClient = useQueryClient();
  return useMutation({
    ...teamQueryApi.editTeam,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: teamsKeys.details(variables.teamId),
      });
      queryClient.invalidateQueries({
        queryKey: teamsKeys.list(),
      });
    },
  });
}

import type { AlertColor } from "@mui/material";
import { useCallback, useContext } from "react";

import { ToastContext, type ToastMessage } from "./ToastContext";

type ExtraOptions = Partial<Omit<ToastMessage, "severity" | "message">>;

export const useToast = () => {
  const { upsertMessage, removeMessage } = useContext(ToastContext);

  const show = useCallback(
    (
      message: string,
      options: { severity?: AlertColor } & ExtraOptions = {}
    ) => {
      const key = options.key ?? new Date().getTime();
      upsertMessage({ message, ...options, key });
      return key;
    },
    [upsertMessage]
  );

  const remove = useCallback(
    (key: string | number) => {
      removeMessage(key);
    },
    [removeMessage]
  );

  return {
    show,
    remove,
    info: (message: string, options: ExtraOptions = {}) =>
      show(message, { severity: "info", ...options }),
    success: (message: string, options: ExtraOptions = {}) =>
      show(message, { severity: "success", ...options }),
    warning: (message: string, options: ExtraOptions = {}) =>
      show(message, { severity: "warning", ...options }),
    error: (message: string, options: ExtraOptions = {}) =>
      show(message, { severity: "error", ...options }),
  };
};

import type { LoaderFunctionArgs } from "react-router-dom";

import userQueryApi from "@/data/api/user";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function techSupportDashboardLoader({
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) {
    return null;
  }

  queryClient.prefetchQuery(userQueryApi.getAllUsers);

  return null;
}

export type TechSupportDashboardLoaderReturnType = Awaited<
  ReturnType<typeof techSupportDashboardLoader>
>;

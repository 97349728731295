import type { LoaderFunctionArgs } from "react-router-dom";

import projectQueryApi from "@/data/api/project";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function projectTeamsLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { projectId } = params;

  if (!projectId) {
    throw new Response("No Project Id Specified", { status: 400 });
  }
  const account = await authenticate(request);

  if (!account) {
    return {
      projectId,
    };
  }

  queryClient.prefetchQuery(projectQueryApi.getProjectTeams(projectId));

  queryClient.prefetchQuery(projectQueryApi.getProject(projectId));

  return {
    projectId,
  };
}

export type ProjectTeamsLoaderReturnType = Awaited<
  ReturnType<typeof projectTeamsLoader>
>;

import type { LoaderFunctionArgs } from "react-router-dom";

import { teamQueryApi } from "@/data/api/teams";
import userQueryApi from "@/data/api/user";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function settingsPeopleLoader({
  request,
}: LoaderFunctionArgs) {
  const account = await authenticate(request);

  if (!account) {
    return null;
  }

  queryClient.prefetchQuery(teamQueryApi.getTeams);
  queryClient.prefetchQuery(userQueryApi.getAllUsers);

  return null;
}

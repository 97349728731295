import { Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useToast } from "@/components/Snackbar/useToast";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import Spinner from "@/components/atoms/Spinner/Spinner";
import { teamQueryApi } from "@/data/api/teams";
import useIsAuthenticated from "@/features/auth/hooks/useIsAuthenticated";

export function Component() {
  const { token } = useParams<{
    token?: string;
  }>();

  const redeemToken = useMutation(teamQueryApi.redeemAccessCode);
  const navigate = useNavigate();
  const alert = useToast();

  const handleTokenRedeem = useCallback(
    async (token: string) => {
      if (redeemToken.isPending) return;
      try {
        const site = await redeemToken.mutateAsync(token);
        if (site) {
          navigate(`/gallery/${site.id}`);
        }
      } catch (error) {
        alert.error("Failed to join team");
        navigate("/gallery");
      }
    },
    [navigate, alert, redeemToken]
  );

  const isAuthenticated = useIsAuthenticated();
  const [hasStarted, setHasStarted] = useState<boolean>(false);

  useEffect(() => {
    if (token && isAuthenticated && !hasStarted) {
      setHasStarted(true);
      handleTokenRedeem(token);
    }
  }, [isAuthenticated, hasStarted, handleTokenRedeem, token]);

  return !isAuthenticated ? (
    <Spinner aria-label="Not authenticated" />
  ) : !token ? (
    <Navigate to="/gallery" />
  ) : redeemToken.isPending ? (
    <Spinner aria-label="Loading auth status" />
  ) : (
    <Typography>Something went wrong...</Typography>
  );
}

export default Component;

import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { createSelectorHook, useDispatch } from "react-redux";
import { combineReducers } from "redux";

import settingsReducer from "./slices/settings.slice";

// Reform the RootReducer with the persisted reducer for media
const persistedReducer = combineReducers({
  settings: settingsReducer,
});

// Use this to disable redux-persist?
// Note you also have to remove the PersistGate from App.tsx
// export const store = createStore(rootReducer, initialState, enhancer);

// export const store = createStore(persistedReducer, initialState, enhancer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: import.meta.env.DEV,
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(Sentry.createReduxEnhancer({})),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = createSelectorHook();
export const appDispatch = store.dispatch;

import type { LoaderFunctionArgs } from "react-router-dom";

import organisationQueryApi from "@/data/api/organisation";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function organisationProjectsLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const { orgId } = params;

  if (!orgId) {
    throw new Response("No Project Id Specified", { status: 400 });
  }
  const account = await authenticate(request);

  if (!account) {
    return {
      orgId,
    };
  }

  queryClient.prefetchQuery(
    organisationQueryApi.getOrganisationDisplayProjects(orgId),
  );

  queryClient.prefetchQuery(organisationQueryApi.getOrganisation(orgId));

  return {
    orgId,
  };
}

export type OrganisationProjectsLoaderReturnType = Awaited<
  ReturnType<typeof organisationProjectsLoader>
>;

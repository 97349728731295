import { redirect } from "react-router-dom";

import queryClient from "@/queryClient";
import Sentry from "@/utils/interfaces/sentry.interface";

export default async function logoutLoader() {
  // Storage.clearAll();
  window.localStorage.removeItem("auth");
  // broadcast custom event
  window.dispatchEvent(new Event("local-storage"));
  const { default: Mixpanel } = await import(
    "@/utils/interfaces/mixpanel.interface"
  );
  Mixpanel.reset();
  // store.dispatch(TrackReset());
  Sentry.logout();
  queryClient.clear();

  // Wait for 500 ms to make sure the logout is complete
  await new Promise((resolve) => setTimeout(resolve, 500));

  return redirect("/login", { status: 302 });
}

enum MediaPermissionFlagBits {
  Any = 0x7fffffff,

  // "Read" permissions - generally safer to grant to anyone
  /// <summary>View media that I didn't create (view my own is always allowed)</summary>
  ViewOthersMedia = 0x1,
  /// <summary>Export, download or copy media out of the team</summary>
  ExportMedia = 0x2,
  /// <summary>See who has uploaded this media item</summary>
  ViewMediaUploader = 0x4,
  // UNUSED = 0x8,
  /// <summary>Can see deleted media item</summary>
  ViewDeleted = 0x10,
  // UNUSED = 0x20,
  // UNUSED = 0x40,
  /// <summary>Can see the audit trail of activity on this media item
  ViewAuditTrail = 0x80,

  // "Write" permissions - require more scrutiny
  /// <summary>Upload media into this context</summary>
  UploadMedia = 0x100,
  /// <summary>Edit media items that I uploaded</summary>
  EditOwnMedia = 0x200,
  /// <summary>Remove media items that I uploaded from the team</summary>
  DeleteOwnMedia = 0x400,
  /// <summary>Edit media items that others uploaded</summary>
  EditOthersMedia = 0x800,
  /// <summary>Remove media items that others uploaded from the team</summary>
  DeleteOthersMedia = 0x1000,
  /// <summary>Comment on media items</summary>
  AddComment = 0x2000,
  /// <summary>Delete comments on media items</summary>
  DeleteComments = 0x4000,
  /// <summary>Create tags on media items which are not team tags</summary>
  CreateCustomTags = 0x8000,
  /// <summary>Create share links to media items</summary>
  CreateShareLinks = 0x10000,
  // UNUSED = 0x20000,
  // UNUSED = 0x40000,
  // UNUSED = 0x80000,
  // UNUSED = 0x100000,
  // UNUSED = 0x200000,
  // UNUSED = 0x400000,
  // UNUSED = 0x800000,
  // UNUSED = 0x1000000,
  // UNUSED = 0x2000000,
  // UNUSED = 0x4000000,
  // UNUSED = 0x8000000,
  // UNUSED = 0x10000000,
  // UNUSED = 0x20000000,
  // UNUSED = 0x40000000,
}

export default MediaPermissionFlagBits;

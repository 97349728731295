import type { LoaderFunctionArgs } from "react-router-dom";

import { CONSTANTS } from "@/common";
import { readURLParams } from "@/context/filter";
import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

export default async function galleryLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);

  const urlParams = readURLParams(searchParams);
  const account = await authenticate(request);

  const id = params.siteId;

  if (!id) {
    throw new Response("No Team Id Specified", { status: 400 });
  }

  if (!account) {
    return { id };
  }

  queryClient.prefetchQuery(
    teamQueryApi.getTeamMediaGroups(id, {
      bbox: urlParams.bbox ?? undefined,
      dateString: urlParams.dateString,
      dateStringEnd: urlParams.dateStringEnd,
      dayOnly: urlParams.dayOnly,
      finishedOnly: urlParams.finishedOnly,
      includeEmpty: urlParams.includeEmpty,
      is360: urlParams.is360,
      publishedOnly: urlParams.publishedOnly,
      sort: urlParams.sort,
      search: urlParams.search,
      tags: urlParams.tags,
      tagMode: urlParams.tagMode,
      types: urlParams.types ? [urlParams.types] : undefined,
      userIds: urlParams.userIds,
      floorplanId: urlParams.floorplanId,
    }),
  );

  queryClient.prefetchInfiniteQuery({
    ...teamQueryApi.getInfiniteMedia(id, {
      bbox: urlParams.bbox ?? undefined,
      dateString: urlParams.dateString,
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      dateStringEnd: urlParams.dateStringEnd,
      dayOnly: urlParams.dayOnly,
      finishedOnly: urlParams.finishedOnly,
      includeEmpty: urlParams.includeEmpty,
      is360: urlParams.is360,
      publishedOnly: urlParams.publishedOnly,
      sort: urlParams.sort,
      search: urlParams.search,
      tags: urlParams.tags,
      tagMode: urlParams.tagMode,
      types: urlParams.types ? [urlParams.types] : undefined,
      userIds: urlParams.userIds,
      floorplanId: urlParams.floorplanId,
    }),
    pages: 1,
  });

  return { id };
}

export type GalleryLoaderReturnType = Awaited<ReturnType<typeof galleryLoader>>;

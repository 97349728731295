/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SubscriptionStatus {
  NONE = "None",
  INACTIVE = "Inactive",
  ACTIVE = "Active",
  PAUSED = "Paused",
  TRIAL = "Trial",
  CANCELLING = "Cancelling",
}

import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";
import type {
  AccessRolePatchDto,
  AccessRolePostDto,
  ArchiveOrganisationPatchDto,
  OrganisationInvitesDto,
  OrganisationUsersPatchDto,
  OrganisationsPatchDto,
  OrganisationsPostDto,
} from "@/api/generated";

import { cancelableQuery, mutationOptions } from "./helpers";

interface ArchiveOrganisationData extends ArchiveOrganisationPatchDto {
  organisationId: string;
}

interface GraphPayload {
  dateString?: string | undefined;
  dateStringEnd?: string | undefined;
  granularity?: "year" | "month" | "week" | "day";
}

interface ActivityPayload {
  dateString?: string;
  dateStringEnd?: string;
}

export const organisationKeys = {
  root: "organisation" as const,
  permissions: () => [organisationKeys.root, "permissions" as const] as const,
  list: () => [organisationKeys.root, "list" as const] as const,
  organisation: (id: string) => [organisationKeys.root, id] as const,
  details: (id: string) =>
    [...organisationKeys.organisation(id), "details" as const] as const,
  members: (id: string) =>
    [...organisationKeys.organisation(id), "members" as const] as const,
  projects: (id: string) =>
    [...organisationKeys.organisation(id), "projects" as const] as const,
  displayProjects: (id: string) =>
    [...organisationKeys.projects(id), "display" as const] as const,
  invitations: (id: string) =>
    [...organisationKeys.organisation(id), "invitations" as const] as const,
  graph: (
    id: string,
    filter: {
      granularity?: string;
      dateString?: string;
      dateStringEnd?: string;
    }
  ) =>
    [...organisationKeys.organisation(id), "graph" as const, filter] as const,
  activity: (
    id: string,
    type: "tags" | "users" | "teams" | "projects",
    filter: {
      granularity?: string;
      dateString?: string;
      dateStringEnd?: string;
    }
  ) => [...organisationKeys.graph(id, filter), type] as const,
  stats: (id: string) =>
    [...organisationKeys.organisation(id), "stats" as const] as const,
  roles: (id: string) =>
    [...organisationKeys.organisation(id), "roles" as const] as const,
  role: (id: string, roleId: string) =>
    [...organisationKeys.roles(id), roleId] as const,
  hints: (organisationId: string) =>
    [organisationKeys.root, "hints" as const, organisationId] as const,
  hint: (organisationId: string, domain: string) =>
    [...organisationKeys.hints(organisationId), domain] as const,
};

const organisationQueryApi = {
  deleteOrganisation: mutationOptions({
    mutationFn: (organisationId: string) =>
      api.organisations.deleteApiOrganisations(organisationId),
  }),

  getOrganisation: (organisationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisations(organisationId),
          signal
        ),
      queryKey: organisationKeys.details(organisationId),
    }),

  editOrganisation: mutationOptions({
    mutationFn: ({
      organisationId,
      ...data
    }: OrganisationsPatchDto & {
      organisationId: string;
    }) =>
      api.organisations.patchApiOrganisations(organisationId, undefined, data),
  }),

  removeOrganisationMember: mutationOptions({
    mutationFn: (data: { organisationId: string; userId: string }) =>
      api.organisations.deleteApiOrganisationsMembers(
        data.organisationId,
        data.userId
      ),
  }),

  editOrganisationMember: mutationOptions({
    mutationFn: ({
      userId,
      organisationId,
      ...data
    }: OrganisationUsersPatchDto & {
      organisationId: string;
      userId: string;
    }) =>
      api.organisations.patchApiOrganisationsMembers(
        organisationId,
        userId,
        undefined,
        data
      ),
  }),

  getOrganisations: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.organisations.getApiOrganisations1(), signal),
    queryKey: organisationKeys.list(),
  }),

  createOrganisation: mutationOptions({
    mutationFn: (data: OrganisationsPostDto) =>
      api.organisations.postApiOrganisations(undefined, data),
  }),

  getOrganisationProjects: (organisationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsProjects(organisationId),
          signal
        ),
      queryKey: organisationKeys.projects(organisationId),
    }),

  getOrganisationMembers: (organisationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsMembers(organisationId),
          signal
        ),
      queryKey: organisationKeys.members(organisationId),
    }),

  getOrganisationInvitations: (organisationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsInvites(organisationId),
          signal
        ),
      queryKey: organisationKeys.invitations(organisationId),
    }),

  revokeOrganisationInvitations: mutationOptions({
    mutationFn: ({
      organisationId,
      emails,
    }: {
      organisationId: string;
      emails: string[];
    }) =>
      api.organisations.deleteApiOrganisationsInvites(organisationId, emails),
  }),

  getOrganisationGraph: (organisationId: string, data: GraphPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsGraph(
            organisationId,
            data.dateString,
            data.dateStringEnd,
            data.granularity
          ),
          signal
        ),
      queryKey: organisationKeys.graph(organisationId, data),
    }),

  getOrganisationTagActivity: (organisationId: string, data: ActivityPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsActivityTags(
            organisationId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: organisationKeys.activity(organisationId, "tags", data),
    }),

  getOrganisationUserActivity: (
    organisationId: string,
    data: ActivityPayload
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsActivityUsers(
            organisationId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: organisationKeys.activity(organisationId, "users", data),
    }),

  getOrganisationTeamActivity: (
    organisationId: string,
    data: ActivityPayload
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsActivityTeams(
            organisationId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: organisationKeys.activity(organisationId, "teams", data),
    }),

  getOrganisationProjectActivity: (
    organisationId: string,
    data: ActivityPayload
  ) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsActivityProjects(
            organisationId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: organisationKeys.activity(organisationId, "projects", data),
    }),

  getOrganisationDisplayProjects: (organisationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsProjectsDisplay(organisationId),
          signal
        ),
      queryKey: organisationKeys.displayProjects(organisationId),
    }),

  getOrganisationStats: (organisationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsStats(organisationId),
          signal
        ),
      queryKey: organisationKeys.stats(organisationId),
    }),

  inviteOrganisationMember: mutationOptions({
    mutationFn: ({
      organisationId,
      ...data
    }: { organisationId: string } & OrganisationInvitesDto) =>
      api.organisations.postApiOrganisationsMembers(
        organisationId,
        undefined,
        data
      ),
  }),

  getOrganisationPermissions: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(
        api.organisations.getApiOrganisationsPermissions(),
        signal
      ),
    queryKey: organisationKeys.permissions(),
  }),

  getOrganisationRoles: (organisationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsRoles1(organisationId),
          signal
        ),
      queryKey: organisationKeys.roles(organisationId),
    }),

  getOrganisationRole: (organisationId: string, roleId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsRoles(organisationId, roleId),
          signal
        ),
      queryKey: organisationKeys.role(organisationId, roleId),
    }),

  editOrganisationRole: mutationOptions({
    mutationFn: ({
      organisationId,
      roleId,
      ...data
    }: { organisationId: string; roleId: string } & AccessRolePatchDto) =>
      api.organisations.patchApiOrganisationsRoles(
        organisationId,
        roleId,
        undefined,
        data
      ),
  }),

  deleteOrganisationRole: mutationOptions({
    mutationFn: (data: { organisationId: string; roleId: string }) =>
      api.organisations.deleteApiOrganisationsRoles(
        data.organisationId,
        data.roleId
      ),
  }),

  createOrganisationRole: mutationOptions({
    mutationFn: ({
      organisationId,
      ...data
    }: { organisationId: string } & AccessRolePostDto) =>
      api.organisations.postApiOrganisationsRoles(
        organisationId,
        undefined,
        data
      ),
  }),

  archiveOrganisation: mutationOptions({
    mutationFn: ({ organisationId, ...data }: ArchiveOrganisationData) =>
      api.organisations.patchApiOrganisationsArchive(
        organisationId,
        undefined,
        data
      ),
  }),
  getOrganisationHints: (organisationId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.organisations.getApiOrganisationsOrganisationHints(
            organisationId
          ),
          signal
        ),
      queryKey: organisationKeys.hints(organisationId),
    }),
};

export default organisationQueryApi;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerGetDto } from "../models/CustomerGetDto";
import type { StripeCheckoutPostDto } from "../models/StripeCheckoutPostDto";
import type { StripePortalPostDto } from "../models/StripePortalPostDto";
import type { StripeRedirectGetDto } from "../models/StripeRedirectGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class StripeService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get the calling user's Customer information (Auth policies: User)
   * **Scope**: User
   * @param xVersion The requested API version
   * @returns CustomerGetDto Success
   * @throws ApiError
   */
  public getApiStripeCustomer(
    xVersion: string = "2",
  ): CancelablePromise<CustomerGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/stripe/customer",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a new checkout session.  Note it doesn't do the redirect here because of CORS issues on Stripe's end.
   * Instead, we return the redirect URL and manually do it on the frontend. (Auth policies: User)
   * **Scope**: User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns StripeRedirectGetDto Success
   * @throws ApiError
   */
  public postApiStripeCheckoutSession(
    xVersion: string = "2",
    requestBody?: StripeCheckoutPostDto,
  ): CancelablePromise<StripeRedirectGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/stripe/checkout-session",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a new portal session. Note it doesn't do the redirect here because of CORS issues on Stripe's end.
   * Instead, we return the redirect URL and manually do it on the frontend. (Auth policies: User)
   * **Scope**: User
   * @param xVersion The requested API version
   * @param requestBody
   * @returns StripeRedirectGetDto Success
   * @throws ApiError
   */
  public postApiStripePortalSession(
    xVersion: string = "2",
    requestBody?: StripePortalPostDto,
  ): CancelablePromise<StripeRedirectGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/stripe/portal-session",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

import { Divider, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import userQueryApi from "@/data/api/user";
import useAppNavigate from "@/hooks/useAppNavigate";

export function Component() {
  const { data: users } = useQuery(userQueryApi.getAllUsers);
  const navigate = useAppNavigate();

  return (
    <Stack spacing={2} divider={<Divider />} py={2}>
      <Typography>Tech Support Dashboard</Typography>
    </Stack>
  );
}

export default Component;

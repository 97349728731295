import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@/components/Snackbar/useToast";

import { teamQueryApi, teamsKeys } from "@/data/api/teams";

export default function useCreateTeamInvitation() {
  const queryClient = useQueryClient();
  const alert = useToast();
  return useMutation({
    ...teamQueryApi.createTeamInvitations,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: teamsKeys.members(variables.teamId),
      });
      queryClient.invalidateQueries({
        queryKey: teamsKeys.invitations(variables.teamId),
      });
      alert.success("Members invited successfully");
    },
  });
}

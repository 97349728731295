import { useMemo } from "react";

import useAuth from "./useAuth";

export default function useIsAuthenticated() {
  const auth = useAuth();

  const isAuthenticated = useMemo(() => {
    if (!auth) {
      return false;
    }

    const { expires_on } = auth;

    if (expires_on * 1000 < Date.now()) {
      return false;
    }

    return true;
  }, [auth]);

  return isAuthenticated;
}

import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import organisationQueryApi from "@/data/api/organisation";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const OrganisationRoleParamsSchema = z.object({
  orgId: z.string(),
  roleId: z.string(),
});

export default async function organisationRoleLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { orgId: organisationId, roleId } =
    OrganisationRoleParamsSchema.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      organisationId,
      roleId,
    };
  }

  queryClient.prefetchQuery(
    organisationQueryApi.getOrganisation(organisationId),
  );
  const initialRole = await queryClient.ensureQueryData(
    organisationQueryApi.getOrganisationRole(organisationId, roleId),
  );

  return {
    organisationId,
    roleId,
    initialRole,
  };
}

export type OrganisationRoleLoaderReturnType = Awaited<
  ReturnType<typeof organisationRoleLoader>
>;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class SettingsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get the maximum number of bytes allowed for one chunk (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @returns number Success
   * @throws ApiError
   */
  public getApiSettingsChunkBytes(
    xVersion: string = "2",
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/settings/chunk-bytes",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get the maximum number of chunks for 1 upload (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @returns number Success
   * @throws ApiError
   */
  public getApiSettingsMaxChunks(
    xVersion: string = "2",
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/settings/max-chunks",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get the maximum number of bytes for 1 upload. (Auth policies: AuthorisedApp)
   * **Scope**: AuthorisedApp
   * @param xVersion The requested API version
   * @returns number Success
   * @throws ApiError
   */
  public getApiSettingsMaxBytes(
    xVersion: string = "2",
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/settings/max-bytes",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

import type { GpsRecord } from "@/api/generated";

export type Dimensions = { height: number; width: number };
export type Coord = { x: number; y: number };
export type Bounds = { min: Coord; max: Coord };

export type Point2D = { x: number; y: number };
export type ArrPoint2D = number[];

export const GIsPoint2D = (p: Record<string, unknown>): p is Point2D =>
  p.x !== undefined && p.y !== undefined;
export const GIsArrPoint2D = (p: unknown): p is ArrPoint2D =>
  Array.isArray(p) && p.length >= 2;

export const PosFromArrP = (P: ArrPoint2D): Point2D => ({ x: P[0], y: P[1] });

export const PosFromGPS = (P: GpsRecord): Point2D => ({
  x: P.longitude ?? 0,
  y: P.latitude ?? 0,
});

export const PosFromArr = (P: ArrPoint2D[]): Point2D[] =>
  P.map((p) => (GIsArrPoint2D(p) ? { x: p[0], y: p[1] } : {})).filter((p) =>
    GIsPoint2D(p)
  ) as Point2D[];

export const PosToArr = (P: Point2D[]): ArrPoint2D[] =>
  P.map((p) => (GIsPoint2D(p) ? [p.x, p.y] : [])).filter((p) =>
    GIsArrPoint2D(p)
  );

export const PosToArrP = (p: Point2D): ArrPoint2D => [p.x, p.y];

export const Dims = (b: Bounds): Dimensions => {
  return {
    height: b.max.y - b.min.y,
    width: b.max.x - b.min.x,
  };
};

import { useLocalStorage } from "usehooks-ts";
import type { z } from "zod";

import { AccessTokenSchema } from "../schema/AccessTokenSchema";

export default function useAuth() {
  const [auth] = useLocalStorage<z.infer<typeof AccessTokenSchema> | null>(
    "auth",
    window.localStorage.getItem("auth")
      ? AccessTokenSchema.parse(
          JSON.parse(window.localStorage.getItem("auth") as string)
        )
      : null
  );

  return auth;
}

import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export interface FallbackProps {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}

const Fallback = ({ error, componentStack, resetError }: FallbackProps) => {
  // When resetError() is called it will remove the Fallback component
  // and render the Sentry ErrorBoundary's children in their initial state */

  // Can't track with mixpanel - outside the provider :(
  // const track = useTrack();

  // useEffect(() => {
  //   track({ name: "Crash", data: { cause: "js", reason: error.message } });
  // }, [error]);

  const handleReset = () => {
    resetError();
    window.location.reload();
  };
  const handleReload = () => {
    resetError();
    window.location.reload();
  };
  return (
    <Box
      style={{
        margin: 32,
      }}
    >
      <h1>Oops! Something went wrong...</h1>
      <div>{error.toString()}</div>
      <Paper
        style={{
          paddingBottom: 8,
          marginBottom: 8,
        }}
      >
        <pre>{componentStack}</pre>
      </Paper>
      <Button color="primary" onClick={handleReload}>
        Click here to reload!
      </Button>
      <Button color="secondary" onClick={handleReset}>
        Click here to reset!
      </Button>
    </Box>
  );
};

export default Fallback;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FloorplanGetDto } from "../models/FloorplanGetDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class FloorplansService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete a floorplan - only works if there are no media attached to it (Auth policies: AuthorisedApp, User)
   * @param floorplanId
   * @param xVersion The requested API version
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public deleteApiFloorplans(
    floorplanId: string,
    xVersion: string = "2",
  ): CancelablePromise<FloorplanGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/floorplans/{floorplanId}",
      path: {
        floorplanId: floorplanId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get a specific floorplan (Auth policies: AuthorisedApp, User)
   * @param floorplanId
   * @param xVersion The requested API version
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public getApiFloorplans(
    floorplanId: string,
    xVersion: string = "2",
  ): CancelablePromise<FloorplanGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/floorplans/{floorplanId}",
      path: {
        floorplanId: floorplanId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Upload a new version for a floorplan (Auth policies: AuthorisedApp, User)
   * @param floorplanId
   * @param xVersion The requested API version
   * @param formData
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public putApiFloorplans(
    floorplanId: string,
    xVersion: string = "2",
    formData?: {
      /**
       * Optional ID to try to use in the database
       */
      Id?: string;
      /**
       * The byte array of the file to upload.
       * Check size limit with GET /api/settings/chunk-bytes?
       */
      Data: Blob;
      /**
       * Total number of bytes of this upload
       */
      ByteCount: number;
      /**
       * Name of the file, including extension
       */
      FileName: string;
      Title: string;
      Description?: string;
      /**
       * If provided (and valid), the rendered version of the floorplan image will be
       * cropped to this box.
       * Must be in provided in the format (top,left,width,height), in pixel units.
       * Must be positive values within the bounds of the image
       */
      CropBox?: Array<number>;
      /**
       * If provided, a human-readable name for this version (must be unique within this floorplan).
       * If not, one will be generated based on revision number and date.
       */
      VersionName?: string;
      /**
       * If provided, the ID of the team to which this floorplan belongs.
       * Either TeamId or ProjectId (or both) must be set.
       */
      TeamId?: string;
      /**
       * If provided, the ID of the project to which this floorplan belongs.
       * Either TeamId or ProjectId (or both) must be set.
       */
      ProjectId?: string;
    },
  ): CancelablePromise<FloorplanGetDto> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/api/floorplans/{floorplanId}",
      path: {
        floorplanId: floorplanId,
      },
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get a list of all floorplans I have access to (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public getApiFloorplans1(
    xVersion: string = "2",
  ): CancelablePromise<Array<FloorplanGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/floorplans",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Upload a floorplan (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param formData
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public postApiFloorplans(
    xVersion: string = "2",
    formData?: {
      /**
       * Optional ID to try to use in the database
       */
      Id?: string;
      /**
       * The byte array of the file to upload.
       * Check size limit with GET /api/settings/chunk-bytes?
       */
      Data: Blob;
      /**
       * Total number of bytes of this upload
       */
      ByteCount: number;
      /**
       * Name of the file, including extension
       */
      FileName: string;
      Title: string;
      Description?: string;
      /**
       * Direction of floorplan, expressed as degrees clockwise between "up" on the image and north.
       * If TopLeft and BottomRight are both provided, the heading will be derived and the given heading ignored.
       */
      Heading?: number;
      /**
       * Height of the floorplan, in metres either above sea level or above some other reference point;  to be used
       * if we ever want to render photo locations in 3D
       */
      Height?: number;
      "TopLeft.Longitude"?: number;
      "TopLeft.Latitude"?: number;
      "BottomRight.Longitude"?: number;
      "BottomRight.Latitude"?: number;
      /**
       * TODO: Not yet supported.
       * If provided (and valid), the rendered version of the floorplan image will be
       * cropped to this box.
       * Must be in provided in the format (top,left,width,height), in pixel units.
       * Must be positive values within the bounds of the image
       */
      CropBox?: Array<number>;
      /**
       * If provided, a human-readable name for this version.
       * If not, one will be generated based on revision number and date.
       */
      VersionName?: string;
      TeamId?: string;
      ProjectId?: string;
    },
  ): CancelablePromise<FloorplanGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/floorplans",
      headers: {
        "X-Version": xVersion,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Rollback a floorplan to an old version (Auth policies: AuthorisedApp, User)
   * @param floorplanId
   * @param versionName
   * @param xVersion The requested API version
   * @returns FloorplanGetDto Success
   * @throws ApiError
   */
  public patchApiFloorplans(
    floorplanId: string,
    versionName: string,
    xVersion: string = "2",
  ): CancelablePromise<FloorplanGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/floorplans/{floorplanId}/{versionName}",
      path: {
        floorplanId: floorplanId,
        versionName: versionName,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

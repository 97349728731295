enum ProjectPermissionFlagBits {
  Any = 0x7fffffff,

  // "Read" permissions - generally safer to grant to anyone
  /// <summary>View media in a project</summary>
  ViewProjectMedia = 0x1,
  /// <summary>View teams in a project</summary>
  ViewProjectTeams = 0x2,
  /// <summary>See who else is in this project</summary>
  ViewProjectUsers = 0x4,
  /// <summary>View project dashboard</summary>
  ViewProjectDashboard = 0x8,
  ///<summary>If this project is archived, you can still view it</summary>
  ViewArchived = 0x10,
  ///<summary>See sites in this project which have been archived
  SeeArchivedSites = 0x20,
  // UNUSED = 0x40,
  /// <summary>Can see the audit trail of activity on this project
  ViewAuditTrail = 0x80,

  // "Write" permissions - require more scrutiny
  /// <summary>Join project subteams</summary>
  JoinProjectTeams = 0x100,
  /// <summary>Create new teams in a project, or add existing teams to the project</summary>
  AddTeamToProject = 0x200,
  /// <summary>Invite new users to the project</summary>
  InviteUsersToProject = 0x400,
  /// <summary>Change the role of existing project members</summary>
  ChangeProjectUserRoles = 0x800,
  /// <summary>Change the definition of project roles</summary>
  EditProjectRoles = 0x1000,
  /// <summary>Add/update the list of project tags, including autotags</summary>
  ManageProjectTags = 0x2000,
  /// <summary>Edit project metadata, like name, description, etc</summary>
  ManageProjectFloorplans = 0x4000,
  EditProjectMetadata = 0x8000,
  RemoveTeamsFromProject = 0x10000,
  // UNUSED = 0x20000,
  // UNUSED = 0x40000,
  // UNUSED = 0x80000,
  // UNUSED = 0x100000,
  // UNUSED = 0x200000,
  // UNUSED = 0x400000,
  // UNUSED = 0x800000,
  // UNUSED = 0x1000000,
  // UNUSED = 0x2000000,
  // UNUSED = 0x4000000,
  // UNUSED = 0x8000000,
  /// <summary>Archive the project</summary>
  ArchiveProject = 0x10000000,
  // UNUSED = 0x20000000,
  // UNUSED = 0x40000000,
}

export default ProjectPermissionFlagBits;

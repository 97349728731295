/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AconexIntegrationPostDto } from "../models/AconexIntegrationPostDto";
import type { AconexProjectGetDto } from "../models/AconexProjectGetDto";
import type { AconexUserGetDto } from "../models/AconexUserGetDto";
import type { AuditGraphGetDto } from "../models/AuditGraphGetDto";
import type { AuditLogGetDto } from "../models/AuditLogGetDto";
import type { ConnectionGetDto } from "../models/ConnectionGetDto";
import type { DropboxIntegrationPostDto } from "../models/DropboxIntegrationPostDto";
import type { DropboxUserGetDto } from "../models/DropboxUserGetDto";
import type { FieldViewFormTemplateGetDto } from "../models/FieldViewFormTemplateGetDto";
import type { FieldViewIntegrationPostDto } from "../models/FieldViewIntegrationPostDto";
import type { FieldViewProjectInformationGetDto } from "../models/FieldViewProjectInformationGetDto";
import type { GoogleDriveFileGetDto } from "../models/GoogleDriveFileGetDto";
import type { GoogleDriveIntegrationPostDto } from "../models/GoogleDriveIntegrationPostDto";
import type { GoogleDriveUserGetDto } from "../models/GoogleDriveUserGetDto";
import type { IntegrationGetDto } from "../models/IntegrationGetDto";
import type { LogGrouping } from "../models/LogGrouping";
import type { MicroserviceIntegrationType } from "../models/MicroserviceIntegrationType";
import type { OneDriveIntegrationPostDto } from "../models/OneDriveIntegrationPostDto";
import type { OneDriveUserGetDto } from "../models/OneDriveUserGetDto";
import type { ProcoreCompanyGetDto } from "../models/ProcoreCompanyGetDto";
import type { ProcoreIntegrationPostDto } from "../models/ProcoreIntegrationPostDto";
import type { ProcoreProjectGetDto } from "../models/ProcoreProjectGetDto";
import type { ProcoreUserGetDto } from "../models/ProcoreUserGetDto";
import type { SafetyCultureIntegrationPostDto } from "../models/SafetyCultureIntegrationPostDto";
import type { ScStatus } from "../models/ScStatus";
import type { Status } from "../models/Status";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class ConnectService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete an integration I have access to (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public deleteApiConnectIntegrations(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/connect/integrations/{integrationId}",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get an integration I have access to (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public getApiConnectIntegrations(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/integrations/{integrationId}",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Disconnect me from all integrations (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns boolean Success
   * @throws ApiError
   */
  public deleteApiConnectConnections(
    xVersion: string = "2",
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/connect/connections",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all my connections (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns ConnectionGetDto Success
   * @throws ApiError
   */
  public getApiConnectConnections(
    xVersion: string = "2",
  ): CancelablePromise<Array<ConnectionGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/connections",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Disconnect me from a specific type (Auth policies: AuthorisedApp, User)
   * @param type
   * @param xVersion The requested API version
   * @returns boolean Success
   * @throws ApiError
   */
  public deleteApiConnectConnections1(
    type: MicroserviceIntegrationType,
    xVersion: string = "2",
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/connect/connections/{type}",
      path: {
        type: type,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all my connections of a specific type (Auth policies: AuthorisedApp, User)
   * @param type
   * @param xVersion The requested API version
   * @returns ConnectionGetDto Success
   * @throws ApiError
   */
  public getApiConnectConnections1(
    type: MicroserviceIntegrationType,
    xVersion: string = "2",
  ): CancelablePromise<Array<ConnectionGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/connections/{type}",
      path: {
        type: type,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Validate/authenticate your aconex token
   * @param userId BuiltView User Id
   * @param code Your OAUTH token for Google Drive
   * @param clientId
   * @param grantType
   * @param redirectUri
   * @param state
   * @param xVersion The requested API version
   * @returns AconexUserGetDto Success
   * @throws ApiError
   */
  public getApiConnectAconex(
    userId: string,
    code?: string,
    clientId?: string,
    grantType?: string,
    redirectUri?: string,
    state?: string,
    xVersion: string = "2",
  ): CancelablePromise<AconexUserGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/aconex/{userId}",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        code: code,
        client_id: clientId,
        grant_type: grantType,
        redirect_uri: redirectUri,
        state: state,
      },
    });
  }
  /**
   * Get a list of aconex projects for your authenticated user with the current company id (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns AconexProjectGetDto Success
   * @throws ApiError
   */
  public getApiConnectAconexProjects(
    xVersion: string = "2",
  ): CancelablePromise<Array<AconexProjectGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/aconex/projects",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all integrations (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public getApiConnect(
    xVersion: string = "2",
  ): CancelablePromise<Array<IntegrationGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Audit integrations - developers and tech support only. (Auth policies: AuthorisedApp, User)
   * @param from
   * @param to
   * @param groupBy
   * @param xVersion The requested API version
   * @returns AuditGraphGetDto Success
   * @throws ApiError
   */
  public getApiConnectAuditGraph(
    from?: string,
    to?: string,
    groupBy?: LogGrouping,
    xVersion: string = "2",
  ): CancelablePromise<Array<AuditGraphGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/audit/graph",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        from: from,
        to: to,
        groupBy: groupBy,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Audit integrations by ID - developers and tech support only. (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param from
   * @param to
   * @param groupBy
   * @param xVersion The requested API version
   * @returns AuditGraphGetDto Success
   * @throws ApiError
   */
  public getApiConnectAuditGraphIntegration(
    integrationId: string,
    from?: string,
    to?: string,
    groupBy?: LogGrouping,
    xVersion: string = "2",
  ): CancelablePromise<Array<AuditGraphGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/audit/graph/integration/{integrationId}",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        from: from,
        to: to,
        groupBy: groupBy,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Audit integrations by type - developers and tech support only. (Auth policies: AuthorisedApp, User)
   * @param integrationType
   * @param from
   * @param to
   * @param groupBy
   * @param xVersion The requested API version
   * @returns AuditGraphGetDto Success
   * @throws ApiError
   */
  public getApiConnectAuditGraphType(
    integrationType: MicroserviceIntegrationType,
    from?: string,
    to?: string,
    groupBy?: LogGrouping,
    xVersion: string = "2",
  ): CancelablePromise<Array<AuditGraphGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/audit/graph/type/{integrationType}",
      path: {
        integrationType: integrationType,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        from: from,
        to: to,
        groupBy: groupBy,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get audit log by ID - developers and tech support only. (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param from
   * @param to
   * @param xVersion The requested API version
   * @returns AuditLogGetDto Success
   * @throws ApiError
   */
  public getApiConnectAuditIntegration(
    integrationId: string,
    from?: string,
    to?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<AuditLogGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/audit/integration/{integrationId}",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        from: from,
        to: to,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get audit log by type - developers and tech support only. (Auth policies: AuthorisedApp, User)
   * @param integrationType
   * @param from
   * @param to
   * @param xVersion The requested API version
   * @returns AuditLogGetDto Success
   * @throws ApiError
   */
  public getApiConnectAuditType(
    integrationType: MicroserviceIntegrationType,
    from?: string,
    to?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<AuditLogGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/audit/type/{integrationType}",
      path: {
        integrationType: integrationType,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        from: from,
        to: to,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get all my integrations (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public getApiConnectIntegrations1(
    xVersion: string = "2",
  ): CancelablePromise<Array<IntegrationGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/integrations",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Validate/authenticate your dropbox token
   * @param userId BuiltView User Id
   * @param code Your OAUTH token for Dropbox
   * @param redirectUri Redirect URI used in the oauth call
   * @param xVersion The requested API version
   * @returns DropboxUserGetDto Success
   * @throws ApiError
   */
  public getApiConnectDropbox(
    userId: string,
    code?: string,
    redirectUri?: string,
    xVersion: string = "2",
  ): CancelablePromise<DropboxUserGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/dropbox/{userId}",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        code: code,
        redirect_uri: redirectUri,
      },
    });
  }
  /**
   * Validate the fieldview API token (Auth policies: AuthorisedApp)
   * @param apiToken Your API security token configured in Field View
   * @param xVersion The requested API version
   * @returns Status Success
   * @throws ApiError
   */
  public getApiConnectFieldview(
    apiToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<Status> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/fieldview",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        apiToken: apiToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new fieldview integration (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public postApiConnectFieldview(
    xVersion: string = "2",
    requestBody?: FieldViewIntegrationPostDto,
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/connect/fieldview",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Use an API token to get a list of project IDs.  FieldView API token required in the (Auth policies: AuthorisedApp)
   * @param apiToken Your API security token configured in Field View
   * @param xVersion The requested API version
   * @returns FieldViewProjectInformationGetDto Success
   * @throws ApiError
   */
  public getApiConnectFieldviewProjects(
    apiToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<FieldViewProjectInformationGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/fieldview/projects",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        apiToken: apiToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Use an API token and project ID to get a list of FormTemplateIds (Auth policies: AuthorisedApp)
   * @param projectId The unique ID of the project
   * @param apiToken Your API security token configured in Field View
   * @param xVersion The requested API version
   * @returns FieldViewFormTemplateGetDto Success
   * @throws ApiError
   */
  public getApiConnectProjectsFormTemplates(
    projectId: number,
    apiToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<FieldViewFormTemplateGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/projects/{projectId}/form-templates",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        apiToken: apiToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Validate/authenticate your google drive token (Auth policies: AuthorisedApp, User)
   * @param authToken Your OAUTH token for Google Drive
   * @param xVersion The requested API version
   * @returns GoogleDriveUserGetDto Success
   * @throws ApiError
   */
  public getApiConnectGoogleDrive(
    authToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<GoogleDriveUserGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/google-drive",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        authToken: authToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new Google Drive integration (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public postApiConnectGoogleDrive(
    xVersion: string = "2",
    requestBody?: GoogleDriveIntegrationPostDto,
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/connect/google-drive",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get your google drive files (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns GoogleDriveFileGetDto Success
   * @throws ApiError
   */
  public getApiConnectGoogleDriveFiles(
    xVersion: string = "2",
  ): CancelablePromise<Array<GoogleDriveFileGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/google-drive/files",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Validate/authenticate your onedrive token
   * @param userId BuiltView User Id
   * @param code Your OAUTH token for OneDrive
   * @param redirectUri Redirect URI used in the oauth call
   * @param xVersion The requested API version
   * @returns OneDriveUserGetDto Success
   * @throws ApiError
   */
  public getApiConnectMicrosoftOnedrive(
    userId: string,
    code?: string,
    redirectUri?: string,
    xVersion: string = "2",
  ): CancelablePromise<OneDriveUserGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/microsoft-onedrive/{userId}",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        code: code,
        redirect_uri: redirectUri,
      },
    });
  }
  /**
   * Validate/authenticate your procore token
   * @param userId BuiltView User Id
   * @param code Your OAUTH token for Google Drive
   * @param clientId
   * @param grantType
   * @param redirectUri
   * @param state
   * @param xVersion The requested API version
   * @returns ProcoreUserGetDto Success
   * @throws ApiError
   */
  public getApiConnectProcore(
    userId: string,
    code?: string,
    clientId?: string,
    grantType?: string,
    redirectUri?: string,
    state?: string,
    xVersion: string = "2",
  ): CancelablePromise<ProcoreUserGetDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/procore/{userId}",
      path: {
        userId: userId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        code: code,
        client_id: clientId,
        grant_type: grantType,
        redirect_uri: redirectUri,
        state: state,
      },
    });
  }
  /**
   * Get a list of procore companies for your authenticated user (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @returns ProcoreCompanyGetDto Success
   * @throws ApiError
   */
  public getApiConnectProcoreCompanies(
    xVersion: string = "2",
  ): CancelablePromise<Array<ProcoreCompanyGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/procore/companies",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get a list of procore projects for your authenticated user with the current company id (Auth policies: AuthorisedApp, User)
   * @param companyId Procore company id
   * @param xVersion The requested API version
   * @returns ProcoreProjectGetDto Success
   * @throws ApiError
   */
  public getApiConnectProcoreCompaniesProjects(
    companyId: number,
    xVersion: string = "2",
  ): CancelablePromise<Array<ProcoreProjectGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/procore/companies/{companyId}/projects",
      path: {
        companyId: companyId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Validate the safetyculture API token (Auth policies: AuthorisedApp)
   * @param apiToken Your API security token configured in Safety Culture
   * @param xVersion The requested API version
   * @returns ScStatus Success
   * @throws ApiError
   */
  public getApiConnectSafetyculture(
    apiToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<ScStatus> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/connect/safetyculture",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        apiToken: apiToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Sync an integration I have access to (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public patchApiConnectIntegrationsSync(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/connect/integrations/{integrationId}/sync",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Pause an integration I have access to (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public patchApiConnectIntegrationsPause(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/connect/integrations/{integrationId}/pause",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Resume an integration I have access to (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public patchApiConnectIntegrationsResume(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/connect/integrations/{integrationId}/resume",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new aconex integration (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public postApiConnectAconex(
    xVersion: string = "2",
    requestBody?: AconexIntegrationPostDto,
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/connect/aconex",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new Dropbox integration (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public postApiConnectDropbox(
    xVersion: string = "2",
    requestBody?: DropboxIntegrationPostDto,
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/connect/dropbox",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new onedrive integration (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public postApiConnectMicrosoftOnedriveIntegrations(
    xVersion: string = "2",
    requestBody?: OneDriveIntegrationPostDto,
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/connect/microsoft-onedrive/integrations",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new procore integration (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public postApiConnectProcore(
    xVersion: string = "2",
    requestBody?: ProcoreIntegrationPostDto,
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/connect/procore",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Create a new safetyculture integration (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns IntegrationGetDto Success
   * @throws ApiError
   */
  public postApiConnectSafetyCulture(
    xVersion: string = "2",
    requestBody?: SafetyCultureIntegrationPostDto,
  ): CancelablePromise<IntegrationGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/connect/safety-culture",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}

import type { AccountsGetDto } from "@/api/generated";
import SentryConcrete from "@/utils/sentry.concrete";

const shouldUseSentry = (): boolean => import.meta.env.PROD;

const init = () => {
  if (!shouldUseSentry()) return;
  SentryConcrete.init();
};

const identify = (user: AccountsGetDto) => {
  if (!shouldUseSentry()) return;
  const name =
    user.givenName && user.familyName
      ? `${user.givenName} ${user.familyName}`
      : undefined;
  SentryConcrete.setUser(user.id, user.email, name);
};

const logout = () => {
  if (!shouldUseSentry()) return;
  SentryConcrete.clearUser();
};

const captureException = (e: Error | string) => {
  if (!shouldUseSentry()) return;
  SentryConcrete.captureException(e);
};

const showReportDialog = () => {
  if (!shouldUseSentry()) return;
  SentryConcrete.showReportDialog();
};

const Sentry = {
  init,
  identify,
  logout,
  showReportDialog,
  captureException,
};

export default Sentry;

import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import mediaApi from "@/data/api/media";
import queryClient from "@/queryClient";
import { authenticateOrMediaCode } from "@/utils/authenticator";

const ParamsSchema = z.object({
  mediaId: z.string(),
  sharecode: z.string().optional(),
  siteId: z.string().optional(),
});

export default async function mediaItemLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const parsedParams = await ParamsSchema.parseAsync(params);
  const { mediaId } = parsedParams;
  const sharecode = await authenticateOrMediaCode(request, parsedParams);

  queryClient.prefetchQuery(mediaApi.getMedia(mediaId, sharecode));
  await queryClient.prefetchQuery(mediaApi.getMediaLinks(mediaId, sharecode));
  return parsedParams;
}

export type MediaItemLoaderReturnType = Awaited<
  ReturnType<typeof mediaItemLoader>
>;

import { useToast } from "@/components/Snackbar/useToast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import projectQueryApi, { projectKeys } from "@/data/api/project";

export default function useInviteProjectMember() {
  const queryClient = useQueryClient();
  const alert = useToast();

  return useMutation({
    ...projectQueryApi.inviteProjectMembers,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.members(variables.projectId),
      });

      alert.success("Members invited successfully");
    },
  });
}

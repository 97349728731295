/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FieldViewFormTemplateGetDto } from "../models/FieldViewFormTemplateGetDto";
import type { FieldViewIntegrationGetDto } from "../models/FieldViewIntegrationGetDto";
import type { FieldViewIntegrationPatchDto } from "../models/FieldViewIntegrationPatchDto";
import type { FieldViewIntegrationPostDto } from "../models/FieldViewIntegrationPostDto";
import type { FieldViewProjectInformationGetDto } from "../models/FieldViewProjectInformationGetDto";
import type { FieldViewSyncResultDto } from "../models/FieldViewSyncResultDto";
import type { FieldViewTaskTypeGetDto } from "../models/FieldViewTaskTypeGetDto";
import type { Status } from "../models/Status";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class FieldViewService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Delete (soft) a single integration I have access to (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns FieldViewIntegrationGetDto Success
   * @throws ApiError
   */
  public deleteApiIntegrationsFieldviewIntegrations(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<FieldViewIntegrationGetDto> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/api/integrations/fieldview/integrations/{integrationId}",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Update the integration - only API token for now (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @param requestBody
   * @returns FieldViewIntegrationGetDto Success
   * @throws ApiError
   */
  public patchApiIntegrationsFieldviewIntegrations(
    integrationId: string,
    xVersion: string = "2",
    requestBody?: FieldViewIntegrationPatchDto,
  ): CancelablePromise<FieldViewIntegrationGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/integrations/fieldview/integrations/{integrationId}",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Validate the fieldview API token (Auth policies: AuthorisedApp)
   * @param apiToken Your API security token configured in Field View
   * @param xVersion The requested API version
   * @returns Status Success
   * @throws ApiError
   */
  public getApiIntegrationsFieldview(
    apiToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<Status> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/fieldview",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        apiToken: apiToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Use an API token to get a list of project IDs.  FieldView API token required in the (Auth policies: AuthorisedApp)
   * @param apiToken Your API security token configured in Field View
   * @param xVersion The requested API version
   * @returns FieldViewProjectInformationGetDto Success
   * @throws ApiError
   */
  public getApiIntegrationsFieldviewProjects(
    apiToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<FieldViewProjectInformationGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/fieldview/projects",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        apiToken: apiToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Use an API token and project ID to get a list of FormTemplateIds (Auth policies: AuthorisedApp)
   * @param projectId The unique ID of the project
   * @param apiToken Your API security token configured in Field View
   * @param xVersion The requested API version
   * @returns FieldViewFormTemplateGetDto Success
   * @throws ApiError
   */
  public getApiIntegrationsFieldviewProjectsFormTemplates(
    projectId: number,
    apiToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<FieldViewFormTemplateGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/fieldview/projects/{projectId}/form-templates",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        apiToken: apiToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Use an API token and project ID to get a list of TaskTypes (Auth policies: AuthorisedApp)
   * @param projectId The unique ID of the project
   * @param apiToken Your API security token configured in Field View
   * @param xVersion The requested API version
   * @returns FieldViewTaskTypeGetDto Success
   * @throws ApiError
   */
  public getApiIntegrationsFieldviewProjectsTaskTypes(
    projectId: number,
    apiToken?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<FieldViewTaskTypeGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/fieldview/projects/{projectId}/task-types",
      path: {
        projectId: projectId,
      },
      headers: {
        "X-Version": xVersion,
      },
      query: {
        apiToken: apiToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get a list of fieldview integrations that I have access to (Auth policies: AuthorisedApp)
   * @param xVersion The requested API version
   * @returns FieldViewIntegrationGetDto Success
   * @throws ApiError
   */
  public getApiIntegrationsFieldviewIntegrations(
    xVersion: string = "2",
  ): CancelablePromise<Array<FieldViewIntegrationGetDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/fieldview/integrations",
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create a new fieldview integration (Auth policies: AuthorisedApp, User)
   * @param xVersion The requested API version
   * @param requestBody
   * @returns FieldViewIntegrationGetDto Success
   * @throws ApiError
   */
  public postApiIntegrationsFieldviewIntegrations(
    xVersion: string = "2",
    requestBody?: FieldViewIntegrationPostDto,
  ): CancelablePromise<FieldViewIntegrationGetDto> {
    return this.httpRequest.request({
      method: "POST",
      url: "/api/integrations/fieldview/integrations",
      headers: {
        "X-Version": xVersion,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Sync a single integration now
   * @param integrationId
   * @param xVersion The requested API version
   * @returns FieldViewSyncResultDto Success
   * @throws ApiError
   */
  public getApiIntegrationsFieldviewIntegrationsSync(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<FieldViewSyncResultDto> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/fieldview/integrations/{integrationId}/sync",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Sync all fieldview integrations
   * Note - this won't work, since the projects api has a quota of 1.
   * So, I need to (a) fetch projects and then pass that down to each of the things (though this has other problems),
   * or (b) figure out which one was least latest updated, and update just that one;  and then next time this is called, do it again.
   * Then the function can call it once a minute or 5 minutes or whatever to keep things synced.
   * @param integrationId
   * @param xVersion The requested API version
   * @returns FieldViewSyncResultDto Success
   * @throws ApiError
   */
  public getApiIntegrationsFieldviewIntegrationsSync1(
    integrationId?: string,
    xVersion: string = "2",
  ): CancelablePromise<Array<FieldViewSyncResultDto>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/api/integrations/fieldview/integrations/sync",
      headers: {
        "X-Version": xVersion,
      },
      query: {
        integrationId: integrationId,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Refresh a single integration I have access to (i.e. do a photo sync immediately) (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns FieldViewIntegrationGetDto Success
   * @throws ApiError
   */
  public patchApiIntegrationsFieldviewIntegrationsRefresh(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<FieldViewIntegrationGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/integrations/fieldview/integrations/{integrationId}/refresh",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Pause a single integration I have access to (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns FieldViewIntegrationGetDto Success
   * @throws ApiError
   */
  public patchApiIntegrationsFieldviewIntegrationsPause(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<FieldViewIntegrationGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/integrations/fieldview/integrations/{integrationId}/pause",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Resume a single paused integration I have access to (Auth policies: AuthorisedApp, User)
   * @param integrationId
   * @param xVersion The requested API version
   * @returns FieldViewIntegrationGetDto Success
   * @throws ApiError
   */
  public patchApiIntegrationsFieldviewIntegrationsResume(
    integrationId: string,
    xVersion: string = "2",
  ): CancelablePromise<FieldViewIntegrationGetDto> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/api/integrations/fieldview/integrations/{integrationId}/resume",
      path: {
        integrationId: integrationId,
      },
      headers: {
        "X-Version": xVersion,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}

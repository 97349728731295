import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";
import type {
  AccessRolePatchDto,
  AccessRolePostDto,
  ArchiveProjectPatchDto,
  MediaItemGraphRecordGetDto,
  ProjectInvitesDto,
  ProjectUsersPatchDto,
  ProjectsPatchDto,
  ProjectsPostDto,
} from "@/api/generated";

import { cancelableQuery, mutationOptions } from "./helpers";

interface ArchiveProjectData extends ArchiveProjectPatchDto {
  projectId: string;
}

export const projectKeys = {
  root: "project" as const,
  permissions: () => [projectKeys.root, "permissions" as const] as const,
  list: () => [projectKeys.root, "list" as const] as const,
  listArchived: () => [...projectKeys.list(), "archived" as const] as const,
  project: (id: string) => [projectKeys.root, id] as const,
  details: (id: string) =>
    [...projectKeys.project(id), "details" as const] as const,
  members: (id: string) =>
    [...projectKeys.project(id), "members" as const] as const,
  teams: (id: string) =>
    [...projectKeys.project(id), "teams" as const] as const,
  displayTeams: (id: string) =>
    [...projectKeys.teams(id), "display" as const] as const,
  display: (id: string) =>
    [...projectKeys.project(id), "display" as const] as const,

  invitations: (id: string) =>
    [...projectKeys.project(id), "invitations" as const] as const,
  graph: (
    id: string,
    dateString?: string | undefined,
    dateStringEnd?: string | undefined,
    granularity?: string
  ) =>
    [
      ...projectKeys.project(id),
      "graph" as const,
      {
        dateString,
        dateStringEnd,
        granularity,
      },
    ] as const,
  activity: (
    id: string,
    type: "users" | "teams" | "tags",
    dateString?: string | undefined,
    dateStringEnd?: string | undefined
  ) => [...projectKeys.graph(id, dateString, dateStringEnd), type] as const,
  stats: (id: string) =>
    [...projectKeys.project(id), "stats" as const] as const,
  roles: (id: string) =>
    [...projectKeys.project(id), "roles" as const] as const,
  role: (id: string, roleId: string) =>
    [...projectKeys.roles(id), roleId] as const,
};

interface EditProjectRole extends AccessRolePatchDto {
  projectId: string;
  roleId: string;
}

interface CreateProjectRole extends AccessRolePostDto {
  projectId: string;
}

interface GraphPayload {
  dateString?: string | undefined;
  dateStringEnd?: string | undefined;
  granularity?: "year" | "month" | "week" | "day";
}

interface ActivityPayload {
  dateString?: string | undefined;
  dateStringEnd?: string | undefined;
}

const projectQueryApi = {
  deleteProject: mutationOptions({
    mutationFn: (projectId: string) =>
      api.projects.deleteApiProjects(projectId),
  }),

  getProject: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjects(projectId), signal),
      queryKey: projectKeys.details(projectId),
    }),

  editProject: mutationOptions({
    mutationFn: ({
      projectId,
      ...data
    }: ProjectsPatchDto & {
      projectId: string;
    }) => api.projects.patchApiProjects(projectId, undefined, data),
  }),

  removeProjectMember: mutationOptions({
    mutationFn: (data: { projectId: string; userId: string }) =>
      api.projects.deleteApiProjectsMembers(data.projectId, data.userId),
  }),

  editProjectMember: mutationOptions({
    mutationFn: ({
      userId,
      projectId,
      ...data
    }: ProjectUsersPatchDto & {
      projectId: string;
      userId: string;
    }) =>
      api.projects.patchApiProjectsMembers(projectId, userId, undefined, data),
  }),

  createProject: mutationOptions({
    mutationFn: (data: ProjectsPostDto) =>
      api.projects.postApiProjects(undefined, data),
  }),

  getProjectTeams: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjectsTeams(projectId), signal),
      queryKey: projectKeys.teams(projectId),
    }),

  getProjectMembers: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjectsMembers(projectId), signal),
      queryKey: projectKeys.members(projectId),
    }),

  getProjectInvitations: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjectsInvites(projectId), signal),
      queryKey: projectKeys.invitations(projectId),
    }),

  revokeProjectInvitations: mutationOptions({
    mutationFn: ({
      projectId,
      emails,
    }: {
      projectId: string;
      emails: string[];
    }) => api.projects.deleteApiProjectsInvites(projectId, emails),
  }),

  getProjectGraph: (projectId: string, data: GraphPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.projects.getApiProjectsGraph(
            projectId,
            data.dateString,
            data.dateStringEnd,
            data.granularity
          ),
          signal
        ),
      queryKey: projectKeys.graph(
        projectId,
        data.dateString,
        data.dateStringEnd,
        data.granularity
      ),
      select: (data) =>
        (data as MediaItemGraphRecordGetDto[]).filter(
          (value) => new Date(value.date).getFullYear() > 1950
        ),
    }),

  getProjectTagActivity: (projectId: string, data: ActivityPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.projects.getApiProjectsActivityTags(
            projectId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: projectKeys.activity(
        projectId,
        "tags",
        data.dateString,
        data.dateStringEnd
      ),
      select: (data) =>
        data.filter(
          (value) =>
            value.latestDate && new Date(value.latestDate).getFullYear() > 1950
        ),
    }),

  getProjectUserActivity: (projectId: string, data: ActivityPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.projects.getApiProjectsActivityUsers(
            projectId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: projectKeys.activity(
        projectId,
        "users",
        data.dateString,
        data.dateStringEnd
      ),
      select: (data) =>
        data.filter(
          (value) =>
            value.latestDate && new Date(value.latestDate).getFullYear() > 1950
        ),
    }),
  getProjectTeamActivity: (projectId: string, data: ActivityPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.projects.getApiProjectsActivityTeams(
            projectId,
            undefined,
            undefined,
            data?.dateString,
            data?.dateStringEnd
          ),
          signal
        ),
      queryKey: projectKeys.activity(
        projectId,
        "teams",
        data.dateString,
        data.dateStringEnd
      ),
      select: (data) =>
        data.filter(
          (value) =>
            value.latestDate && new Date(value.latestDate).getFullYear() > 1950
        ),
    }),

  getProjectDisplayTeams: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.projects.getApiProjectsTeamsDisplay(projectId),
          signal
        ),
      queryKey: projectKeys.displayTeams(projectId),
    }),

  getProjectDisplay: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjectsDisplay(projectId), signal),
      queryKey: projectKeys.display(projectId),
      staleTime: 1000 * 60 * 60,
    }),

  getProjectStats: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjectsStats(projectId), signal),
      queryKey: projectKeys.stats(projectId),
    }),

  getProjects: () =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjects1(), signal),
      queryKey: projectKeys.list(),
    }),

  getArchivedProjects: () =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjectsArchived(), signal),
      queryKey: projectKeys.listArchived(),
    }),

  inviteProjectMembers: mutationOptions({
    mutationFn: ({
      projectId,
      ...data
    }: { projectId: string } & ProjectInvitesDto) =>
      api.projects.postApiProjectsMembers(projectId, undefined, data),
  }),

  addTeamToProject: mutationOptions({
    mutationFn: ({
      projectId,
      teamId,
    }: {
      projectId: string;
      teamId: string;
    }) => api.projects.postApiProjectsTeams(projectId, teamId),
  }),

  getProjectRoles: (projectId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(api.projects.getApiProjectsRoles1(projectId), signal),
      queryKey: projectKeys.roles(projectId),
    }),

  getProjectRole: (projectId: string, roleId: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.projects.getApiProjectsRoles(projectId, roleId),
          signal
        ),
      queryKey: projectKeys.role(projectId, roleId),
    }),

  editProjectRole: mutationOptions({
    mutationFn: ({ projectId, roleId, ...data }: EditProjectRole) =>
      api.projects.patchApiProjectsRoles(projectId, roleId, undefined, data),
  }),

  deleteProjectRole: mutationOptions({
    mutationFn: ({
      projectId,
      roleId,
    }: {
      projectId: string;
      roleId: string;
    }) => api.projects.deleteApiProjectsRoles(projectId, roleId),
  }),

  createProjectRole: mutationOptions({
    mutationFn: ({ projectId, ...data }: CreateProjectRole) =>
      api.projects.postApiProjectsRoles(projectId, undefined, data),
  }),

  getProjectPermissions: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.projects.getApiProjectsPermissions(), signal),
    queryKey: projectKeys.permissions(),
  }),

  archiveProject: mutationOptions({
    mutationFn: ({ projectId, ...data }: ArchiveProjectData) =>
      api.projects.patchApiProjectsArchive(projectId, undefined, data),
  }),
};

export default projectQueryApi;

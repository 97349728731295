import type { LoaderFunctionArgs } from "react-router-dom";

import { authClient } from "../../authClient";

export default async function loginCallbackLoader({
  request,
}: LoaderFunctionArgs) {
  // const account = await authenticate(request);
  authClient.handleCallback(request.url);
  return null;
}

import { useMemo } from "react";

interface UsePermissionProps {
  /**
   * The permission from the site, media, organization, or project.
   * If undefined or null, then the permission is assumed to be 0x7fffffff.
   * This means that the permission is allowed in any parent permission.
   */
  parentPermission?: number;
  /**
   * The permission to check if allowed in the parent permission.
   */
  requiredPermission: number;
}

/**
 * Check if a permission is allowed in a parent permission.
 * @param props The parent permission and the permission to check.
 * @returns Whether the permission is allowed in the parent permission.
 */
export default function usePermission(props: UsePermissionProps) {
  const { parentPermission = 0x7fffffff, requiredPermission } = props;

  const hasPermission = useMemo(
    () => !!(parentPermission & requiredPermission),

    [parentPermission, requiredPermission],
  );

  return hasPermission;
}

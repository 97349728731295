import { authClient } from "@/features/auth/authClient";

import { BuiltView360Client } from "./generated";

export const getAccessHeaders = async (
  headers?: Record<string, string>,
): Promise<Record<string, string>> => {
  try {
    // const headerKeys = Object.keys(headers ?? {}).map((hk) => hk.toLowerCase());

    const hasAuth = await authClient.hasValidAuth(); // Check if the user has auth
    // const hasMediaCode = headerKeys.includes("x-media-code"); // Check if the user has a media code
    // If the user has an account then try and use it
    // But if they have an x-media-code header but no auth then don't worry about the token, just pass through the headers
    if (!hasAuth) {
      return headers ?? {};
    }
    const token = await authClient.getToken(); // Get the token
    if (token) {
      return {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return headers ?? {};
  } catch (e) {
    console.log("error in api client auth", e);
    throw e;
  }
};

const api = new BuiltView360Client({
  BASE: import.meta.env.VITE_BACKEND_URL,
  HEADERS: async ({ headers }) => getAccessHeaders(headers),
});

export default api;

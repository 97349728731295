import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";

import { cancelableQuery } from "./helpers";
type GraphPayload = {
  dateString?: string | undefined;
  dateStringEnd?: string | undefined;
  granularity?: "year" | "month" | "week" | "day";
};

export const userKeys = {
  root: "users" as const,
  list: () => [userKeys.root, "list" as const] as const,
  user: (id: string, sharecode?: string) =>
    sharecode
      ? ([userKeys.root, id, sharecode] as const)
      : ([userKeys.root, id] as const),
  teams: (id: string, sharecode?: string) =>
    sharecode
      ? ([...userKeys.user(id), sharecode, "teams"] as const)
      : ([...userKeys.user(id), "teams"] as const),
  projects: (id: string, sharecode?: string) =>
    sharecode
      ? ([...userKeys.user(id), sharecode, "projects"] as const)
      : ([...userKeys.user(id), "projects"] as const),
  details: (id: string, sharecode?: string) =>
    [...userKeys.user(id, sharecode), "details"] as const,
  graph: (
    id: string,
    dateString?: string | undefined,
    dateStringEnd?: string | undefined,
    granularity?: string,
  ) =>
    [
      ...userKeys.user(id),
      "graph" as const,
      {
        dateString,
        dateStringEnd,
        granularity,
      },
    ] as const,
};

const userQueryApi = {
  getAllUsers: queryOptions({
    queryFn: ({ signal }) => cancelableQuery(api.users.getApiUsers(), signal),
    queryKey: userKeys.list(),
  }),
  getUser: (userId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.users.getApiUsers1(userId, undefined, sharecode),
          signal,
        ),
      queryKey: userKeys.user(userId, sharecode),
    }),
  getUserTeams: (userId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.users.getApiUsersTeams(userId, undefined, sharecode),
          signal,
        ),
      queryKey: userKeys.teams(userId, sharecode),
    }),
  getUserProjects: (userId: string, sharecode?: string) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.users.getApiUsersProjects(userId, undefined, sharecode),
          signal,
        ),
      queryKey: userKeys.projects(userId, sharecode),
    }),
  getUserMediaGraph: (userId: string, data: GraphPayload) =>
    queryOptions({
      queryFn: ({ signal }) =>
        cancelableQuery(
          api.users.getApiUsersGraph(
            userId,
            data?.dateString,
            data?.dateStringEnd,
            data?.granularity,
          ),
          signal,
        ),
      queryKey: userKeys.graph(
        userId,
        data.dateString,
        data.dateStringEnd,
        data.granularity,
      ),
    }),
};

export default userQueryApi;

import { useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import api from "@/api";
import {
  type AccountInfoDto,
  type MediaItemsFullGetDto,
  type MediaLinkGetDto,
  MediaType,
  PhotoSizeType,
} from "@/api/generated";
import { useTrack } from "@/context/Tracking";
import accountApi from "@/data/api/accounts";
import useIsAuthenticated from "@/features/auth/hooks/useIsAuthenticated";
import logger from "@/utils/logger";
import type { ReportItem } from "@/utils/reporting/types";

import {
  convertBase64ToBlob,
  type ExportData,
  exportIsActive,
  type ExportItem,
  generateName,
  getSelectedLinkForExport,
  getThumbnailForExport,
  toBase64,
  toBlob,
  urlToArrayBuffer,
} from "./utilities";

// 5GB in MB; chosen basically at random
const LOCAL_DOWNLOAD_LIMIT = 5 * 1024; //* 1024 * 1024;

const addExifToBlob = async (
  media: MediaItemsFullGetDto,
  blob: Blob
): Promise<Blob> => {
  // console.log("Adding exif to blob");
  // const blob = await fetch(dataUrl).then((res) => res.blob());
  const b64 = await toBase64(blob);
  let updatedBlob = blob;
  const piexif = await import("piexifjs");
  try {
    const data = piexif.load(b64);
    let updated = false;
    if (!data.Exif[piexif.ExifIFD.DateTimeOriginal]) {
      data.Exif[piexif.ExifIFD.DateTimeOriginal] = DateTime.fromJSDate(
        new Date(media.timestamp)
      ).toFormat("yyyy:MM:dd HH:mm:ss"); // @todo format
      updated = true;
    }
    if (
      !data.GPS[piexif.GPSIFD.GPSLatitude] ||
      !data.GPS[piexif.GPSIFD.GPSLongitude]
    ) {
      const lat = media.latitude;
      const lng = media.longitude;
      if (lat && lng) {
        data.GPS[piexif.GPSIFD.GPSLatitudeRef] = lat < 0 ? "S" : "N";
        data.GPS[piexif.GPSIFD.GPSLatitude] =
          piexif.GPSHelper.degToDmsRational(lat);
        data.GPS[piexif.GPSIFD.GPSLongitudeRef] = lng < 0 ? "W" : "E";
        data.GPS[piexif.GPSIFD.GPSLongitude] =
          piexif.GPSHelper.degToDmsRational(lng);
        updated = true;
      }
    }
    if (updated) {
      const newB64 = piexif.insert(piexif.dump(data), b64);
      updatedBlob = await convertBase64ToBlob(newB64);
    }
  } catch (e) {
    console.log({ e });
  }
  return updatedBlob;
};

const preventNavigating = (e: BeforeUnloadEvent) => {
  // Cancel the event as stated by the standard.
  e.preventDefault();
  // Chrome requires returnValue to be set.
  e.returnValue = "";
};

export interface IExportManager {
  /** Given an export definition, create the appropriate file, download it, and return the ID of the export. */
  createExport: (data: ExportData) => number;
  /** Remove a single upload by ID.  @todo cancel if in progress. */
  clearSingle: (id: number) => void;
  /** Cancel an in-progress export */
  cancel: (id: ExportItem["id"]) => void;
  /** Remove uploads that are successful or failed */
  clear: () => void;
}

export const ExportContext = createContext<IExportManager>({
  createExport: () => {
    throw Error("ExportContext has no Provider!");
  },
  cancel: () => {
    throw Error("ExportContext has no Provider!");
  },
  clearSingle: () => {
    throw Error("ExportContext has no provider");
  },
  clear: () => {
    throw Error("ExportContext has no Provider!");
  },
});

export const ExportProgressContext = createContext<ExportItem[]>([]);

interface ExportContextWrapperProps {
  children: JSX.Element | JSX.Element[];
}

const getNewUIDGenerator = (): (() => number) => {
  let lastId = -1;
  return () => {
    lastId += 1;
    return lastId;
  };
};

const to_suffix = (x: number): string => (x <= 1 ? "" : `_${x}`);

export function ExportProvider({
  children,
}: ExportContextWrapperProps): JSX.Element {
  const [items, setItems] = useState<ExportItem[]>([]);

  const generateUID = useMemo(getNewUIDGenerator, []);

  const isAuthenticated = useIsAuthenticated();
  const { data: userMe } = useQuery({
    ...accountApi.getMe,
    enabled: isAuthenticated,
  });

  const updateItemFactory =
    (id: number) => (updatedData: Partial<ExportItem>) =>
      setItems((oldItems) =>
        oldItems
          .map((f) => (f.id === id ? { ...f, ...updatedData } : f))
          .sort((a, b) => b.id - a.id)
      );

  const track = useTrack();

  const download = useCallback(
    async (
      id: number,
      data: ExportData,
      updateFile: (updatedData: Partial<ExportItem>) => void
    ) => {
      try {
        let loaded = 0;
        const sharecode = data.options.sharecode;
        /** The total number of "operations" to complete */
        const total = data.mediaIds.length * (data.format === "zip" ? 5 : 5);
        const cts = new AbortController();
        setItems((oldItems) =>
          oldItems.concat([
            {
              id,
              name: data.options.filename,
              type: data.type,
              total,
              loaded: 0,
              cancelTokenSource: cts,
              status: "pending",
            },
          ])
        );

        /** @todo make this fetch parallel? */
        // For each media item, fetch its links and info.
        const mediaList: {
          media: MediaItemsFullGetDto;
          links: MediaLinkGetDto[];
          user: AccountInfoDto;
          floorplan?: ReportItem["floorplan"];
          sharecode?: string;
        }[] = [];
        for (let i = 0; i < data.mediaIds.length; i++) {
          cts.signal.addEventListener("abort", () => {
            throw new Error("Cancelled");
          });

          const mediaId = data.mediaIds[i];

          if (!mediaId) continue;

          const media = await api.mediaItems.getApiMedia(
            mediaId,
            undefined,
            sharecode
          );
          updateFile({ id, loaded: loaded++, status: "in progress" });
          const links = await api.mediaItems.getApiMediaLinks(
            mediaId,
            undefined,
            undefined,
            sharecode
          );
          updateFile({ id, loaded: loaded++ });
          const user = await api.users.getApiUsers1(
            media.userId,
            undefined,
            sharecode
          );
          updateFile({ id, loaded: loaded++ });

          let floorplan: ReportItem["floorplan"] | undefined = undefined;
          console.log(
            "Floorplan?",
            media.floorplanId,
            media.floorplanX,
            media.floorplanY
          );
          if (media.floorplanId && !!media.floorplanX && !!media.floorplanY) {
            try {
              const fp = await api.floorplans.getApiFloorplans(
                media.floorplanId
              );
              floorplan = {
                fp,
                location: { x: media.floorplanX, y: media.floorplanY },
              };
            } catch (e) {}
          }
          // var sharecode =
          //   data.format === "zip"
          //     ? undefined
          //     : (await createCode(mediaId).unwrap())?.code;
          updateFile({ id, loaded: loaded++ });

          mediaList.push({ media, links, sharecode, user, floorplan });
        }

        // Prepare the data the exporter needs
        if (data.format === "zip") {
          if (mediaList.length === 1) {
            const mediaListItem = mediaList[0];

            if (!mediaListItem) return;
            // Download just one item
            const { media, user, links } = mediaListItem;
            // const a = document.createElement("a");
            let b = await toBlob(
              getSelectedLinkForExport(links, data.options.imageQuality)
            );
            if (b) {
              if (media.type === MediaType.PHOTO) {
                b = await addExifToBlob(media, b);
              }
              const s = URL.createObjectURL(b);
              // a.href = s;
              const ext =
                media.type === MediaType.PHOTO
                  ? ".jpg"
                  : ".mp4"; /** @todo actually check the file type */
              // a.download = `${generateName(media, user, data.options.downloadSettings)}${ext}`;
              // document.body.appendChild(a);
              // a.click();
              // document.body.removeChild(a);
              saveAs(
                s,
                `${generateName(
                  media,
                  user,
                  data.options.downloadSettings
                )}${ext}`
              );
              track({
                name: "Download",
                data: {
                  "Download resolution": data.options.imageQuality,
                  from: "Gallery",
                  inPdf: false,
                  inPpt: false,
                  inZip: false,
                  isMine: media.userId === userMe?.id,
                },
                mediaItemId: media.id,
                siteId: data.options.teamId,
              });

              updateFile({ id, loaded: total, status: "success" });
            } else throw new Error("Failed to download media from server");
          } else {
            // Check if I have too many bytes for a local download
            const res = await api.mediaItems.getApiMediaZipCheckSize(
              mediaList.map((m) => m.media.id)
            );

            if (res.sizeOfExport < LOCAL_DOWNLOAD_LIMIT) {
              // Download all items as a zip
              const JSZip = (await import("jszip")).default;
              const zip = new JSZip();

              // track used names to make sure there are no duplicates.
              const names: string[] = [];

              for (let i = 0; i < mediaList.length; i++) {
                cts.signal.addEventListener("abort", () => {
                  throw new Error("Cancelled");
                });
                const mediaListItem = mediaList[i];
                if (!mediaListItem) continue;
                const { media, user, links } = mediaListItem;
                const buf = await urlToArrayBuffer(
                  getSelectedLinkForExport(links, data.options.imageQuality)
                );
                const name = generateName(
                  media,
                  user,
                  data.options.downloadSettings
                );
                console.log(
                  `Zipping as ${
                    name + to_suffix(names.filter((n) => n === name).length + 1)
                  }.${
                    media.type === MediaType.PHOTO
                      ? "jpg"
                      : "mp4" /** @todo do this properly */
                  }`
                );
                track({
                  name: "Download",
                  data: {
                    "Download resolution": data.options.imageQuality,
                    from: "Gallery",
                    inPdf: false,
                    inPpt: false,
                    inZip: true,
                    isMine: media.userId === userMe?.id,
                  },
                  mediaItemId: media.id,
                  siteId: data.options.teamId,
                });

                zip.file(
                  `${
                    name + to_suffix(names.filter((n) => n === name).length + 1)
                  }.${
                    media.type === MediaType.PHOTO
                      ? "jpg"
                      : "mp4" /** @todo do this properly */
                  }`,
                  buf,
                  { binary: true }
                );
                names.push(name);
                updateFile({ id, loaded: loaded++, status: "success" });
              }
              const content = await zip.generateAsync({ type: "blob" });
              saveAs(content, data.options.filename);
            } else {
              let size: PhotoSizeType = PhotoSizeType.DISPLAY;
              switch (data.options.imageQuality) {
                case "display":
                  size = PhotoSizeType.DISPLAY;
                  break;
                case "original":
                  size = PhotoSizeType.ORIGINAL;
                  break;
                case "nano":
                  size = PhotoSizeType.NANO;
                  break;
              }
              await api.mediaItems.postApiMediaZip(undefined, {
                mediaItemIds: mediaList.map((m) => m.media.id),
                settings: {
                  filename: data.options.filename,
                  imageQuality: size,
                  options: data.options.downloadSettings,
                },
              });
              window.alert(
                "Your download is being prepared.  An email will be sent to your account when the file is ready to download."
              );
              updateFile({
                id,
                loaded: loaded + mediaList.length,
                status: "success",
              });
            }
            try {
              track({
                name: "Export media",
                data: {
                  fileName: data.options.filename,
                  numLandscape: -1, //Non-trivial
                  numPortrait: -1, //Non-trivial
                  numMedia: mediaList.length,
                  numPhotos: mediaList.map(
                    (m) => m.media.type === MediaType.PHOTO
                  ).length,
                  numVideos: mediaList.map(
                    (m) => m.media.type === MediaType.VIDEO
                  ).length,
                  numWithDescription: mediaList.map(
                    (m) => !!m.media.description
                  ).length,
                  numWithLocation: mediaList.map(
                    (m) => m.media.latitude && m.media.longitude
                  ).length,
                  numWithMarkup: mediaList.map((m) => m.media.isMarkedUp)
                    .length,
                  numWithTags: mediaList.map(
                    (m) => (m.media.tags ?? []).length > 0
                  ).length,
                  type: data.format,
                },
                siteId: data.options.teamId,
              });
            } catch {
              logger.warn("Failed to track export", "Export provider");
            }
          }
        } else if (data.format === "csv") {
          const ids = mediaList.map((m) => m.media.id);

          const csvResponse = (await api.mediaItems.getApiMediaCsvIds(
            ids
          )) as unknown as string;

          const asBlob = new Blob([csvResponse]);

          saveAs(asBlob, `${data.options.filename}.csv`);

          updateFile({ id, loaded: total, status: "success" });
        } else {
          const reportItems: ReportItem[] = await Promise.all(
            mediaList.map(({ media, links, user, sharecode, floorplan }) => {
              cts.signal.addEventListener("abort", () => {
                throw new Error("Cancelled");
              });

              const reportItem: ReportItem = {
                description: media.description,
                id: media.id,
                is360: media.is360,
                permalink:
                  data.options.imageQuality === "nano"
                    ? media.nanoLink
                    : getSelectedLinkForExport(
                        links,
                        data.options.imageQuality
                      ),
                floorplan,
                tags: media.tags ?? [],
                thumbnail: getThumbnailForExport(links) ?? media.nanoLink,
                timestamp: new Date(media.timestamp),
                type: media.type === MediaType.PHOTO ? "photo" : "video", // @todo clean this up
                bvLink: `${window.location.origin}/gallery/view/${media.id}/${sharecode}`, // @todo add sharecode?
                location:
                  !!media.latitude && !!media.longitude
                    ? {
                        latitude: media.latitude,
                        longitude: media.longitude,
                      }
                    : undefined,
                teamName: data.options.teamName,
                user: user,
              };
              track({
                name: "Download",
                data: {
                  "Download resolution": data.options.imageQuality,
                  from: "Gallery",
                  inPdf: data.format === "pdf",
                  inPpt: data.format === "ppt",
                  inZip: false,
                  isMine: media.userId === userMe?.id,
                  mapZoomLevel: data.options.mapZoomLevel,
                },
                mediaItemId: media.id,
                siteId: data.options.teamId,
              });

              updateFile({ id, loaded: loaded++ });
              return reportItem;
            })
          );

          /** Generate and download a report */
          const report = new (
            data.format === "ppt"
              ? (await import("@/utils/reporting/ppt/pptReport")).default
              : (await import("@/utils/reporting/pdf/pdfReport")).default
          )(
            reportItems,
            data.options.template,
            data.options.downloadSettings,
            data.options.mapZoomLevel
          );

          const numLandscape = report.pages
            .map(
              (page) =>
                page.images.filter(
                  (image) => (image.res?.width ?? 0) >= (image.res?.height ?? 0)
                ).length
            )
            .reduce((a, b) => a + b, 0);
          const numPortrait = report.pages
            .map(
              (page) =>
                page.images.filter(
                  (image) => (image.res?.width ?? 0) < (image.res?.height ?? 0)
                ).length
            )
            .reduce((a, b) => a + b, 0);

          const amountLeft = total - loaded;
          // Do the actual exports
          const success = await report.download(data.options.filename, (x) =>
            updateFile({
              id,
              loaded: loaded + x * amountLeft,
              status: "in progress",
            })
          );
          if (success) {
            updateFile({ id, status: "success" });
            try {
              track({
                name: "Export media",
                data: {
                  fileName: data.options.filename,
                  numLandscape,
                  numPortrait,
                  numMedia: mediaList.length,
                  numPhotos: mediaList.map(
                    (m) => m.media.type === MediaType.PHOTO
                  ).length,
                  numVideos: mediaList.map(
                    (m) => m.media.type === MediaType.VIDEO
                  ).length,
                  numWithDescription: mediaList.map(
                    (m) => !!m.media.description
                  ).length,
                  numWithLocation: mediaList.map(
                    (m) => m.media.latitude && m.media.longitude
                  ).length,
                  numWithMarkup: mediaList.map((m) => m.media.isMarkedUp)
                    .length,
                  numWithTags: mediaList.map((m) => m.media.tags?.length)
                    .length,
                  type: data.format,
                },
                siteId: data.options.teamId,
              });
            } catch {
              logger.warn("Failed to track export", "Export provider");
            }
          } else
            updateFile({
              id,
              status: "failed",
              error: "Failed to download report",
            });
        }
      } catch (e) {
        if (e instanceof Error && e.message === "Cancelled")
          updateFile({ id, error: "Export cancelled", status: "cancelled" });
        else
          updateFile({
            id,
            error: (e as Error)?.message ?? "Unknown",
            status: "failed",
          });
      }
    },
    [userMe, track]
  );

  const createExport = useCallback(
    (data: ExportData): number => {
      const id = generateUID();
      const updateFile = updateItemFactory(id);
      download(id, data, updateFile);
      return id;
    },
    [download, generateUID, updateItemFactory]
  );

  const [cancelQueue, setCancelQueue] = useState<ExportItem["id"][]>([]);

  useEffect(() => {
    if (cancelQueue.length === 0) return;
    for (const id of cancelQueue) {
      items.find((f) => f.id === id)?.cancelTokenSource?.abort();
    }
    setCancelQueue([]);
  }, [items, cancelQueue]);

  const cancel = useCallback((id: ExportItem["id"]) => {
    setCancelQueue((oldQueue) => [...oldQueue, id]);
  }, []);

  const clear = useCallback(() => {
    setItems((oldItems) => oldItems.filter(exportIsActive));
  }, []);

  const clearSingle = useCallback((id: number) => {
    setItems((oldItems) => {
      // const item = oldItems.find(oldItem=>oldItem.id === id);
      // if (item && exportIsActive(item)) item.cancel();
      return oldItems.filter((oldItem) => oldItem.id !== id);
    });
  }, []);

  // Warn page unload if downloads are in progress
  useEffect(() => {
    if (items.filter(exportIsActive).length > 0)
      window.onbeforeunload = preventNavigating;
    else window.onbeforeunload = () => null;
  }, [items]);

  const exportManager: IExportManager = useMemo(
    () => ({
      createExport,
      cancel,
      clear,
      clearSingle,
    }),
    [createExport, cancel, clear, clearSingle]
  );

  return (
    <ExportContext.Provider value={exportManager}>
      <ExportProgressContext.Provider value={items}>
        {children}
      </ExportProgressContext.Provider>
    </ExportContext.Provider>
  );
}

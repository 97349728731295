import type { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import { teamQueryApi } from "@/data/api/teams";
import queryClient from "@/queryClient";
import { authenticate } from "@/utils/authenticator";

const NewTeamRoleQueryParamsSchema = z.object({
  media: z.coerce.number().optional(),
  site: z.coerce.number().optional(),
});

const NewTeamRoleParamsSchmea = z.object({
  teamId: z.string(),
});

export default async function teamNewRoleLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);
  const structuredSearchParams = Object.fromEntries(searchParams.entries());
  const { media, site } = NewTeamRoleQueryParamsSchema.parse(
    structuredSearchParams,
  );

  const { teamId } = NewTeamRoleParamsSchmea.parse(params);

  const account = await authenticate(request);

  if (!account) {
    return {
      teamId,
      initialMediaPermission: media,
      initialSitePermission: site,
    };
  }

  queryClient.prefetchQuery(teamQueryApi.getTeam(teamId));

  return {
    teamId,
    initialMediaPermission: media,
    initialSitePermission: site,
  };
}

export type TeamNewRoleLoaderReturnType = Awaited<
  ReturnType<typeof teamNewRoleLoader>
>;

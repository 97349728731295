import { useMutation, useQueryClient } from "@tanstack/react-query";

import { projectKeys } from "@/data/api/project";
import { teamQueryApi, teamsKeys } from "@/data/api/teams";

export default function useCreateTeam() {
  const queryClient = useQueryClient();
  return useMutation({
    ...teamQueryApi.createTeam,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: teamsKeys.list(),
      });
      if (data.projectId) {
        queryClient.invalidateQueries({
          queryKey: projectKeys.teams(data.projectId),
        });
      }
    },
  });
}

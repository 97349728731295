import MediaPermissionFlagBits from "./MediaPermissionFlagBits";
import OrganisationPermissionFlagBits from "./OrganisationPermissionFlagBits";
import ProjectPermissionFlagBits from "./ProjectPermissionFlagBits";
import SitePermissionFlagBits from "./SitePermissionFlagBits";

const PermissionFlags = {
  Site: SitePermissionFlagBits,
  Media: MediaPermissionFlagBits,
  Organisation: OrganisationPermissionFlagBits,
  Project: ProjectPermissionFlagBits,
};

export default PermissionFlags;
